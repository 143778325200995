import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Catalogo } from 'src/app/shared/components/procesos/dialog/models/Catalogo';
import { ICarrousel } from 'src/app/shared/components/procesos/dialog/models/ICarrousel';
import { CartaDigital } from 'src/app/shared/components/procesos/gestion-externo-carta/models/CartaDigital';
import { Auditoria, colections, documentsExterno } from 'src/app/shared/cons/db.colections';
import { Utils } from 'src/app/shared/helpers/utils';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
@Injectable({
  providedIn: 'root'
})
export class CartaDaService {

  
  transaccion: TransaccionModel = new TransaccionModel();
  constructor(
    private db: AngularFirestore,
  ) { }

  getCartaExterno(rutaParcial: string) {
    const cartaExternoRef = this.db.collection(colections.EXTERNO, ref =>
      ref.where(documentsExterno.RUTA_PARCIAL, '==', rutaParcial)
        .where(Auditoria.es_borrado, '==', false));
  
    return cartaExternoRef.snapshotChanges().pipe(
      switchMap(cartaSnapshot => {
        if (cartaSnapshot.length === 0) {
          return of(null);
        }
        const cartaDoc = cartaSnapshot[0].payload.doc.data() as CartaDigital;      
        cartaDoc.id = cartaSnapshot[0].payload.doc.id;
        const catalogoRef = this.db.collection(colections.EXTERNO).doc(cartaDoc.id).collection(colections.CARTA_DIGITAL);
  
        return catalogoRef.snapshotChanges().pipe(
          map(catalogosSnaps => {
            const catalogos = catalogosSnaps.map(cata => {
              const catalogoItem = cata.payload.doc.data() as Catalogo;
              catalogoItem.lista_productos.forEach(prod=>{
                if(prod.imagen){
                  prod.lista_imagen_carrousel =[];
                  prod.lista_imagen = Utils.ObjecToListToArray(prod.imagen);
                  prod.lista_imagen.forEach(objImagen=>{
                    const imgCarrusel:ICarrousel={
                      image:objImagen.path_image,
                      title:objImagen.nombre_original
                    }
                    prod.lista_imagen_carrousel!.push(imgCarrusel);
                  })
                }
              });
              catalogoItem.id = cata.payload.doc.id;              
              return catalogoItem;
            });
            return {
              ...cartaDoc,
              lista_catalogo: catalogos
            } as CartaDigital;
          })
        );
      }),
      catchError(error => {
        console.error('Error al combinar observables:', error);
        return of(null);
      })
    );
  }
  
  
  
}
