import { Component, EventEmitter,OnInit, Output } from '@angular/core';
import { Observable, Subscription} from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { catchError, delay, filter, map, switchMap, tap } from 'rxjs/operators';
import { UIService } from '../../services/ui.services';
import { DatosPlataforma, EstadoRegistro, Msjs } from '../../cons/common';
import { CookiesTokensService } from '../../services/cookies.tokens.service';
import { Utils } from '../../helpers/utils';
import { CONFIGURACION } from '../../cons/config';
import { RegistroService } from '../../services/registro.service';
import { AutoUnsubscribe } from "../../../shared/helpers/decorators/AutoUnsubscribe";
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { IMenuItems } from '../../models/menu-items';
import { SnackBarType } from '../../types/snackbar-type';
import { StaticRoutes } from '../../cons/route.class';
import { Router } from '@angular/router';
import { EmpresaBLService } from '../services/services.bl/empresa.bl.service';
import { UINavigateSidenavService } from '../../services/ui.navigate-sidenav.services';
import { Icons } from '../../cons/icons';
import { UsuarioDAService } from '../services/services.da/usuario.da.service';
import { IUsuario } from '../dialog/models/usuario.model';
import { UsuarioColaboradorBlService } from '../services/services.bl/usuarioColaborador.bl.service';
import { CColaborador } from '../procesos/gestion-colaborador/models/ccolaborador.model';
import { AuthSessionService } from '../../auth/auth.session.service';
import { ISession } from '../../auth/interface/ISession';
import { MensajeBlService } from '../gestion-monto/services/mensaje.bl.service';
import { Mensaje } from '../gestion-monto/models/Mensaje';
import { IEmpresa } from '../dialog/models/empresa.model';


@Component({
  selector: 'app-nav-bar-admin',
  templateUrl: './nav-bar-admin.component.html',
  styleUrls: ['./nav-bar-admin.component.scss']
})
@AutoUnsubscribe

export class NavBarAdminComponent implements OnInit {
  es_autenticado:boolean | null =false;
  userData$: Subscription = new Subscription();
  imageAvatar!:string | null;
  imageAvatarEmpresa!:string | null;
  inicialesAvatar!:string | null;
  inicialesAvatarEmp!: string;
  tipoUsuarioAvatar!:string | null;
  @Output() sidenavToggleAdmin = new EventEmitter<void>();
  lRoles_primarcas: any[] = CONFIGURACION.ROLES_PRIMARCAS;
  empresa:any;
  readonly datosEmpresa: typeof DatosPlataforma = DatosPlataforma;
  sbEmpresa!: Subscription;
  estadoRegistroPendiente:boolean=true;
  sbEstadoRegistro!: Subscription;
  menuAvatarUsuario!:IMenuItems[];
  nextStepSb!:Subscription;
  //variables de administracion
  cambiarMenuSuscripcion!:Subscription;
  visibleMenuMobile:boolean=false;
  visibleAvatarSinPago:boolean = true;
  visibleSidenavSinPago:boolean = true;
  reloadSb!: Subscription;
  readonly Icons: typeof Icons = Icons;
  //cambio datos sesion emspresa o administrador
  usuarioSb!:Subscription;
  empresaSb!:Subscription;
  sessionSb!:Subscription;
  empresaSinPagoSb!:Subscription;
  modalSb!:Subscription;




  constructor(
    private authService:AuthService,
    private uiService:UIService,
    private registroService: RegistroService,
    private spinnerPageService: SpinnerPageService,
    private router: Router,
    private empresaBLService: EmpresaBLService,
    private uiNavigateSide: UINavigateSidenavService,
    private usuarioService: UsuarioDAService,
    private usuarioColaboradorBLServices: UsuarioColaboradorBlService,
    private cookiesService: CookiesTokensService,
    private authSessionService: AuthSessionService,
    private uiServices: UIService,
    
  ) {

  }


  ngOnInit(): void {

    //es activado por el webjob los 30 de cada mes: EfectuarCorteServicio()
    this.empresaSinPagoSb = this.empresaBLService.getEmpresaCache().pipe(filter(emp=>emp!=null && emp!= undefined)).subscribe(empresa=>{
      const empresaRes= empresa as IEmpresa;
      this.activarSuspenderCuenta(empresaRes);
    });



    this.authService.getUserIDAsync().then(user=>{
      if(user){
        this.es_autenticado = true;
      }
      else{
        this.es_autenticado = false;
      }
    });

    //thread 3
    this.cargarNavBar();

    //viene del paso previo layout
    this.sbEmpresa = this.registroService.loadEmpresa$.subscribe(() => {
      if(this.registroService.usuario!=null){
        this.cargarNavBar();
      }
    });

    //se ejecuta cuando se recarga la pagina con un f5
    if(this.registroService.usuario==null && this.registroService.estado ==null)
    {
      this.spinnerPageService.show();
      this.authService.getUserIDAsync().then(user=>{
        if(user){
          //recarga el usuario y la empresa y propaga la carga por reloadUsuario$
          this.empresaBLService.getUsuarioAdministradorColaboradorEmpresa(user.uid);
        }else{
          //si no tiene sesion activa se le cierra el app y se manda al index
          this.onLogout();
        }
      });
    }

    //se ejecuta cuando se recarga la pagina con un f5
    //disparado por: this.empresaBLService.getUsuarioAdministradorColaboradorEmpresa(user.uid)
    this.nextStepSb = this.registroService.reloadUsuario$.subscribe(() => {
      if( this.registroService.esSuperAdministrador){
        const menuSidenav = Utils.GetArrayMenu(this.registroService.empresa.lista_modulos_seleccionados);
        //regenera el menu de sidenav
        this.uiNavigateSide.fetchOptionMenuSidenav(menuSidenav);       
      }
      else{
        //regenera el menu de sidenav
        const menuSidenav = Utils.generarMenuSidenav(
          this.registroService.usuario.modulo_rol,
          this.registroService.empresa.lista_modulos_seleccionados
        );

        this.uiNavigateSide.fetchOptionMenuSidenav(menuSidenav);       
      }      
      
      this.cargarNavBar();
      this.recargarAlActualizar();
      this.spinnerPageService.hide();
    });

    //modo registro
    this.sbEstadoRegistro = this.registroService.nextStepsFromNavBar$.subscribe(() => {
      //this.spinnerPageService.show();
      this.determinarEstadoRegistro();
    });

    //viene del menu del avatar usuario, si el usuario suspende la cuenta, la facturación sigue hasta el fin de mes y queda con esa deuda, no se 
    //generan mas recibos hasta que pague la cuenta a pesar de que active la cuenta, igual quedará suspendida por falta de pago
    this.cambiarMenuSuscripcion = this.registroService.cambiarMenuSuscripcion$.subscribe((res) => {
      this.menuAvatarUsuario[1].hidden = !res;//ocultamos la opcion de activar suscripcion
      this.menuAvatarUsuario[2].hidden = res;//mostramos la opcion de activar suscripcion
      if(res){//activar suscripcion
        if (this.uiService.snackAvisoXRef) {
          this.uiService.CloseSnackBarAvisoX();
        }
      }
    });

    /*cuando ya existe inicio sesión*/
    //=======================================================//

    //cuando se actualizan los datos del usuario administrador
    this.usuarioSb = this.usuarioService.usuario$.pipe(
      filter(usuario => usuario !== undefined)).subscribe(usuario => {
      if (usuario && usuario.tiene_empresa==true && usuario.tiene_modulos==true) {
        this.registroService.usuario = usuario as IUsuario;
        this.cookiesService.setUser(usuario);
        this.cargarNavBar();
        this.spinnerPageService.hide();
      }
      else{
        //this.spinnerPageService.show();
      }
    });

    //cuando se actualizan los datos del usuario colaborador
    this.empresaSb = this.usuarioColaboradorBLServices.getUsuarioColaboradorXEmpresa()
    .pipe(filter(colaborador=> colaborador!==undefined)).subscribe(colaborador=>{
      if (colaborador) {
        if(colaborador.es_vigente){
          this.spinnerPageService.hide();
          let usuarioColaborador: CColaborador = { ...colaborador }
          usuarioColaborador.password = '';
          if(colaborador.empresa) this.registroService.empresa = {...colaborador.empresa};
          delete usuarioColaborador.empresa; //porque es muy grande para la cookie, explorar guardar otra cookie para empresa
          this.cookiesService.setUser(usuarioColaborador);
          this.registroService.usuario = {...colaborador};
          this.cargarNavBar();
        }
        else{
          //esto esta controlado a nivel de auth
          this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_CUENTA_SUSPENDIDA_COLABORADOR, 5000);
          this.authService.logout();
        }

      }
    });

    /*1.Para el doble inicio de sesión*/
    this.sessionSb = this.authSessionService.session$.subscribe(session => {
      if (session !== undefined) {
        if(session!== null){
          if(session.id_usuario_cuenta=='fake_user'){
            this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_DOBLE_SESION_CERRAR, 5000);
            this.authService.logout();
          }

          if (session?.token_session !== this.authService.uniqueSession?.token_session) {
            this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_DOBLE_SESION_CERRAR, 5000);
            this.authService.logout();
          }
        }
      }
    });

  }

  activarSuspenderCuenta(empresa: IEmpresa){
    if(empresa.es_sin_pago==true){
      this.visibleSidenavSinPago = false;
      this.visibleAvatarSinPago= false;
      this.modalSb = this.uiServices.showModalAviso({
        title:Msjs.TITULO_SUSPENCION,
        message: Msjs.MENSAJE_CUENTA_SUSPENDIDA_SIN_PAGO,
        icon:Icons.WARNING,
      }).subscribe(()=>{
        //this.uiService.ShowSnackBar(SnackBarType.AVISO,Msjs.TITULO_SUSPENCION_DEUDA);
      });      
    }else{
      if(empresa.es_sin_pago==false){
        this.visibleSidenavSinPago = true;
        this.visibleAvatarSinPago= true;
        this.modalSb?.unsubscribe();
      }
    }
  }

  recargarAlActualizar() {
    //redirige al index siempre en cuando este registrado
    if (this.registroService.usuario.administrador
      && this.registroService.usuario.tiene_empresa
      && this.registroService.usuario.tiene_modulos) {
      this.router.navigate(['/' + StaticRoutes.ADMINISTRADOR + '/' + StaticRoutes.PANEL_ADMINISTRADOR]);
    }
    //cuando no es administrador
    if(!this.registroService.usuario.administrador){
      this.router.navigate(['/' + StaticRoutes.ADMINISTRADOR + '/' + StaticRoutes.PANEL_ADMINISTRADOR]);
    }
    /*Tambien se puede obtener la url del sidenav desde los modulos de la empresa y sus roles*/
  }

  cargarNavBar(){
    if (this.registroService.empresa) {
      this.es_autenticado = true;
      //seteando estado registro;
      this.registroService.comprobarEstadoRegistro();
      this.empresa = this.registroService.empresa;
      if (this.empresa.logo_empresa) {
        if ('1' in this.empresa.logo_empresa) {
          this.imageAvatarEmpresa = Utils.ObjecToListToArray(this.empresa?.logo_empresa)[0].path_image;
        }
        else {
          this.imageAvatarEmpresa = null;
        }
      }
      else {
        this.imageAvatarEmpresa = null;
      }
      //this.imageAvatarEmpresa = this.empresa.logo_empresa == undefined ? null : Utils.ObjecToListToArray(this.empresa?.logo_empresa)[0].path_image;

      this.inicialesAvatarEmp = this.extraerIniciales(this.empresa.nombre_razon);
      this.inicialesAvatar = this.registroService.usuario.nombres.charAt(0) + this.registroService.usuario.apellido_paterno.charAt(0);
      this.tipoUsuarioAvatar = this.registroService.usuario.administrador == true ? this.lRoles_primarcas[0].denominacion : this.lRoles_primarcas[1].denominacion;
      if ('imagen' in this.registroService.usuario) {
        if ('1' in this.registroService.usuario.imagen) {
          this.imageAvatar = Utils.ObjecToListToArray(this.registroService.usuario.imagen)[0].path_image;
        }
        else {
          this.imageAvatar = null;
        }
        //this.imageAvatar = this.registroService.usuario.imagen == undefined ? null : Utils.ObjecToListToArray(this.registroService.usuario.imagen)[0].path_image;
      }
      else {
        this.imageAvatar = null;
      }

      //cuando cambia el estado de la empresa asincronamente en el layoutadmin
      if (this.registroService.estado == EstadoRegistro.REGISTRO_COMPLETO_ADMIN)//administrador
      {
        //para mostrar el boton del sidenav
        this.visibleMenuMobile = true;
        this.menuAvatarUsuario = CONFIGURACION.OPCIONES_AVATAR_USUARIO_ADMINISTRADOR;
        if (!this.registroService.empresa.es_vigente) {
          this.menuAvatarUsuario[1].hidden = true;//ocultamos la opcion de activar suscripcion
          this.menuAvatarUsuario[2].hidden = false;//mostramos la opcion de activar suscripcion
        }
      }
      else{
        this.spinnerPageService.hide();
        this.menuAvatarUsuario = CONFIGURACION.OPCIONES_AVATAR_USUARIO_OPERADOR;
      }

      //mensaje permanente si empresa esta suspendida, para colaborador bloquear todo la plataforma
      if (!this.registroService.empresa.es_vigente ){
        if(this.registroService.estado == EstadoRegistro.REGISTRO_COMPLETO_ADMIN ||
          this.registroService.estado == EstadoRegistro.REGISTRO_COMPLETO_USUARIO){
            this.uiService.ShowSnackBar(SnackBarType.AVISO, Msjs.TITULO_SUSPENCION);
            //quitamos el menu mobile
            this.visibleMenuMobile = false;
        }else{
          if(this.registroService.estado == EstadoRegistro.REGISTRO_COMPLETO_ADMIN ||
            this.registroService.estado == EstadoRegistro.REGISTRO_COMPLETO_USUARIO)
            {
              if (this.uiService.snackAvisoXRef) {
                this.uiService.CloseSnackBarAvisoX();
              }
              //mostramos el menu mobile
              this.visibleMenuMobile = true;
            }
        }

      }
      this.determinarEstadoRegistro();

     
    }
    this.spinnerPageService.hide();

  } 

  determinarEstadoRegistro(){
    switch (this.registroService.estado) {
      case (EstadoRegistro.REGISTRO_USUARIO): {
        this.estadoRegistroPendiente=true;
        this.menuAvatarUsuario=[];
        break;
      }
      case (EstadoRegistro.REGISTRO_EMPRESA): {
        this.estadoRegistroPendiente=true;
        this.menuAvatarUsuario=[];
        break;
      }
      case (EstadoRegistro.REGISTRO_MODULO): {
        this.estadoRegistroPendiente=true;
        this.menuAvatarUsuario=[];
        break;
      }
      case (EstadoRegistro.REGISTRO_COMPLETO_ADMIN): {
        this.estadoRegistroPendiente=false;
        this.menuAvatarUsuario = CONFIGURACION.OPCIONES_AVATAR_USUARIO_ADMINISTRADOR;
        break;
      }
      case (EstadoRegistro.REGISTRO_COMPLETO_USUARIO): {
        this.estadoRegistroPendiente=false;
        this.menuAvatarUsuario = CONFIGURACION.OPCIONES_AVATAR_USUARIO_OPERADOR;
        this.visibleMenuMobile = true;
        break;
      }
    }
    this.spinnerPageService.hide();
  }

  extraerIniciales(cadena:string){
    const words = cadena.split(' ');
    let initials = '';

    for (let i = 0; i < words.length && i < 2; i++) {
      initials += words[i][0];
    }

    return initials;

  }



  // en una recarga de la ventana, se cierra la sesion automaticamente
  //ini activar para confirmar cierre de ventana o pestaña
  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler($event:any) {
  //   $event.preventDefault();
  //   //$event.returnValue = '';
  //   const confirmationMessage = '¿Está seguro que desea cerrar la sesión?';
  //   $event.returnValue = confirmationMessage;
  //   return confirmationMessage;
  // }

  // @HostListener('window:unload', ['$event'])
  // beforeUnloadHander($event:any) {
  //   this.authService.logout();
  // }
  //fin

  onToggleSidenav(){
    this.sidenavToggleAdmin.emit();
  }

  onLogout(){
    //para no mostrar en el index
    if(this.uiService.snackAvisoXRef){
      this.uiService.CloseSnackBarAvisoX();
    }
    if(this.sessionSb){
      this.sessionSb.unsubscribe();
    }
    this.authService.logout();
  }

}
