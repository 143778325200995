import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CEncuesta } from './models/encuesta.model';
import { Utils } from '../../helpers/utils';
import { serverTimestamp } from 'firebase/firestore';
import { colections } from '../../cons/db.colections';
import { IUsuario } from '../dialog/models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class EncuestaDaService {

  constructor(private db: AngularFirestore,) {

   }

   insertEncuesta( idEmpresa:string, usuario:IUsuario, encuesta:CEncuesta){
    const docEncuesta = Utils.SerializeJsonToDb(encuesta);
    docEncuesta.fecha_creacion = serverTimestamp();
    //docEncuesta.usuario_creacion = usuario.correo_electronico;
    docEncuesta.es_vigente = true;
    docEncuesta.es_borrado = false;
    const refEmpresa =  this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const refEncuesta = refEmpresa.collection(colections.ENCUESTA);
    return refEncuesta.add(docEncuesta);
   }
   async getEncuestas(idEmpresa:string){
    let listaFinalEncuestas:CEncuesta[]=[];
    const refEmpresa =  this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const refEncuesta = refEmpresa.collection(colections.ENCUESTA);
    const listaEncuestas = await refEncuesta.get().toPromise();
    if(!listaEncuestas.empty){
      listaEncuestas.forEach(encuesta=>{
        const enc = encuesta.data() as CEncuesta;
        if(enc.codigo_mozo)listaFinalEncuestas.push(enc);
      });
    }
    return listaFinalEncuestas;
   }
}
