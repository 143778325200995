import { Injectable } from '@angular/core';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { VentaCerradaDaService } from './venta-cerrada-da.service';
import { Observable } from 'rxjs';
import { Ventas } from '../../registrar-pedido/models/Ventas';

@Injectable({
  providedIn: 'root'
})
export class VentaCerradaBlService {

  constructor(
    private registroService: RegistroService,
    private ventaCerradaServiceDA: VentaCerradaDaService
  ) { }

  getVentas(idTurno:string):Observable<Ventas[]| null>{
    const idEmpresa = this.registroService.empresa.id;
    return this.ventaCerradaServiceDA.getVentas(idEmpresa, idTurno);
  }

  getVentaFlag(idTurno:string){
    const idEmpresa = this.registroService.empresa.id;
    return this.ventaCerradaServiceDA.getVentaFlag(idEmpresa, idTurno);
  }

  existFetchVentas(listaTurno:string[]){
    const idEmpresa = this.registroService.empresa.id;    
    this.ventaCerradaServiceDA.fetchVentasXIDTurno(idEmpresa,listaTurno);
    
  }

  getVentasXIDTurno():Observable<Ventas[] | null | undefined>{
    return this.ventaCerradaServiceDA.turnoRptVentasTodo$;
  }
 
}
