<div *ngIf="isLoading" fxLayout="column" style="margin-top: 50px;">
  <app-spinner></app-spinner>
</div>
<div *ngIf="!isLoading" class="container-button-table">
  <!-- boton estatico -->
  <div fxLayout="row" fxLayoutGap="10px">
    <div *ngIf="!turno.id" fxHide.xs style="margin-bottom: 10px;">
      <button mat-mini-fab color="primary" class="upload-btn" type="button" (click)="nuevoTurno()"
        matTooltip="Nuevo Turno" matTooltipPosition="before">
        <mat-icon>{{Icons.ADD}}
        </mat-icon>
      </button>
    </div>
    <div *ngIf="turno.id" fxHide.xs style="margin-bottom: 10px;">
      <button mat-mini-fab color="primary" class="upload-btn" type="button" (click)="cerrarTurno()"
        matTooltip="Cerrar turno" matTooltipPosition="before">
        <mat-icon>{{Icons.LOCK}}
        </mat-icon>
      </button>
    </div>
  </div>
  <!-- boton flotante     -->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div *ngIf="!turno.id" class="floating-button" fxShow.xs fxHide>
      <button mat-fab color="primary" type="button" (click)="nuevoTurno()" matTooltip="Nuevo Turno"
        matTooltipPosition="after">
        <mat-icon>{{Icons.ADD}}
        </mat-icon>
      </button>
    </div>
    <div *ngIf="turno.id" [ngClass]="turno.id? 'floating-button-mini3':'floating-button-mini'" fxShow.xs fxHide>
      <button mat-mini-fab color="primary" type="button" (click)="cerrarTurno()" matTooltip="Cerrar Turno"
        matTooltipPosition="after">
        <mat-icon>{{Icons.LOCK}}
        </mat-icon>
      </button>
    </div>
  </div>
</div>
<div *ngIf="!isLoading">
  <div *ngIf="!turno.id" style="margin-left: 10px; margin-top: 10px;">
    <h4>No se aperturó un turno.</h4>
  </div>
  <div *ngIf="turno.id" fxLayout="row" fxLayoutAlign="center center" style="margin-top: 20px;">
    <mat-card fxFlex="98%" fxFlex.xs="100%">
      <mat-card-header>
        <mat-card-title>Turno</mat-card-title>
        <mat-card-subtitle>
          <div fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column" fxLayoutGap.xs="5px">
            <div fxLayout="row" matTooltip="Fecha de registro">
              <mat-icon class="mat-18">{{Icons.CALENDAR_MONTH}}</mat-icon>
              <h3>
                {{turno.fecha_registro}}
              </h3>
            </div>
            <div fxLayout="row" matTooltip="Usuario de creación">
              <mat-icon class="mat-18">{{Icons.MANAGE_ACCOUNTS}}</mat-icon>
              <h3>
                {{turno.usuario_creacion | hideMail}}
              </h3>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
              <div fxLayout="row" fxLayout.xs="column" >
                <div fxLayout="row">
                  <mat-icon class="mat-18">{{Icons.SAVINGS}}</mat-icon>
                  <h3 matTooltip="Monto inicial en efectivo">S/ {{turno.monto_inicial_efectivo | number:'1.2' }} &nbsp;</h3>
                </div>
                <div fxLayout="row wrap">
                  <h3 matTooltip="Total Efectivo Añadido" >(+)S/ {{totalEfectivoAnadido | number:'1.2' }} &nbsp; </h3>
                  <h3 matTooltip="Total Efectivo Retirado" >(-)S/ {{totalEfectivoRetirado | number:'1.2' }} &nbsp;</h3>                  
                </div>
                <h3 matTooltip="No Incluye Ventas" style="font-weight: bold;" > = S/ {{totalEfectivoDisponibleCaja | number:'1.2' }}</h3>                
              </div>
              <div>
                <button *ngIf="!esVigenteSolicitud && !tieneSolicitudPendiente" style="margin-top: -10px;" mat-icon-button matTooltip="Modificar efectivo inicial" (click)="modificarEfectivoInicial()">
                  <mat-icon>{{Icons.EDIT}}</mat-icon>
                </button>
              </div>
              <div *ngIf="turno.correccion_monto_inicial">
                <button style="margin-top: -10px;"  mat-icon-button matTooltip="Historial" (click)="mostrarHistorial()">
                  <mat-icon>{{Icons.EVENT_NOTE}}</mat-icon>
                </button>
              </div>
              <div *ngIf="rolAdministrador && esVigenteSolicitud && tipoProcesoPendiente===ProcesoType.CAMBIAR_MONTO_INICIAL">
                <button [@blink]="blinkState" color="primary" style="margin-top: -10px;"  mat-icon-button [matTooltip]="estadoMontoInicial | uppercase" (click)="procesarSolicitud(SistOperation.PROCESAR_SOLICITUD)">
                  <mat-icon>{{Icons.LIVE_HELP}}</mat-icon>
                </button>
              </div>
              <div *ngIf="esVigenteSolicitud && !rolAdministrador && tipoProcesoPendiente===ProcesoType.CAMBIAR_MONTO_INICIAL">
                <mat-chip-list matTooltip="Estado de solicitud" >
                  <mat-chip [@blink]="blinkState" selected  [ngClass]="{
                    'chip-activo': estadoMontoInicial===SolicitarCambioType.APROBADO,
                    'chip-solicitado': estadoMontoInicial===SolicitarCambioType.SOLICITADO,
                    'chip-rechazado': estadoMontoInicial===SolicitarCambioType.RECHAZADO}" >
                    {{estadoMontoInicial | toUpper }}
                  </mat-chip>
              </mat-chip-list>
              </div>

            </div>

          </div>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div fxLayout="row" fxLayout.xs="column" style="margin-top: 20px;" fxLayoutGap.xs="20px" >
          <div fxFlex="50%" fxLayout="column" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutGap="10px">
              <div class="variacion-efectivo">
                <h3>Efectivo Añadido (+ S/{{totalEfectivoAnadido | number:'1.2'}})</h3>
              </div>
              <button *ngIf="!tieneSolicitudPendiente" mat-icon-button color="primary" matTooltip="Añadir Efectivo" (click)="aniadirEfectivo()">
                <mat-icon>{{Icons.ADD}}</mat-icon>
              </button>
              <button *ngIf="turno.montos_aniadidos" style="margin-top: -10px;"  mat-icon-button matTooltip="Historial" (click)="mostrarHistorialAnadir()">
                <mat-icon>{{Icons.EVENT_NOTE}}</mat-icon>
              </button>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
              <!-- monto anadido -->
              <div *ngIf="turno.montos_aniadidos">
                <ng-container *ngFor="let item of turno.montos_aniadidos" >
                  <div *ngIf="item.es_vigente"  fxLayout="column">
                    <div matTooltip="Usuario">
                      <mat-icon class="mat-18">{{Icons.MANAGE_ACCOUNTS}}</mat-icon>
                      &nbsp;&nbsp;{{item.usuario_creacion | hideMail}}
                    </div>
                    <div fxLayout="row">
                      <div matTooltip="Fecha">
                        <mat-icon class="mat-18">{{Icons.CALENDAR_MONTH}}</mat-icon>
                        &nbsp;&nbsp;
                        {{item.fecha_key.anio}}/
                        {{item.fecha_key.mes}}/
                        {{item.fecha_key.dia}}&nbsp;&nbsp;
                        {{item.fecha_key.hora}}:
                        {{item.fecha_key.minuto}}:
                        {{item.fecha_key.segundo}}
                      </div>
                    </div>
                    <div fxLayout="row">
                      <mat-icon class="mat-18">{{Icons.PAYMENTS}}</mat-icon>
                      &nbsp;&nbsp;&nbsp;
                      <div matTooltip="Añadido">{{item.monto}} (+)</div>
                    </div>
                    <div style="padding-top: 5px;" fxLayout="row" >
                      <mat-chip-list matTooltip="Estado" >
                          <mat-chip selected  [ngClass]="{
                            'chip-activo': item.estado_cambio===SolicitarCambioType.APROBADO,
                            'chip-solicitado': item.estado_cambio===SolicitarCambioType.SOLICITADO,
                            'chip-rechazado': item.estado_cambio===SolicitarCambioType.RECHAZADO
                        }" >
                            {{item.estado_cambio | toUpper }}
                          </mat-chip>
                      </mat-chip-list>
                      <div
                      style="margin-top: 5px;"
                      *ngIf="rolAdministrador &&
                      item.estado_cambio!==SolicitarCambioType.APROBADO &&
                      tipoProcesoPendiente ===ProcesoType.CAMBIAR_ANADIR_MONTO"
                      fxLayout="row">
                        <div matTooltip="Atender">
                          <button [@blink]="blinkState" color="primary" style="margin-top: -10px;"  mat-icon-button (click)="procesarSolicitud(SistOperation.PROCESAR_AGREGAR)">
                            <mat-icon>{{Icons.LIVE_HELP}}</mat-icon>
                          </button>
                        </div>
                      </div>

                      <div style="margin-top: 5px;" *ngIf="item.estado_cambio===SolicitarCambioType.APROBADO"
                      fxLayout="row">
                        <div matTooltip="Imprimir">
                          <button class="impresora" style="margin-top: -10px;"  mat-icon-button (click)="imprimir(item)">
                            <mat-icon>{{Icons.PRINT}}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- imprimir comprobante -->
                  <div class="imprimir"
                  [id]="item.fecha_key.anio+
                  item.fecha_key.mes+
                  item.fecha_key.dia+
                  item.fecha_key.hora+
                  item.fecha_key.minuto+
                  item.fecha_key.segundo"
                  *ngIf="item.estado_cambio===SolicitarCambioType.APROBADO">
                    <div class="ticket">
                      <div class="logo">
                        <img *ngIf="logoEmpresa"
                        [src]="logoEmpresa"
                        alt="Logotipo"
                        height="80px">
                      </div>
                      <section class="centrado">
                        <div class="texto" >
                          <h3>{{empresa.nombre_razon}}</h3>
                        </div>
                        <div>RUC {{empresa.ruc}}</div>
                        <div class="titulo-ticket">
                          <h2>TICKET EFECTIVO AÑADIDO</h2>
                        </div>
                        Fecha Aprobación<br>{{item.fecha_key.anio}}/
                        {{item.fecha_key.mes}}/
                        {{item.fecha_key.dia}}&nbsp;&nbsp;
                        {{item.fecha_key.hora}}:
                        {{item.fecha_key.minuto}}:
                        {{item.fecha_key.segundo}}<br>
                        <div class="usuarios" >
                          <div class="subtitle">SOLICITADO: {{item.usuario_creacion | hideMail }}</div>
                          <div class="subtitle">APROBADO: {{item.usuario_aprobacion | hideMail }}</div>
                          <div class="monto">
                            Monto Añadido: S/ {{item.monto | number:'1.2'}} (+)
                          </div>
                        </div>
                        <div class="centrado">
                          <div class="contenedor-firma"  >
                            <table class="table">
                              <tr>
                                <th class="col-left"></th>
                                <th class="col-center">
                                  <div class="firma" >
                                  </div>
                                  <div class="texto-firma" >
                                    Firma
                                  </div>

                                </th>
                                <th class="col-right"></th>
                              </tr>
                            </table>
                          </div>

                        </div>
                        <div class="comentario">Comentarios:</div>
                        <div class="comentario"></div>
                        <div class="footer-imprimir" >
                          Impreso por: {{usuario.correo_electronico |hideMail}}
                        </div>
                      </section>
                  </div>

                  </div>
                </ng-container>

              </div>
            </div>
          </div>
          <div fxShow.xs fxHide.gt-xs><mat-divider></mat-divider></div>
          <div fxFlex="50%" fxLayout="column" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutGap="10px">
              <div class="variacion-efectivo">
                <h3>Efectivo Retirado (- S/{{totalEfectivoRetirado | number:'1.2'}})</h3>
              </div>
              <button *ngIf="!tieneSolicitudPendiente" mat-icon-button color="primary" matTooltip="Retirar efectivo" (click)="retirarEfectivo()">
                <mat-icon>{{Icons.ADD}}</mat-icon>
              </button>
              <button *ngIf="turno.montos_retirados" style="margin-top: -10px;"  mat-icon-button matTooltip="Historial" (click)="mostrarHistorialRetirar()">
                <mat-icon>{{Icons.EVENT_NOTE}}</mat-icon>
              </button>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
              <!-- monto anadido -->
              <div *ngIf="turno.montos_retirados">
                <ng-container *ngFor="let item of turno.montos_retirados" >
                  <div *ngIf="item.es_vigente"  fxLayout="column">
                    <div matTooltip="Usuario">
                      <mat-icon class="mat-18">{{Icons.MANAGE_ACCOUNTS}}</mat-icon>
                      &nbsp;&nbsp;{{item.usuario_creacion | hideMail}}
                    </div>
                    <div fxLayout="row">
                      <div matTooltip="Fecha">
                        <mat-icon class="mat-18">{{Icons.CALENDAR_MONTH}}</mat-icon>
                        &nbsp;&nbsp;
                        {{item.fecha_key.anio}}/
                        {{item.fecha_key.mes}}/
                        {{item.fecha_key.dia}}&nbsp;&nbsp;
                        {{item.fecha_key.hora}}:
                        {{item.fecha_key.minuto}}:
                        {{item.fecha_key.segundo}}
                      </div>
                    </div>
                    <div fxLayout="row">
                      <mat-icon class="mat-18">{{Icons.PAYMENTS}}</mat-icon>
                      &nbsp;&nbsp;&nbsp;
                      <div matTooltip="Retirado">{{item.monto}} (-)</div>
                    </div>
                    <div style="padding-top: 5px;" fxLayout="row" >
                      <mat-chip-list matTooltip="Estado" >
                          <mat-chip selected  [ngClass]="{
                            'chip-activo': item.estado_cambio===SolicitarCambioType.APROBADO,
                            'chip-solicitado': item.estado_cambio===SolicitarCambioType.SOLICITADO,
                            'chip-rechazado': item.estado_cambio===SolicitarCambioType.RECHAZADO
                        }" >
                            {{item.estado_cambio | toUpper }}
                          </mat-chip>
                      </mat-chip-list>
                      <div style="margin-top: 5px;" *ngIf="rolAdministrador &&
                      item.estado_cambio!==SolicitarCambioType.APROBADO &&
                      tipoProcesoPendiente===ProcesoType.CAMBIAR_RETIRAR_MONTO"
                      fxLayout="row">
                        <div matTooltip="Atender">
                          <button [@blink]="blinkState" color="primary" style="margin-top: -10px;"  mat-icon-button (click)="procesarSolicitud(SistOperation.PROCESAR_RETIRAR)">
                            <mat-icon>{{Icons.LIVE_HELP}}</mat-icon>
                          </button>
                        </div>
                      </div>

                      <div style="margin-top: 5px;" *ngIf="item.estado_cambio===SolicitarCambioType.APROBADO" fxLayout="row">
                        <div matTooltip="Imprimir">
                          <button class="impresora" style="margin-top: -10px;"  mat-icon-button (click)="imprimir(item)">
                            <mat-icon>{{Icons.PRINT}}</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- imprimir comprobante -->
                  <div class="imprimir"
                  [id]="item.fecha_key.anio+
                  item.fecha_key.mes+
                  item.fecha_key.dia+
                  item.fecha_key.hora+
                  item.fecha_key.minuto+
                  item.fecha_key.segundo
                  "
                  *ngIf="item.estado_cambio===SolicitarCambioType.APROBADO"
                  >
                    <div class="ticket">
                      <div class="logo">
                        <img *ngIf="logoEmpresa"
                        [src]="logoEmpresa"
                        alt="Logotipo"
                        height="80px">
                      </div>
                      <section class="centrado">
                        <div class="texto" >
                          <h3>{{empresa.nombre_razon}}</h3>
                        </div>
                        <div>RUC {{empresa.ruc}}</div>
                        <div class="titulo-ticket">
                          <h2>TICKET EFECTIVO RETIRADO</h2>
                        </div>
                        Fecha Aprobación<br>{{item.fecha_key.anio}}/
                        {{item.fecha_key.mes}}/
                        {{item.fecha_key.dia}}&nbsp;&nbsp;
                        {{item.fecha_key.hora}}:
                        {{item.fecha_key.minuto}}:
                        {{item.fecha_key.segundo}}<br>
                        <div class="usuarios" >
                          <div class="subtitle">SOLICITADO: {{item.usuario_creacion | hideMail }}</div>
                          <div class="subtitle">APROBADO: {{item.usuario_aprobacion | hideMail }}</div>
                          <div class="monto">
                            Monto Retirado: S/ {{item.monto | number:'1.2'}} (-)
                          </div>
                        </div>
                        <div class="centrado">
                          <div class="contenedor-firma"  >
                            <table class="table">
                              <tr>
                                <th class="col-left"></th>
                                <th class="col-center">
                                  <div class="firma" >
                                  </div>
                                  <div class="texto-firma" >
                                    Firma
                                  </div>

                                </th>
                                <th class="col-right"></th>
                              </tr>
                            </table>
                          </div>

                        </div>
                        <div class="comentario">Comentarios:</div>
                        <div class="comentario"></div>
                        <div class="footer-imprimir" >
                          Impreso por: {{usuario.correo_electronico |hideMail}}
                        </div>
                      </section>
                  </div>

                  </div>
                </ng-container>

              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
