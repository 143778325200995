import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { Ventas } from '../../registrar-pedido/models/Ventas';
import { Auditoria, colections, documentReporteVentas } from 'src/app/shared/cons/db.colections';
import { map } from 'rxjs/operators';
import { Utils } from 'src/app/shared/helpers/utils';


@Injectable({
  providedIn: 'root'
})
export class VentaCerradaDaService {
  transaccion: TransaccionModel = new TransaccionModel();

  private turnoRptCacheVentasTodo: BehaviorSubject<Ventas[] | undefined | null> = new BehaviorSubject<Ventas[] | undefined |null>(undefined);
  public turnoRptVentasTodo$: Observable<Ventas[] | undefined |null> = this.turnoRptCacheVentasTodo.asObservable();
  private fetchRptVentasTodoSb!: Subscription;
  
  constructor(private db: AngularFirestore) { }

  fetchVentasXIDTurno(idEmpresa:string,listaTurno:string[]): void {
    if(this.fetchRptVentasTodoSb && !this.fetchRptVentasTodoSb.closed){
      this.turnoRptCacheVentasTodo.next(this.turnoRptCacheVentasTodo.value);
      return;
    }
    this.fetchRptVentasTodoSb = this.getVentasCerradasXIDTurno(idEmpresa, listaTurno).subscribe(turno => {
      this.turnoRptCacheVentasTodo.next(turno);
    });
  }
  stopFetchingVentasXIDTurno(): void {
    if (this.fetchRptVentasTodoSb) {
      this.fetchRptVentasTodoSb.unsubscribe();
    }
    this.turnoRptCacheVentasTodo.next(undefined);
  }

  async getVentasHistorico(idEmpresa: string, listaTurno:string[]) {
    let listaVentas:Ventas[]=[];
    if(listaTurno.length==0){
      return null;
    }    
    const documentos = await this.db.collection(colections.EMPRESA, ref =>
      ref.where(Auditoria.es_borrado, '==', false))
      .doc(idEmpresa).collection(colections.REPORTE_VENTAS, ref1 =>
      ref1.where(Auditoria.es_vigente, '==', true)
      .where(documentReporteVentas.ID_TURNO,'in',listaTurno))
      .get().toPromise();
      if(!documentos.empty){
        documentos.docs.forEach(rep=>{
            const data = rep.data() as Ventas;
            data.id = rep.id;
            listaVentas.push(data);
        });
        return listaVentas;
      }else{
        return null;
      }
  }

    
  getVentas(idEmpresa: string, idTurno:string) {
    if(idTurno===undefined ||  idTurno===''){
      return of(null);
    }    
    return this.db.collection(colections.EMPRESA, ref =>
      ref.where(Auditoria.es_borrado, '==', false))
      .doc(idEmpresa).collection(colections.REPORTE_VENTAS, ref1 =>
      ref1.where(Auditoria.es_vigente, '==', true)
      .where(documentReporteVentas.ID_TURNO,'==',idTurno))
      .snapshotChanges()
      .pipe(
        map(listaVentas => {
          if (listaVentas.length > 0) {
            // Reinicia el array dentro del pipe para asegurarte de que esté vacío en cada actualización
            let docsListaVentas: Ventas[] = [];
            listaVentas.forEach(venta => {
              const vta = Utils.convertDate( venta.payload.doc.data()) as Ventas;
              vta.id = venta.payload.doc.id;
              docsListaVentas.push(vta);
            });
          
            return docsListaVentas;
          } else {
          
            return null;
          }
        })
    );
  }
  getVentasCerradasXIDTurno(idEmpresa: string, listaIdTurno:string[]){
    return this.db.collection(colections.EMPRESA, ref =>
      ref.where(Auditoria.es_borrado, '==', false))
      .doc(idEmpresa).collection(colections.REPORTE_VENTAS, ref1 =>
      ref1.where(Auditoria.es_vigente, '==', true)
      .where(documentReporteVentas.ID_TURNO,'in',listaIdTurno)
      .orderBy(documentReporteVentas.FECHA_CREACION,'desc'))      
      .snapshotChanges()
      .pipe(
        map(listaVentas => {
          if (listaVentas.length > 0) {
            // Reinicia el array dentro del pipe para asegurarte de que esté vacío en cada actualización
            let docsListaVentas: Ventas[] = [];
            listaVentas.forEach(venta => {
              const vta = Utils.convertDate( venta.payload.doc.data()) as Ventas;
              vta.id = venta.payload.doc.id;
              docsListaVentas.push(vta);
            });
          
            return docsListaVentas;
          } else {
          
            return null;
          }
        })
    );
  }

  getVentaFlag(idEmpresa:string, idTurno:string){
    if(idTurno===undefined ||  idTurno===''){
      return of(false);
    }
    return this.db.collection(colections.EMPRESA, ref =>
      ref.where(Auditoria.es_borrado, '==', false))
      .doc(idEmpresa).collection(colections.REPORTE_VENTAS, ref1 =>
      ref1.where(Auditoria.es_vigente, '==', true)
      .where(documentReporteVentas.ID_TURNO,'==',idTurno)
      .limit(1))     
      .snapshotChanges()
      .pipe(
        map(listaVentas => {
          if (listaVentas.length > 0) {
            
            return true;
          } else {
            return false;
          }
        })
      );
  }


}
