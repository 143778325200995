import { Component, OnInit } from '@angular/core';
import { Msjs, Msjs_Validations, TipoReporte } from '../../cons/common';
import { RegistroService } from '../../services/registro.service';
import { FunctionsService } from '../../services/functions.service';
import { Fecha } from '../gestion-monto/models/Fecha';
import * as moment from "moment";
import { Utils } from '../../helpers/utils';
import { GenerarReporteService } from '../../services/generar-reporte.service';
import { UIService } from '../../services/ui.services';
import { SnackBarType } from '../../types/snackbar-type';
import { Subscription } from 'rxjs';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { GestionMontoBlService } from '../gestion-monto/services/gestion-monto.bl.service';

@Component({
  selector: 'app-gestion-rpt-historico',
  templateUrl: './gestion-rpt-historico.component.html',
  styleUrls: ['./gestion-rpt-historico.component.scss']
})
export class GestionRptHistoricoComponent implements OnInit {
  tipoReporte:number=-1;
  descripcionReportes:any[]=[];
  maxDateInicial = new Date();
  minDateInicial = new Date();
  maxDateFinal = new Date();
  minDateFinal = new Date();
  fechaInicio:Date |null=null;
  fechaFinal:Date | null=null;
  fechaHoy: Fecha = new Fecha();
  fechaAyerIso!:Date;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  disabled_controls_inicial:boolean =false;
  isLoading:boolean = true;
  readonly TipoReporte: typeof TipoReporte = TipoReporte;
  procesadoRptSb!: Subscription;
  
  constructor(
    private registroService: RegistroService,
    private functionService: FunctionsService,
    private generarReporte: GenerarReporteService,
    private uiService: UIService,
    private readonly spinnerPageService: SpinnerPageService,
   
  ) { }

  async ngOnInit(): Promise<void> {
    this.TipoReporte.TipoReporte.forEach(rep=>{
      this.descripcionReportes.push(rep);
    });    

    if(this.registroService.empresa){      

      await this.cargarFechas();
      this.isLoading = false;      
      let fechaRegistro = this.registroService.empresa.inicio_operacion;
      fechaRegistro = new Date(fechaRegistro);
      this.minDateInicial.setFullYear(fechaRegistro.getFullYear());   
      this.maxDateInicial = this.fechaAyerIso;
      this.minDateInicial = fechaRegistro; 
      this.maxDateFinal = this.fechaAyerIso;
      this.minDateFinal = fechaRegistro;      
    }

    this.procesadoRptSb = this.generarReporte.procesadoRpt.subscribe(flag=>{
      if(flag){
        this.spinnerPageService.show();
      }else{
        this.spinnerPageService.hide();
        if(this.generarReporte.noResultados){
          this.uiService.ShowSnackBar(
            SnackBarType.ERROR,
            Msjs.MSJ_NO_VENTA_CERRADA,
            3000
          );
        }
        
      }
    });
  }

  async cargarFechas() {
    const fechaServer = moment(await this.functionService.GetDateServer()).local().format();
    const fechaAyerMoment = moment(fechaServer).subtract(1, 'day');
    this.fechaAyerIso = fechaAyerMoment.toDate();      
    const fechaServerProcesado = Utils.separarFechasHoras(fechaServer);
    this.fechaHoy.anio = fechaServerProcesado[0]; //anio
    this.fechaHoy.mes = fechaServerProcesado[1]; //mes
    this.fechaHoy.dia = fechaServerProcesado[2]; //dia
    this.fechaHoy.hora = fechaServerProcesado[3]; //hora
    this.fechaHoy.minuto = fechaServerProcesado[4]; //minuto
    this.fechaHoy.segundo = fechaServerProcesado[5]; //seg
  }

  selectReporte(){    
    if(this.fechaInicio!=null && this.fechaFinal!=null){
      if(this.tipoReporte==-1){
        this.uiService.ShowSnackBar( SnackBarType.ERROR, Msjs.MSJ_NO_SELECCIONO_REPORTE, 3000);
        return;
      }
      this.generarReporte.selectReporte(this.tipoReporte, null,this.fechaInicio, this.fechaFinal);
    }else{
      this.uiService.ShowSnackBar( SnackBarType.ERROR, Msjs.MSJ_NO_SELECCIONO_FECHAS, 3000);
    }  
  }

}
