<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title fxLayout="row" class="dialog-title">
    Registrarme
    <div class="title-step">
      ({{etapa}} de 3)
    </div>
  </div>
  <app-button type="icon" color="gray" [icon_fig]="CLOSE" (click)="onClose()" ></app-button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <section>
    <form fxLayout="column" #f="ngForm" (ngSubmit)="onSubmit(f)" fxLayoutGap="10px" >
      <mat-form-field [hintLabel]="Msjs_Validations.MSJ_VAL_REQ6_DIGITOS_CODIGO">
        <mat-label>
          <span >Ingresa el código enviado al correo solicitado</span>
        </mat-label>
        <input
        class="input-captcha"
        type="text"
        matInput
        placeholder="000000"
        ngModel
        name="codigoInput"
        required
        minlength="6"
        maxlength="6"
        pattern="[0-9]*"
        #codigoInput="ngModel"
        (keypress) ="onlyNumbers($event)"
        >
        <mat-hint align="end" >{{codigoInput.value?.length}} / 6</mat-hint>
        <mat-error *ngIf="codigoInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        <mat-error *ngIf="codigoInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
        <mat-error *ngIf="codigoInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_MIN6_DIGITOS}}</mat-error>
        <mat-error *ngIf="codigoInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_MAX6_DIGITOS}}</mat-error>
      </mat-form-field>
      <mat-divider class="mat-divider-content" ></mat-divider>
      <h5>Esto es una comprobación para ver que eres una persona.</h5>
      <div class="actions-dialog" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px" fxFlex>
        <app-button [width]="100" text="Continuar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="f.invalid" [attr_type]="'submit'" ></app-button>
        <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray" (click)="onClose()"></app-button>
        <app-button fxShow.xs fxHide  type="outline" color="gray-b0" [icon_fig]="CANCEL" (click)="onClose()" ></app-button>
      </div>
      <h5 *ngIf="disabled_counter" >{{btnText}}</h5>
      <div *ngIf="!disabled_counter"  fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <app-button [text]="btnText" type="basic" (click)="onSendSMS()" ></app-button>

      </div>
    </form>
  </section>
</mat-dialog-content>
