<div style="margin-top: 10px; margin-left: 10px;" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign.xs="center center" fxLayoutAlign="start center">
    <mat-form-field style="width: 250px;">
        <mat-label>Tipo de Reporte</mat-label>
        <mat-select [(ngModel)]="tipoReporte">
            <ng-container *ngFor="let reporte of descripcionReportes" >
                <mat-option [value]="reporte.codigo" >{{reporte.titulo}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
    <app-button [width]="100" text="Generar"[attr_type]="'button'" (click)="selectReporte()" ></app-button>
</div>
<div fxLayout="row" fxLayoutAlign="center center" *ngIf="tipoReporte!=-1">
    <mat-card style="min-width: 95%;">
        <mat-card-title>{{descripcionReportes[tipoReporte-1].titulo}}</mat-card-title>
        <mat-card-subtitle>{{descripcionReportes[tipoReporte-1].descripcion}}</mat-card-subtitle>
    </mat-card>    
</div>
