import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { IProductoAnidado } from "./IProductoAnidado";
export class Producto implements AuditoriaSet{
  nombre_producto: string='';
  auto_numerico!:number;
  codigo_producto: string='';
  cantidad_stock?: number;
  tipo_producto: string='';
  precio_unitario: number=0;
  stock_ilimitado: boolean=true;
  tiene_subproductos: boolean=false;
  tiene_caracteristicas:boolean =false;
  cantidad_caracteristicas_seleccionables?:number;
  lista_caracteristicas?:string[];
  descripcion_producto: string='';
  imagen?:string[];
  id?: string;
  es_borrado: boolean=false;
  es_vigente: boolean=false;
  usuario_creacion: any;
  usuario_modificacion?: any;
  fecha_creacion?: any;
  fecha_modificacion?: any;
  nombres_completo:string[] =[];
  subproductos?: IProductoAnidado[];
  id_productos_anidados_completo?:string[];

  //propiedades utilitarias
  estado?:string;
  fecha_registro?:string
  masElementos?: boolean
  totalRegistros_query?:number
  previousPage?: boolean;
  nextPage?:boolean;
  numeracion?:number;
  totalRegistrosActivos?:number;
  totalRegistrosInactivos?:number;
  totalRegistros_doc?:number;
  stock_ilimitado_label?:string;
  tiene_subproductos_label?:string;
  cantidad_imagenes?:number;
  lista_imagen?:any[]
  total_stock?:string;
  ref?:any 

}
