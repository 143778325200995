import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestion-rpt-inventarios',
  templateUrl: './gestion-rpt-inventarios.component.html',
  styleUrls: ['./gestion-rpt-inventarios.component.scss']
})
export class GestionRptInventariosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
