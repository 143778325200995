import { serverTimestamp, Timestamp } from '@angular/fire/firestore';
import { ListaTipoProducto, TipoArchivoFormato } from '../cons/common';
import { IImageUploaded } from '../components/upload/IImageUploaded';

import { ItemSidenav, IRolAcceso } from '../models/item.model';
import { CModuloRolSeleccionado } from '../components/procesos/gestion-colaborador/models/modulo-rol-seleccionado';
import { v4 as uuidv4 } from 'uuid';
import { IAmbienteMesa } from '../components/procesos/gestion-ambientes-mesas/models/IAmbienteMesa';
import { EstadosMesaType } from '../types/estado-mesa-type';
import { Fecha } from '../components/gestion-monto/models/Fecha';
import { AccionMesaType } from '../types/accion-mesa-type';
import { IProductoTicket } from '../components/registrar-pedido/models/IProductoTicket';
import { EstadosProductoType } from '../types/estado-producto-type';
import { EstadosPedidoType } from '../types/estado-pedido-type';
import { ICaracteristicaProducto } from '../components/registrar-pedido/models/CaracteristicaProducto';
import * as CryptoJS from 'crypto-js';

export class Utils {
  public static convertDate(firebaseObject: any) {
    if (!firebaseObject) return null;
    for (const [key, value] of Object.entries(firebaseObject)) {

      // covert items inside array
      //if (value && Array.isArray(value) )
      //firebaseObject[key] = value.map(item => self.toDate(item));

      // convert inner objects
      if (value && typeof value === 'object') {
        if ('seconds' in value) {
          firebaseObject[key] = (value as Timestamp).toDate();
        }
      }

      // convert simple properties
      //if (value && value.hasOwnProperty('seconds'))
      //firebaseObject[key] = (value as Timestamp).toDate();
    }
    return firebaseObject;
  }

  public static SerializeJsonToDb(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  public static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public static CadenaToArray(inputString: string): string[] {
    return inputString.split(/\s+/).filter(word => word.length > 0);
  }

  public static ToDateISO(fecha: string, retornaHora: boolean) {
    const date = new Date(fecha);
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    if (retornaHora) {
      const format = 'dd/MM/yyyy HH:mm:ss';
      return format.replace('yyyy', year).replace('MM', month).replace('dd', day).replace('HH', hours).replace('mm', minutes).replace('ss', seconds);
    } else {
      const format = 'dd/MM/yyyy';
      return format.replace('yyyy', year).replace('MM', month).replace('dd', day).replace('HH', hours).replace('mm', minutes).replace('ss', seconds);
    }

  }

  public static ComprobarExtensionArchivo(formatoArchivo: string, requiredFileType: string) {
    let flag: boolean = false;
    let formato_corto: string = '';
    let formato: string = '';
    for(const tipo of TipoArchivoFormato.tipoArchivoFormato){
      if (tipo.tipo == requiredFileType) {
        flag = true;
        formato = tipo.tipos_archivos.filter(item => item == formatoArchivo)[0];
        if(formato===undefined){
          flag=false;
          break;
        }
        else{
          formato = formato.substring(formato.indexOf("/") + 1);
        }
      }
    }
    return [flag, formato_corto, formato];
  }

  public static ExtraerExtension(file: File) {
    let ext = file.type.substring(file.type.indexOf("/") + 1);
    return ext;
  }

  public static InputOnlyNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  public static InputOnlyHorasMinutos24H(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode
    if ((charCode >= 48 && charCode <= 57) || charCode === 58) {
      return true;
    }
    return false;
  }

  public static ArrayToObjectToObjects(urls: any[]) {
    var objImagenes: { [k: string]: unknown } = {};
    var contador = 1;

    urls.forEach(url => {
      var objImagen: { [k: string]: unknown } = {};
      objImagen.path_image = urls[contador - 1].path_image;
      //metadatos
      objImagen.nombre_original = urls[contador - 1].nombre_original;
      objImagen.nombre_plataforma = urls[contador - 1].nombre_plataforma;
      objImagen.path_api = urls[contador - 1].path_api;
      objImagen.tamanioKBs = urls[contador - 1].tamanioKBs;
      objImagen.extension = urls[contador - 1].extension;
      objImagenes[contador.toString()] = objImagen;
      contador += 1
    });
    return objImagenes;
  }

  public static ArrayToObjectToObjectsRol(urls: any[]) {
    var objRoles: { [k: string]: unknown } = {};
    var contador = 1;

    urls.forEach(url => {
      var objRol: { [k: string]: unknown } = {};
      objRol.acceso = urls[contador - 1].acceso;
      //metadatos
      objRol.personalizado = urls[contador - 1].personalizado;
      objRol.rol = urls[contador - 1].rol;
      objRoles[contador.toString()] = objRol;
      contador += 1
    });
    return objRoles;
  }

  public static ObjectToArrayRol(obj: any) {
    let finalArray = new Array();
    let keysObject: string[] = Object.keys(Object.assign({}, obj));

    keysObject.forEach(key => {
      var objNuevo: { [k: string]: unknown } = {};
      objNuevo['acceso'] = obj[key].acceso;
      objNuevo['rol'] = obj[key].rol;
      objNuevo['personalizado'] = obj[key].personalizado;
      finalArray.push(objNuevo);
    });
    return finalArray;
  }

  public static ObjecToListToArray(obj: any) {
    let finalArray = new Array();
    let keysObject: string[] = Object.keys(Object.assign({}, obj));
    keysObject.forEach(key => {
      var objNuevo: { [k: string]: unknown } = {};
      objNuevo['path_image'] = obj[key].path_image;
      objNuevo['extension'] = obj[key].extension;
      objNuevo['nombre_original'] = obj[key].nombre_original;
      objNuevo['nombre_plataforma'] = obj[key].nombre_plataforma;
      objNuevo['path_api'] = obj[key].path_api;
      objNuevo['tamanioKBs'] = obj[key].tamanioKBs;
      finalArray.push(objNuevo);
    });
    return finalArray;
  }

 

  public static ObjecListToArrayMenu(obj: any) {
    let finalArray = new Array();
    let keysObject: string[] = Object.keys(Object.assign({}, obj));
    keysObject.forEach(key => {
      var objNuevo: { [k: string]: unknown } = {};
      objNuevo['link'] = obj[key].link;
      objNuevo['icono'] = obj[key].icono;
      objNuevo['nombre'] = obj[key].nombre;
      objNuevo['active'] = obj[key].active;
      objNuevo['visible'] = obj[key].visible;
      objNuevo['visible_text'] = obj[key].visible_text;
      objNuevo['modulo'] = obj[key].modulo;
      objNuevo['orden'] = obj[key].orden;
      objNuevo['codigo'] = obj[key].codigo;
      finalArray.push(objNuevo);
    });
    return finalArray;
  }

  public static ObjecModuloToListToArray(obj: any) {
    let finalArray = new Array();
    let keysObject: string[] = Object.keys(Object.assign({}, obj));
    keysObject.forEach(key => {
      var objNuevo: { [k: string]: unknown } = {};
      objNuevo['cantidad_total_usuarios'] = obj[key].cantidad_total_usuarios;
      objNuevo['cantidad_usuarios_x_defecto'] = obj[key].cantidad_usuarios_x_defecto;
      objNuevo['costo_modulo'] = obj[key].costo_modulo;
      objNuevo['costo_total'] = obj[key].costo_total;
      objNuevo['costo_usuario_adicional'] = obj[key].costo_usuario_adicional;
      objNuevo['nombre'] = obj[key].nombre;
      objNuevo['orden'] = obj[key].orden;
      objNuevo['menu'] = obj[key].menu;
      objNuevo['roles'] = obj[key].roles;
      objNuevo['codigo'] = obj[key].codigo;

      finalArray.push(objNuevo);
    });
    return finalArray;
  }

  public static GetArrayMenuRol(obj: any) {
    let finalArray = new Array();
    let keysObject: string[] = Object.keys(Object.assign({}, obj));
    let rolAcceso: IRolAcceso[] = []
    keysObject.forEach(key => {
      var objRolAcceso: IRolAcceso = {
        acceso: obj[key].acceso,
        rol: obj[key].rol,
        personalizado: obj[key].personalizado !== undefined ? obj[key].personalizado : null,
      }
      rolAcceso.push(objRolAcceso);
    });
    return rolAcceso;
  }




  public static GetArrayMenu(datos: any[]) {
    const menuArray: any[] = [];

    datos.forEach((dato) => {
      const menuKeys = Object.keys(dato.menu);
      const menuItems = menuKeys.map((key) => dato.menu[key]);
      menuArray.push(...menuItems);
    });

    let finalArray: ItemSidenav[] = []
    menuArray.forEach(element => {

      const itemSidenav: ItemSidenav = {
        active: element.active,
        icono: element.icono,
        link: element.link,
        nombre: element.nombre,
        visible: element.visible,
        modulo: element.modulo,
        orden: element.orden,
        codigo: element.codigo
      }
      finalArray.push(itemSidenav);
      //reordenando
      finalArray.sort((a, b) => a.orden - b.orden);
    });

    return finalArray;


  }

  public static MergeObjetosDeObjetos(files_restantes: IImageUploaded[], filesObjOfObj: any) {
    const objFilesUploadReciente = this.ObjecToListToArray(filesObjOfObj);
    let listaConcatenada: any;
    if (files_restantes.length > 0) {
      listaConcatenada = files_restantes.concat(objFilesUploadReciente);
    } else {
      listaConcatenada = objFilesUploadReciente;
    }

    const listaFinal = this.ArrayToObjectToObjects(listaConcatenada);
    return listaFinal;

  }

  public static DiferenciasObjetoDeObjetos(files_restantes: IImageUploaded[], filesObjOfObj: any) {
    let listObjConvertido: IImageUploaded[] = [];
    let listObjEliminar: IImageUploaded[] = [];
    const objetosPropFiles = Object.keys(filesObjOfObj);
    for (const propiedad of objetosPropFiles) {
      let objConvertido: IImageUploaded = {
        nombre_plataforma: filesObjOfObj[propiedad].nombre_plataforma,
        nombre_original: filesObjOfObj[propiedad].nombre_original,
        path_api: filesObjOfObj[propiedad].path_api,
        path_image: filesObjOfObj[propiedad].path_image,
        extension: filesObjOfObj[propiedad].extension,
        tamanioKBs: filesObjOfObj[propiedad].tamanioKBs,
      }
      listObjConvertido.push(objConvertido);
    }

    if (files_restantes.length == 0) {
      //si no hay ningun restando queire decir que se quito todo
      return listObjEliminar = listObjConvertido;
    } else {
      //busca la diferencia entre los dos arrays, es decir retornar los objetos eliminados (!)
      const diferencia = listObjConvertido.filter(obj1 => !files_restantes.some(obj2 => obj1.nombre_plataforma === obj2.nombre_plataforma));
      const listObjEliminar = [...diferencia];
      return listObjEliminar;
    }
  }

  public static generarCodigo(): string {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let codigo = '';

    for (let i = 0; i < 6; i++) {
      const indice = Math.floor(Math.random() * caracteres.length);
      codigo += caracteres.charAt(indice);
    }

    return codigo;
  }

  public static convertUndefinedToNull(obj: any): any {
    const newObj = { ...obj };
    for (const key in newObj) {
      if (newObj.hasOwnProperty(key)) {
        if (typeof newObj[key] === 'object' && newObj[key] !== null) {
          // Si la propiedad es un objeto y no es nulo, aplicamos la función recursivamente
          newObj[key] = this.convertUndefinedToNull(newObj[key]);
        } else if (newObj[key] === undefined) {
          // Si la propiedad es undefined, la convertimos a null
          newObj[key] = null;
        }
      }
    }
    return newObj;
  }

  public static generarMenuSidenav(moduloRolColaborador: CModuloRolSeleccionado[], listaModulosEmpresa: any[]): ItemSidenav[] {
    const rolesColaborador = moduloRolColaborador;
    const modulosRolesEmpresa = listaModulosEmpresa;
    const item_sidenavs: ItemSidenav[] = [];
    //recuperando opciones del menu sidenav
    rolesColaborador.forEach((rol) => {
      modulosRolesEmpresa.forEach(modulo_rol => {
        const menu = Utils.ObjecListToArrayMenu(modulo_rol.menu) as ItemSidenav[];
        menu.forEach(item_menu => {
          rol.acceso.forEach((acceso) => {
            if (item_menu.link === acceso) {
              item_sidenavs.push(item_menu);
            }
          });
        })
      });
    });
    return item_sidenavs;
  }

  public static formatString(str: string, values: string[]) {
    return str.replace(/\{(\d+)\}/g, (match, index) => values[index] || match);
  }

  public static generateColor(str: string) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      // Aumentar la variación de hash entre diferentes strings
      hash = (str.charCodeAt(i) * 17) + ((hash << 5) - hash);
    }

    const hue = Math.abs(hash) % 360; // Asegurar que hue esté entre 0 y 359
    const saturation = 45 + (Math.abs(hash) % 20); // Saturación entre 80 y 100
    const lightness = 40 + (Math.abs(hash) % 15); // Luminosidad entre 60 y 75

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  public static generateUniqueToken(): string {
    // Genera un UUID
    const uniqueId = uuidv4();

    // Obtiene la marca de tiempo actual
    const timestamp = new Date().getTime();
    // Combina el UUID y la marca de tiempo para obtener un token único
    const token = `${uniqueId}-${timestamp}`;

    return token;
  }

  public static generarCodigoTipoNombreFecha(codigo:string, valor2:string, fecha:string ){
    const hoy = new Date(fecha);
    const finalAnio = hoy.getFullYear().toString().slice(-2);
    const finalMes = (hoy.getMonth()+1).toString().padStart(2, '0');
    const finalDia = hoy.getDate().toString().padStart(2, '0');

    if(valor2.trim() !== ''){
      const nombreProducto = valor2.toUpperCase().replace(/\s+/g, '').substring(0, 2);
      const codigoAleatorio = Math.random().toString(36).substring(2, 6).toUpperCase(); // Genera una cadena aleatoria
      codigo += nombreProducto + finalAnio + finalMes + finalDia + codigoAleatorio;
      return codigo
    }
    else
    {
      return '';

    }
  }

  public static generarCodigoRandom(longitud: number): string {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let resultado = '';
    for (let i = 0; i < longitud; i++) {
      const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
      resultado += caracteres.charAt(indiceAleatorio);
    }
    return resultado;
  }


  public static sanitizarNombre(nombres:string){
    let nombresLimpio = nombres.trim();
    let listaNombres = nombresLimpio.split(' ');
    const art_prep:string[]=['EL','LA','LOS','LAS','DE',',',';','.','CON','SUS','EN','UN'];
    art_prep.forEach(arti=>{
      listaNombres = listaNombres.filter(palabra=> palabra!==arti);
    });

    return listaNombres;

  }
  
  public static getAmbientesCantidad(originalObj:any, usuario:string){
    let ambienteMesa:IAmbienteMesa[] = new Array();
    let contadorOrden:number=1;
    let contador=1;    
    Object.keys(originalObj).forEach(key => {
      let numeracion = 1;    
      if (key.startsWith('campo')) {
        const numero = key.replace('campo', '');
        const nombreKey = 'nombre' + numero;
        const limite =  parseInt(originalObj[key], 10)       
        while(numeracion<=limite){
          ambienteMesa.push({
            ambiente:originalObj[nombreKey],
            orden:contadorOrden,
            accion_mesa:AccionMesaType.ANIADIR,
            mesa_numero:contador, 
            mesa_estado:EstadosMesaType.DISPONIBLE,
            fecha_creacion:null,
            usuario_creacion:usuario,
            es_vigente:true,
            auto_numerico:0
          });
          contador++;
          numeracion++;
        }
        contadorOrden+=1;
      }      
    });
    return ambienteMesa;
  }

  public static separarFechas(fecha:string){
    const partes = fecha.split("-");
    return[parseInt(partes[0]), parseInt(partes[1]), parseInt(partes[2])];
  }

  public static separarFechasHoras(fechaHora:string):string[]{
    // Dividir la fecha y la hora
    const [fecha, horaConZona] = fechaHora.split('T');

    // Separar los componentes de la fecha
    const [ano, mes, dia] = fecha.split('-');

    // Separar la hora y la zona horaria, y luego los componentes de la hora
    const [hora, minuto, segundoConZona] = horaConZona.split(':');

    // Extraer los segundos (ignorando la zona horaria)
    const segundo = segundoConZona.substring(0, 2);

    return [ano, mes, dia, hora, minuto, segundo];

  }

  public static generaFechaAutonumerico(fecha:Fecha): number{
    const fechaAutonum = fecha.anio+fecha.mes+fecha.dia+fecha.hora+fecha.minuto+fecha.segundo;
    const autonum:number = Number(fechaAutonum);
    return autonum;
  }
  public static generaMesHoraAutonumerico(fecha:Fecha): number{
    const fechaAutonum = fecha.mes+fecha.dia+fecha.hora+fecha.minuto+fecha.segundo;
    const autonum:number = Number(fechaAutonum);
    return autonum;
  }

  public static generarProductosVariados(productosVariados:IProductoTicket[]){
    let anterior_existentes = '';
    let actual_pedido='';
    let retorno:string[]=[];
    productosVariados.forEach(prod=>{
      if(prod.es_modificado && prod.cantidad_producto_reducido){
        const cantidadRed = prod.cantidad_producto - prod.cantidad_producto_reducido
        anterior_existentes = anterior_existentes+'('+prod.cantidad_producto.toString()+')'+prod.nombre_producto+'(REDUCIR)|'
        actual_pedido = actual_pedido+ '('+cantidadRed.toString()+')'+ prod.nombre_producto+'|'
      }
      if(prod.es_eliminado){        
        anterior_existentes = anterior_existentes+ '('+prod.cantidad_producto.toString()+')'+ prod.nombre_producto+'(ELIMINADO)|'        
      }
    });
    retorno[0] = anterior_existentes.slice(0,-1) ;
    retorno[1] = actual_pedido.slice(0,-1);
    return retorno;

  }


  public static generarMesasFila(mesas:string[]){
    let mesa_conc:string=''
    mesas.forEach(mesa=>{
      mesa_conc = mesa_conc+mesa+'-';
    });
    mesa_conc = mesa_conc.slice(0, -1);
    return mesa_conc;
  }

  /*
  Resolución de Superintendencia N° 025-2000/SUNAT, el 24 de febrero del año 2000​ y 
  Resolución de Superintendencia N° 183-2004/SUNAT.

  Si el monto a redondear es menor a 0.005, se redondea a 0.00.
  Si el monto a redondear es mayor o igual a 0.005, se redondea a 0.01.
  
  Redondeo al céntimo más cercano:
  Los montos totales en los comprobantes de pago se deben redondear al céntimo más cercano. 
  Esto significa que si la tercera cifra decimal es menor que 5, se redondea hacia abajo, y si es 5 o más, se redondea hacia arriba.
  
  Por ejemplo:
  
  S/. 10.144 se redondearía a S/. 10.14.
  S/. 10.145 se redondearía a S/. 10.15.
  Aplicación en Impuesto General a las Ventas (IGV) y otros tributos:
  El cálculo del IGV y otros tributos se realiza sobre el monto de la operación y luego se aplica el redondeo. Esto asegura que los cálculos tributarios sean 
  precisos y estén en línea con la normativa.
  
  Redondeo en operaciones múltiples:
  En operaciones que involucran varios artículos o servicios, se realiza el cálculo del total, 
  se aplica el IGV correspondiente, y luego el monto total (incluyendo impuestos) se redondea según las reglas mencionadas.
  */

  public static redondeoSUNAT(monto:number, decimals:number=2){
    const factor = Math.pow(10,decimals);
    return Math.round(monto * factor)/factor;    
  }

  public static calcularValorProductoImpuesto(precio_total:number,tasaImpuesto: number){    
    const tasaImpuestoDiscreto=(tasaImpuesto/100)    
    const totalConImpuesto= tasaImpuestoDiscreto+1; //representa el 100% del monto total + el porcentaje de impuesto

    const valorNetoVenta = this.redondeoSUNAT(precio_total/ totalConImpuesto);    
    const IGVExtraidoNeto = this.redondeoSUNAT(precio_total-valorNetoVenta);    
    const montos:number[]=[IGVExtraidoNeto, valorNetoVenta]
    return montos;
  }

  public static calcularTotalTicket(incluirServicio: boolean, tasaImpuesto: number, total: number, tasaServicio: number){  
    const tasaImpuestoDiscreto=(tasaImpuesto/100); 
    const tasaServicioDiscreto =(tasaServicio/100);
    const totalConImpuesto= (tasaImpuestoDiscreto+1); //representa el 100% del monto total + el porcentaje de impuesto

    //1. Precio sin IGV 
    const valorNetoVenta = this.redondeoSUNAT(total/ totalConImpuesto);

    //2. Calculo IGV total
    const IGVExtraidoNeto = this.redondeoSUNAT(total-valorNetoVenta);
       
    //3. Calculo Tasa de Servicio si es que se incluye servicio
    let IGVServicio:number=0;
    let subTotalServicio = 0;
    if (incluirServicio) {

         //3.1. calculamos el valor neto del servicio
         subTotalServicio =this.redondeoSUNAT(valorNetoVenta * tasaServicioDiscreto);

          /* Segun Texto Único Ordenado del Decreto Legislativo N° 821. Artículo 13° del TUO del IGV e ISC:
          “La base imponible del impuesto está constituida por el valor de la contraprestación en dinero o en 
          especie que el adquirente del bien o el usuario del servicio otorga a cambio de dicho bien o servicio,
          incluyendo cualquier concepto que incremente el valor de la operación, con excepción del propio IGV.”*/

         //3.2. calculamos el impuesto a la tasa de servicio
         IGVServicio = this.redondeoSUNAT(subTotalServicio * tasaImpuestoDiscreto);
     }   

    // Redondeo del total y convertir en unidad correcta
    const IGV = IGVExtraidoNeto + IGVServicio;
    const subTotal = valorNetoVenta;
    const tasaServicioCalculado = subTotalServicio+ IGVServicio;    
    const montoTotalCalculado = valorNetoVenta+subTotalServicio+IGV// dentro del IGV mayor ya se encuentra incluido el IGV de servicio
    const nuevoTotal = this.redondeoSUNAT(montoTotalCalculado);

    const returnMontos: number[] = [IGV, subTotal, tasaServicioCalculado,subTotalServicio, IGVServicio,nuevoTotal];
    return returnMontos;    
}

  public static calcularDescuento(tasaDescuento:number, precioProductoUnitario:number, tasaImpuesto:number, cantidadUnitariaDscto:number, cantidadTotalProducto:number ){
      /*considerar que el descuento se aplica al valor de venta del producto, no afecta el impuesto, es decir
      si el precio es 90, el valor de venta sería 81.818181..., y si aplicamos un 50% de descuento, solo afecta al valor de venta
      el impuesto de 8.18 mantiene su valor
      */
      const tasaDescuendoDiscreto = tasaDescuento/100;
      const tasaImpuestoDiscreto=(tasaImpuesto/100);
      const totalConImpuesto= tasaImpuestoDiscreto+1; //representa el 100% del monto total + el porcentaje de impuesto

      //1. Precio sin IGV 
      const valorNetoVentaunitario = precioProductoUnitario/ totalConImpuesto;
      //2. Calculo IGV total
      const IGVExtraidoUnitario = precioProductoUnitario-valorNetoVentaunitario;
      //complemento del dscto
      const compDsctoDiscreto = 1 - tasaDescuendoDiscreto;

      //calculando monto con Dscto
      let valorNetoTotalConDscto = (valorNetoVentaunitario* compDsctoDiscreto)*cantidadUnitariaDscto;
      let igvDelValorNetoTotalConDscto = (valorNetoTotalConDscto*tasaImpuestoDiscreto)*cantidadUnitariaDscto;

      //calculando monto sin dscto
      let valorNetoVentaIntegro =0;
      let igvNetoVentaIntegro =0;
      const cantUnitariaSinDscto = cantidadTotalProducto-cantidadUnitariaDscto;
      if(cantUnitariaSinDscto>0){
        valorNetoVentaIntegro = valorNetoVentaunitario* cantUnitariaSinDscto;
        igvNetoVentaIntegro = IGVExtraidoUnitario * cantUnitariaSinDscto;
      }
      //uniendo cantidades
      valorNetoTotalConDscto = this.redondeoSUNAT(valorNetoTotalConDscto + valorNetoVentaIntegro);
      igvDelValorNetoTotalConDscto = this.redondeoSUNAT(igvDelValorNetoTotalConDscto+ igvNetoVentaIntegro);     
    
      const ValorFinalConDsctoEImp = this.redondeoSUNAT((valorNetoTotalConDscto+ igvDelValorNetoTotalConDscto));
      const valRetur:number[]=[igvDelValorNetoTotalConDscto,ValorFinalConDsctoEImp, valorNetoTotalConDscto];
      return valRetur;
  }


  public static getTipoProducto(esBebida:boolean){
    const listaTipoComida:ListaTipoProducto[] =  [
      ListaTipoProducto.listaTipoProducto[1].codigo_producto,
      ListaTipoProducto.listaTipoProducto[4].codigo_producto,
      ListaTipoProducto.listaTipoProducto[5].codigo_producto,
      ListaTipoProducto.listaTipoProducto[6].codigo_producto// producto tipo combo puede ser comida y bebida
    ];
    const listaTipoBebida:ListaTipoProducto[] =  [
      ListaTipoProducto.listaTipoProducto[0].codigo_producto,
      ListaTipoProducto.listaTipoProducto[2].codigo_producto,
      ListaTipoProducto.listaTipoProducto[3].codigo_producto,
      ListaTipoProducto.listaTipoProducto[6].codigo_producto// producto tipo combo puede ser comida y bebida
    ]

    if(esBebida){
      return listaTipoBebida;
    }else{
      return listaTipoComida;
    }
  }

  public static getProductoNoCombo(){
    const listaTipoComida:ListaTipoProducto[] =  [
      ListaTipoProducto.listaTipoProducto[0].codigo_producto,
      ListaTipoProducto.listaTipoProducto[1].codigo_producto,
      ListaTipoProducto.listaTipoProducto[2].codigo_producto,
      ListaTipoProducto.listaTipoProducto[3].codigo_producto,
      ListaTipoProducto.listaTipoProducto[4].codigo_producto,
      ListaTipoProducto.listaTipoProducto[5].codigo_producto// producto tipo combo puede ser comida y bebida
    ];    

    return listaTipoComida;
  }

  public static getProductoCombo(){
    const listaTipoComida:ListaTipoProducto[] =  [
      ListaTipoProducto.listaTipoProducto[6].codigo_producto// producto tipo combo puede ser comida y bebida
    ];    

    return listaTipoComida;
  }


  public static generarEstadoResumenProducto(listaProducto: IProductoTicket[]){
    const pendienteP = listaProducto.filter(p=>p.estado_producto_vigente==EstadosProductoType.PENDIENTE);
    const procesoP = listaProducto.filter(p=>p.estado_producto_vigente==EstadosProductoType.EN_PROCESO);
    const terminadoP = listaProducto.filter(p=>p.estado_producto_vigente==EstadosProductoType.TERMINADO);
    if(pendienteP){
      return EstadosProductoType.PENDIENTE;
    }else if(procesoP){
      return EstadosProductoType.EN_PROCESO;
    } else if(terminadoP){
      return EstadosProductoType.TERMINADO;
    }else{
      return EstadosProductoType.PENDIENTE;
    }
  }

  public static  totalizadorCaracteristicaProducto(listaCaracteristicaProducto:ICaracteristicaProducto[]) {
    //interfaceutilitaria
    interface Resultado {
      codigo_producto: string;
      caracteristica_seleccionada: string;
      cantidad: number;
    }
    // Crear un objeto para totalizar las cantidades por combinacion de codigo_producto y caracteristica_seleccionada
    const totalizado: {
      [key: string]: {
        codigo_producto: string;
        caracteristica_seleccionada: string;
        cantidad: number;
      };
    } = listaCaracteristicaProducto.reduce((acc, obj) => {
      const key = `${obj.codigo_producto}_${obj.caracteristica_seleccionada}`;
      if (!acc[key]) {
        acc[key] = {
          codigo_producto: obj.codigo_producto,
          caracteristica_seleccionada: obj.caracteristica_seleccionada,
          cantidad: 0,
        };
      }
      acc[key].cantidad += obj.cantidad;
      return acc;
    }, {} as { [key: string]: { codigo_producto: string; caracteristica_seleccionada: string; cantidad: number } });

    // Convertir el objeto totalizado en un array de resultados
    const resultados: Resultado[] = Object.values(totalizado);
    let lista_caract_seleccionada:ICaracteristicaProducto[]=[]

    resultados.forEach((objRes) => {
      const datosAdicionales = listaCaracteristicaProducto.filter(
        (obj) =>
          obj.codigo_producto == objRes.codigo_producto &&
          objRes.caracteristica_seleccionada == obj.caracteristica_seleccionada
      )[0];

      const caracteristicaProducto: ICaracteristicaProducto = {
        caracteristica_seleccionada:
          datosAdicionales.caracteristica_seleccionada,
        tipo_producto: datosAdicionales.tipo_producto,
        codigo_producto: objRes.codigo_producto,
        cantidad: objRes.cantidad,
        nombre_producto: datosAdicionales.nombre_producto,
        grupo_producto: datosAdicionales.grupo_producto,
      };
      //insertamos un nuevo total de producto con caracteristica
      lista_caract_seleccionada?.push(
        caracteristicaProducto
      );
    });
    return lista_caract_seleccionada;
  }

  //para comprobar si es administrador por cada ruta
  public static esAdministrador(
      usuario:any, 
      esSuperAdministrador:boolean | null, 
      rolesModulosUsuarioColaborador:CModuloRolSeleccionado[] | null,
      opcion: ItemSidenav){
      let esAdministrador = false;
      const admGen='administrador general';
      if(usuario!=undefined){
        if(esSuperAdministrador){
          esAdministrador=true;          
        }else{
          const moduloSel = rolesModulosUsuarioColaborador!.filter(mod=>mod.nombre_modulo==opcion.modulo);
          const esAdmin = moduloSel.filter(modSel=>modSel.rol===admGen)
            if(esAdmin.length>0){
              esAdministrador = true;              
            }
            else{
              esAdministrador = false;
            }
        }
      }
      return esAdministrador;

  }
  //para comprobar si tiene algun modulo de administrador
  public static esAdministradorModular(
    usuario:any, 
    esSuperAdministrador:boolean | null, 
    rolesModulosUsuarioColaborador:CModuloRolSeleccionado[] | null){
    let esAdministrador = false;
    const admGen='administrador general';
    if(usuario!=undefined){
      if(esSuperAdministrador){
        esAdministrador=true;          
      }else{
        const moduloSel = rolesModulosUsuarioColaborador!.filter(mod=>mod.rol==admGen);        
          if(moduloSel.length>0){
            esAdministrador = true;              
          }
          else{
            esAdministrador = false;
          }
      }
    }
    return esAdministrador;

}

public static cypher(cifrar:boolean, llave:string, data:string){
  if(cifrar){        
    const encrypted = CryptoJS.AES.encrypt(data, llave).toString();
    return encrypted;
  }else{
    return data;
  }

}

  //metodo recursivo para comparar objetos
  public static deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (!keys2.includes(key)) return false;
      if (!this.deepEqual(obj1[key], obj2[key])) return false;
    }

    return true;
  }
  //copia objetos y sus propeidades profundamente, no funciona con date
  public static deepClone<T>(obj: T): T {
    if (obj === null || typeof obj !== "object") return obj;

    if (Array.isArray(obj)) {
        return obj.map(item => this.deepClone(item)) as any;
    }

    const copy: any = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            copy[key] = this.deepClone(obj[key]);
        }
    }
    return copy;
}




}
