import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CriteriosBusqueda, MsjEstado, Msjs, SistOperation, TableTypes, TamanioPagina } from '../../cons/common';
import { Icons } from '../../cons/icons';
import { Table } from '../table/table.model';
import { Pedido } from '../registrar-pedido/models/Pedido';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { UIService } from '../../services/ui.services';
import { TableService } from '../table/table.service';
import { RegistroService } from '../../services/registro.service';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { BuscarService } from '../buscar/buscar.service';
import { CBuscar } from '../buscar/CBuscar';
import { Subject, Subscription, of } from 'rxjs';
import { ITablePedidosFiltro } from '../registrar-pedido/services/models/ITablePedidosFiltro';
import { PedidoBlService } from '../registrar-pedido/services/services.bl/pedido.bl.service';
import { GestionMontoBlService } from '../gestion-monto/services/gestion-monto.bl.service';
import { concatMap, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Turno } from '../gestion-monto/models/Turno';
import { EstadosPedidoType } from '../../types/estado-pedido-type';
import { ITableEvento } from '../table/ITableEvento';
import { GestionAmbientesBlService } from '../procesos/gestion-ambientes-mesas/services/services.bl/gestion-ambientes.bl.service';
import { IProductoTicket } from '../registrar-pedido/models/IProductoTicket';
import * as printJS from 'print-js';
import { Utils } from '../../helpers/utils';
import * as moment from "moment";
import { FunctionsService } from '../../services/functions.service';
import { Fecha } from '../gestion-monto/models/Fecha';
import { EstadoPedido } from '../registrar-pedido/models/EstadoPedido';
import { ProcesoType } from '../../types/proceso-type';
import { SnackBarType } from '../../types/snackbar-type';
import { CONFIGURACION } from '../../cons/config';
import { GestionOperacionesBLService } from '../procesos/gestion-operaciones/services/gestion-operaciones.bl.service';
import { ModoOperacion } from '../procesos/gestion-operaciones/models/ModoOperacion';
import { AspectosGenerales } from '../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { GestionAspectosBlService } from '../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { MediaObserver } from '@angular/flex-layout';
@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.scss']
})

@AutoUnsubscribe

export class PedidosComponent implements OnInit, OnDestroy {
  tipoTabla: string = TableTypes.LISTAR_PEDIDOS;
  readonly Icons: typeof Icons = Icons;
  readonly EstadosPedidoType: typeof EstadosPedidoType = EstadosPedidoType;
  dataTable: Table = new Table();
  pedidos: Pedido[] = [];
  isExpanded: boolean = false;
  buscar: CBuscar = new CBuscar();
  listaPedidoSb!: Subscription;
  btnBuscarSb!: Subscription;
  turnoSb!: Subscription;
  btnLimpiarSb!: Subscription;
  btnEditarSb!: SistOperation;
  btnAgregarSb!: Subscription;
  btnVerSb!: Subscription;
  btnImprimirSb!: Subscription;
  listaProductoSb!: Subscription;
  btnResolverSb!: Subscription;
  btnTraspasarProductoSb!: Subscription;
  private unsubscribe$ = new Subject<void>();
  isLoading: boolean = false;
  tieneTurnoAperturado: boolean = false;
  turno: Turno = new Turno();
  listadoMesas: number[] = new Array();

  //variablesImpresion
  pedidoImprimir: Pedido = new Pedido();
  totalProductoImprimir: number = 0;
  totalItemsImprimir: number = 0;
  listaProductoPedido!: IProductoTicket[];
  listaMesasImprimir: string = '';
  empresa!: any;
  logoEmpresa!: string;
  usuario: any;
  usuarioImprimir!: string;
  fechaHoy: Fecha = new Fecha();
  historialVariacionPedido: EstadoPedido = new EstadoPedido();
  modalSb!: Subscription;
  configModoOp!: Subscription;
  modoOp: ModoOperacion = new ModoOperacion();
  existeConfigModoOp: boolean = false;
  btnDividirCuentaSb!: Subscription;

  aspectosGenerales = new AspectosGenerales();
  tasasSb = new Subscription();
  tipoComprobante: number = 3;



  PENDIENTE: number = 0;
  EN_PROCESO: number = 0;
  TERMINADO: number = 0;
  ANULADO: number = 0;
  PENDIENTE_REVISION: number = 0;
  PENDIENTE_REABRIR: number = 0;
  esPantallaXs: boolean = false;
  mediaSub!: Subscription
  tamanioPantalla: string = '';
  constructor(
    private uiService: UIService,
    private tableService: TableService,
    private registroService: RegistroService,
    private spinnerPageService: SpinnerPageService,
    private buscarService: BuscarService,
    private pedidoBlService: PedidoBlService,
    private gestionMontoBLService: GestionMontoBlService,
    private pedidoBLService: PedidoBlService,
    private gestionAmbienteBLService: GestionAmbientesBlService,
    private cdRef: ChangeDetectorRef,
    private functionService: FunctionsService,
    private gestionOpBlService: GestionOperacionesBLService,
    private gestionAspectosBL: GestionAspectosBlService,
    private mediaObserver: MediaObserver,
  ) {
    //definimos las propiedades de la tabla
    this.dataTable.modoXs = ['numeracion', 'codigo_pedido', 'estado_pedido_vigente', 'mesas_asignado', 'operaciones'];
    this.dataTable.modoM = ['numeracion', 'codigo_pedido', 'estado_pedido_vigente', 'total_productos_pedidos', 'fecha_registro', 'mesas_asignado', 'pedido_incidencia', 'operaciones'];
    this.dataTable.modoLg = ['numeracion', 'codigo_pedido', 'estado_pedido_vigente', 'total_productos_pedidos', 'mesas_asignado', 'total_string', 'fecha_registro', 'usuario_registro', 'pedido_incidencia', 'operaciones'];
    this.dataTable.columnaEstado = 'estado_pedido_vigente';
    this.dataTable.columnaImagen = '';
    this.dataTable.columnaEstiloEstado = 'estado_pedido_vigente';
    this.dataTable.pageSizeOptions = TamanioPagina.LISTA_TAMANIOS
    this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[0];// tamaño de pagina
    this.dataTable.currentPage = 1; //pagina inicial
    this.dataTable.nextPage = null;
    this.dataTable.previousPage = null;
    this.dataTable.columnaChip = undefined;
    this.dataTable.operaciones = [SistOperation.AGREGAR, SistOperation.EDITAR_PEDIDO, SistOperation.VER, SistOperation.IMPRIMIR, SistOperation.VER_HISTORIAL, SistOperation.RESPONDER_SOLICITUD, SistOperation.TRASPASAR_PRODUCTO, SistOperation.DIVIDIR_CUENTA];
    this.dataTable.filtro[0] = null; //filtro codigo
    //this.dataTable.filtro[1] = null; //filtro estado
    this.dataTable.filtro[2] = null; //filtro usuario
    //this.dataTable.filtro[3] = null; //filtro mesas
    this.dataTable.filtro[4] = null; //filtro estadoPedidoVigenteFiltro
    this.dataTable.minPage = null;//cambia ordenamiendo descendente
    this.dataTable.maxPage = true;//cambia ordenamiendo ascendente

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {

    // Suscribirse a los cambios en el tamaño de pantalla
    this.mediaSub = this.mediaObserver.asObservable().subscribe((changes) => {
      this.tamanioPantalla = changes[0].mqAlias;
      this.redimensionaPantalla();
    });


    //solo para impresion
    if (this.registroService.empresa && this.registroService.usuario) {
      this.empresa = this.registroService.empresa;
      if (this.empresa.logo_empresa) {
        this.logoEmpresa = Utils.ObjecToListToArray(this.empresa?.logo_empresa)[0].path_image;
      }

      this.usuarioImprimir = this.registroService.usuario.correo_electronico;
      this.cargarTasas();
    }

    this.spinnerPageService.show();
    this.cargarBusqueda();
    if (this.registroService.empresa) {
      //consultando existencia de turno
      this.gestionMontoBLService.existFetchTurno();
      //cargando el modo de operacion de visualizacion de pedido, por mesero o de todos
      this.gestionOpBlService.existFetchDscto();
      //cargando el modo de operacion de visualizacion de pedido, por mesero o de todos
      this.configModoOp?.unsubscribe();
      this.turnoSb?.unsubscribe();
      this.dataTable.usuarioAdministrador = this.registroService.esAdministrador;
      this.isLoading = true;

      //juntamos los dos observables que se tienen que ejecutar consecutivamente
      // Primer Observable: getTurno
      this.turnoSb = this.gestionMontoBLService
        .getTurno()
        .pipe(
          filter((turno) => turno !== undefined),
          tap((turno) => {
            if (turno && turno.id) {
              this.isLoading = false;
              this.tieneTurnoAperturado = true;
              this.turno = turno;
            } else {
              this.isLoading = false;
              this.tieneTurnoAperturado = false;
            }
          }),
          concatMap((turno) => {
            // Aquí asumimos que turno siempre será definido por el filter anterior
            return this.gestionOpBlService.getModoOp().pipe(
              filter((modoOp) => modoOp !== undefined),
              tap((modoOp) => {
                if (modoOp) {
                  this.isLoading = false;
                  this.modoOp = modoOp;
                  this.existeConfigModoOp = true;
                  // Generando la tabla de pedidos que pertenecen al turno
                  if (modoOp.es_individualizado) {
                    this.generarTabla(this.turno.id!, true);
                  } else {
                    this.generarTabla(this.turno.id!, false);
                  }
                } else {
                  this.generarTabla(this.turno.id!, false);
                  this.isLoading = false;
                }
              })
            );
          })
        )
        .subscribe();


      // this.turnoSb = this.gestionMontoBLService
      //   .getTurno()
      //   .pipe(filter((turno) => turno !== undefined))
      //   .subscribe((turno) => {
      //     if (turno && turno.id) {
      //       this.isLoading = false;
      //       this.tieneTurnoAperturado = true;
      //       this.turno = turno;
      //     } else {
      //       this.isLoading = false;
      //       this.tieneTurnoAperturado = false;
      //     }
      //   });


      // this.configModoOp = this.gestionOpBlService
      //   .getModoOp()
      //   .pipe(filter(modoOp => modoOp !== undefined))
      //   .subscribe((modoOp) => {
      //     if (modoOp) {
      //       this.isLoading = false;
      //       this.modoOp = modoOp;
      //       this.existeConfigModoOp = true;
      //       //generando la tabla de pedidos que pertenecen al turno
      //       if(modoOp.es_individualizado){
      //         this.generarTabla(this.turno.id!, true);
      //       }
      //       else{
      //         this.generarTabla(this.turno.id!, false);
      //       }
      //     } else {
      //       this.generarTabla(this.turno.id!, false);
      //       this.isLoading = false;
      //     }
      //   });
    }

    this.btnDividirCuentaSb = this.tableService.btnPagoDividirCuenta$.subscribe(
      (callEvento: ITableEvento) => {
        if (this.tipoTabla == callEvento.key) {
          if (callEvento.data) {
            const filaPedido = callEvento.data as Pedido;
            if (filaPedido.es_pedido_incidencia) {
              this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DIVIDIR_PEDIDO_INCIDENCIA, 3000);
              return;
            }
            if (filaPedido.es_ambiente_mesa_derivado != undefined && filaPedido.es_ambiente_mesa_derivado == true) {
              this.uiService.ShowSnackBar(
                SnackBarType.ERROR,
                Msjs.MSJ_ERROR_DIVIDIR_PEDIDO,
                5000
              );
              return;
            }
          }
          const pedido = callEvento.data as Pedido;
          if (this.turno.id && pedido.id) {
            this.spinnerPageService.show();
            this.uiService.showDialogTraspasoProductos({ dato: pedido, turno: this.turno, esDividir: true });
          }
        }
      }
    );


    //boton agregar producto
    this.btnAgregarSb = this.tableService.btnAgregar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        const pedido = callEvento.data as Pedido;
        if (pedido.es_pedido_incidencia) {
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DIVIDIR_PEDIDO_INCIDENCIA, 3000);
          return;
        }
        this.spinnerPageService.show();
        if (this.turno.id && pedido.id) {
          this.uiService.showAgregarPedido.next({
            idTurno: this.turno.id,
            pedido: pedido
          });//llama a la pestaña registrar pedido.                            
        }
      }
    });

    //boton ver pedido
    this.btnVerSb = this.tableService.btnVer$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        const pedido = callEvento.data as Pedido;
        if (this.turno.id && pedido.id) {
          this.spinnerPageService.show();
          this.uiService.showDialoVerPedido({ dato: pedido, turno: this.turno });
        }
      }
    });

    this.btnImprimirSb = this.tableService.btnImprimir$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((callEvento: ITableEvento) => {
          if (this.tipoTabla === callEvento.key && this.turno.id && callEvento.data.id) {
            this.pedidoImprimir = callEvento.data as Pedido;
            // solo cierra pedido cuando es distinto de reabrir
            if (this.pedidoImprimir.estado_pedido_vigente !== EstadosPedidoType.PENDIENTE_REABRIR) {
              // Devuelve un Observable del modal que espera una confirmación.
              const msjPrevio = this.pedidoImprimir.codigo_pedido + ' (mesa: ' + this.pedidoImprimir.mesas_completo + ')';
              const msj = Msjs.MENSAJE_CERRAR_PEDIDO.replace('$', msjPrevio)
              return this.uiService.showModalQuestions({
                title: Msjs.TITULO_CONFIRMACION,
                message: msj,
                icon: Icons.QUESTION_MARK
              }).pipe(
                take(1), // Asegura que el Observable se complete después de la primera emisión.
                switchMap((result: boolean | null) => {
                  if (result) {
                    // Si el usuario confirma, procede a obtener los productos del pedido.                  
                    this.spinnerPageService.show();
                    return this.pedidoBLService.getProductosXPedido(this.turno.id!, callEvento.data.id);
                  } else {
                    // Si el usuario no confirma, devuelve un observable que no emite nada.
                    return of(null);
                  }
                })
              );
            }
            else {
              // si el pedido esta en pendiente de reabrir
              this.spinnerPageService.show();
              return this.pedidoBLService.getProductosXPedido(this.turno.id!, callEvento.data.id);
            }
          } else {
            // Si las condiciones no se cumplen, devuelve un observable que no emite nada.
            return of(null);
          }
        })
      )
      .subscribe(async listaProds => {
        this.spinnerPageService.hide();
        if (listaProds) {

          this.listaProductoPedido = JSON.parse(JSON.stringify(listaProds));
          //quitando productos prepagados
          this.listaProductoPedido = JSON.parse(JSON.stringify(this.listaProductoPedido.filter(prod => prod.es_pagado == undefined)));
          this.totalProductoImprimir = this.listaProductoPedido.reduce((acc, prod) => acc + prod.cantidad_producto, 0) || 0;
          this.totalItemsImprimir = this.listaProductoPedido.length;
          this.listaMesasImprimir = Utils.generarMesasFila(this.pedidoImprimir.mesas_completo);

          await this.cargarFechas();
          //imprimir sin mostrar pregunta
          if (this.pedidoImprimir.estado_pedido_vigente === EstadosPedidoType.PENDIENTE_REABRIR) {
            await this.cargarFechas();
            this.uiService.printComanda$.next(this.tipoTabla);
            //this.printComanda();
            return;
          }
          this.cdRef.detectChanges();
          this.uiService.printComanda$.next(this.tipoTabla);
          //this.printComanda();
          //cerrando pedido
          //generando historial de pedido
          this.historialVariacionPedido = new EstadoPedido();
          this.historialVariacionPedido.es_vigente = true;
          this.historialVariacionPedido.estado_pedido = EstadosPedidoType.CERRADO;
          this.historialVariacionPedido.fecha_key = { ...this.fechaHoy };
          this.historialVariacionPedido.proceso = ProcesoType.CERRAR_PEDIDO;
          this.historialVariacionPedido.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
          this.historialVariacionPedido.usuario_creacion = this.registroService.usuario.correo_electronico;
          //cambiando de estado al pedido seleccionado
          this.pedidoImprimir.estado_pedido_vigente = EstadosPedidoType.CERRADO;
          this.spinnerPageService.show();
          this.pedidoBLService.updatePedidoEstadoCerradoOTerminado(this.turno.id!, this.pedidoImprimir, this.historialVariacionPedido).then(tx => {
            if (tx.tx) {
              this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 5000);
              //limpiando el pedido
              this.historialVariacionPedido = new EstadoPedido();
              this.pedidoImprimir = new Pedido();
              this.spinnerPageService.hide();
              //cambiando de pestaña a pedidos cerrados
              this.uiService.showCerrarPedido.next({
                idTurno: this.turno.id!,
                pedido: this.pedidoImprimir
              });
            }
            else {
              this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
              this.spinnerPageService.hide();
            }
          });
        }
      });

    //boton Editar pedido
    this.btnEditarSb = this.tableService.btnEditarPedido$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        const pedido = callEvento.data as Pedido;
        if (pedido.es_pedido_incidencia) {
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DIVIDIR_PEDIDO_INCIDENCIA, 3000);
          return;
        }
        this.spinnerPageService.show();
        if (this.turno.id && pedido.id) {
          this.uiService.showRegistrarPedido.next({
            idTurno: this.turno.id,
            pedido: pedido
          });//llama a la pestaña registrar pedido.          

        }
      }
    });

    //busqueda
    this.btnBuscarSb = this.buscarService.goBuscar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.dataTable.filtro[0] = null; //filtro codigo
        //this.dataTable.filtro[1] = null; //filtro estado
        this.dataTable.filtro[2] = null; //filtro usuario        


        this.dataTable.minPage = null;
        this.dataTable.maxPage = null;
        this.dataTable.nextPage = null;
        this.dataTable.previousPage = null;
        this.dataTable.currentPage = 1;
        this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[0];//tamaño de pagina
        const data: CBuscar = callEvento.data;

        switch (data.criterio_seleccionado) {
          case CriteriosBusqueda.codigo: {
            this.dataTable.filtro[0] = data.valor_criterio; //filtro codigo
            break;
          }
          case CriteriosBusqueda.estado: {
            this.dataTable.filtro[1] = data.estado_seleccionado == MsjEstado.ABIERTO ? true : false; //CODIGO estado
            break;
          }
          case CriteriosBusqueda.usuario_creacion: {
            this.dataTable.filtro[2] = data.valor_criterio;// usuario
            break;
          }
          // case CriteriosBusqueda.mesa: {
          //   this.dataTable.filtro[3] = data.valor_criterio;// mesas
          //   break;
          // }
          default: {
            this.dataTable.filtro[0] = null; //filtro codigo
            this.dataTable.filtro[1] = null; //filtro estado
            this.dataTable.filtro[2] = null; //filtro usuario
            this.dataTable.filtro[3] = null; //filtro mesas
            this.dataTable.filtro[4] = null; //filtro estadoPedidoVigenteFiltro


          }
        }

        if (this.turno.id) {
          this.generarTabla(this.turno.id);
        }
      }
    });

    //limpiar
    this.btnLimpiarSb = this.buscarService.goLimpiar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.restablecerEstado();
      }
    });

    this.btnResolverSb = this.tableService.btnResponderSolicitud$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        let pedido = callEvento.data as Pedido;
        if (pedido.es_pedido_incidencia) {
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DIVIDIR_PEDIDO_INCIDENCIA, 3000);
          return;
        }

        if (this.turno.id && pedido.id) {
          const msj = Msjs.MENSAJE_ABRIR_PEDIDO.replace('$', pedido.codigo_pedido)
          this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: msj, icon: Icons.QUESTION_MARK })
            .subscribe(async result => {
              await this.cargarFechas();
              //para administrador, reabre el pedido, para colaborador solicita reapertura
              this.historialVariacionPedido = new EstadoPedido();
              this.historialVariacionPedido.es_vigente = true;
              this.historialVariacionPedido.estado_pedido = result ? EstadosPedidoType.TERMINADO : EstadosPedidoType.CERRADO;
              this.historialVariacionPedido.fecha_key = { ...this.fechaHoy };
              this.historialVariacionPedido.proceso = result ? ProcesoType.ACEPTAR_REABRIR : ProcesoType.RECHAZAR_REABRIR
              this.historialVariacionPedido.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
              this.historialVariacionPedido.usuario_creacion = this.registroService.usuario.correo_electronico;
              //cambiando de estado al pedido seleccionado
              pedido.estado_pedido_vigente = result ? EstadosPedidoType.TERMINADO : EstadosPedidoType.CERRADO;
              this.spinnerPageService.show();
              this.pedidoBLService.updatePedidoEstadoCerradoOTerminado(this.turno.id!, pedido, this.historialVariacionPedido).then(tx => {
                if (tx.tx) {
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 5000);
                  //limpiando el pedido
                  this.historialVariacionPedido = new EstadoPedido();
                  this.spinnerPageService.hide();
                  //cambiando de pestaña a pedidos abiertos
                  this.uiService.showListarPedido.next();
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
              });
            });
        }
      }
    });

    this.btnTraspasarProductoSb = this.tableService.btnPagoTraspasarProducto$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        const pedido = callEvento.data as Pedido;
        if (pedido.es_pedido_incidencia) {
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DIVIDIR_PEDIDO_INCIDENCIA, 3000);
          return;
        }
        this.spinnerPageService.show();
        if (this.turno.id && pedido.id) {
          this.uiService.showDialogTraspasoProductos({ dato: pedido, turno: this.turno, esDividir: false, lista_pedidos: this.dataTable.data });
        }
      }
    }
    );


  }
  redimensionaPantalla() {
    if (this.tamanioPantalla === 'xs') {
      this.esPantallaXs = true;
    } else {
      this.esPantallaXs = false;
    }
  }

  cargarTasas() {
    this.gestionAspectosBL.existFetchTasas();
    if (this.tasasSb) {
      this.tasasSb.unsubscribe();
    }
    this.tasasSb = this.gestionAspectosBL.getTasas().subscribe((tasas) => {
      if (tasas) {
        this.aspectosGenerales = tasas;
      }
    });
  }

  async cargarFechas() {
    const fechaServer = moment(await this.functionService.GetDateServer()).local().format();
    const fechaServerProcesado = Utils.separarFechasHoras(fechaServer);
    this.fechaHoy.anio = fechaServerProcesado[0];//anio
    this.fechaHoy.mes = fechaServerProcesado[1];//mes
    this.fechaHoy.dia = fechaServerProcesado[2];//dia
    this.fechaHoy.hora = fechaServerProcesado[3];//hora
    this.fechaHoy.minuto = fechaServerProcesado[4];//minuto
    this.fechaHoy.segundo = fechaServerProcesado[5];//seg  
  }

  // printComanda(){
  //   var style = CONFIGURACION.COMANDA;

  //   const id='comandaPed';
  //   printJS({ printable: id, type: 'html', style:style, css:[CONFIGURACION.COMANDA_FUENTE] });
  // }



  restablecerMesas() {
    this.gestionAmbienteBLService.updateConfiguracionAmbienteDisponible();

  }

  cargarBusqueda() {
    this.buscar.lista_criterio = [CriteriosBusqueda.codigo];

    if (this.registroService.usuario && this.registroService.esAdministrador) {
      this.buscar.lista_criterio.push(CriteriosBusqueda.usuario_creacion);
    }

    this.buscar.key = this.tipoTabla;
    this.buscar.lista_estado = [MsjEstado.ABIERTO, MsjEstado.CERRADO];
  }

  generarTabla(idTurno: string, vistaIndividualizada: boolean = false) {
    this.listaPedidoSb?.unsubscribe();

    this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: true });//loading tabla
    const tablaFiltro: ITablePedidosFiltro = {
      codigoFiltro: this.dataTable.filtro[0], //codigo
      estadoFiltro: this.dataTable.filtro[1], //estado
      usuarioFiltro: this.dataTable.filtro[2], //usuario
      mesas: this.dataTable.filtro[3], //mesas
      estadoPedidoVigenteFiltro: this.dataTable.filtro[4], //estadoPedidoVigenteFiltro
      minPage: this.dataTable.minPage,
      maxPage: this.dataTable.maxPage,

    }

    this.listaPedidoSb = this.pedidoBlService.getPedidos(
      this.registroService.empresa.id,
      tablaFiltro,
      idTurno,
      false,//esCerrar
      false,//esPagar
      false,//esIncidencia
      vistaIndividualizada

    ).subscribe((pedidos: Pedido[] | null) => {
      if (pedidos != null && pedidos.length > 0 && pedidos[0].codigo_pedido != '') {
        this.dataTable.data = [...pedidos];
        this.PENDIENTE = pedidos[0].totalRegistrosPendiente ? pedidos[0].totalRegistrosPendiente : 0;
        this.EN_PROCESO = pedidos[0].totalRegistrosEnProceso ? pedidos[0].totalRegistrosEnProceso : 0;
        this.TERMINADO = pedidos[0].totalRegistrosTerminado ? pedidos[0].totalRegistrosTerminado : 0;
        this.ANULADO = pedidos[0].totalRegistrosAnulado ? pedidos[0].totalRegistrosAnulado : 0;
        this.PENDIENTE_REVISION = pedidos[0].totalRegistrosPendienteRevision ? pedidos[0].totalRegistrosPendienteRevision : 0;
        this.PENDIENTE_REABRIR = pedidos[0].totalRegistrosPendienteReabrir ? pedidos[0].totalRegistrosPendienteReabrir : 0;
      } else {
        this.dataTable.data = [];
        this.PENDIENTE = 0;
        this.EN_PROCESO = 0;
        this.TERMINADO = 0;
        this.ANULADO = 0;
        this.PENDIENTE_REVISION = 0;
        this.PENDIENTE_REABRIR = 0;

      }
      //genera filtro mesas
      this.listadoMesas = new Array();
      if (pedidos != null) {
        pedidos.forEach(pedido => {
          pedido.mesas_completo.forEach(mesas => {
            // solo para mesas existentes
            if (!isNaN(parseFloat(mesas)) && isFinite(Number(mesas))) {
              this.listadoMesas.push(Number(mesas));
            }
          });
        });
      }

      this.listadoMesas = Array.from(new Set(this.listadoMesas));
      this.listadoMesas.sort((a, b) => a - b); //ordenamos las mesas


      this.tableService.loadDataTable$.next(this.tipoTabla);//refrescamos el objeto tabla
      this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: false });//loading tabla

      this.spinnerPageService.hide();//cerrado del ngOnInit

    });


  }

  seleccionarMesa(mesa: number) {
    this.dataTable.filtro[0] = null;
    this.dataTable.filtro[1] = null;
    this.dataTable.filtro[2] = null;
    this.dataTable.filtro[3] = mesa.toString();
    this.dataTable.filtro[4] = null;

    if (this.turno.id) {
      this.generarTabla(this.turno.id);
    }
  }

  panelExpandedChange(event: any) {
    this.isExpanded = event;
  }

  restablecerEstado() {
    this.dataTable.filtro[0] = null;
    this.dataTable.filtro[1] = null;
    this.dataTable.filtro[2] = null;
    this.dataTable.filtro[3] = null;
    this.dataTable.filtro[4] = null;

    if (this.turno.id) {
      this.generarTabla(this.turno.id);
    }
    this.isExpanded = false;

  }

  filtroEstados(estado: EstadosPedidoType) {
    this.dataTable.filtro[4] = estado;
    if (this.turno.id) {
      this.generarTabla(this.turno.id);
    }
  }

}
