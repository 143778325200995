import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { CCliente } from "./cliente.model";

export class CEncuesta   {
  codigo_mozo:number=0;
  puntaje_servicio:number=0;
  puntaje_coctel:number=0;
  puntaje_piqueos:number=0;
  comentario:string='';
  cliente:CCliente = new CCliente();
  auditoria?:AuditoriaSet;
  fecha_creacion:any;
  comentario_encuestador:string='';

}
