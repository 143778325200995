import { Injectable } from '@angular/core';
import { CartaDaService } from './carta-da.service';

@Injectable({
  providedIn: 'root'
})
export class CartaBlService {

  constructor(
    private cartaDaService:CartaDaService
  ) { }

  getCartaExterno(rutaParcial:string){
    return this.cartaDaService.getCartaExterno(rutaParcial);
  }
}
