import { Component, Inject, OnInit } from '@angular/core';
import { Msjs, Msjs_Validations, PatternsRegex, SistOperation } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { TipoPregunta } from '../models/tipo-pregunta';
import { NgForm } from '@angular/forms';
import { UIService } from 'src/app/shared/services/ui.services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileTypes } from 'src/app/shared/types/file-types';
import { Subscription } from 'rxjs';
import { EncuestaEncabezado } from '../models/encuesta-encabezado';
import { Utils } from 'src/app/shared/helpers/utils';
import { UploadService } from '../../upload/upload.service';
interface IImagen{
  path_image:string;
  extension:string;
  nombre_original:string;
  nombre_plataforma:string;
  path_api:string;
  tamanioKBs:number;
}

@Component({
  selector: 'app-gestionar-formulario-encuesta',
  templateUrl: './gestionar-formulario-encuesta.component.html',
  styleUrls: ['./gestionar-formulario-encuesta.component.scss']
})



export class GestionarFormularioEncuestaComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  isLoading:boolean=false;
  titleModal:string='';
  tituloPregunta:string=''
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  modoOperacion: SistOperation = SistOperation.VER;
  readonly SistOperation: typeof SistOperation = SistOperation;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  codigo:number =0;
  cabeceraEditar!:EncuestaEncabezado;
  fileType:string = FileTypes.IMAGE;
  label:string='Foto :';
  sizeMaxHeightWidth:string='alto 200px - ancho 200px';
  esModoEdicion:boolean = false;
  disabled_controls:boolean = false;
  files_uploadedLogo:any[]=[];
  files_uploadedFondo:any[]=[];
  sbModalQuest!:Subscription;  
  files_upload_subs!:Subscription;
  files_uploadLogo:File[]=[];
  files_uploadFondo:File[]=[];
  tieneCambiosPendientes:boolean = false;
  keyLogo:string='Logo';
  keyFondo:string='Fondo';

  
  constructor(      
      @Inject(MAT_DIALOG_DATA) public datoPasado:any,
      private dialogRef: MatDialogRef<GestionarFormularioEncuestaComponent>,
      private uiService:UIService,
      private uploadService:UploadService,
  ) { }

  ngOnInit(): void {
    this.titleModal = this.datoPasado.data.titulo;
    this.modoOperacion = this.datoPasado.operacion;

    if(this.modoOperacion==SistOperation.EDITAR){
      this.uploadService.removeAllFiles();
      this.esModoEdicion=true;
      this.cabeceraEditar = this.datoPasado.data.encabezado;
      let listaImagenLogo :IImagen[]=[];
      let listaImagenFondo :IImagen[]=[];
      if(this.cabeceraEditar.path_logo){
        let imagenLogo:IImagen={
          extension:this.cabeceraEditar.path_logo.extension,
          nombre_original:this.cabeceraEditar.path_logo.nombre_original,
          nombre_plataforma: this.cabeceraEditar.path_logo.nombre_plataforma,
          path_api:this.cabeceraEditar.path_logo.path_api,
          path_image:this.cabeceraEditar.path_logo.path_image,
          tamanioKBs:this.cabeceraEditar.path_logo.tamanioKBs
        }
        listaImagenLogo.push(imagenLogo);
      }
      if(this.cabeceraEditar.path_fondo){
        let imagenFondo:IImagen={
          extension:this.cabeceraEditar.path_fondo.extension,
          nombre_original:this.cabeceraEditar.path_fondo.nombre_original,
          nombre_plataforma: this.cabeceraEditar.path_fondo.nombre_plataforma,
          path_api:this.cabeceraEditar.path_fondo.path_api,
          path_image:this.cabeceraEditar.path_fondo.path_image,
          tamanioKBs:this.cabeceraEditar.path_fondo.tamanioKBs
        }
        listaImagenFondo.push(imagenFondo);
      }
      
      this.files_uploadedLogo = listaImagenLogo;
      this.files_uploadedFondo = listaImagenFondo;
      this.tituloPregunta = this.cabeceraEditar.titulo;  
      
    }

    this.files_upload_subs = this.uploadService.files_upload$.subscribe((uploads)=>{
      if(this.modoOperacion==SistOperation.NUEVO){
        if(uploads.key!==null){
          if(uploads.key==this.keyLogo){
            if(this.esModoEdicion){
              // para lista de imagenes existentes modificadas
              this.files_uploadedLogo = uploads.images_uploaded;
            }
            this.files_uploadLogo = uploads.images_upload
          }
          if(uploads.key==this.keyFondo){
            if(this.esModoEdicion){
              // para lista de imagenes existentes modificadas
              this.files_uploadedFondo = uploads.images_uploaded;
            }
            this.files_uploadFondo = uploads.images_upload
          }
        }  
      }
      if(this.modoOperacion==SistOperation.EDITAR){
        if(uploads.key!==null){
          if(uploads.key==this.keyLogo){
            if(this.esModoEdicion &&  uploads.images_upload.length>0){
              // para lista de imagenes existentes modificadas
              this.files_uploadLogo = uploads.images_upload;
              this.cabeceraEditar.path_logo_editar = this.cabeceraEditar.path_logo;            
            }
            
          }
          if(uploads.key==this.keyFondo){
            if(this.esModoEdicion && uploads.images_upload.length>0){
              // para lista de imagenes existentes modificadas
              this.files_uploadedFondo = uploads.images_upload;
              this.cabeceraEditar.path_fondo_editar = this.cabeceraEditar.path_fondo;            
            }
              
          }
        }  
      }     
        
    });

  }

  agregarFormulario(f:NgForm){
    if(f.valid){
      if(this.modoOperacion==SistOperation.NUEVO){
        let encabezado:EncuestaEncabezado = {
          codigo_encuesta:  Utils.generarCodigoRandom(6),
          path_logo: this.files_uploadLogo[0],
          path_fondo: this.files_uploadFondo[0],
          titulo: this.tituloPregunta        
        }
        this.dialogRef.close( encabezado);
      }
      if(this.modoOperacion==SistOperation.EDITAR){
        let encabezado:EncuestaEncabezado = {
          codigo_encuesta:  Utils.generarCodigoRandom(6),
          ...(this.files_uploadLogo.length>0 && {path_logo: this.files_uploadLogo[0], path_logo_editar:this.cabeceraEditar.path_logo_editar}),
          ...(this.files_uploadFondo.length>0 && {path_fondo: this.files_uploadLogo[0], path_fondo_editar:this.cabeceraEditar.path_fondo_editar}),          
          titulo: this.tituloPregunta        
        }
        this.dialogRef.close( encabezado);
      }

    }
  }

  onClose(){
    this.dialogRef.close();
  }

  cancelarPregunta(){
    this.dialogRef.close();
  }

}
