import { Injectable } from '@angular/core';
import { ReporteType } from '../types/reporte-type';
import { VentaCerradaBlService } from '../components/procesos/services/venta-cerrada-bl.service';
import { Turno } from '../components/gestion-monto/models/Turno';
import { Subject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../helpers/decorators/AutoUnsubscribe';
import { filter } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { IReporteVentas } from './models/IReporteVentas';
import { IObjRpt } from './models/IObjRpt';
import { IReportePagos } from './models/IReportePagos';
import { IReporteHistorialProducto } from './models/IReporteHistorialProducto';
import { IReporteHistorialMesa } from './models/IReporteHistorialMesa';
import { IReportePedidosAnulados } from './models/IReportePedidosAnulados';


@Injectable({
  providedIn: 'root'
})

@AutoUnsubscribe

export class GenerarReporteService {
  readonly ReporteType: typeof ReporteType = ReporteType;
  ventasSb!:Subscription;
  procesadoRpt = new Subject<boolean>();
  noResultados:boolean = false;

  constructor(
    private ventaCerrada: VentaCerradaBlService,
    
  ) { }

  selectReporte(event:any, turno:Turno){
    switch(event){
      // reporte de ventas
      case 1:{
        this.procesadoRpt.next(true);
        this.generarReporteVentasGeneral(turno);
        break;        
      }
      case 2:{
        this.procesadoRpt.next(true);
        this.generarReportePagosGeneral(turno);
        break;
      }
      case 3:{
        this.procesadoRpt.next(true);
        this.generarReporteVentasHistorialMesa(turno);
        break;
      }
      case 4:{
        this.procesadoRpt.next(true);
        this.generarReporteVentasHistorialProductos(turno);
        break;
      }
      case 5:{
        this.procesadoRpt.next(true);
        this.generarReportePedidosAnulados(turno);
        break;
      }

    }
  }

  generarReportePedidosAnulados(turno:Turno){
    this.noResultados = false;
    const listaTurno:string[]=[];
    listaTurno.push(turno.id!);
    let dataReportVentas:IReportePedidosAnulados[]=[];

    this.ventaCerrada.existFetchVentas(listaTurno);
    this.ventasSb?.unsubscribe();
    this.ventasSb = this.ventaCerrada.getVentasXIDTurno().pipe(
      filter((listaVentas) => listaVentas !== undefined)).subscribe((listaVentas) => {          
      if (listaVentas) {       
        //generando datos reporte
        listaVentas.forEach(item => {
          if(item.pedido!.es_pedido_anulado || item.pedido!.es_pedido_incidencia ){
            item.pedido!.lista_producto!.forEach(producto => {            
              const dataReporte: IReportePedidosAnulados = {
                codigo_pedido: item.pedido!.codigo_pedido,
                fecha_creacion_pedido: item.fecha_key.dia+'/'+item.fecha_key.mes+'/'+item.fecha_key.anio,
                hora_creacion_pedido: item.fecha_key.hora+':'+item.fecha_key.minuto+':'+item.fecha_key.segundo,
                mesas_completo: item.pedido!.mesas_completo.join(','),
                igv_pedido: item.pedido!.igv,
                ...(item.pedido!.servicio_igv && { igv_servicio: item.pedido!.servicio_igv }),
                ...(item.pedido!.servicio_subtotal && { subtotal_servicio: item.pedido!.servicio_subtotal }),
                ...(item.pedido!.servicio_total && { total_servicio: item.pedido!.servicio_total }),
                subtotal_pedido: item.pedido!.sub_total,
                total_pedido:  Number((Math.floor( item.pedido!.total * 100) / 100).toFixed(2)),
                total_tipos_productos_pedidos: item.pedido!.total_productos_pedidos,
                usuario_creacion_pedido:item.pedido!.usuario_creacion_busqueda.split('@')[0],
                //para pedidos que nacen como incidencia
                ...(item.pedido!.es_pedido_incidencia &&{es_pedido_incidencia: item.pedido!.es_pedido_incidencia?'Si':'No',}),
                ...(item.pedido!.usuario_incidencia &&{pedido_incidencia_asignado_a: item.pedido!.usuario_incidencia.correo_electronico.split('@')[0],}),
                //producto
                cantidad_producto: producto.cantidad_producto,
                nombre_producto: producto.nombre_producto,
                tipo_producto:producto.tipo_producto,
                codigo_producto: producto.codigo_producto,
                //codigo_grupo_pedido: producto.codigo_ticket,
                precio_unitario_producto:  Number((Math.floor(producto.precio_unitario * 100) / 100).toFixed(2)),
                usuario_creacion_producto:producto.usuario_creacion.split('@')[0],
                fecha_creacion_producto: producto.fecha_key.dia+'/'+producto.fecha_key.mes+'/'+producto.fecha_key.anio,
                hora_creacion_producto: producto.fecha_key.hora+':'+producto.fecha_key.minuto+':'+producto.fecha_key.segundo,
                precio_total_producto:  Number((Math.floor(producto.precio_total * 100) / 100).toFixed(2)),              
                igv_valor_producto: producto.igv_valor_producto,
                es_anulado:item.pedido!.es_pedido_anulado?'Si':'No',
                es_incidencia:item.pedido!.es_pedido_incidencia?'Si':'No',
                usuario_aprobacion:item.usuario_creacion.split('@')[0]          
              }
              dataReportVentas.push(dataReporte);
            });
          }         
        });
        //en caso no se tengan pedidos anulados
        if(dataReportVentas.length==0){
          this.noResultados = true;
          this.procesadoRpt.next(false);
          return;
        }
        const objRpt:IObjRpt={
          data_reporte:dataReportVentas,
          nombre_reporte:'Reporte_PedidosAnulados',
          nombre_sheets:'Anulados'
        }
        this.ventasSb?.unsubscribe();
        this.exportToExcel(objRpt);
      }else{
        this.noResultados = true;
        this.procesadoRpt.next(false);        
      }
    })

  }

  generarReporteVentasHistorialMesa(turno:Turno){
    this.noResultados = false;
    const listaTurno:string[]=[];
    listaTurno.push(turno.id!);
    let dataReportPagos:IReporteHistorialMesa[]=[];

    this.ventaCerrada.existFetchVentas(listaTurno);
    this.ventasSb?.unsubscribe();
    this.ventasSb = this.ventaCerrada.getVentasXIDTurno().pipe(
      filter((listaVentas) => listaVentas !== undefined)).subscribe((listaVentas) => {          
      if (listaVentas) {
        //ordenando historial        
        listaVentas.forEach(item=>{
          item.pedido!.historial_mesa!.sort((a,b)=>a.auto_numerico - b.auto_numerico);  
        });
        //console.log(listaVentas);
        //generando datos reporte
        listaVentas.forEach(item => {
          item.pedido!.historial_mesa!.forEach(historial => {
            const dataReporte: IReporteHistorialMesa = {
              //pedido
              codigo_pedido: item.pedido!.codigo_pedido,
              fecha_creacion_pedido: item.fecha_key.dia+'/'+item.fecha_key.mes+'/'+item.fecha_key.anio,
              hora_creacion_pedido: item.fecha_key.hora+':'+item.fecha_key.minuto+':'+item.fecha_key.segundo,
              mesas_completo: item.pedido!.mesas_completo.join(','),              
              usuario_creacion_pedido: item.pedido!.usuario_creacion_busqueda.split('@')[0],
              //para pedidos que nacen como incidencia
              ...(item.pedido!.es_pedido_incidencia && { es_pedido_incidencia: item.pedido!.es_pedido_incidencia ? 'Si' : 'No', }),
              ...(item.pedido!.usuario_incidencia && { pedido_incidencia_asignado_a: item.pedido!.usuario_incidencia.correo_electronico.split('@')[0], }),
              //historial
              ambiente: historial.ambiente.toUpperCase(),
              mesa_numero:historial.mesa_numero,
              mesa_estado:historial.mesa_estado.toUpperCase(),
              accion_mesa:historial.accion_mesa.toUpperCase(),
              ...(historial!.estado_cambio && { estado_cambio: historial!.estado_cambio.toUpperCase() }),
              fecha_creacion:historial.fecha_key!.dia+'/'+historial.fecha_key!.mes+'/'+historial.fecha_key!.anio,
              hora_creacion:historial.fecha_key!.hora+':'+historial.fecha_key!.minuto+':'+historial.fecha_key!.segundo,              
              usuario_creacion:historial.usuario_creacion.split('@')[0],
              ...(historial!.proceso && { proceso: historial!.proceso }),
              //modificacion                 
              ...(historial!.usuario_aprobacion && { usuario_aprobacion: historial!.usuario_aprobacion.split('@')[0] }),
            }
            dataReportPagos.push(dataReporte);
          });
        });
        const objRpt:IObjRpt={
          data_reporte:dataReportPagos,
          nombre_reporte:'Reporte_HistorialMesa',
          nombre_sheets:'HistorialMesa'
        }
        this.ventasSb?.unsubscribe();
        this.exportToExcel(objRpt);
      }else{
        this.noResultados = true;
        this.procesadoRpt.next(false);
        
      }
    })

  }

  generarReporteVentasHistorialProductos(turno:Turno){
    this.noResultados = false;
    const listaTurno:string[]=[];
    listaTurno.push(turno.id!);
    let dataReportPagos:IReporteHistorialProducto[]=[];

    this.ventaCerrada.existFetchVentas(listaTurno);
    this.ventasSb?.unsubscribe();
    this.ventasSb = this.ventaCerrada.getVentasXIDTurno().pipe(
      filter((listaVentas) => listaVentas !== undefined)).subscribe((listaVentas) => {          
      if (listaVentas) {
        //ordenando historial        
        listaVentas.forEach(item=>{
          item.pedido!.historial_producto!.sort((a,b)=>a.auto_numerico - b.auto_numerico);  
        });
        console.log(listaVentas);
        //generando datos reporte
        listaVentas.forEach(item => {
          item.pedido!.historial_producto!.forEach(historial => {
            const dataReporte: IReporteHistorialProducto = {
              //pedido
              codigo_pedido: item.pedido!.codigo_pedido,
              fecha_creacion_pedido: item.fecha_key.dia+'/'+item.fecha_key.mes+'/'+item.fecha_key.anio,
              hora_creacion_pedido: item.fecha_key.hora+':'+item.fecha_key.minuto+':'+item.fecha_key.segundo,
              mesas_completo: item.pedido!.mesas_completo.join(','),              
              usuario_creacion_pedido: item.pedido!.usuario_creacion_busqueda.split('@')[0],
              //para pedidos que nacen como incidencia
              ...(item.pedido!.es_pedido_incidencia && { es_pedido_incidencia: item.pedido!.es_pedido_incidencia ? 'Si' : 'No', }),
              ...(item.pedido!.usuario_incidencia && { pedido_incidencia_asignado_a: item.pedido!.usuario_incidencia.correo_electronico.split('@')[0], }),
              //historial
              nombre_producto: historial.nombre_producto,
              codigo_producto:historial.codigo_producto,
              codigo_grupo:historial.codigo_ticket,
              cantidad_producto:historial.cantidad_producto,
              estado_producto:historial.estado_producto.toUpperCase(),    
              fecha_estado_producto:historial.fecha.dia+'/'+historial.fecha.mes+'/'+historial.fecha.anio,
              hora_estado_producto:historial.fecha.hora+':'+historial.fecha.minuto+':'+historial.fecha.segundo,              
              usuario_creacion_estado:historial.usuario_creacion.split('@')[0],
              //modificacion
              ...(historial!.es_modificado && { es_modificado: historial!.es_modificado?'Si':'No' }),
              ...(historial!.cantidad_producto_reducido && { cantidad_producto_reducido: historial!.cantidad_producto_reducido! }),
              ...(historial!.es_eliminado && { es_eliminado: historial!.es_eliminado?'Si':'No' }),
              ...(historial!.estado_cambio && { estado_cambio: historial!.estado_cambio!.toUpperCase() }),
              ...(historial!.proceso && { proceso: historial!.proceso!.toUpperCase() }),       
              ...(historial!.usuario_aprobacion && { usuario_aprobacion: historial!.usuario_aprobacion.split('@')[0] }),
            }
            dataReportPagos.push(dataReporte);
          });
        });
        const objRpt:IObjRpt={
          data_reporte:dataReportPagos,
          nombre_reporte:'Reporte_HistorialProducto',
          nombre_sheets:'HistorialProducto'
        }
        this.ventasSb?.unsubscribe();
        this.exportToExcel(objRpt);
      }else{
        this.noResultados = true;
        this.procesadoRpt.next(false);        
      }
    })

  }

  generarReportePagosGeneral(turno:Turno){
    this.noResultados = false;
    const listaTurno:string[]=[];
    listaTurno.push(turno.id!);
    let dataReportPagos:IReportePagos[]=[];

    this.ventaCerrada.existFetchVentas(listaTurno);
    this.ventasSb?.unsubscribe();
    this.ventasSb = this.ventaCerrada.getVentasXIDTurno().pipe(
      filter((listaVentas) => listaVentas !== undefined)).subscribe((listaVentas) => {          
      if (listaVentas) { 
        //generando datos reporte
        listaVentas.forEach(item => {
          item.pedido!.lista_pago_total!.forEach(pago => {
            const dataReporte: IReportePagos = {
              //pedido
              codigo_pedido: item.pedido!.codigo_pedido,
              fecha_creacion_pedido: item.fecha_key.dia+'/'+item.fecha_key.mes+'/'+item.fecha_key.anio,
              hora_creacion_pedido: item.fecha_key.hora+':'+item.fecha_key.minuto+':'+item.fecha_key.segundo,
              mesas_completo: item.pedido!.mesas_completo.join(','),
              igv_pedido: item.pedido!.igv,
              ...(item.pedido!.servicio_igv && { igv_servicio: item.pedido!.servicio_igv }),
              ...(item.pedido!.servicio_subtotal && { subtotal_servicio: item.pedido!.servicio_subtotal }),
              ...(item.pedido!.servicio_total && { total_servicio: item.pedido!.servicio_total }),
              subtotal_pedido: item.pedido!.sub_total,
              total_pedido:  Number((Math.floor( item.pedido!.total * 100) / 100).toFixed(2)),
              total_tipos_productos_pedidos: item.pedido!.total_productos_pedidos,
              usuario_creacion_pedido: item.pedido!.usuario_creacion_busqueda.split('@')[0],
              //para pedidos que nacen como incidencia
              ...(item.pedido!.es_pedido_incidencia && { es_pedido_incidencia: item.pedido!.es_pedido_incidencia ? 'Si' : 'No', }),
              ...(item.pedido!.usuario_incidencia && { pedido_incidencia_asignado_a: item.pedido!.usuario_incidencia.correo_electronico.split('@')[0], }),
              //pagos
              monto: Number(pago.monto!),
              codigo_tipo_pago:pago.tipo_pago.codigo_medio,
              nombre_medio_pago:pago.tipo_pago.nombre_medio,                  
              usuario_creacion_pago:pago.usuario_creacion_busqueda,
              fecha_creacion_pago:pago.fecha_key.dia+'/'+pago.fecha_key.mes+'/'+pago.fecha_key.anio,
              hora_creacion_pago:pago.fecha_key.hora+':'+pago.fecha_key.minuto+':'+pago.fecha_key.segundo,         
            }
            dataReportPagos.push(dataReporte);
          });
        });       
        const objRpt:IObjRpt={
          data_reporte:dataReportPagos,
          nombre_reporte:'Reporte_Pagos',
          nombre_sheets:'pagos'
        }
        this.ventasSb?.unsubscribe();
        this.exportToExcel(objRpt);
      }
      else{
        this.noResultados = true;
        this.procesadoRpt.next(false);        
      }
    })

  }

  generarReporteVentasGeneral(turno:Turno){
    this.noResultados = false;
    const listaTurno:string[]=[];
    listaTurno.push(turno.id!);
    let dataReportVentas:IReporteVentas[]=[];

    this.ventaCerrada.existFetchVentas(listaTurno);
    this.ventasSb?.unsubscribe();
    this.ventasSb = this.ventaCerrada.getVentasXIDTurno().pipe(
      filter((listaVentas) => listaVentas !== undefined)).subscribe((listaVentas) => {          
      if (listaVentas) {       
        //generando datos reporte
        listaVentas.forEach(item => {
          item.pedido!.lista_producto!.forEach(producto => {
            const dataReporte: IReporteVentas = {
              codigo_pedido: item.pedido!.codigo_pedido,
              fecha_creacion_pedido: item.fecha_key.dia+'/'+item.fecha_key.mes+'/'+item.fecha_key.anio,
              hora_creacion_pedido: item.fecha_key.hora+':'+item.fecha_key.minuto+':'+item.fecha_key.segundo,
              mesas_completo: item.pedido!.mesas_completo.join(','),
              igv_pedido: item.pedido!.igv,
              ...(item.pedido!.servicio_igv && { igv_servicio: item.pedido!.servicio_igv }),
              ...(item.pedido!.servicio_subtotal && { subtotal_servicio: item.pedido!.servicio_subtotal }),
              ...(item.pedido!.servicio_total && { total_servicio: item.pedido!.servicio_total }),
              subtotal_pedido: item.pedido!.sub_total,
              total_pedido:  Number((Math.floor( item.pedido!.total * 100) / 100).toFixed(2)),
              total_tipos_productos_pedidos: item.pedido!.total_productos_pedidos,
              usuario_creacion_pedido:item.pedido!.usuario_creacion_busqueda.split('@')[0],
              //para pedidos que nacen como incidencia
              ...(item.pedido!.es_pedido_incidencia &&{es_pedido_incidencia: item.pedido!.es_pedido_incidencia?'Si':'No',}),
              ...(item.pedido!.usuario_incidencia &&{pedido_incidencia_asignado_a: item.pedido!.usuario_incidencia.correo_electronico.split('@')[0],}),
              
              //producto
              cantidad_producto: producto.cantidad_producto,
              nombre_producto: producto.nombre_producto,
              tipo_producto:producto.tipo_producto,
              codigo_producto: producto.codigo_producto,
              //codigo_grupo_pedido: producto.codigo_ticket,
              precio_unitario_producto:  Number((Math.floor(producto.precio_unitario * 100) / 100).toFixed(2)),
              usuario_creacion_producto:producto.usuario_creacion.split('@')[0],
              fecha_creacion_producto: producto.fecha_key.dia+'/'+producto.fecha_key.mes+'/'+producto.fecha_key.anio,
              hora_creacion_producto: producto.fecha_key.hora+':'+producto.fecha_key.minuto+':'+producto.fecha_key.segundo,
              precio_total_producto:  Number((Math.floor(producto.precio_total * 100) / 100).toFixed(2)),              
              igv_valor_producto: producto.igv_valor_producto,
              //descuentos e incidencias
              descuento_producto: producto.descuento?'Si':'No',
              porcentaje_descuento_producto: producto.descuento? producto.descuento.porcentaje_descuento:0,
              cantidad_unidades_afectas_descuento:producto.descuento?producto.descuento.cantidad_producto:0,
              comentario_descuento:producto.descuento?producto.descuento.comentario? producto.descuento.comentario:'-':'-',
              incidencia_producto:producto.incidencia?'Si':'No',
              cantidad_unidades_afectas_incidencia: producto.incidencia? producto.incidencia.cantidad_producto:0,
              comentario_incidencia: producto.incidencia?producto.incidencia.comentario? producto.incidencia.comentario:'-':'-',
            }
            dataReportVentas.push(dataReporte);
          });
        }); 
        const objRpt:IObjRpt={
          data_reporte:dataReportVentas,
          nombre_reporte:'Reporte_Ventas',
          nombre_sheets:'ventas'
        }
        this.ventasSb?.unsubscribe();
        this.exportToExcel(objRpt);
      }else{
        this.noResultados = true;
        this.procesadoRpt.next(false);        
      }
    })

  }

  exportToExcel(objRpt: IObjRpt): void {
    (() => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(objRpt.data_reporte);
      const workbook: XLSX.WorkBook = { Sheets: { [objRpt.nombre_sheets]: worksheet }, SheetNames: [objRpt.nombre_sheets] };
      //console.log("Workbook:", workbook);
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //console.log("Excel buffer:", excelBuffer);
      this.saveAsExcelFile(excelBuffer, objRpt.nombre_reporte);
    })();
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    this.procesadoRpt.next(false);
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, `${fileName}_export_${new Date().getTime()}.xlsx`);    
  }
}
