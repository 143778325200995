import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Icons } from 'src/app/shared/cons/icons';
import { Utils } from 'src/app/shared/helpers/utils';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { Msjs, Msjs_Validations, PatternsRegex} from '../../../cons/common'
import { SnackBarType } from '../../../types/snackbar-type';
import { AutoUnsubscribe } from "../../../helpers/decorators/AutoUnsubscribe";
import { RecaptchaService } from 'src/app/shared/services/recaptcha.service';
import { RecaptchaType } from 'src/app/shared/types/types-recaptcha';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

declare var grecaptcha: any;

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})

@AutoUnsubscribe

export class CaptchaComponent implements OnInit {

  CLOSE:string = Icons.CLOSE;
  CANCEL:string = Icons.CANCEL;
  isLoading:boolean=false;
  error_resultado:boolean=false;
  //constantes
  readonly Msjs_Validations:typeof Msjs_Validations = Msjs_Validations;
  readonly RecaptchaType: typeof RecaptchaType = RecaptchaType;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  loadingSubscription!:Subscription;

  lCaptcha:string[]=[];
  onlyNumbers = Utils.InputOnlyNumbers;
  
  incorrectoCel:boolean= true;
  loadingGeneral:boolean = false;
  loadingGeneralSb!:Subscription;
  esValidoResultado:boolean = false;
  visibleOperacion:boolean = true;
  contadorIndice:number = 1;
  private readonly key = environment.key.COMPROBANTE_ELECTRONICO_KEY;

  constructor(
    private functionService:FunctionsService,
    private uiService:UIService,
    private recaptchaService: RecaptchaService
    ) {}

  ngOnInit(): void {
    this.loadingSubscription?.unsubscribe;
    this.loadingSubscription = this.uiService.loadingStateChanged.subscribe(loading=> this.isLoading = loading);

    this.functionService.GetCaptchaAritmetico().then(result=>{
     this.lCaptcha = this.decryptCaptcha(result);
      //para ocultar el modal
      this.uiService.loadingStateChanged.next(false);
    });
  }

  decryptCaptcha(response:string){
    const resp = JSON.parse(CryptoJS.AES.decrypt(response, this.key).toString(CryptoJS.enc.Utf8));
    return resp;

  }

  validarResultado(form:NgForm){    
    let flag = Number(form.value.resultado)===Number(this.lCaptcha[2])? true:false;
    if(flag){
      this.esValidoResultado=true;
      this.visibleOperacion = false;
      this.contadorIndice +=1;
    }else{
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_RESPUESTA_INCORRECTA);
      return;
    }
  }

  tieneEmail(event:any,form:NgForm){
    let numeroMovilx = form.value.email;
    const regex = PatternsRegex.onlyEmail
    if(regex.test(numeroMovilx)){
      this.incorrectoCel=false;
    }
    else{
      this.incorrectoCel = true;    
    }    
  }

  waitForReCaptcha(callback: () => void) {
    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.ready(() => {
        callback();
      });
    } else {
      const interval = setInterval(() => {
        if (typeof grecaptcha !== 'undefined') {
          clearInterval(interval);
          grecaptcha.ready(() => {
            callback();
          });
        }
      }, 100);
    }
  }

  onSubmit(form:NgForm){
    let correo = form.value.email;
    const actionUser= RecaptchaType.REGISTER;
    this.recaptchaService.executeAction(actionUser).then((token) => {
     
      this.isLoading=true;
      this.verifyCaptcha(token, correo, actionUser);
    },
      (error) => {
        console.log(error);
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_VERIFICACION_RECAPTCHA_GRAL);
      });  
  }

  verifyCaptcha(token: string, correo:string, actionUser:string) {    
    //se emplea recaptcha version 3 invisible
    this.functionService.VerifyCaptcha(token, actionUser).then(res=>{      
      if(res.success){
        //enviar mail al cliente        
        this.uiService.CloseModalCaptcha(),
        this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MSJ_ENVIO_EMAIL),
        this.functionService.SendMailGoDaddySMTPRegister(correo).then(resultado=>{
          const resltDecrypt =  this.decryptCaptcha(resultado.data);
          if(resltDecrypt.tx){            
            this.isLoading = false;
            this.contadorIndice+=1;
            this.uiService.ShowModalCaptchaCodigo({codigo:resltDecrypt.code, correo: correo, etapa:this.contadorIndice});          
          }
          else{
            this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs_Validations.MSJ_VAL_SOLO_EMAIL),
            this.isLoading = false;
            this.onClose();
          }          
        });
      }else{
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_VERIFICACION_RECAPTCHA)
      }
    }, error=>{      
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_VERIFICACION_RECAPTCHA)
    });     
  }

  onClose(){
    this.uiService.CloseModalCaptcha();
  }
}
