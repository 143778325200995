import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ListaTipoPregunta, Msjs, Msjs_Validations, PatternsRegex, SistOperation } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { TipoPregunta } from '../models/tipo-pregunta';
import { EncuestaFinal } from '../models/encuesta-final';
import { NgModel } from '@angular/forms';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-tipo-pregunta',
  templateUrl: './tipo-pregunta.component.html',
  styleUrls: ['./tipo-pregunta.component.scss']
})
export class TipoPreguntaComponent implements OnInit {

  readonly Icons: typeof Icons = Icons;
  isLoading:boolean=false;
  titleModal:string='';
  codigo:number =0;
  visibleTipoLista:boolean = false;
  visibleTipoBinaria:boolean = false;
  visibleTipoSeleccionSatisfaccion:boolean = false;
  visibleOpinionCliente:boolean = false;
  visibleOpinionEncuestador:boolean = false;
  visibleRegistroCliente:boolean = false;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  modoOperacion: SistOperation = SistOperation.VER;
  readonly SistOperation: typeof SistOperation = SistOperation;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly ListaTipoPregunta : typeof ListaTipoPregunta = ListaTipoPregunta;
  tipoPregunta:TipoPregunta = new TipoPregunta();
  encuestaFinal: EncuestaFinal = new EncuestaFinal(false,true,null,null,null,null);
  txtOpcion:string ='';
  listaOpcion:string[]=[];
  @ViewChild('txtOpcionInput') txtOpcionInput!: NgModel;
  preguntaEditar:TipoPregunta = new TipoPregunta();
  
  
  constructor(
    private uiService: UIService,
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef: MatDialogRef<TipoPreguntaComponent>,
  ) { }

  ngOnInit(): void {
    this.titleModal = this.datoPasado.data.tipo_pregunta.titulo;
    this.modoOperacion = this.datoPasado.data.modoOperacion;
    this.codigo = this.datoPasado.data.tipo_pregunta.codigo;

    if(this.modoOperacion==SistOperation.EDITAR){
      this.preguntaEditar = this.datoPasado.data.preguntaEditar;
      this.tipoPregunta.pregunta = this.preguntaEditar.pregunta;
      this.listaOpcion =  this.preguntaEditar.lista_opcion!
    }
  
  }

  onClose(){
    this.dialogRef.close();
  }
  aniadirOpcionLista(){
    if(this.txtOpcion!='' && this.txtOpcion){
      this.listaOpcion.push(this.txtOpcion);
      this.txtOpcion ='';
      setTimeout(() => {
        if (this.txtOpcionInput) {
          this.txtOpcionInput.reset();
        }
      });
    }else{
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_NO_OPCION, 3000);
    }
  
  }
  agregarPregunta(){
    if(this.modoOperacion==SistOperation.NUEVO){
      this.tipoPregunta.codigo_pregunta = Utils.generarCodigoRandom(6);
    }else{
      this.tipoPregunta.codigo_pregunta = this.preguntaEditar.codigo_pregunta;
    }    
    this.tipoPregunta.lista_opcion = this.listaOpcion;
    this.tipoPregunta.tipo_pregunta = this.codigo;    
    this.dialogRef.close(this.tipoPregunta);
  }

  cancelarPregunta(){
    this.dialogRef.close();
  }
  removerOpcion(opt:string){
    this.listaOpcion = this.listaOpcion.filter(opcion=>opcion!=opt);
  }



}
