import { CCliente } from "../../formularios-encuesta/models/cliente.model";

export class TipoPregunta{
    codigo_pregunta:string='';
    pregunta:string='';
    tipo_pregunta:number=0;
    lista_opcion?:string[];//tipolista
    opinion_cliente?:string;
    comentario_encuestador?:string;
    cliente?:CCliente;
    
}