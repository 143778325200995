<div fxLayout="column" style="margin-bottom: 30px;" fxLayoutAlign="center center">
    <div *ngIf="isLoading"  fxLayout="column" style="margin-top: 50px;">
        <app-spinner></app-spinner>
    </div>
    <div *ngIf="!isLoading && turno.id!==undefined" fxLayout="column">
        <div class="titulos-ind" fxLayout="row" fxLayoutAlign="space-between center" >
            <h3>Indicadores Económicos</h3>
            <h3 matTooltip="Incluye añadidos y descuentos" >Efectivo Caja : S/ {{totalEfectivoDisponibleCaja | number:'1.2'}}</h3>
        </div>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around center" *ngIf="listaIndicadoresEconomicos.length>0" >
            <ng-container *ngFor="let indicador of listaIndicadoresEconomicos">
                <div>
                    <app-indicador-resumen 
                    [showDecimal]="true" 
                    [title]="indicador.title" 
                    [icon]="indicador.icon" 
                    [qyt]="indicador.qyt" 
                    [isGray]="indicador.isGray" >
                    </app-indicador-resumen>
                </div>
            </ng-container>       
        </div>
        <div style="margin-top: 20px;">
            <mat-divider></mat-divider>
        </div>
        <div class="titulos-ind">
            <h3>Indicadores Cuantificables</h3>
        </div>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around center" *ngIf="listaIndicadoresCantidad.length>0">
            <ng-container *ngFor="let indicador of listaIndicadoresCantidad">
                <app-indicador-resumen    [showDecimal]="false" 
                [title]="indicador.title" 
                [icon]="indicador.icon" 
                [qyt]="indicador.qyt" 
                [isGray]="indicador.isGray">
                </app-indicador-resumen>
            </ng-container>
        </div>
    </div>
    <div *ngIf="turno.id===undefined">
        <span>No se aperturó un turno.</span>
    </div>


</div>