import { ChangeDetectorRef, Component, DoCheck, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Icons } from 'src/app/shared/cons/icons';
import { CEncuesta } from '../models/encuesta.model';
import { Msjs, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { MatStepper } from '@angular/material/stepper';
import { Utils } from 'src/app/shared/helpers/utils';
import {
  trigger,
  state,
  style,
  animate,
  transition
  // ... puedes importar más funciones si las necesitas
} from '@angular/animations';
import { UIService } from 'src/app/shared/services/ui.services';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { EncuestaBlService } from '../encuesta.bl.service';
import { Subscription } from 'rxjs';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { IObjRpt } from 'src/app/shared/services/models/IObjRpt';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { IEncuesta } from '../models/IEncuesta';

export interface mozo {
  nombres:string,
  codigo:number
}



@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class EncuestaComponent implements OnInit {

  errorMsg:string='La pregunta es requerida';
  readonly Icons: typeof Icons = Icons;
  encuesta:CEncuesta= new CEncuesta();
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;

  validMozoFlag:boolean = false;
  validServicioFlag:boolean = false;
  validCoctelesFlag:boolean = false;
  validPiqueosFlag:boolean = false;
  validRecomendacion:boolean = false;
  validComentarioFlag:boolean = false;
  validComentarioEncuestador:boolean = false;
  validDatosFlag:boolean = false;
  
  onlyNumbers = Utils.InputOnlyNumbers;

  @ViewChild('stepper', {static:false}) private myStepper!: MatStepper;
  previousStepCount:number=1;
  visible1:boolean = true;
  visible2:boolean = false;
  visibleBoton:boolean = false;

  lMozo:mozo[]=[
    {
      nombres: 'No recuerdo',
      codigo:1
    },
    {
      nombres: 'Viviana',
      codigo:2
    },
    {
      nombres: 'Rody',
      codigo:3
    },
    {
      nombres: 'Yuriko',
      codigo:4
    },
    {
      nombres: 'Percy',
      codigo:5
    },
    {
      nombres: 'Arelys',
      codigo:6
    },
    {
      nombres: 'Teresa',
      codigo:7
    },
    {
      nombres: 'Joel',
      codigo:8
    },
    {
      nombres: 'Edith',
      codigo:9
    },
    {
      nombres: 'Celeste',
      codigo:10
    },
    {
      nombres: 'Sergio',
      codigo:11
    },
    {
      nombres: 'Jack',
      codigo:12
    },
    {
      nombres: 'Lizbeth',
      codigo:10
    },
]


  constructor(
    private cdRef: ChangeDetectorRef,
    private uiService:UIService,
    private encuestaBLService: EncuestaBlService,
    private registroService:RegistroService
    ) { }

  ngOnInit(): void {
    if(this.registroService.empresa){
      this.visibleBoton = true;
    }
  }
  generarReporte(){
    this.encuestaBLService.getEncuestas().then(lista=>{
      if(lista.length>0){        
        let listaEncuestas:IEncuesta[]=[];
        lista.sort((a,b)=>a.fecha_creacion-b.fecha_creacion);
        console.log(lista);                
        lista.forEach(enc=>{          
          const mozox:mozo = this.lMozo.filter(mzo=>mzo.codigo==enc.codigo_mozo)[0] as mozo;
          if('codigo' in mozox){
            const milis = enc.fecha_creacion.seconds * 1000 + Math.floor(enc.fecha_creacion.nanoseconds / 1000000);
            const fechaCreacion = new Date(milis);
            const mozoNombre = this.lMozo.filter(mozo=>mozo.codigo==mozox.codigo)[0].nombres;//temporal hasta sacar la otra version
            const encF:IEncuesta = {
              fecha_creacion:fechaCreacion,
              nombre_mozo: !mozoNombre?'':mozoNombre, //!mozox.nombres?'':mozox.nombres,
              puntaje_servicio:enc.puntaje_servicio,
              puntaje_coctel:enc.puntaje_coctel==0?'-': enc.puntaje_coctel.toString(),
              puntaje_piqueos:enc.puntaje_piqueos==0?'-': enc.puntaje_piqueos.toString(),
              nos_recomendarias: enc.puntaje_recomendacion==0?'No':'Si',
              comentario:enc.comentario,
              ...(enc.cliente.nombres!=='' &&{cliente_nombre: enc.cliente.nombres+' '+enc.cliente.apellido_paterno+' '+enc.cliente.apellido_materno}),
              ...(enc.cliente.correo_electronico!=='' &&{correo_electronico: enc.cliente.correo_electronico}),
              ...(enc.cliente.numero_telefono!=='' &&{numero_telefono: enc.cliente.numero_telefono}),
              comentario_encuestador: enc.comentario_encuestador
            };
            listaEncuestas.push(encF);
          }else{
            console.log(mozox);
          }
        
        });

        const objRpt:IObjRpt={
          data_reporte:listaEncuestas,
          nombre_reporte:'Reporte_Encuestas',
          nombre_sheets:'Encuesta'
        }
        this.exportToExcel(objRpt);
      }else{
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 5000);
      }
     
    });
  }

  exportToExcel(objRpt: IObjRpt): void {
    (() => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(objRpt.data_reporte);
      const workbook: XLSX.WorkBook = { Sheets: { [objRpt.nombre_sheets]: worksheet }, SheetNames: [objRpt.nombre_sheets] };
      //console.log("Workbook:", workbook);
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //console.log("Excel buffer:", excelBuffer);
      this.saveAsExcelFile(excelBuffer, objRpt.nombre_reporte);
    })();
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {    
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, `${fileName}_export_${new Date().getTime()}.xlsx`);    
  }

  sendServicio(punto:number){
    this.encuesta.puntaje_servicio = punto;
    this.validCoctelesFlag = true;
  }

  sendCocteles(punto:number){
    this.encuesta.puntaje_coctel = punto;
    this.validPiqueosFlag=true;
  }

  sendPiqueo(punto:number){
    this.encuesta.puntaje_piqueos = punto;
    this.validRecomendacion=true;
  }

  sendRecomendacion(punto:number){
    this.encuesta.puntaje_recomendacion = punto;
    this.validComentarioFlag=true;
  }

  sendComentario(){
    this.validDatosFlag=true;
  }
  sendOmitirDatosPersonales(){
    this.validComentarioEncuestador=true;
  }

  nextMozo(){
    this.validMozoFlag = true;

  }

  ngAfterViewChecked(){
    if(this.myStepper){
      if(this.myStepper.steps.length!==this.previousStepCount){
        this.myStepper.selectedIndex = this.myStepper.steps.length-1;
        this.previousStepCount = this.myStepper.steps.length;
        //console.log(this.myStepper.steps.length);
        // Solicita una nueva detección de cambios
        this.cdRef.detectChanges();
      }
    }
  }
  Guardar(){
    this.visible1= true;
    this.visible2 = false;
    this.encuestaBLService.insertEncuesta(this.encuesta).then(res=>{
      if(res.id){
        this.encuesta = new CEncuesta();
        this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 5000);
      }else{
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 5000);
      }
    }).catch(error=>{
      this.uiService.ShowSnackBar(SnackBarType.ERROR, error, 5000);
      //console.log(error)
    });
  }

  irEncuesta(){
    this.visible1= false;
    this.visible2 = true;
  }

}
