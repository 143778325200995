import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TableService } from '../table/table.service';
import { VentaCerradaBlService } from '../procesos/services/venta-cerrada-bl.service';
import { BuscarService } from '../buscar/buscar.service';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { RegistroService } from '../../services/registro.service';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { CriteriosBusqueda, MsjEstado, Msjs, SistOperation, TableTypes, TamanioPagina } from '../../cons/common';
import { Icons } from '../../cons/icons';
import { Table } from '../table/table.model';
import { CBuscar } from '../buscar/CBuscar';
import { GestionMontoBlService } from '../gestion-monto/services/gestion-monto.bl.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Turno } from '../gestion-monto/models/Turno';
import { ITablePedidosFiltro } from '../registrar-pedido/services/models/ITablePedidosFiltro';
import { PedidoBlService } from '../registrar-pedido/services/services.bl/pedido.bl.service';
import { Pedido } from '../registrar-pedido/models/Pedido';
import { ITableEvento } from '../table/ITableEvento';
import { UIService } from '../../services/ui.services';
import { SnackBarType } from '../../types/snackbar-type';
import { FunctionsService } from '../../services/functions.service';
import { SendApiComprobanteElectronicoService } from '../../services/send-api-comprobante-electronico.service';
import { IRespuestaAnulacionReseller } from '../../services/models/IRespuestaAnulacionReseller';
import { IProductoTicket } from '../registrar-pedido/models/IProductoTicket';
import { GestionAspectosBlService } from '../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { AspectosGenerales } from '../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { Utils } from '../../helpers/utils';
import { Fecha } from 'functions/src/class_interface';
import { SendPersona } from '../../services/models/sendPersona';
import { IGetAPIRUC } from '../../services/models/IGetAPIRUC';
import { EstadosComprobanteElectronicoType } from '../../types/estado-comprobante-electronico-type';
import { IRespuestaReseller } from '../../services/models/IRespuestaReseller';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-gestion-ventas-cerradas',
  templateUrl: './gestion-ventas-cerradas.component.html',
  styleUrls: ['./gestion-ventas-cerradas.component.scss']
})

@AutoUnsubscribe

export class GestionVentasCerradasComponent implements OnInit {

  tipoTabla: string = TableTypes.LISTAR_VENTA_CERRADA;
  readonly TableTypes: typeof TableTypes = TableTypes;
  readonly EstadosComprobanteElectronicoType: typeof EstadosComprobanteElectronicoType = EstadosComprobanteElectronicoType;
  tieneTurnoAperturado: boolean = false;
  isLoading: boolean = false;
  isExpanded: boolean = false;
  readonly Icons: typeof Icons = Icons;
  listadoMesas: number[] = new Array();
  dataTable: Table = new Table();
  buscar: CBuscar = new CBuscar();
  turnoSb!:Subscription;
  esAdministrador: boolean = false;
  turno: Turno = new Turno();
  listaPedidoSb!: Subscription;  
  btnVerSb!:Subscription;
  btnBuscarSb!: Subscription;
  btnAnularComprobanteSb!: Subscription;
  btnConsultarAnularComprobanteSb!: Subscription;
  btnEmitirComprobanteSb!: Subscription;
  sendApiComprobanteSb!:Subscription;
  modalSb!:Subscription;
  reimprimirSb!:Subscription;
  listaProductoSb!:Subscription;
  tasasSb!:Subscription;
  //reimprimir comprobante
  listaProductoPedido: IProductoTicket[]=[];
  pedido:Pedido = new Pedido();
  aspectosGenerales = new AspectosGenerales();
  listaMesasImprimir:string='';
  fechaKeyPagoTotal:Fecha = new Fecha();
  tipoComprobante:number=0;
  usuarioSend: SendPersona = new SendPersona();
  rucResponse: IGetAPIRUC | null=null;
  result!:IRespuestaReseller;
  esPantallaXs: boolean = false;
  mediaSub!: Subscription
  tamanioPantalla: string = '';

  constructor(
    private tableService: TableService,    
    private registroService: RegistroService,
    private spinnerPageService: SpinnerPageService,
    private buscarService: BuscarService,
    private gestionMontoBLService: GestionMontoBlService,
    private pedidoBlService: PedidoBlService,
    private uiService: UIService,    
    private sendApiComprobante: SendApiComprobanteElectronicoService,
    private pedidoBLService: PedidoBlService,
    private gestionAspectosBL: GestionAspectosBlService,
    private cdRef: ChangeDetectorRef,
    private mediaObserver: MediaObserver,
  ) {
      //definimos las propiedades de la tabla
      this.dataTable.modoXs = ['numeracion', 'codigo_pedido', 'emision_comprobante','mesas_asignado','operaciones'];
      this.dataTable.modoM = ['numeracion', 'codigo_pedido', 'emision_comprobante', 'total_productos_pedidos','fecha_registro','mesas_asignado','descuento','incidencia','pedido_incidencia' ,'operaciones'];
      this.dataTable.modoLg = ['numeracion', 'codigo_pedido', 'emision_comprobante', 'total_productos_pedidos','mesas_asignado','total_string','fecha_registro', 'usuario_registro', 'descuento','incidencia','pedido_incidencia', 'operaciones'];
      this.dataTable.columnaEstado = 'emision_comprobante';
      this.dataTable.columnaImagen = '';
      this.dataTable.columnaEstiloEstado = 'emision_comprobante';
      this.dataTable.pageSizeOptions = TamanioPagina.LISTA_TAMANIOS
      this.dataTable.pageSize =  TamanioPagina.LISTA_TAMANIOS[10];// tamaño de pagina
      this.dataTable.currentPage = 1; //pagina inicial
      this.dataTable.nextPage = null;
      this.dataTable.previousPage = null;
      this.dataTable.columnaChip = undefined;
      this.dataTable.operaciones = [SistOperation.EMITIR_COMPROBANTE,SistOperation.ANULAR_COMPROBANTE,SistOperation.SINCRONIZAR_COMPROBANTE, SistOperation.REIMPRIMIR_COMPROBANTE,SistOperation.VER];
      this.dataTable.filtro[0] = null; //filtro codigo
      this.dataTable.filtro[1] = null;
      this.dataTable.filtro[2] = null; //filtro usuario
      //this.dataTable.filtro[3] = null; //filtro mesas
      this.dataTable.filtro[4] = null; //filtro estadoPedidoVigenteFiltro
      this.dataTable.minPage = true;//cambia ordenamiendo descendente
      this.dataTable.maxPage = null;//cambia ordenamiendo ascendente
   }

  ngOnInit(): void {
    // Suscribirse a los cambios en el tamaño de pantalla
    this.mediaSub = this.mediaObserver.asObservable().subscribe((changes) => {
      this.tamanioPantalla = changes[0].mqAlias;
      this.redimensionaPantalla();
    });

    this.spinnerPageService.show();
    this.cargarBusqueda();

    if (this.registroService.empresa) {
      this.esAdministrador = this.registroService.esAdministrador;
        
      this.dataTable.usuarioAdministrador = this.registroService.esAdministrador;
      this.isLoading = true;
      //consultando existencia de turno
      this.gestionMontoBLService.existFetchTurno();
      this.turnoSb?.unsubscribe();
      this.turnoSb = this.gestionMontoBLService.getTurno().pipe(filter((turno) => turno !== undefined)).subscribe((turno) => {
          if (turno && turno.id) {
            this.isLoading = false;
            this.tieneTurnoAperturado = true;
            this.turno = turno;
            //generando la tabla de pedidos que pertenecen al turno
            this.generarTabla(turno.id);
          } else {
            this.isLoading = false;
            this.tieneTurnoAperturado = false;
          }
          //this.spinnerPageService.hide();
        });

      this.gestionAspectosBL.existFetchTasas();
      this.tasasSb?.unsubscribe();
      this.tasasSb = this.gestionAspectosBL.getTasas().subscribe((tasas) => {
        if (tasas) {
            this.aspectosGenerales = tasas;
          }
          this.spinnerPageService.hide();
      });
    }else{
      this.spinnerPageService.hide();
    }

    //boton ver pedido
    this.btnVerSb?.unsubscribe();
    this.btnVerSb = this.tableService.btnVer$.subscribe(
      (callEvento: ITableEvento) => {
        if (this.tipoTabla == callEvento.key) {
          const pedido = callEvento.data as Pedido;
          if (this.turno.id && pedido.id) {
            this.spinnerPageService.show();
            this.uiService.showDialoVerPedido({
              dato: pedido,
              turno: this.turno,
            });
          }
        }
      }
    );

    this.reimprimirSb?.unsubscribe();
    this.reimprimirSb = this.tableService.btnImprimir$.subscribe((callEvento:ITableEvento)=>{
      if (this.tipoTabla == callEvento.key) {
        this.pedido = new Pedido();
        this.pedido = callEvento.data as Pedido;
        this.listaProductoSb?.unsubscribe();
        this.listaProductoSb = this.pedidoBLService.getProductosXPedido(this.turno.id!, this.pedido.id!).subscribe(listaProds => {
          if (listaProds) { 
            this.listaProductoPedido = [];
            this.spinnerPageService.hide();
            //dando la forma para el comprobante
            this.listaProductoPedido = JSON.parse(JSON.stringify(listaProds));
            this.fechaKeyPagoTotal = this.pedido.fecha_key_pago_total!
            this.listaMesasImprimir = Utils.generarMesasFila(this.pedido.mesas_completo);
            this.tipoComprobante = this.pedido.comprobante_electronico!.tipo_de_comprobante==1?2:1;//1: factura nubefact | 2:factura en el sistema; 2:boleta nubefact | 1:boleta en el sistema
            if(this.pedido.comprobante_electronico){
              const entidadRecepcion = this.pedido.comprobante_electronico.entidad_recepcion!
              if(this.pedido.comprobante_electronico.tipo_de_comprobante==1)//factura para reseller nubefact
              {
                this.rucResponse = entidadRecepcion as IGetAPIRUC;
              }
              else{
                this.usuarioSend = entidadRecepcion as  SendPersona;
              }
            }
            this.result = this.pedido.comprobante_electronico!;

            //quitanto productos prepagados            
            this.listaProductoPedido = JSON.parse(JSON.stringify(this.listaProductoPedido.filter(prod => prod.es_pagado == undefined)));
            this.cdRef.detectChanges();
            //comunica que se emitie comprobante  de venta   
            this.uiService.printComanda$.next(this.tipoTabla);
          }
        });
      }
    });

    //emitir comprobante
    this.btnEmitirComprobanteSb?.unsubscribe();
    this.btnEmitirComprobanteSb = this.tableService.btnEmitirComprobante$.subscribe( (callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        if(this.registroService.empresa.comprobante_electronico == undefined){
          this.uiService.ShowSnackBar(
            SnackBarType.ERROR,Msjs.MSJ_ERROR_NO_CUENTA_CONFIG_COMPROBANTE_ELECTRONICO,
            3000
          );
          return;
        }
        this.pedido = new Pedido();
        this.pedido = callEvento.data as Pedido;
        if(this.pedido.es_pedido_incidencia){
          this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs.MSJ_ERROR_DIVIDIR_PEDIDO_INCIDENCIA,3000);
          return;
        }
        this.listaProductoSb?.unsubscribe();
        this.listaProductoSb = this.pedidoBLService.getProductosXPedido(this.turno.id!, this.pedido.id!).subscribe(listaProds => {
        if (listaProds) {         
          this.listaProductoPedido = [];
          this.spinnerPageService.hide();
          this.listaProductoPedido = JSON.parse(JSON.stringify(listaProds));
          //quitanto productos prepagados          
          this.listaProductoPedido = JSON.parse(JSON.stringify(this.listaProductoPedido.filter(prod => prod.es_pagado == undefined)));
          this.uiService.showDialogEmitirComprobante({ dato:this.pedido, listaProductoPedido:this.listaProductoPedido, idTurno:this.turno.id!});         
        }
        });        
      }
    });

    //consultar anular
    this.btnConsultarAnularComprobanteSb?.unsubscribe();
    this.btnConsultarAnularComprobanteSb = this.tableService.btnSincronizarComprobante$.subscribe( (callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        const pedido = callEvento.data as Pedido;
        if(this.pedido.es_pedido_incidencia){
          this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs.MSJ_ERROR_DIVIDIR_PEDIDO_INCIDENCIA,3000);
          return;
        }
        this.sendApiComprobanteSb?.unsubscribe();
        this.spinnerPageService.show();
        this.sendApiComprobanteSb =  this.sendApiComprobante.consultaAnularComprobanteElectronico(pedido,true,this.turno.id!).subscribe(res=>{
          this.spinnerPageService.hide();
          const respuestaRess= res as IRespuestaAnulacionReseller;
          //console.log(respuestaRess);
          if(respuestaRess.status=='ok'){
            if(respuestaRess.data.aceptada_por_sunat){
              this.uiService.ShowSnackBar(
                SnackBarType.SUCCESS,
                Msjs.MSJ_SUCCESS_SE_ANULO_COMPROBANTE,
                3000
              );
              return;
            }
            if(respuestaRess.data.sunat_soap_error){
              this.uiService.ShowSnackBar(
                SnackBarType.ERROR,
                respuestaRess.data.sunat_soap_error,
                5000
              );
              return;
            }         
          }else{
            this.spinnerPageService.hide();
            if(respuestaRess.data.sunat_description!=null){
              const msjError = respuestaRess.data.sunat_description
              this.uiService.ShowSnackBar(
                SnackBarType.ERROR,
                Msjs.MSJ_ERROR_ANULACION_COMPROBANTE_ELECTRONICO + msjError,
                3000
              );
            }            
          }
        });
      }
    });

    //anular comprobante
    this.btnAnularComprobanteSb?.unsubscribe();
    this.btnAnularComprobanteSb = this.tableService.btnAnularComprobante$.subscribe(
      (callEvento: ITableEvento) => {
        if (this.tipoTabla == callEvento.key) {
          const pedido = callEvento.data as Pedido;
          if(this.pedido.es_pedido_incidencia){
            this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs.MSJ_ERROR_DIVIDIR_PEDIDO_INCIDENCIA,3000);
            return;
          }
          if(pedido.comprobante_electronico==undefined){            
            this.uiService.ShowSnackBar(
              SnackBarType.ERROR,
              Msjs.MSJ_ERROR_NO_CUENTA_COMPROBANTE_ELECTRONICO,
              3000
            );
          }else{
            const tipoComprobante = pedido.comprobante_electronico!.tipo_de_comprobante==1?'(Factura)':'(Boleta de Venta)'
            const msjComprobante = (pedido.comprobante_electronico!.serie+'-'+pedido.comprobante_electronico!.numero+' '+tipoComprobante)
            this.modalSb = this.uiService
            .showModalQuestions({
              title: Msjs.TITULO_CONFIRMACION,
              message: Msjs.MENSAJE_CONFIRMACION_ELIMINAR_COMPROBANTE + msjComprobante+'?',
              icon: Icons.QUESTION_MARK,
            })
            .subscribe(async (result) => {
              if (result) {
                this.spinnerPageService.show();
                this.sendApiComprobanteSb?.unsubscribe();
                this.sendApiComprobanteSb = this.sendApiComprobante.anularComprobanteElectronico(pedido,true,this.turno.id!).subscribe(res=>{
                  this.spinnerPageService.hide();
                  const respuestaRess= res as IRespuestaAnulacionReseller;
                  if(respuestaRess.status=='ok' && respuestaRess.data.aceptada_por_sunat){
                    this.uiService.ShowSnackBar(
                      SnackBarType.SUCCESS,
                      Msjs.MSJ_SUCCESS_SE_ANULO_COMPROBANTE,
                      3000
                    );
                  }else{
                    this.spinnerPageService.hide();
                    if(respuestaRess.data.sunat_description!=null){
                      const msjError = respuestaRess.data.sunat_description
                      this.uiService.ShowSnackBar(
                        SnackBarType.ERROR,
                        Msjs.MSJ_ERROR_ANULACION_COMPROBANTE_ELECTRONICO + msjError,
                        3000
                      );
                    }
                    
                  }                  
                });

              }});
          }
        }
      }
    );

    //busqueda
    this.btnBuscarSb?.unsubscribe();
    this.btnBuscarSb = this.buscarService.goBuscar$.subscribe(
      (callEvento: ITableEvento) => {
        if (this.tipoTabla == callEvento.key) {
          this.dataTable.filtro[0] = null; //filtro codigo
          //this.dataTable.filtro[1] = null; //filtro estado
          this.dataTable.filtro[2] = null; //filtro usuario

          this.dataTable.minPage = null;
          this.dataTable.maxPage = null;
          this.dataTable.nextPage = null;
          this.dataTable.previousPage = null;
          this.dataTable.currentPage = 1;
          this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[1]; //tamaño de pagina
          const data: CBuscar = callEvento.data;

          switch (data.criterio_seleccionado) {
            case CriteriosBusqueda.codigo: {
              this.dataTable.filtro[0] = data.valor_criterio; //filtro codigo
              break;
            }
            case CriteriosBusqueda.estado: {
              this.dataTable.filtro[1] =
                data.estado_seleccionado == MsjEstado.ABIERTO ? true : false; //CODIGO estado
              break;
            }
            case CriteriosBusqueda.usuario_creacion: {
              this.dataTable.filtro[2] = data.valor_criterio; // usuario
              break;
            }
            // case CriteriosBusqueda.mesa: {
            //   this.dataTable.filtro[3] = data.valor_criterio;// mesas
            //   break;
            // }
            default: {
              this.dataTable.filtro[0] = null; //filtro codigo
              this.dataTable.filtro[1] = null; //filtro estado
              this.dataTable.filtro[2] = null; //filtro usuario
              this.dataTable.filtro[3] = null; //filtro mesas
              this.dataTable.filtro[4] = null; //filtro estadoPedidoVigenteFiltro
            }
          }

          if (this.turno.id) {
            this.generarTabla(this.turno.id);
          }
        }
      }
    );
  }

  redimensionaPantalla() {
    if (this.tamanioPantalla === 'xs') {
      this.esPantallaXs = true;
    } else {
      this.esPantallaXs = false;
    }
  }

  cargarBusqueda() {
    this.buscar.lista_criterio = [CriteriosBusqueda.codigo];

    if (this.registroService.usuario && this.registroService.esAdministrador) {
      this.buscar.lista_criterio.push(CriteriosBusqueda.usuario_creacion);
    }  
    
    this.buscar.key = this.tipoTabla;
    this.buscar.lista_estado = [MsjEstado.ABIERTO, MsjEstado.CERRADO];
  }
  
  panelExpandedChange(event: any) {
    this.isExpanded = event;
  }
  seleccionarMesa(mesa:number){
    this.dataTable.filtro[0] = null;
    this.dataTable.filtro[1] = null;
    this.dataTable.filtro[2] = null;
    this.dataTable.filtro[3] = mesa.toString();
    this.dataTable.filtro[4] = null;
   
    if(this.turno.id){
      this.generarTabla(this.turno.id);
    }    
  }

  generarTabla(idTurno:string){
    this.listaPedidoSb?.unsubscribe();
    this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: true });//loading tabla
    const tablaFiltro: ITablePedidosFiltro={
      codigoFiltro: this.dataTable.filtro[0], //codigo
      estadoFiltro: this.dataTable.filtro[1], //estado
      usuarioFiltro: this.dataTable.filtro[2], //usuario
      mesas: this.dataTable.filtro[3], //mesas
      estadoPedidoVigenteFiltro: this.dataTable.filtro[4], //estadoPedidoVigenteFiltro
      minPage: this.dataTable.minPage,
      maxPage: this.dataTable.maxPage
    }

    const esVentaCerrada:boolean = true;

    this.listaPedidoSb = this.pedidoBlService.getPedidos(
      this.registroService.empresa.id,
      tablaFiltro,
      idTurno,
      true,//esCerrar
      false,//esPagar
      false,//esIncidencia
      false,//esIndividualizado
      esVentaCerrada
    ).subscribe((pedidos: Pedido[] |null)=>{
      if(pedidos!=null && pedidos.length>0 && pedidos[0].codigo_pedido!= '' ){
        this.dataTable.data =[];
        this.dataTable.data = [...pedidos];
      }else{
        this.dataTable.data = [];
      }
      //genera filtro mesas
      this.listadoMesas = new Array();
      if(pedidos!=null){
        pedidos.forEach(pedido=>{
          pedido.mesas_completo.forEach(mesas=>{
            // solo para mesas existentes
            if( !isNaN(parseFloat(mesas)) && isFinite(Number(mesas))){
              this.listadoMesas.push(Number(mesas));
            }
            
          });
        });
      }

      this.listadoMesas = Array.from(new Set(this.listadoMesas));
      this.listadoMesas.sort((a, b) => a - b); //ordenamos las mesas


      this.tableService.loadDataTable$.next(this.tipoTabla);//refrescamos el objeto tabla
      this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: false });//loading tabla

      this.spinnerPageService.hide();//cerrado del ngOnInit
    });

  }

  restablecerEstado(){
    this.dataTable.filtro[0] = null;
    this.dataTable.filtro[1] = null;
    this.dataTable.filtro[2] = null;
    this.dataTable.filtro[3] = null;
    this.dataTable.filtro[4] = null;   
    if(this.turno.id){
      this.generarTabla(this.turno.id);
    }
    this.isExpanded = false;
  }
  

}
