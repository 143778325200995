import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Icons } from '../../cons/icons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Pedido } from '../registrar-pedido/models/Pedido';
import { PedidoBlService } from '../registrar-pedido/services/services.bl/pedido.bl.service';
import { Turno } from '../gestion-monto/models/Turno';
import { Observable, Subscription, of } from 'rxjs';
import { IProductoTicket } from '../registrar-pedido/models/IProductoTicket';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { EstadosProductoType } from '../../types/estado-producto-type';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { IAmbienteMesa } from '../procesos/gestion-ambientes-mesas/models/IAmbienteMesa';

import { Historial } from '../historial/models/Historial';
import { Fecha } from '../gestion-monto/models/Fecha';
import { SistOperation } from '../../cons/common';
import { UIService } from '../../services/ui.services';
import { EstadosProducto } from '../registrar-pedido/models/EstadoProducto';
import { EstadoPedido } from '../registrar-pedido/models/EstadoPedido';
import { blinkAnimation } from '../../animations/animations';
import { GestionAspectosBlService } from '../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { AspectosGenerales } from '../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { catchError, filter, startWith, tap } from 'rxjs/operators';
import { SnackBarType } from '../../types/snackbar-type';
import { ProcesoType } from 'src/app/shared/types/proceso-type';

@Component({
  selector: 'app-ver-pedido',
  templateUrl: './ver-pedido.component.html',
  styleUrls: ['./ver-pedido.component.scss'],
  animations: [blinkAnimation]
})


@AutoUnsubscribe

export class VerPedidoComponent implements OnInit, OnDestroy {
  readonly Icons: typeof Icons = Icons;
  pedido = new Pedido();
  turno = new Turno();
  listaProductoSb!:Subscription;
  historialSb!:Subscription;
  readonly ProcesoType: typeof ProcesoType =
  ProcesoType;
  listaProductoPedido: IProductoTicket[] = [];
  totalProductos:number =0;
  readonly EstadosProductoType:typeof EstadosProductoType = EstadosProductoType;
  listaHMesa: Historial[]=[];
  listaHProducto:Historial[]=[];
  listaHPedido: Historial[]=[];
  startY: number = 0;
  endY: number = 0;
  blinkState: string = 'inactive';
  configTasasSb!:Subscription;
  aspectosGenerales!:AspectosGenerales;
  isLoading: boolean = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<VerPedidoComponent>,
    private pedidoBLService: PedidoBlService,
    private readonly spinnerPageService: SpinnerPageService,    
    private uiService:UIService,
    private gestionAspectosBL: GestionAspectosBlService,
  ) { }

  ngOnInit(): void {
    //animacion
    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 800);  // Cambia el estado cada 500ms
      
    this.pedido = this.datoPasado.dato as Pedido;
    this.turno = this.datoPasado.turno as Turno;
    if (this.turno.id && this.pedido.id) {
      this.cargarProductosXPedido(this.turno.id, this.pedido.id);
      this.isLoading = true;
      this.cargarTasas();  
    }
  }

  cargarTasas(){
    this.gestionAspectosBL.existFetchTasas();
    this.configTasasSb?.unsubscribe();
    this.configTasasSb = this.gestionAspectosBL.getTasas().subscribe( aspGen=>{
      if (aspGen) {  
        this.isLoading = false;
        this.aspectosGenerales = aspGen;
      }
    });
  }

  touchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
  }

  touchMove(event: TouchEvent) {
    this.endY = event.touches[0].clientY;
  }

  touchEnd(event: TouchEvent) {
    if (this.startY > this.endY + 30) {
      //console.log('Deslizado hacia arriba');
      // Acción específica al deslizar hacia arriba
    } else if (this.startY < this.endY - 30) {
      //console.log('Deslizado hacia abajo');
      // Acción específica al deslizar hacia abajo
    }
  }


  cargarProductosXPedido(idTurno:string, idPedido:string){
    
    this.listaProductoSb = this.pedidoBLService.getProductosXPedido(idTurno, idPedido)
          .subscribe(listaProds => {
            if (listaProds) {
              this.totalProductos =0;
              this.listaProductoPedido=[];
              this.spinnerPageService.hide();
              this.listaProductoPedido = JSON.parse(JSON.stringify(listaProds));         
              this.listaProductoPedido.forEach(prod=>{
                this.totalProductos +=prod.cantidad_producto;
              });
            }                   
            
          });
  }

  mostrarHistorialMesa(){
    this.listaHMesa=[];
    this.spinnerPageService.show();
    if(this.turno.id && this.pedido.id){
      if(this.historialSb){
        this.historialSb.unsubscribe();
      }
      
      this.historialSb = this.pedidoBLService.getHistorial(this.turno.id, this.pedido.id, true,false,false).subscribe((items)=>{
        this.spinnerPageService.hide();
        const hMesa = JSON.parse(JSON.stringify(items)) as IAmbienteMesa[];        
        hMesa.forEach(histMesa=>{
          let index:number = 1
          const historial:Historial ={
            indice: index,
            fecha:histMesa.fecha_key || new Fecha(),
            actual:'Mesa: N°'+histMesa.mesa_numero+' (Ambiente: '+histMesa.ambiente+')' ,
            usuario_creacion:histMesa.usuario_creacion,            
            estado: histMesa.accion_mesa,
            //seteando propiedades opcionales
            ...(histMesa.estado_cambio && {estado_cambio:histMesa.estado_cambio}),
            ...(histMesa.accion_mesa && {accion_mesa:histMesa.accion_mesa}),
            ...(histMesa.proceso && {proceso:histMesa.proceso}),
            ...(histMesa.anotacion && {anotacion:histMesa.anotacion}),
            ...(histMesa.usuario_aprobacion && {usuario_aprobacion:histMesa.usuario_aprobacion}),       
          }
          this.listaHMesa.push(historial);         
        });
        this.uiService.showModalHistorial({data:this.listaHMesa, titulo:'Mesa'});

      });

    }  
  }

  mostrarHistorialProductos(){
    this.listaHProducto=[];
    if(this.turno.id && this.pedido.id){
      if(this.historialSb){
        this.historialSb.unsubscribe();
      }      
      this.historialSb = this.pedidoBLService.getHistorial(this.turno.id, this.pedido.id, false,true,false).subscribe((items)=>{
        this.spinnerPageService.hide();
        const hMesa = JSON.parse(JSON.stringify(items)) as EstadosProducto[];
        hMesa.forEach(histProd=>{         
          let index:number = 1
          const historial:Historial ={
            indice: index,
            fecha:histProd.fecha || new Fecha(),
            actual: histProd.cantidad_producto.toString()+' '+histProd.nombre_producto,
            usuario_creacion:histProd.usuario_creacion,
            estado_producto: histProd.estado_producto,
            //seteando propiedades opcionales
            ...(histProd.cantidad_producto && {cantidad_producto:histProd.cantidad_producto}),
            ...(histProd.estado_cambio && {estado_cambio:histProd.estado_cambio}),
            ...(histProd.es_modificado==true && {es_modificado:true}),
            ...(histProd.es_eliminado==true && {es_eliminado:true}),
            ...(histProd.es_modificado==true && {reducir_cantidad:'Reducir: '+ histProd.cantidad_producto_reducido?.toString()}),
            ...(histProd.proceso && {proceso:histProd.proceso}),
            ...(histProd.anotacion && {anotacion:histProd.anotacion}),
            ...(histProd.usuario_aprobacion && {usuario_aprobacion:histProd.usuario_aprobacion}),       
          }
          this.listaHProducto.push(historial);
        });
        this.uiService.showModalHistorial({data:this.listaHProducto, titulo:'Productos'});

      });

    }  

  }

  mostrarHistorialPedido(){
    this.listaHPedido=[];
    if(this.turno.id && this.pedido.id){
      if(this.historialSb){
        this.historialSb.unsubscribe();
      }      
      this.historialSb = this.pedidoBLService.getHistorial(this.turno.id, this.pedido.id, false,false,true).subscribe((items)=>{
        this.listaHPedido=[];
        this.spinnerPageService.hide();
        const hPedido = JSON.parse(JSON.stringify(items)) as EstadoPedido[];      
        hPedido.forEach(histPed=>{
          
          let index:number = 1
          const historial:Historial ={
            indice: index,
            fecha: histPed.fecha_key || new Fecha(),
            actual: histPed.proceso,
            usuario_creacion:histPed.usuario_creacion,
            estado_pedido: histPed.estado_pedido,
            //seteando propiedades opcionales          
                      
            ...(histPed.anotacion && {anotacion:histPed.anotacion}),
            ...(histPed.usuario_aprobacion && {usuario_aprobacion:histPed.usuario_aprobacion}),       
          }
          this.listaHPedido.push(historial);
        });
        this.uiService.showModalHistorial({data:this.listaHPedido, titulo:'Pedido'});

      });
    } 
  }

  onClose(){
    
    if(this.listaProductoSb){
      this.listaProductoSb.unsubscribe();
    }
    if(this.historialSb){
      this.historialSb.unsubscribe();
    }
    
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    if(this.listaProductoSb){
      this.listaProductoSb.unsubscribe();
    }
    if(this.historialSb){
      this.historialSb.unsubscribe();
    }
    
  }


}
