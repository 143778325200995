import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { VentaCerradaBlService } from '../../procesos/services/venta-cerrada-bl.service';
import * as moment from "moment";
import { Utils } from 'src/app/shared/helpers/utils';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { Fecha } from 'functions/src/class_interface';
import { GestionMontoBlService } from '../../gestion-monto/services/gestion-monto.bl.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Turno } from '../../gestion-monto/models/Turno';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';

@Component({
  selector: 'app-graph-tendencia',
  templateUrl: './graph-tendencia.component.html',
  styleUrls: ['./graph-tendencia.component.scss']
})

@AutoUnsubscribe

export class GraphTendenciaComponent implements OnInit {
  cantidadDias:number[]=[7,15,30,60];
  cantidadSel:number = 0;
  isLoading:boolean = true;

   /*Chart*/
  public lineChartData: ChartConfiguration['data'] ={datasets:[],labels:[]};
  public delayed:boolean =false;
  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {
        ticks: {
          // Configurar la rotación de las etiquetas del eje X
          maxRotation: 35,  // Rotación máxima
          minRotation: 35,   // Rotación mínima para fijar la inclinación
          autoSkip: true,    // Salta etiquetas automáticamente si hay demasiadas
          padding: 10        // Espacio entre la etiqueta y el eje
        }
      },
      'y-axis-0':
        {
          display:false
        },
      'y-axis-1': {
        position: 'left',
        grid: {
          color: 'rgba(255,0,0,0.3)',
        },
        ticks: {
          color: 'red'
        }
      }
    },    
    
    plugins: {
      legend: { display: true }, 
    }
  };

  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    //console.log(event, active);
  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    //console.log(event, active);
  }

  fechaHoy: Fecha = new Fecha();
  gestionMontoSb!:Subscription;
  listaTurno: Turno[]=[];
  valorMedio:number=0;
  ventaMinima:number=0;
  indiceVentaMinima:number =0;
  ventaMaxima:number=0;
  indiceVentaMaxima:number =0;
  sbEmpresa!:Subscription;


  constructor(
    private gestionMontoBlService: GestionMontoBlService,    
    private registroService: RegistroService,  
    private cdRef: ChangeDetectorRef,    
  ) { }

  ngOnInit(): void {
    this.cantidadSel = this.cantidadDias[0]; // 7 dias inicial
    if(this.registroService.empresa){
      this.cargarTurnosCerrados(this.cantidadSel);
    }

    this.sbEmpresa?.unsubscribe();
    this.sbEmpresa = this.registroService.loadEmpresa$.subscribe(() => {
      if(this.registroService.usuario!=null){
        this.cargarTurnosCerrados(this.cantidadSel);
      }
    });

  }

  cambiarDias(){    
    this.cargarTurnosCerrados(this.cantidadSel);
  }



  cargarTurnosCerrados(cantidadSel: number){
    this.isLoading = true;
    this.listaTurno=[];
    this.gestionMontoBlService.existFetchTurnoReporte(cantidadSel);
    this.gestionMontoSb?.unsubscribe();
    this.gestionMontoSb = this.gestionMontoBlService.getTurnoReporte().pipe(
      filter(turno=>turno!==undefined// comparacion estricta para que diferencie los undefined de los nulls
    )) .subscribe(turn=>{
      if(turn){        
        this.listaTurno =turn;
        this.lineChartData ={datasets:[],labels:[]};        
        const dataVentasRecaudada:number[] =[];  
        const labels:string[]=[];
        let acum:number =0;
        this.valorMedio =0;
        this.ventaMinima =0;
        this.ventaMaxima =0;
        this.indiceVentaMaxima =0;
        this.indiceVentaMinima =0;

        this.listaTurno.forEach(turn=>{
          dataVentasRecaudada.push(turn.monto_final_recaudado_turno!);        
          labels.push(turn.fecha_key.dia+'/'+turn.fecha_key.mes+'/'+turn.fecha_key.anio);
          acum+=(turn.monto_final_recaudado_turno!*100)
        });
        this.lineChartData.datasets.push(
          {
            data: dataVentasRecaudada,
            label: 'Ventas',
            yAxisID: 'y-axis-1',
            backgroundColor: 'rgba(255,0,0,0.3)',
            borderColor: 'red',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)',
            fill: 'origin',
          },
        );      
        this.lineChartData.labels = labels;
        
        this.valorMedio = (acum/100)/this.listaTurno.length;

        //obteniendo el minimo
        const minimos = dataVentasRecaudada.reduce((acc, currentValue, currentIndex) => {
          if (currentValue < acc.minValue) {
            return { minValue: currentValue, minIndex: currentIndex };
          }
          return acc;
        }, { minValue: dataVentasRecaudada[0], minIndex: 0 });
        this.ventaMinima= minimos.minValue;
        this.indiceVentaMinima = minimos.minIndex;

        //obteniendo el maximo
        const maximos = dataVentasRecaudada.reduce((acc, currentValue, currentIndex) => {
          if (currentValue > acc.maxValue) {
            return { maxValue: currentValue, maxIndex: currentIndex };
          }
          return acc;
        }, { maxValue: dataVentasRecaudada[0], maxIndex: 0 });
        this.ventaMaxima = maximos.maxValue;
        this.indiceVentaMaxima = maximos.maxIndex;
        this.isLoading=false;
        this.cdRef.detectChanges();

      }else{
        this.isLoading=false;
        this.listaTurno =[];
        this.lineChartData ={datasets:[],labels:[]};
        this.valorMedio =0;
        this.ventaMinima =0;
        this.ventaMaxima =0;
        this.indiceVentaMaxima =0;
        this.indiceVentaMinima =0;

        this.cdRef.detectChanges();
      }
  });
  }
}
