// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDwPeunEHSRiGvCplitcozCJJplLTjU59E",
    authDomain: "quipu-ia-dev-0001.firebaseapp.com",
    databaseURL: "https://quipu-ia-dev-0001-default-rtdb.firebaseio.com",
    projectId: "quipu-ia-dev-0001",
    storageBucket: "quipu-ia-dev-0001.appspot.com",
    messagingSenderId: "832091406945",
    appId: "1:832091406945:web:e107c34e866fc84724a020",
    measurementId: "G-EWXSZ3C505"
  },
  key:{
    SECRET_KEY:"EEt5!;P&){p6Owf?Zq~NTX:~bsSynd",
    SECRET_KEY_P:"AEt5!;P&){p6Owf?Zq~NTX:~bsSya",
    COMPROBANTE_ELECTRONICO_KEY:"A$b@D!f2)G6%hY:M8^WzQ+~K9oL?2<d."//en caso de cambiar tambien sincronizar con las configuraciones de las funciones
  },
  apisPERU:{
    token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImFwcC50ZXN0LnguY2xvdWRAZ21haWwuY29tIn0.4L7iA8aRyPvASWMRQoNeL_xEf5ajMzuSC22_25E0Y4Q",
    apiDNI:"https://dniruc.apisperu.com/api/v1/dni/",
    apiRUC:"https://dniruc.apisperu.com/api/v1/ruc/",
  },
  recaptcha:{
    key:'6LdrTE8qAAAAAGsDk0mdnD3Pjd_oh5X1cx_rQWwG'
  }
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
