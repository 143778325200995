import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Pedido } from '../registrar-pedido/models/Pedido';
import { EstadoPedido } from '../registrar-pedido/models/EstadoPedido';
import { Fecha } from '../gestion-monto/models/Fecha';
import { IProductoTicket } from '../registrar-pedido/models/IProductoTicket';
import { Subscription } from 'rxjs';
import { RegistroService } from '../../services/registro.service';
import { FunctionsService } from '../../services/functions.service';
import { Utils } from '../../helpers/utils';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
//import * as moment from "moment";
import { CONFIGURACION } from '../../cons/config';
import * as printJS from 'print-js';
import { UIService } from '../../services/ui.services';
import { AspectosGenerales } from '../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { SendPersona } from '../../services/models/sendPersona';
import { IRespuestaReseller } from '../../services/models/IRespuestaReseller';
import jsPDF from 'jspdf';
import * as QRCode from 'qrcode';
import { IGetAPIRUC } from '../../services/models/IGetAPIRUC';

@Component({
  selector: 'app-imprimir-pedido',
  templateUrl: './imprimir-pedido.component.html',
  styleUrls: ['./imprimir-pedido.component.scss'],
})
@AutoUnsubscribe
export class ImprimirPedidoComponent implements OnInit {
  @Input() pedidoImprimir: Pedido = new Pedido();
  @Input() listaProductoPedido!: IProductoTicket[];
  @Input() listaMesasImprimir: string = '';
  @Input() fechaHoy: Fecha = new Fecha();
  @Input() key: string = '';
  @Input() tipoComprobante: number = 3; //1:boleta de venta; 2:factura; 3: Comanda
  @Input() aspectosGenerales: AspectosGenerales = new AspectosGenerales();
  @Input() usuarioSend: SendPersona = new SendPersona();
  @Input() respuestaReseller!: IRespuestaReseller;
  @Input() rucResponse: IGetAPIRUC | null = null;

  printComandaSb!: Subscription;
  empresa!: any;
  logoEmpresa!: string;
  usuarioImprimir!: string;

  constructor(
    private registroService: RegistroService,
    private uiService: UIService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    //solo para impresion
    if (this.registroService.empresa && this.registroService.usuario) {
      this.empresa = this.registroService.empresa;
      this.logoEmpresa = Utils.ObjecToListToArray(
        this.empresa?.logo_empresa
      )[0].path_image;
      this.usuarioImprimir = this.registroService.usuario.correo_electronico;
    }
    this.printComandaSb = this.uiService.printComanda$.subscribe(
      (keyTipoTabla: string) => {
        if (keyTipoTabla == this.key) {
          this.cdRef.detectChanges();
          this.generarComprobanteElectronicoHTML();
        }
      }
    );
  }

  truncarADosDecimales(num: number) {
    let truncado = Math.floor(num * 100) / 100;
    // Asegurarse de que el número siempre tenga dos decimales
    return truncado.toFixed(2);
  }

  generarComprobanteElectronico() {
    // Cargar las fuentes TTF (regular y bold)
    Promise.all([
      fetch('../../../../assets/fonts/IBMPlexSans-Regular.ttf').then(
        (response) => response.arrayBuffer()
      ),
      fetch('../../../../assets/fonts/IBMPlexSans-Bold.ttf').then((response) =>
        response.arrayBuffer()
      ),
    ]).then(([regularFontBuffer, boldFontBuffer]) => {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: [80, 297], // 80mm de ancho, altura ajustable
      });

      // Convertir los arrayBuffer a base64
      const regularFontBase64 = btoa(
        new Uint8Array(regularFontBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
      const boldFontBase64 = btoa(
        new Uint8Array(boldFontBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );

      // Agregar las fuentes al sistema de archivos virtual de jsPDF
      doc.addFileToVFS('IBM_Plex_Sans_Regular.ttf', regularFontBase64);
      doc.addFont('IBM_Plex_Sans_Regular.ttf', 'IBM_Plex_Sans', 'normal');

      doc.addFileToVFS('IBM_Plex_Sans_Bold.ttf', boldFontBase64);
      doc.addFont('IBM_Plex_Sans_Bold.ttf', 'IBM_Plex_Sans', 'bold');

      const marginLeft = 5; // Márgenes de 5mm a la izquierda
      const marginRight = 70; // 80mm - 10mm de margen a la derecha (80mm ancho total menos 10mm de margen derecho)

      let y = 10; // Iniciar la posición vertical (y)

      // Configurar el estilo del texto
      doc.setFontSize(12);
      doc.setFont('IBM_Plex_Sans', 'bold'); // Poner en negrita
      const nombreEmpresaDivido = doc.splitTextToSize(
        this.empresa.nombre_razon,
        50
      ); // Ajustar al ancho del documento
      doc.text(nombreEmpresaDivido, 40, y, { align: 'center' });
      //doc.text(this.empresa.nombre_razon, 40, y, { align: 'center' }); // Nombre de la empresa
      y += 5;
      doc.setFontSize(10);
      doc.text('RUC ' + this.empresa.ruc, 40, y, { align: 'center' }); // RUC de la empresa
      y += 5;

      doc.setFont('IBM_Plex_Sans', 'normal'); // Cambiar a texto regular
      doc.text(this.empresa.direccion, 40, y, { align: 'center' }); // Dirección de la empresa
      y += 10;

      // Título del comprobante en negrita
      doc.setFont('IBM_Plex_Sans', 'bold'); // Poner en negrita
      if (this.tipoComprobante == 1) {
        doc.text('BOLETA DE VENTA ELECTRÓNICA', 40, y, { align: 'center' });
        y += 5;
        doc.setFont('IBM_Plex_Sans', 'normal');
        doc.text(
          'N°  ' +
            this.respuestaReseller.serie +
            ' ' +
            this.respuestaReseller.numero,
          40,
          y,
          { align: 'center' }
        );
      } else if (this.tipoComprobante == 2) {
        doc.text('FACTURA ELECTRÓNICA', 40, y, { align: 'center' });
        y += 5;
        doc.setFont('IBM_Plex_Sans', 'normal');
        doc.text(
          'N°  ' +
            this.respuestaReseller.serie +
            ' ' +
            this.respuestaReseller.numero,
          40,
          y,
          { align: 'center' }
        );
      } else if (this.tipoComprobante == 3) {
        doc.text('PRE - CUENTA', 40, y, { align: 'center' });
        y += 5;
        doc.setFont('IBM_Plex_Sans', 'normal');
        doc.text('N°  ' + this.pedidoImprimir.codigo_pedido, 40, y, {
          align: 'center',
        });
      }
      y += 10;
      if (this.tipoComprobante == 3) {
        doc.setFont('IBM_Plex_Sans', 'bold');
        const recortarStringAntesDeArroba = (cadena: string): string =>
          cadena.split('@')[0];
        doc.text(
          'Gestor Mesa: ' +
            recortarStringAntesDeArroba(this.pedidoImprimir.usuario_creacion),
          marginLeft,
          y
        );
        y += 5;
        doc.text('Mesa: ' + this.listaMesasImprimir, marginLeft, y);
        y += 5;
        // Nombres ajustables a la siguiente línea si es necesario

        doc.text(
          'Impreso por: ' + recortarStringAntesDeArroba(this.usuarioImprimir),
          marginLeft,
          y
        );
        y += 5;
      } else if (this.tipoComprobante == 1 || this.tipoComprobante == 2) {
        if (this.tipoComprobante == 1) {
          // "Doc N°" en negrita y el DNI en regular
          doc.setFont('IBM_Plex_Sans', 'bold');
          doc.text('Doc N°: ', marginLeft, y); // "Doc N°" en negrita
          doc.setFont('IBM_Plex_Sans', 'normal');
          doc.text(this.usuarioSend.dni, marginLeft + 15, y); // DNI en regular
          y += 5;

          doc.setFont('IBM_Plex_Sans', 'bold');
          doc.text('Nombres: ', marginLeft, y); // "Doc N°" en negrita
          // Nombres ajustables a la siguiente línea si es necesario
          const nombresCompletos =
            this.usuarioSend.nombres +
            ' ' +
            this.usuarioSend.apellidoPaterno +
            ' ' +
            this.usuarioSend.apellidoMaterno;
          const nombresDivididos = doc.splitTextToSize(nombresCompletos, 50); // Ajustar al ancho del documento
          doc.setFont('IBM_Plex_Sans', 'normal');
          doc.text(nombresDivididos, marginLeft + 18, y);
          y += nombresDivididos.length * 5; // Aumentar y según el número de líneas
        } else {
          doc.setFont('IBM_Plex_Sans', 'bold');
          doc.text('RUC N°: ', marginLeft, y); // "Doc N°" en negrita
          doc.setFont('IBM_Plex_Sans', 'normal');
          doc.text(this.rucResponse!.ruc, marginLeft + 15, y); // DNI en regular
          y += 5;

          doc.setFont('IBM_Plex_Sans', 'bold');
          doc.text('Razón Social: ', marginLeft, y); // "Doc N°" en negrita
          // Nombres ajustables a la siguiente línea si es necesario
          const nombresCompletos = this.rucResponse!.razonSocial;
          const nombresDivididos = doc.splitTextToSize(nombresCompletos, 50); // Ajustar al ancho del documento
          doc.setFont('IBM_Plex_Sans', 'normal');
          doc.text(nombresDivididos, marginLeft + 23, y);
          y += nombresDivididos.length * 5; // Aumentar y según el número de líneas
        }
      }
      doc.setFont('IBM_Plex_Sans', 'bold');
      doc.text('Fecha: ', marginLeft, y); // "Doc N°" en negrita
      const fecha =
        this.fechaHoy.dia +
        '/' +
        this.fechaHoy.mes +
        '/' +
        this.fechaHoy.anio +
        ' ' +
        this.fechaHoy.hora +
        ':' +
        this.fechaHoy.minuto +
        ':' +
        this.fechaHoy.segundo;
      doc.setFont('IBM_Plex_Sans', 'normal');
      doc.text(fecha, marginLeft + 15, y);
      y += 10;

      if (this.tipoComprobante != 3) {
        // Línea separadora
        doc.line(marginLeft, y, marginRight, y);
        y += 5;
        doc.setFont('IBM_Plex_Sans', 'bold');
        const recortarStringAntesDeArroba = (cadena: string): string =>
          cadena.split('@')[0];
        doc.text(
          'Gestor Mesa: ' +
            recortarStringAntesDeArroba(this.pedidoImprimir.usuario_creacion),
          marginLeft,
          y
        );
        y += 5;
        doc.text('Mesa: ' + this.listaMesasImprimir, marginLeft, y);
        y += 5;
        // Línea separadora
        doc.line(marginLeft, y, marginRight, y);
        y += 5;
      }

      // Encabezados de la tabla de productos
      doc.setFont('IBM_Plex_Sans', 'bold');
      const colCant = marginLeft; // 20% del ancho
      const colDesc = marginLeft + 15; // 50% del ancho, comenzando después de Cantidad
      const colImporte = marginLeft + 10 + 40; // 30% del ancho, comenzando después de Descripción

      doc.text('Cant.', colCant, y); // Cantidad encabezado
      doc.text('Descripción', colDesc, y); // Descripción encabezado
      doc.text('Importe', colImporte, y); // Importe encabezado
      y += 5;

      // Línea separadora
      doc.line(marginLeft, y, marginRight, y);
      y += 5;

      // Información del encabezado (en texto regular)
      doc.setFont('IBM_Plex_Sans', 'normal'); // Cambiar a texto regular
      doc.setFontSize(9);

      // Productos
      this.listaProductoPedido.forEach((producto, index) => {
        if (producto.tiene_incidencia) {
          doc.text(
            String(producto.incidencia!.cantidad_producto),
            marginLeft,
            y
          ); // Cantidad alineada a la izquierda
        } else {
          doc.text(String(producto.cantidad_producto), marginLeft, y); // Cantidad alineada a la izquierda
        }
        const desc = producto.descuento
          ? '(-' +
            producto.descuento!.porcentaje_descuento +
            '% x' +
            producto.descuento.cantidad_producto +
            ')'
          : '';
        const inc =
          producto.tiene_incidencia && this.tipoComprobante == 3 ? '(i)' : '';

        const descripcion =
          producto.nombre_producto +
          ' (P.U.' +
          this.truncarADosDecimales(producto.precio_unitario) +
          ')' +
          desc +
          inc;
        const descDividido = doc.splitTextToSize(descripcion, 40); // Ajustar al ancho del documento
        doc.text(descDividido, marginLeft + 15, y); // Descripción alineada a la izquierda
        doc.text(String(producto.precio_total.toFixed(2)), marginRight, y, {
          align: 'right',
        }); // Precio alineado a la derecha
        if (this.listaProductoPedido.length > index + 1) {
          y += 10;
        } else {
          y += 5;
        }
      });

      // Línea separadora
      doc.line(marginLeft, y, marginRight, y);
      y += 5;

      doc.setFontSize(10);
      // subtotal
      doc.setFont('IBM_Plex_Sans', 'bold'); // Negrita para "Total"
      doc.text('Sub Total:', marginLeft, y);
      doc.setFont('IBM_Plex_Sans', 'normal'); // Regular para el valor
      doc.text(String(this.pedidoImprimir.sub_total), marginRight, y, {
        align: 'right',
      });
      y += 5;

      // subtotal
      if (this.pedidoImprimir.servicio_subtotal) {
        doc.setFont('IBM_Plex_Sans', 'bold'); // Negrita para "Total"
        doc.text(
          'Servicio: (' + this.aspectosGenerales.tasa_servicio + '%)',
          marginLeft,
          y
        );
        doc.setFont('IBM_Plex_Sans', 'normal'); // Regular para el valor
        doc.text(
          String(this.pedidoImprimir.servicio_subtotal),
          marginRight,
          y,
          { align: 'right' }
        );
        y += 5;
      }

      // total igv
      doc.setFont('IBM_Plex_Sans', 'bold'); // Negrita para "Total"
      const textoIGV = this.aspectosGenerales.incluir_servicio
        ? 'Total ' +
          this.aspectosGenerales.sigla_impuesto +
          ' (' +
          this.aspectosGenerales.tasa_impuesto +
          '%)'
        : this.aspectosGenerales.sigla_impuesto +
          ' (' +
          this.aspectosGenerales.tasa_impuesto +
          '%)';
      doc.text(textoIGV, marginLeft, y);
      doc.setFont('IBM_Plex_Sans', 'normal'); // Regular para el valor
      doc.text(String(this.pedidoImprimir.igv), marginRight, y, {
        align: 'right',
      });
      y += 5;
      //total
      doc.line(marginLeft, y, marginRight, y);
      y += 5;
      doc.setFontSize(11);
      doc.setFont('IBM_Plex_Sans', 'bold'); // Negrita para "Total"
      doc.text('TOTAL: ', marginLeft, y);
      //doc.setFont('IBM_Plex_Sans', 'normal'); // Regular para el valor
      doc.text(
        String('S/ ' + this.truncarADosDecimales(this.pedidoImprimir.total)),
        marginRight,
        y,
        { align: 'right' }
      );
      y += 5;
      // Línea separadora
      doc.line(marginLeft, y, marginRight, y);
      y += 5;

      if (this.tipoComprobante != 3) {
        doc.setFontSize(9);
        doc.setFont('IBM_Plex_Sans', 'normal'); // Regular para el valor
        const textoRep =
          'REPRESENTACIÓN IMPRESA DE LA BOLETA DE VENTA ELECTRÓNICA,PARA CONSULTAR VISITA:';
        const textoRepDiv = doc.splitTextToSize(textoRep, 60); // Ajustar al ancho del documento
        doc.text(textoRepDiv, 38, y, { align: 'center' });
        y += 12;

        const enlace = this.respuestaReseller.enlace!;
        const enlaceDiv = doc.splitTextToSize(enlace, 60); // Ajustar al ancho del documento
        doc.text(enlaceDiv, 38, y, { align: 'center' });
        y += 10;

        doc.setFontSize(8);
        const autorizacion =
          'Autorizado mediante Resolución de Intendencia No.034-005-0005315';
        const autorizacionDiv = doc.splitTextToSize(autorizacion, 60); // Ajustar al ancho del documento
        doc.text(autorizacionDiv, 38, y, { align: 'center' });
        y += 5;
      }

      // ** Condicional para mostrar el QR solo si tipoComprobante == 3 **
      if (this.tipoComprobante != 3) {
        // Generar el código QR a partir de la cadena
        QRCode.toDataURL(
          this.respuestaReseller.cadena_para_codigo_qr,
          { width: 100 },
          (err, url) => {
            if (!err) {
              // Agregar la imagen del QR al PDF
              doc.addImage(url, 'PNG', marginLeft + 15, y, 30, 30); // Posicionar el QR

              // Convertir el PDF en un Blob y abrirlo para imprimir
              const blob = doc.output('blob');
              const blobUrl = URL.createObjectURL(blob);
              printJS({ printable: blobUrl, type: 'pdf' });
            }
          }
        );
      } else {
        // Convertir el PDF en un Blob y abrirlo para imprimir sin el QR
        const blob = doc.output('blob');
        const blobUrl = URL.createObjectURL(blob);
        printJS({ printable: blobUrl, type: 'pdf' });
      }
    });
  } 


  async generarComprobanteElectronicoHTML() {
    // Comenzar a construir el HTML dinámico
    let htmlContent = `
      <html>
      <head>
        <style>
          body {
            font-family: 'IBM Plex Sans', sans-serif;
            margin: 0;
            padding: 10px;
            width: 80mm;
            font-size: 19px;
            line-height: 1; /* Reducir más el interlineado general */
          }
          .header {
            text-align: center;
            font-weight: bold;
            font-size: 19px;
            line-height: 0.7; /* Compactar mucho más el interlineado del header */
            margin: 0; /* Eliminar cualquier margen adicional */
          }
          .details {
            text-align: left;
            font-size: 18px;
            line-height: 1; /* Reducir más el interlineado */
            margin-top: 5px; /* Reducir el espacio antes de los detalles */
            margin-bottom: 5px; /* Reducir el espacio después de los detalles */
          }
          .details th, .details td {
            padding: 3px; /* Reducir el relleno entre celdas */
            line-height: 1; /* Reducir más el interlineado */
          }
          .separator {
            border-top: 1px solid black;
            margin: 8px 0; /* Reducir el margen del separador */
          }
          .footer {
            font-size: 16px;
            text-align: center;
            line-height: 1; /* Reducir más el interlineado */
            margin-top: 10px; /* Reducir espacio antes del pie de página */
          }
          .totals, .importe {
            text-align: right; /* Alinear los totales e importes a la derecha */
            font-weight: bold;
            line-height: 1; /* Reducir más el interlineado */
          }
          .totals-final {
            text-align: right; /* Alinear los totales e importes a la derecha */
            font-weight: bold;
            font-size: 22px;
            line-height: 1; /* Reducir más el interlineado */
          }
          .total-divider {
            border-top: 1px solid black;
            margin-top: 8px; /* Reducir el margen superior del separador */
            margin-bottom: 5px; /* Reducir el margen inferior del separador */
          }
          .qr-code {
            text-align: center;
            margin-top: 10px; /* Reducir el margen superior del QR */
          }
        </style>
      </head>
      <body>
        <div class="header">
          <p>${this.empresa.nombre_razon}</p>
          <p>RUC: ${this.empresa.ruc}</p>
          <p>${this.empresa.direccion}</p>
          <p>${
            this.tipoComprobante === 1
              ? 'BOLETA ELECTRÓNICA'
              : this.tipoComprobante === 2
              ? 'FACTURA ELECTRÓNICA'
              : 'PRE-CUENTA'
          }</p>`
          if(this.tipoComprobante==3 && this.pedidoImprimir.es_pedido_incidencia){
            htmlContent +=`<h2>INCIDENCIA</h2>` 
          }
          htmlContent+=`
          <p>${
            this.tipoComprobante === 3
              ? 'N° ' + this.pedidoImprimir.codigo_pedido
              : 'N° ' + this.respuestaReseller.serie + ' ' + this.respuestaReseller.numero
          }</p>
        </div>
        <div class="separator"></div>
        <div class="details">
          <table width="100%">
            <tr><td>Fecha:</td><td>${this.fechaHoy.dia}/${this.fechaHoy.mes}/${this.fechaHoy.anio} ${this.fechaHoy.hora}:${this.fechaHoy.minuto}:${this.fechaHoy.segundo}</td></tr>`;
   
    // Añadir detalles del cliente si es boleta o factura
    if (this.tipoComprobante === 1 || this.tipoComprobante === 2) {
      if (this.tipoComprobante === 1) {
        htmlContent += `<tr><td>Doc N°:</td><td>${this.usuarioSend.dni}</td></tr>`;
        const nombresCompletos = `${this.usuarioSend.nombres} ${this.usuarioSend.apellidoPaterno} ${this.usuarioSend.apellidoMaterno}`;
        htmlContent += `<tr><td>Nombres:</td><td>${nombresCompletos}</td></tr>`;
      } else if (this.tipoComprobante === 2) {
        htmlContent += `<tr><td>RUC N°:</td><td>${this.rucResponse!.ruc}</td></tr>`;
        htmlContent += `<tr><td>Razón Social:</td><td>${this.rucResponse!.razonSocial}</td></tr>`;
      }
    }
  
    // Detalles del pedido o mesa si es pre-cuenta
    const recortarStringAntesDeArroba = (cadena: string): string => cadena.split('@')[0];
    htmlContent += `
      <tr><td>Gestor:</td><td>${recortarStringAntesDeArroba(this.pedidoImprimir.usuario_creacion)}</td></tr>
      <tr><td>Mesa:</td><td>${this.listaMesasImprimir}</td></tr>`;
   
    // Separador
    htmlContent += `</table></div><div class="separator"></div>`;
  
    // Añadir los productos
    htmlContent += `<div class="details">
      <table width="100%">
        <thead>
          <tr><th>Cant.</th><th>Descripción</th><th class="importe">Importe</th></tr>
        </thead>
        <tbody>`;
  
    this.listaProductoPedido.forEach((producto, index) => {
      if(producto.incidencia){
        const cantidadRestante = producto.cantidad_producto - producto.incidencia!.cantidad_producto;
        if(cantidadRestante>0){
          const cantidad = producto.cantidad_producto - producto.incidencia!.cantidad_producto;
          const desc = producto.descuento ? `(-${producto.descuento!.porcentaje_descuento}% x ${producto.descuento.cantidad_producto})` : '';
          const inc = producto.tiene_incidencia && this.tipoComprobante === 3 ? '(i*)' : '';
          const descripcion = `${producto.nombre_producto} (P.U. ${this.truncarADosDecimales(producto.precio_unitario)})${desc}${inc}`;
          htmlContent += `<tr><td>${cantidad}</td><td>${descripcion}</td><td class="importe">${producto.precio_total.toFixed(2)}</td></tr>`;
        }
      }else{        
        const desc = producto.descuento ? `(-${producto.descuento!.porcentaje_descuento}% x ${producto.descuento.cantidad_producto})` : '';        
        const descripcion = `${producto.nombre_producto} (P.U. ${this.truncarADosDecimales(producto.precio_unitario)})${desc}`;
        htmlContent += `<tr><td>${producto.cantidad_producto}</td><td>${descripcion}</td><td class="importe">${producto.precio_total.toFixed(2)}</td></tr>`;
      }
      
      
    });
  
    // Totales
    htmlContent += `</tbody></table></div><div class="separator"></div>`;
    htmlContent += `<div class="details"><table width="100%">`;
  
    htmlContent += `
      <tr class="totals"><td>Sub Total:</td><td>${this.truncarADosDecimales(this.pedidoImprimir.sub_total)}</td></tr>
    `;
  
    if (this.pedidoImprimir.servicio_subtotal) {
      htmlContent += `
        <tr class="totals"><td>Servicio (${this.aspectosGenerales.tasa_servicio}%):</td><td>${this.truncarADosDecimales(this.pedidoImprimir.servicio_subtotal)}</td></tr>
      `;
    }
  
    htmlContent += `
      <tr class="totals"><td>${this.aspectosGenerales.sigla_impuesto} (${this.aspectosGenerales.tasa_impuesto}%):</td><td>${this.truncarADosDecimales(this.pedidoImprimir.igv)}</td></tr>
    `;
  
    // Línea divisoria antes del total
    htmlContent += `
      <tr><td colspan="2"><hr class="separator"></td></tr>
      <tr class="totals-final"><td><strong>Total:</strong></td><td><strong>S/ ${this.truncarADosDecimales(this.pedidoImprimir.total)}</strong></td></tr>
    `;
  
    htmlContent += `</table></div><div class="separator"></div>`;
  
    // Genera el código QR dinámicamente si es boleta o factura
    if (this.tipoComprobante !== 3) {
      htmlContent += `   
      <div class="footer">
        <p>REPRESENTACIÓN IMPRESA DEL COMPROBANTE ELECTRÓNICO,PARA CONSULTAR VISITA:</p>
        <p>${this.respuestaReseller.enlace!}</p>
      </div>
    `;
      const qrCodeUrl = await this.generarQR(this.respuestaReseller.cadena_para_codigo_qr);
      htmlContent += `
        <div class="qr-code">
          <img src="${qrCodeUrl}" alt="QR Code" width="170px" height="170px" />
        </div>
        <div class="footer">          
          <p>Autorizado mediante Resolución de Intendencia No. 034-005-0005315</p>
        </div>
      `;
    }
    if(this.tipoComprobante==3 && this.pedidoImprimir.es_pedido_incidencia){
      htmlContent +=` <div class="footer" style="margin-top:80px">
      <section>Incidencia asignada a:</section>
      <section>${this.pedidoImprimir.usuario_incidencia!.correo_electronico}</section>
      <section>${this.pedidoImprimir.usuario_incidencia!.nombres+' '+this.pedidoImprimir.usuario_incidencia!.apellido_paterno+' '+this.pedidoImprimir.usuario_incidencia!.apellido_materno}</section>
      <div>
      `
    }
  
    htmlContent += `</body></html>`;
  
    // Finalmente, usar PrintJS para imprimir el contenido HTML dinámico
    printJS({
      printable: htmlContent,
      type: 'raw-html',
    });
  }
  
  

generarQR(cadena: string): Promise<string> {
  return new Promise((resolve, reject) => {
    QRCode.toDataURL(cadena, { width: 100 }, (err, url) => {
      if (err) {
        reject(err);
      } else {
        resolve(url);  // Retorna la URL correctamente sin alteraciones
      }
    });
  });
}

  
}
