<div style="margin-top: 10px; margin-left: 10px;" fxLayout="column" fxLayoutGap="20px" >
    <div *ngIf="isLoading" style="margin-top: 20px;"  fxLayout="column"  fxLayoutAlign="center center">
        <app-spinner></app-spinner>
    </div>
    <div *ngIf="!isLoading">
        <div   fxLayout="row" fxLayoutGap="20px" fxLayoutAlign.xs="center center" fxLayoutAlign="start center" >
            <mat-form-field style="width: 150px;">
                <mat-label>Fecha Inicial</mat-label>
                    <input matInput placeholder="mm/dd/aaaa"
                    [max]="maxDateInicial"
                    [min]="minDateInicial"
                    #operaciones="ngModel"
                    ngModel name="operaciones"
                    required
                    disabled
                    [matDatepicker]="pickerInicial"
                    [(ngModel)]="fechaInicio"
                    >
                    <mat-datepicker-toggle matSuffix [for]="pickerInicial"></mat-datepicker-toggle>
                    <mat-datepicker #pickerInicial [disabled]="disabled_controls_inicial"></mat-datepicker>
                    <mat-error *ngIf="operaciones.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO_FORMATO_INCORRECTO}}</mat-error>
            </mat-form-field>
            <mat-form-field style="width: 150px;">
                <mat-label>Fecha Final </mat-label>
                    <input matInput placeholder="mm/dd/aaaa"
                    [max]="maxDateFinal"
                    [min]="minDateFinal"
                    #operaciones="ngModel"
                    ngModel name="operaciones"
                    required
                    disabled
                    [matDatepicker]="pickerFinal"
                    [(ngModel)]="fechaFinal"
                    >
                    <mat-datepicker-toggle matSuffix [for]="pickerFinal"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFinal [disabled]="disabled_controls_inicial"></mat-datepicker>
                    <mat-error *ngIf="operaciones.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO_FORMATO_INCORRECTO}}</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign.xs="center center" fxLayoutAlign="start center" >
            <mat-form-field style="width: 250px;">
                <mat-label>Tipo de Reporte</mat-label>
                <mat-select [(ngModel)]="tipoReporte">
                    <ng-container *ngFor="let reporte of descripcionReportes" >
                        <mat-option [value]="reporte.codigo" >{{reporte.titulo}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <app-button [width]="100" text="Generar"[attr_type]="'button'" (click)="selectReporte()" ></app-button>
        </div>
    </div>  
</div>
<div  fxLayout="row" fxLayoutAlign="center center" *ngIf="tipoReporte!=-1 && !isLoading">
    <mat-card style="min-width: 93%;">
        <mat-card-title>{{descripcionReportes[tipoReporte-1].titulo}}</mat-card-title>
        <mat-card-subtitle>{{descripcionReportes[tipoReporte-1].descripcion}}</mat-card-subtitle>
    </mat-card>    
</div>
