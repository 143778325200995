export abstract class Icons {
  static readonly CLEANING: string = 'cleaning_services';
  static readonly CANCEL: string = 'cancel';
  static readonly LOCK: string = 'lock';
  static readonly FULLSCREEN_EXIT: string = 'fullscreen_exit';
  static readonly CLOSE: string = 'close';
  static readonly RULE: string = 'rule';
  static readonly ERROR: string = 'error';
  static readonly INFO: string = 'info';
  static readonly STAR: string = 'star';
  static readonly ACCOUNT_BALANCE_WALLET: string = 'account_balance_wallet';
  static readonly PRICE_CHECK: string = 'price_check';
  static readonly WARNING: string = 'warning';
  static readonly SUCCESS: string = 'check_circle';
  static readonly QUESTION_MARK: string = 'question_mark';
  static readonly HELP: string = 'help';
  static readonly LOGOUT: string = 'logout';
  static readonly SETTINGS: string = 'settings';
  static readonly ADD: string = 'add';
  static readonly MAIL: string = 'mail';
  static readonly REMOVE: string = 'remove';
  static readonly HORIZONTAL_RULE: string = 'horizontal_rule';
  static readonly SEARCH: string = 'search';
  static readonly PERSON_DISABLED: string = 'person_add_disabled';
  static readonly PERSON_GROUP: string = 'group';
  static readonly SCHEDULE: string = 'schedule';
  static readonly UPDATE: string = 'update';
  static readonly CALENDAR_MONTH: string = 'calendar_month';
  static readonly EVENT_NOTE: string = 'event_note';
  static readonly VISIBILITY: string = 'visibility';
  static readonly PERSON_OFF: string = 'person_off';
  static readonly ACCOUNT_CIRCLE: string = 'account_circle';
  static readonly PERSON: string = 'person';
  static readonly MORE_VERT: string = "more_vert";
  static readonly MANAGE_ACCOUNTS: string = "manage_accounts";
  static readonly PASSWORD: string = "password";
  static readonly SUBJECT: string = "subject";
  static readonly RESTART_ALT: string = "restart_alt";
  static readonly RESTORE: string = "restore";
  static readonly WEBHOOK: string = "webhook";
  static readonly PENDING_ACTIONS: string = "pending_actions";
  static readonly PERSON_ADD: string = "person_add";
  static readonly PERSON_REMOVE: string = "person_remove";
  static readonly EXPAND_LESS: string = "expand_less";
  static readonly EXPAND_MORE: string = "expand_more";
  static readonly APP_REGISTRATION: string = "app_registration";
  static readonly POINT_OF_SALE: string = "point_of_sale";
  static readonly WALLET: string = "wallet";
  static readonly EDIT_SQUARE: string = "edit_square";
  static readonly LINK: string = 'link';
  static readonly LOCAL_ATM: string = 'local_atm';
  static readonly CURRENCY_YEN: string = 'currency_yen';
  static readonly CURRENCY_RUBLE: string = 'currency_ruble';
  static readonly PAID: string = 'paid';
  static readonly SENSOR_DOOR: string = 'sensor_door';
  static readonly ALARM_ON: string = 'alarm_on';




  //pagination
  static readonly FIRST_PAGE: string = "first_page";
  static readonly CHEVRON_RIGHT: string = "chevron_right";
  static readonly CHEVRON_LEFT: string = "chevron_left";
  static readonly LAST_PAGE: string = "last_page";
  static readonly VERTICAL_ALIGN_BOTTOM: string = "vertical_align_bottom";
  static readonly VERTICAL_ALIGN_TOP: string = "vertical_align_top";


  static readonly BADGE: string = 'badge';
  static readonly CASES: string = 'cases';
  static readonly EXTENSION: string = 'extension';

  static readonly CORPORATE: string = 'corporate_fare';
  static readonly DASHBOARD: string = 'dashboard';
  static readonly INSIGHTS: string = 'insights';
  static readonly GROUPS: string = 'groups';
  static readonly PAYMENTS: string = 'payments';
  static readonly ARROW_SPLIT: string = 'arrow_split';
  static readonly CALL_SPLIT: string = 'call_split';
  static readonly IMPORT_EXPORT: string = 'import_export';
  static readonly MEDIATION: string = 'mediation';
  static readonly DISPLAY_SETTINGS: string = 'display_settings';
  static readonly CELL_WIFI: string = 'cell_wifi';
  static readonly WIFI: string = 'wifi';
  static readonly PERSON_PIN: string = 'person_pin';
  static readonly CHAT_BUBBLE: string = 'chat_bubble_outline';
  static readonly TOGGLE_OFF: string = 'toggle_off';
  static readonly TOGGLE_ON: string = 'toggle_on';
  static readonly VPN_KEY: string = "vpn_key";
  static readonly HOME: string = "home";
  static readonly DELETE: string = "delete";
  static readonly SCAN_DELETE: string = 'scan_delete';

  static readonly WIDGETS: string = "widgets";
  static readonly INVENTORY: string = "inventory";
  static readonly EDIT: string = "edit";
  static readonly SWAP_HORIZ: string = "swap_horiz";
  static readonly CHECK: string = "check";
  static readonly CLOSE_SMALL: string = "close_small";
  //modulo gestion de restaurantes
  static readonly LIST: string = "list";//catalogo
  static readonly SAVINGS: string = "savings";//caja
  static readonly FOOD_BANK: string = "food_bank";//cocina
  static readonly PERCENT: string = "percent";//cocina
  static readonly CREDIT_CARD: string = "credit_card";//cocina
  static readonly NOTIFICATION_IMPORTANT: string = "notification_important";//cocina  
  static readonly NIGHTLIFE: string = "nightlife";//bar
  //modulo gestion de crm
  static readonly PEOPLE: string = "people";//gestionar clientes
  static readonly CONNECT_PEOPLE: string = "connect_without_contact";//gestionar comunicaciones
  //proceso gestion de rol
  static readonly ENGINEERING: string = "engineering";//gestionar clientes
  static readonly PRINT: string = "print";//gestionar clientes
  static readonly PRINT_LOCK: string = "print_lock";//gestionar clientes
  static readonly PRINT_DISABLED: string = "print_disabled";//gestionar clientes
  //encuesta
  static readonly SENTIMENT_DISSATISFIED: string = "sentiment_dissatisfied";//gestionar clientes
  static readonly SENTIMENT_VERY_DISSATISFIED: string = "sentiment_very_dissatisfied";//gestionar clientes
  static readonly SENTIMENT_NEUTRAL: string = "sentiment_neutral";//gestionar clientes
  static readonly SENTIMENT_SATISFIED: string = "sentiment_satisfied";//gestionar clientes
  static readonly SENTIMENT_VERY_SATISFIED: string = "sentiment_very_satisfied";//gestionar clientes

  static readonly CATEGORY: string = "category";
  static readonly ASSIGNMENT_ADD: string = "assignment_add";
  static readonly ANALYTICS: string = "analytics";
  //imagenes
  static readonly PHOTO_LIBRARY: string = "photo_library";
  //catalogo
  static readonly BOOK_5: string = "book_5";

  //gestion mesa
  static readonly EDIT_NOTE: string = "edit_note";
  static readonly FORMAT_LIST_NUMBERED: string = "format_list_numbered";
  static readonly TABLE_BAR: string = "table_bar";
  static readonly MENU_BOOK: string = "menu_book";
  static readonly DOWNLOAD: string = "download";
  static readonly BRUNCH_DINING: string = "brunch_dining";
  static readonly FASTFOOD: string = "fastfood";
  static readonly DECK: string = "deck";
  //gestion turno
  static readonly CONTRACT_EDIT: string = "contract_edit";
  //historial  
  static readonly ARROW_FORWARD: string = "arrow_forward";
  static readonly MANAGE_HISTORY: string = "manage_history";
  static readonly CHAT: string = "chat";
  static readonly LIVE_HELP: string = "live_help";
  static readonly CHANGE_CIRCLE: string = "change_circle";
  static readonly SYNC: string = "sync";
  static readonly SEND: string = "send";
  //estados
  static readonly PENDING: string = "pending";
  static readonly GROUP_WORK: string = "group_work";
  static readonly CHECK_SMALL: string = "check_small";
  static readonly DINNER_DINING: string = "dinner_dining";
  static readonly REMOVE_DONE: string = "remove_done";
  static readonly NO_FOOD: string = "no_food";
  //pedido
  static readonly LOCK_OPEN: string = "lock_open";
  static readonly STICKY: string = "sticky_note_2";
  static readonly RESTAURANT: string = "restaurant";
  static readonly ARROW_RIGHT: string = "arrow_right";
  static readonly SKIP_NEXT: string = "skip_next";
  //pago
  static readonly REPORT: string = "report";
  //gestion barra
  static readonly ALARM_SMART_WAKE: string = "alarm_smart_wake";
  static readonly TIMER: string = "timer";
  static readonly QR_CODE_SCANNER: string = "qr_code_scanner";
  static readonly QR_CODE_2: string = "qr_code_2";
  static readonly RECEIPT: string = "receipt";
  static readonly RECEIPT_LONG: string = "receipt_long";
  static readonly NO_PHOTOGRAPHY: string = "no_photography";
  static readonly LOGIN: string = "login";

  static readonly DATA_EXPLORATION: string = 'data_exploration';
  static readonly BAR_CHART_4_BARS: string = 'bar_chart_4_bars';
  static readonly TIMELINE: string = 'timeline';


}
