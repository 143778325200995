<div *ngIf="!tieneTurnoAperturado">
  <div *ngIf="isLoading" style="margin-top: 20px;">
    <app-spinner></app-spinner>
  </div>
  <div *ngIf="!isLoading" style="margin-left: 10px; margin-top: 10px;">
    <h4>No se aperturó un turno.</h4>
  </div>
</div>
<!-- temporal liberar mesas -->
<!-- <button mat-mini-fab color="primary" type="button" matTooltip="Restablecer" (click)="restablecerMesas()"
  matTooltipPosition="after">
  <mat-icon>{{Icons.ENGINEERING}}
  </mat-icon>
</button> -->

<div fxLayout="column" *ngIf="tieneTurnoAperturado" style="overflow: hidden;">
  <mat-accordion displayMode="default" [multi]="true" [hideToggle]="false">
    <mat-expansion-panel [expanded]="isExpanded" (expandedChange)="panelExpandedChange($event)">
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
        <mat-panel-title>
          <mat-icon>{{Icons.SEARCH}}</mat-icon>
          <span class="titulo-accordeon">Búsqueda</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-buscar [busqueda]="buscar"></app-buscar>
      <mat-action-row>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
        <mat-panel-title>
          <mat-icon>{{Icons.TABLE_BAR}}</mat-icon>
          <span class="titulo-accordeon">Mesas</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-chip-list>
        <mat-chip style="cursor: pointer;" *ngFor="let mesa of listadoMesas" removable="false" selectable="true"
          (click)="seleccionarMesa(mesa)">{{mesa}}</mat-chip>
      </mat-chip-list>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="grupo-botones" *ngIf="tieneTurnoAperturado">
    <div fxLayout.xs="row wrap" fxLayoutGap.xs="20px 20px" fxLayoutGap="5px" fxLayout="row"
      fxLayoutAlign="space-between center">
      <button mat-stroked-button matTooltip="Restablecer" class="restablecer" (click)="restablecerEstado()">
        <mat-icon class="mat-18">{{Icons.RESTORE}}</mat-icon>
        <!-- <span fxShow.gt-md fxHide.lt-md style="margin-left: 2px;"> Restablecer</span> -->
      </button>
      <button mat-stroked-button matTooltip="Pendientes" class="estado-pendiente" [disabled]="PENDIENTE==0"
        (click)="filtroEstados(EstadosPedidoType.PENDIENTE)">
        <mat-icon class="mat-18">{{Icons.PENDING}}</mat-icon>
        <span>
          <b>({{PENDIENTE}})</b>
        </span>
        <span fxShow.gt-md fxHide.lt-md> Pendiente</span>
      </button>
      <button mat-stroked-button matTooltip="En Proceso" class="estado-proceso" [disabled]="EN_PROCESO==0"
        (click)="filtroEstados(EstadosPedidoType.EN_PROCESO)">
        <mat-icon class="mat-18">{{Icons.GROUP_WORK}}</mat-icon>
        <span>
          <b>({{EN_PROCESO}})</b>
        </span>
        <span fxShow.gt-md fxHide.lt-md> En Proceso</span>
      </button>
      <button mat-stroked-button matTooltip="Terminado" class="estado-terminado" [disabled]="TERMINADO==0"
        (click)="filtroEstados(EstadosPedidoType.TERMINADO)">
        <mat-icon class="mat-18">{{Icons.CHECK_SMALL}}</mat-icon>
        <span>
          <b>({{TERMINADO}})</b>
        </span>
        <span fxShow.gt-md fxHide.lt-md>Terminado</span>
      </button>
      <!-- <button mat-stroked-button matTooltip="Anulado" class="estado-anulado" [disabled]="ANULADO==0"
        (click)="filtroEstados(EstadosPedidoType.ANULADO)">
        <mat-icon class="mat-18">{{Icons.NO_FOOD}}</mat-icon>
        <span style="margin-right: 5px;"> <b>({{ANULADO}})</b></span>
        <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;">Anulado</span>
      </button> -->
      <button mat-stroked-button matTooltip="Pendiente Reabrir" class="estado-pendiente-reabrir"
        [disabled]="PENDIENTE_REABRIR==0" (click)="filtroEstados(EstadosPedidoType.PENDIENTE_REABRIR)">
        <mat-icon class="mat-18">{{Icons.LIVE_HELP}}</mat-icon>
        <span style="margin-right: 5px;"> <b>({{PENDIENTE_REABRIR}})</b></span>
        <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;">Pendiente Reabrir</span>
      </button>
      <button mat-stroked-button matTooltip="Pendiente Revisión" class="estado-pendiente-revision"
        [disabled]="PENDIENTE_REVISION==0" (click)="filtroEstados(EstadosPedidoType.PENDIENTE_REVISION)">
        <mat-icon class="mat-18">{{Icons.MANAGE_HISTORY}}</mat-icon>
        <span style="margin-right: 5px;"> <b>({{PENDIENTE_REVISION}})</b></span>
        <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;">Pendiente Revisión</span>
      </button>
    </div>
  </div>
  <!-- imprimir comanda -->
  <app-imprimir-pedido [pedidoImprimir]="pedidoImprimir" [listaProductoPedido]="listaProductoPedido"
    [listaMesasImprimir]="listaMesasImprimir" [fechaHoy]="fechaHoy" [key]="tipoTabla"
    [aspectosGenerales]="aspectosGenerales" [tipoComprobante]="tipoComprobante"></app-imprimir-pedido>    
  <div [ngClass]="esPantallaXs?'container-table--xs':'container-table'">
    <app-table [dataTable]="dataTable" [key]="tipoTabla" [showPagination]="false" [rowSelection]="false"></app-table>
  </div>
</div>