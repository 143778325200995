import { Component, OnInit } from '@angular/core';
import { Msjs, TipoReporte } from '../../cons/common';
import { RegistroService } from '../../services/registro.service';
import { GenerarReporteService } from '../../services/generar-reporte.service';
import { Subscription } from 'rxjs';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { UIService } from '../../services/ui.services';
import { SnackBarType } from '../../types/snackbar-type';

@Component({
  selector: 'app-gestion-rpt-inventarios',
  templateUrl: './gestion-rpt-inventarios.component.html',
  styleUrls: ['./gestion-rpt-inventarios.component.scss']
})
export class GestionRptInventariosComponent implements OnInit {
  readonly TipoReporte: typeof TipoReporte = TipoReporte;
  descripcionReportes:any[]=[];
  procesadoRptSb!: Subscription;
  
  constructor(    
    private generarReporte: GenerarReporteService,
    private readonly spinnerPageService: SpinnerPageService,
    private uiService: UIService,
  ) { }

  ngOnInit(): void {
    this.TipoReporte.TipoReporte.forEach(rep=>{
      this.descripcionReportes.push(rep);
    });
    
    this.procesadoRptSb = this.generarReporte.procesadoRpt.subscribe(flag=>{
      if(flag){
        this.spinnerPageService.show();
      }else{
        this.spinnerPageService.hide();
        if(this.generarReporte.noResultados){
          this.uiService.ShowSnackBar(
            SnackBarType.ERROR,
            Msjs.MSJ_NO_VENTA_CERRADA,
            3000
          );
        }
        
      }
    });

  }

  selectReporte(){
    this.generarReporte.selectReporteCatalogos();

  }

}
