import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { serverTimestamp, deleteField, runTransaction, getFirestore } from 'firebase/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { IEmpresa } from 'src/app/shared/components/dialog/models/empresa.model';
import { EncuestaEncabezado } from 'src/app/shared/components/dialog/models/encuesta-encabezado';
import { EncuestaFinal } from 'src/app/shared/components/dialog/models/encuesta-final';
import { IImageUploaded } from 'src/app/shared/components/upload/IImageUploaded';
import { UploadService } from 'src/app/shared/components/upload/upload.service';
import { colections, documentsEmpresa, documentEncuesta } from 'src/app/shared/cons/db.colections';
import { Utils } from 'src/app/shared/helpers/utils';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { RegistroService } from 'src/app/shared/services/registro.service';
@Injectable({
  providedIn: 'root'
})
export class GestionFormularioDaService {
  transaccion: TransaccionModel = new TransaccionModel();
    private formulariosCache: BehaviorSubject<EncuestaFinal[] | null | undefined> = new BehaviorSubject<EncuestaFinal[] | null | undefined>(undefined);
    public formularios$: Observable<EncuestaFinal[] | null | undefined> = this.formulariosCache.asObservable();
    private fetchFormulariosSubscription!: Subscription;
    
  constructor(
      private db: AngularFirestore,
      private uploadService: UploadService,
      private registroService: RegistroService,
  ) { }

  private removeUtilPropertiesEncabezado(formulario:EncuestaFinal): EncuestaFinal {
    delete formulario.encabezado.path_fondo_editar;
    delete formulario.encabezado.path_logo_editar;
    return formulario;
  }

  private removeUtilProperties(formulario:EncuestaFinal): EncuestaFinal {
    delete formulario.id;
    delete formulario.es_guardado;
    return formulario;
  }
  
  fetchFormularioByIdEmpresa(idEmpresa: string): void {
    if (
      this.fetchFormulariosSubscription &&
      !this.fetchFormulariosSubscription.closed
    ) {
      this.formulariosCache.next(this.formulariosCache.value);
      return;
    }
    
    this.fetchFormulariosSubscription = this.getFormularioSnapshot(idEmpresa).subscribe((formularios: EncuestaFinal[] | null) => {
      this.formulariosCache.next(formularios);
    });
  }

  stopFetchingFormularios(): void {
    if (this.fetchFormulariosSubscription) {
      this.fetchFormulariosSubscription.unsubscribe();
    }
    this.formulariosCache.next(undefined);
  }

  getFormularioSnapshot(idEmpresa:string){
    let listaFormularios: EncuestaFinal[] =[];

    const query = this.db.collection(colections.EMPRESA, ref=>
      ref.where(documentsEmpresa.ES_BORRADO,'==',false).
      where(documentsEmpresa.ES_VIGENTE,'==',true))
      .doc(idEmpresa).collection(colections.ENCUESTA, ref1=>ref1.where(documentEncuesta.ES_BORRADO,'==',false))
    
    return query.snapshotChanges().pipe(map((formularios)=>{
      if(formularios.length>0){
        formularios.forEach(formu=>{
          const formularioConvertido = formu.payload.doc.data() as EncuestaFinal;
          formularioConvertido.id = formu.payload.doc.id;         
          formularioConvertido.es_guardado = true;

          listaFormularios.push(formularioConvertido);
        });
        return listaFormularios;
      }else{
        return null;
      }
    }))
  }

  async insertFormulario(
    formulario: EncuestaFinal,
    files_upload: File[],
    pathFileAPI: string,
    idEmpresa: string,
    
  ) {
    //quitando el id
    formulario = { ...this.removeUtilProperties(formulario) };
    const empresaRef =  this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const formularioRef =  empresaRef.collection(colections.ENCUESTA);
    try {
      const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
        //si no contiene imagen
        if (files_upload.length == 0) {
          const docFormulario = Utils.SerializeJsonToDb(formulario);
          docFormulario.fecha_creacion = serverTimestamp();
          transaction.set(formularioRef.doc().ref, docFormulario);
          this.transaccion.tx = true;
          this.transaccion.data = formularioRef.ref.id;
          return this.transaccion;
        }
        //si tiene archivos para subir TODO: escritura bucle
        else {
          return this.uploadService
            .onSaveFiles(files_upload, pathFileAPI).then((urls) => {
              const resultsURls =Utils.ObjecToListToArray(urls);
              if(resultsURls.length>0)formulario.encabezado.path_fondo =  resultsURls[0];
              if(resultsURls.length==2)formulario.encabezado.path_logo =  resultsURls[1];              
              const docFormulario = Utils.SerializeJsonToDb(formulario);
              docFormulario.fecha_creacion = serverTimestamp();
              transaction.set(formularioRef.doc().ref, docFormulario);
              this.transaccion.tx = true;
              this.transaccion.data = formularioRef.ref.id;
              return this.transaccion;
            });
        }
      });
      return transactionResult;
    }
    catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }


  }

  updateFormularioEliminarImagenPrevia(
      idFormulario:string,
      files_uploaded: IImageUploaded[],
      pathFileAPI: string
    ) {
        
      return new Promise<void>((resolve, reject) => {
        this.uploadService
          .depuraFiles(
            pathFileAPI,
            files_uploaded,
            this.registroService.empresa.logo_empresa
          )
          .then(() => {
            return this.db.firestore
              .collection(colections.EMPRESA)
              .doc(this.registroService.empresa.id)
              .update({ logo_empresa: deleteField() })
              .then(() => {
                if (this.registroService.empresa.logo_empresa) {
                  delete this.registroService.empresa.logo_empresa;
                }
              })
              .then(() => {
                resolve();
                return { tx: true };
              })
              .catch((e) => {
                return {
                  tx: false,
                  e: e,
                };
              });
          });
      });
    }


  async UpdateFormulario(
    formulario: EncuestaFinal,
    files_upload: File[],
    pathFileAPI: string,
    idEmpresa: string,
    cabeceraTemporal:EncuestaEncabezado
  ) {
    //quitando el id
    formulario = { ...this.removeUtilProperties(formulario) };
    const empresaRef =  this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const formularioRef =  empresaRef.collection(colections.ENCUESTA).doc(formulario.id);
    try {
      const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
        //si no contiene imagen
        if (files_upload.length == 0) {
          const encuestaFinalUpd:EncuestaFinal = Utils.deepClone(formulario);
          //insertando encabezado
          encuestaFinalUpd.encabezado.titulo = cabeceraTemporal.titulo;
          const docFormulario = Utils.SerializeJsonToDb(encuestaFinalUpd);
          docFormulario.fecha_actualizacion = serverTimestamp();
          transaction.update(formularioRef.ref, docFormulario);
          this.transaccion.tx = true;
          this.transaccion.data = formularioRef.ref.id;
          return this.transaccion;
        }
        //si tiene archivos para subir actualizar
        else {
          if(files_upload.length>0 ){
            const imagenesEliminar:IImageUploaded[]=[];

            //validando que imagenes se van a eliminar
            if(cabeceraTemporal.path_fondo){
              if(formulario.encabezado.path_fondo) imagenesEliminar.push(formulario.encabezado.path_fondo as IImageUploaded);
            }
            if(cabeceraTemporal.path_logo){
              if(formulario.encabezado.path_logo) imagenesEliminar.push(formulario.encabezado.path_logo as IImageUploaded);
            }
            //Eliminando imagenes
            if(imagenesEliminar.length>0){
              return new Promise<void>((resolve, reject) => {
                this.uploadService.depuraFiles(pathFileAPI, imagenesEliminar, this.registroService.empresa.logo_empresa)
                  .then(() => {
                    return this.db.firestore
                      .collection(colections.EMPRESA)
                      .doc(this.registroService.empresa.id)
                      .update({ logo_empresa: deleteField() })
                      .then(() => {
                        if (this.registroService.empresa.logo_empresa) {
                          delete this.registroService.empresa.logo_empresa;
                        }
                      })
                      .then(() => {
                        resolve();
                        return { tx: true };
                      })
                      .catch((e) => {
                        return {
                          tx: false,
                          e: e,
                        };
                      });
                  });
              });
            }

          }
          return this.uploadService
            .onSaveFiles(files_upload, pathFileAPI).then((urls) => {
              const resultsURls =Utils.ObjecToListToArray(urls);
              if(resultsURls.length>0)formulario.encabezado.path_fondo =  resultsURls[0];
              if(resultsURls.length==2)formulario.encabezado.path_logo =  resultsURls[1];              
              const docFormulario = Utils.SerializeJsonToDb(formulario);
              docFormulario.fecha_creacion = serverTimestamp();
              //transaction.set(formularioRef.doc().ref, docFormulario);
              this.transaccion.tx = true;
              this.transaccion.data = formularioRef.ref.id;
              return this.transaccion;
            });
        }
      });
      return transactionResult;
    }
    catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }


  }
}
