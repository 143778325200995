import { Injectable } from '@angular/core';
import { GestionExternoCartaDaService } from './gestion-externo-carta-da.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Observable } from 'rxjs';
import { CartaDigital } from '../models/CartaDigital';

@Injectable({
  providedIn: 'root'
})
export class GestionExternoCartaBlService {

  constructor(
    private gestionExternoCartaDaService: GestionExternoCartaDaService,
    private registroService: RegistroService
  ) { }

  existFetchCarta(){
    const idEmpresa = this.registroService.empresa.id;
    const idUsuario = this.registroService.usuario.id;
    this.gestionExternoCartaDaService.fetchCarta(idEmpresa,idUsuario);
  }

  getCarta():Observable<CartaDigital | null | undefined>{
    return this.gestionExternoCartaDaService.carta$;
  }

  insertCarta(carta: CartaDigital){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionExternoCartaDaService.insertCarta(idEmpresa, correoUsuario,carta);
  }

  updateCarta(carta:CartaDigital){    
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionExternoCartaDaService.updateCarta(carta, correoUsuario);
  }

  updateSincronizarCarta(carta:CartaDigital){
    const idEmpresa = this.registroService.empresa.id;
    return this.gestionExternoCartaDaService.updateSincronizarCarta(idEmpresa, carta);
  }

  getConfiguracionCartaExisteRuta(rutaParcial:string){
    return this.gestionExternoCartaDaService.getConfiguracionCartaExisteRuta(rutaParcial);
  }


  
}
