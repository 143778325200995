import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { EncuestaEncabezado } from "./encuesta-encabezado";
import { TipoPregunta } from "./tipo-pregunta";

export class EncuestaFinal extends AuditoriaSet {
    encabezado:EncuestaEncabezado = {
        titulo:'',
        codigo_encuesta:'',      
        path_fondo:'',
        path_logo:''                
    };    
    tipo_pregunta:TipoPregunta[]=[];
    es_borrado: boolean=false;
    es_vigente: boolean =true;
    usuario_creacion:string='';
    fecha_creacion?: any;
    //
    id?:string;
    es_guardado?:boolean;
}