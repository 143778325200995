export class const_sistema{
  
  public static readonly TASA_IMPUESTO:number=0.18;
  public static readonly DIAS_FACTURACION: number= 25;
  public static readonly DIAS_CORTE_SERVICIO: number= 30;
  
  
}


export interface ITransaccion{
  tx:boolean,
  error?:any// si es que hay error,
  data?:any//datos que se retornan
}
 export interface IModulosSeleccionados{
  cantidad_total_usuarios: number,
  cantidad_usuarios_x_defecto: number,
  codigo: string,
  costo_modulo: number,
  costo_total: number,
  costo_usuario_adicional: number,
  menu: any,
  nombre: string,
  orden: number,
  pendiente_anulacion_modulo?: boolean
  roles: any
 }

 export interface IFacturacion{
  costo_facturacion:ICostoFacturacion[],
  dias_periodo_facturable:number,
  es_periodo_prueba:boolean,
  fecha_modificacion: any,
  fecha_registro:any
  id_empresa:string,
  id_usuario_cuenta:string,
  //
  id?:string
 }

 export interface ICostoFacturacion{
  cantidad_total_usuarios:number,
  cantidad_usuarios_x_defecto:number,
  codigo_modulo:string,
  costo_modulo:number,
  costo_usuario_adicional:number,
  es_activo:boolean,
  es_borrado:false,
  es_pendiente_calculo_variacion:boolean,
  fecha_creacion:any,
  nombre_modulo:string,
  pendiente_anulacion_modulo?:boolean,
  cantidad_usuarios_variacion?: ICantidadUsuariosVariacion
 }

 export interface ICantidadUsuariosVariacion{
  cantidad_reduccion:number,
  cantidad_total_usuarios:number,
  codigo_modulo:string,
  es_decremento:boolean,
  fecha_registro:string
 }


export interface IContadorDocumento {
  id_documento?:string
  total_documentos:number;
  fecha_actualizacion?:any;
  total_documentos_activos:number;
  total_documentos_inactivos:number;
  cantidad_modulos_seleccionados: ICantidadModuloSel[];
}

export interface ICantidadModuloSel{
  codigo:string,
  cantidad_total_usuarios_permitidos:number,
  cantidad_total_usuarios_matriculados:number,
  nombre:string,
  orden:number,
  pendiente_anulacion_modulo?:boolean;
}

export interface IRecibo{
  costo_sub_total_modulos:ICostoModuloRecibo[],
  costo_total_modulos:number,
  tasa_impuesto:number,
  //costo_pendiente_periodo_ant:number,
  costo_final:number,
  costo_impuesto:number,
  periodo:string,
  fecha_calculo:Date,
  ultimo_dia_pago:Date,
  pendiente_pago:boolean
}

export interface ICostoModuloRecibo{
  codigo_modulo:string,
  nombre_modulo:string,
  costo_base:number,
  concepto_costo_base:string,//(costo base 500 incluye 5 usuarios),
  cantidad_usuario_adicional:number,//2
  costo_usuario_adicional:number,//25
  concepto_costo_adicional:string,//(2 usuarios(25*2)),
  total_costo_adicional:number,
  total_costo_modulo:number,
  dias_operativos:number

}

export class documentsEmpresa{
  public static readonly ID_USUARIO_CUENTA:string = 'id_usuario_cuenta';
  public static readonly ID_DOC_USUARIO:string = 'id_doc_usuario';
  public static readonly RUC:string = 'ruc';
  public static readonly ES_BORRADO:string ='es_borrado';
  public static readonly ES_SIN_PAGO:string='es_sin_pago';
}

export class documentRecibo{
  public static readonly PENDIENTE_PAGO:string ='pendiente_pago';
}

export class colections {
  public static readonly USUARIO:string ="tran.usuario";
  public static readonly EMPRESA:string ="tran.empresa";
  public static readonly SIST_MODULOS:string="sist.modulos";
  public static readonly COLABORADOR:string="colaborador";
  public static readonly COLABORADOR_RESUMEN:string="colaborador_resumen";
  public static readonly USUARIO_COLABORADOR:string="tran.usuario_colaborador";
  public static readonly ENCUESTA:string="tran.encuesta";
  public static readonly FACTURACION:string="tran.facturacion";
  public static readonly ACTIVE_SESION:string="sist.active_sesion";
  public static readonly RECIBO:string="tran.recibo";
  public static readonly PRODUCTO:string="tran.producto";
  public static readonly PRODUCTO_RESUMEN:string="producto_resumen";
  public static readonly CATALOGO:string="tran.catalogo";
  public static readonly CATALOGO_RESUMEN:string="catalogo_resumen";
  public static readonly CONFIGURACION_AMBIENTE:string="configuracion_ambiente";//TODO: proximo a ser eliminado
  public static readonly PEDIDO_PRODUCTOS:string="tran.pedido_productos";
  public static readonly HISTORIAL_MESA:string="tran.historial_mesa";
  public static readonly HISTORIAL_PRODUCTO:string="tran.historial_producto";
  public static readonly HISTORIAL_PEDIDO:string="tran.historial_pedido";
  public static readonly AMBIENTE_MESA:string="ambiente_mesa";
  public static readonly CONFIGURACION_TASAS:string='configuracion_tasas';
  public static readonly CONFIGURACION_MEDIOS_PAGO:string='configuracion_medios_pago';
  public static readonly CONFIGURACION_DESCUENTOS:string='configuracion_descuentos';
  public static readonly CONFIGURACION_INCIDENCIAS:string='configuracion_incidencias';
  public static readonly CONFIGURACION_MODO_OPERACION:string='configuracion_modo_operacion';
  public static readonly TURNO:string="tran.turno";
  public static readonly PEDIDO:string="tran.pedido";
  //public static readonly PEDIDO_RESUMEN:string="pedido_resumen";
  public static readonly PEDIDO_RESUMEN:string="pedido_resumen";
  public static readonly MENSAJE:string="tran.mensaje";
}

export class ReduccionUsuario{
  cantidad_reduccion:number=0;
  cantidad_total_usuarios:number=0;
  codigo_modulo:string='';
  es_decremento:boolean= true;
  fecha_registro:string=''
}


export class CostoFacturacion {
  cantidad_total_usuarios:number=0;
  cantidad_usuarios_x_defecto:number=0;

  cantidad_usuarios_variacion?: ReduccionUsuario
  codigo_modulo:string='';
  costo_modulo:number=0;
  nombre_modulo:string='';
  costo_usuario_adicional:number=0;
  fecha_creacion:any;
  fecha_modificacion?:any
  es_activo:boolean=true;
  es_borrado:boolean = false;
  es_pendiente_calculo_variacion:boolean = false;
  pendiente_anulacion_modulo?:boolean

}

export class Facturacion {
  id_empresa:string='';
  id_usuario_cuenta:string='';
  costo_facturacion!: CostoFacturacion[];
  fecha_registro: any;
  fecha_modificacion:any;
  dias_periodo_facturable: number = 30;
  es_periodo_prueba:boolean=false;
  //propiedad utilitaria
  id?:string
}

export interface IComprobanteElectronico{
  numero_inicial_boleta:number,
  numero_actual_boleta:number,
  numero_inicial_factura:number,
  numero_actual_factura:number,
  fecha_creacion:any,
  id?:string
}

export class Fecha {
  dia:string='';
  mes:string='';
  anio:string='';
  hora:string='';
  minuto:string='';
  segundo:string='';
}

export interface IRespuestaAnulacionReseller{
  status:string;
  data:IRespuestaDataAnulacionReseller;
}

export interface IRespuestaDataAnulacionReseller{
  numero:number;
  enlace:string;
  sunat_ticket_numero:string;
  aceptada_por_sunat:boolean;    
  sunat_description:string;
  sunat_note:string;
  sunat_responsecode:string;    
  sunat_soap_error:string;
  xml_zip_base64:string;
  pdf_zip_base64:string;
  cdr_zip_base64:string;
  enlace_del_pdf:string;
  enlace_del_xml:string;
  enlace_del_cdr:string;
  key?:string;
}

export enum RecaptchaType {
  REGISTER = "register",
  LOGIN = "login",
  PASSWORD_RESET = "password_reset"
}
