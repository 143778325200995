import { Component, OnInit } from '@angular/core';
import { ReporteType } from '../../types/reporte-type';
import { GenerarReporteService } from '../../services/generar-reporte.service';
import { GestionMontoBlService } from '../gestion-monto/services/gestion-monto.bl.service';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { Turno } from '../gestion-monto/models/Turno';
import { RegistroService } from '../../services/registro.service';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { UIService } from '../../services/ui.services';
import { SnackBarType } from '../../types/snackbar-type';
import { Msjs, TipoReporte } from '../../cons/common';

@Component({
  selector: 'app-gestion-rpt-turno-actual',
  templateUrl: './gestion-rpt-turno-actual.component.html',
  styleUrls: ['./gestion-rpt-turno-actual.component.scss']
})

@AutoUnsubscribe

export class GestionRptTurnoActualComponent implements OnInit {

  tipoReporte:number=-1;
  descripcionReportes:any[]=[];
  gestionMontoSb!: Subscription;
  turno: Turno = new Turno();
  tieneTurno:boolean = false;
  procesadoRptSb!: Subscription;
  readonly TipoReporte: typeof TipoReporte = TipoReporte;

  constructor(
    private generarReporte: GenerarReporteService,
    private gestionMontoBlService: GestionMontoBlService,
    private registroService:RegistroService,
    private readonly spinnerPageService: SpinnerPageService,
    private uiService: UIService,
  ) { }

  ngOnInit(): void {
    this.TipoReporte.TipoReporte.forEach(rep=>{
      this.descripcionReportes.push(rep);
    });    

    if(this.registroService.empresa){
      this.gestionMontoBlService.existFetchTurno();
      this.gestionMontoSb?.unsubscribe();
      this.gestionMontoSb = this.gestionMontoBlService.getTurno().pipe(
        filter(turno => turno !== undefined// comparacion estricta para que diferencie los undefined de los nulls
        )).subscribe(turn => {
          if (turn) {
            this.turno = turn;
            this.tieneTurno = true;
          }else{
            this.tieneTurno = false;
          }
        });
    }

    this.procesadoRptSb = this.generarReporte.procesadoRpt.subscribe(flag=>{
      if(flag){
        this.spinnerPageService.show();
      }else{
        this.spinnerPageService.hide();
        if(this.generarReporte.noResultados){
          this.uiService.ShowSnackBar(
            SnackBarType.ERROR,
            Msjs.MSJ_NO_ELEMENTOS_VENTA,
            3000
          );
        }
        
      }
    });


  }

  selectReporte(){
    if(this.turno.id!){
      if(this.tipoReporte==-1){
        this.uiService.ShowSnackBar( SnackBarType.ERROR, Msjs.MSJ_NO_SELECCIONO_REPORTE, 3000);
        return;
      }
      this.generarReporte.selectReporte(this.tipoReporte, this.turno, null, null);//los null correposnden a las fechas de inic y fecha de fin para reporte historico
    }else{
      this.uiService.ShowSnackBar(
        SnackBarType.ERROR,
        Msjs.MSJ_ERROR_NO_TURNO,
        3000
      );
    }
    
  }


}
