import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DatosPlataforma, Length_Database, Msjs, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { IEmpresa } from '../../dialog/models/empresa.model';
import { SpConsolaService } from 'src/app/shared/services/sp-consola.service';
import { blinkAnimation } from 'src/app/shared/animations/animations';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/services/ui.services';
import { EmpresaBLService } from '../../services/services.bl/empresa.bl.service';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';

@Component({
  selector: 'app-gestionar-reconexion-servicio',
  templateUrl: './gestionar-reconexion-servicio.component.html',
  styleUrls: ['./gestionar-reconexion-servicio.component.scss'],
  animations: [blinkAnimation],
})
export class GestionarReconexionServicioComponent implements OnInit {
  readonly DatosPlataforma: typeof DatosPlataforma = DatosPlataforma;
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations:typeof Msjs_Validations = Msjs_Validations;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  esBuscado:boolean = false;
  empresa!:any | null;
  disabled:boolean = false;
  blinkState: string = 'inactive';
  modalSb!: Subscription;
  isLoading:boolean = false;

  constructor(
    private spConsolaService: SpConsolaService,
    private uiService: UIService,
    private empresaBLService: EmpresaBLService
  ) { }

  ngOnInit(): void {
    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 1000);
  }

  buscarEmpresa(f:NgForm){
    this.disabled = false;
    this.isLoading = true;
    if(f.valid){
      this.spConsolaService.getDeudaEmpresa(f.value.ruc).then(empresa=>{
        if(empresa!=null){
          this.empresa = empresa;
          this.esBuscado = true;
          this.isLoading = false;          
        }else{
          this.empresa = null;
          this.isLoading = false;
        }
      });

    }
  }

  limpiar(f:NgForm){
    f.form.reset();
    this.empresa = null;
  }

  activarEmpresa(){
    this.modalSb = this.uiService
    .showModalQuestions({
      title: Msjs.TITULO_CONFIRMACION,
      message: '¿Esta seguro de activar la cuenta?',
      icon: Icons.QUESTION_MARK,
    })
    .subscribe((result: any) => {
      if (result) {
        this.isLoading = true;
        this.empresaBLService.updateEmpresaEstadoPago(this.empresa).then(()=>{
         
          this.spConsolaService.getDeudaEmpresa(this.empresa.empresa!.ruc).then(empresa=>{
            if(empresa){
              this.isLoading = false;
              this.empresa = empresa;
              this.esBuscado = true;
              this.uiService.ShowSnackBar(
                SnackBarType.SUCCESS,
                Msjs.MSJ_GUARDADO_OK,
                3000
              );          
            }
          });
        });
      
        
        
      }
    });
  }

}
