import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GestionMontoBlService } from '../../gestion-monto/services/gestion-monto.bl.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Subscription } from 'rxjs';
import { Turno } from '../../gestion-monto/models/Turno';
import { filter } from 'rxjs/operators';
import { VentaCerradaBlService } from '../../procesos/services/venta-cerrada-bl.service';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { Ventas } from '../../registrar-pedido/models/Ventas';
import { IConteo } from './models/IConteo';
import { Chart, ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Importar el plugin de DataLabels

@Component({
  selector: 'app-tendencia-productos',
  templateUrl: './tendencia-productos.component.html',
  styleUrls: ['./tendencia-productos.component.scss'],
})

@AutoUnsubscribe

export class TendenciaProductosComponent implements OnInit {
  cantidadDias:number[]=[7,15,30,60];
  cantidadSel:number = 0;
  isLoading:boolean = true;
  sbEmpresa!:Subscription;
  listaTurno: Turno[]=[];
  gestionMontoSb!:Subscription;
  ventasSb!: Subscription;
  chart: any;
  listaProductos:string[]=[];
  listaCantidades:number[]=[];
  cantidadElementos:number=0;

  @ViewChild('barCanvas') barCanvas!: ElementRef<HTMLCanvasElement>; // Referencia al canvas

  constructor(
    private gestionMontoBlService: GestionMontoBlService,    
    private registroService: RegistroService,  
    private cdRef: ChangeDetectorRef,
    private ventaCerrada: VentaCerradaBlService,
  ) { }

  ngOnInit(): void {    
    this.cantidadSel = this.cantidadDias[0]; // 7 dias inicial
    if(this.registroService.empresa){
      this.cargarTurnosCerrados(this.cantidadSel);
    }

    this.sbEmpresa?.unsubscribe();
    this.sbEmpresa = this.registroService.loadEmpresa$.subscribe(() => {
      if(this.registroService.usuario!=null){
        this.cargarTurnosCerrados(this.cantidadSel);
      }
    });
  }
 
  cargarTurnosCerrados(cantidadSel: number){
    this.isLoading = true;
    this.listaTurno=[];
    this.gestionMontoBlService.existFetchTurnoReporteTodo();
    this.gestionMontoSb?.unsubscribe();
    this.gestionMontoSb = this.gestionMontoBlService.getTurnoReporteTodo().pipe(
      filter(turno=>turno!==undefined// comparacion estricta para que diferencie los undefined de los nulls
    )) .subscribe(turn=>{
      if(turn){        
        this.listaTurno =turn;     
        const listaTurno:string[]=[];
        this.listaTurno.forEach(turnoRet=>{
          listaTurno.push(turnoRet.id!);
        });
        this.isLoading=false;
        this.ventaCerrada.existFetchVentas(listaTurno);
        this.ventasSb?.unsubscribe();
        this.ventasSb = this.ventaCerrada.getVentasXIDTurno().pipe(filter((listaVentas) => listaVentas !== undefined)).subscribe((listaVentas) => {          
          if (listaVentas) {
            //console.log(listaVentas);
            const resultado = this.contarProductos(listaVentas);
            resultado.sort((a,b)=>b.cantidad_producto-a.cantidad_producto).slice(0,15);
            this.cantidadElementos = resultado.length;
            resultado.forEach(item=>{
              this.listaProductos.push(item.nombre_producto);
              this.listaCantidades.push(item.cantidad_producto);
            });
            this.createBarChart();
          }else{

          }
        });
      }else{
        this.isLoading=false;
        this.listaTurno =[];
        this.cdRef.detectChanges();
      }
  });
  }

  contarProductos(listaVentas:Ventas[]){
    const conteoProductosPorDia = new Map<Date, Map<string, { cantidad_producto: number; nombre_producto: string }>>();

    listaVentas.forEach((ventas) => {
      ventas.pedido!.lista_producto!.forEach(producto => {        
        const milliseconds = producto.fecha_creacion.seconds * 1000 + Math.floor(producto.fecha_creacion.nanoseconds / 1000000);
        const codigo_producto=producto.codigo_producto;
        const fecha_creacion = new Date(milliseconds);

        const cantidad_producto = producto.cantidad_producto;        
        const nombre_producto = producto.nombre_producto;
         // Verificamos si ya existe la fecha en el Map
        if (!conteoProductosPorDia.has(fecha_creacion)) {
          conteoProductosPorDia.set(fecha_creacion, new Map<string, { cantidad_producto: number; nombre_producto: string }>());
        }
        // Obtenemos el Map de códigos para esa fecha
        const productosPorCodigo = conteoProductosPorDia.get(fecha_creacion)!;
        // Verificamos si ya existe el código en ese día
        if (productosPorCodigo.has(codigo_producto)) {
          // Si existe, sumamos la cantidad
          productosPorCodigo.set(codigo_producto, {
            cantidad_producto: productosPorCodigo.get(codigo_producto)!.cantidad_producto + cantidad_producto,
            nombre_producto, // el nombre es el mismo para el mismo código
          });
        } else {
          // Si no existe, lo inicializamos con la cantidad actual
          productosPorCodigo.set(codigo_producto, { cantidad_producto, nombre_producto });
        }    
      });        
      
    });
    const resultado: IConteo[] = [];

    conteoProductosPorDia.forEach((productosPorCodigo, fecha_creacion) => {
      productosPorCodigo.forEach(({ cantidad_producto, nombre_producto }, codigo_producto) => {
        resultado.push({ fecha_creacion, codigo_producto, nombre_producto, cantidad_producto });
      });
    });
    return resultado;
    
  }

   // Crear el gráfico de barras
   createBarChart() {
    this.cdRef.detectChanges();
    const ctx = this.barCanvas.nativeElement.getContext('2d'); // Obtener el contexto 2D del canvas

    if (ctx) {
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.listaProductos,          
          datasets: [{
                                 
            data: this.listaCantidades,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',   // Rojo claro
              'rgba(54, 162, 235, 0.2)',   // Azul claro
              'rgba(255, 206, 86, 0.2)',   // Amarillo claro
              'rgba(75, 192, 192, 0.2)',   // Verde azulado claro
              'rgba(153, 102, 255, 0.2)',  // Morado claro
              'rgba(255, 159, 64, 0.2)',   // Naranja claro
              'rgba(255, 99, 132, 0.2)',   // Rojo claro
              'rgba(54, 162, 235, 0.2)',   // Azul claro
              'rgba(255, 206, 86, 0.2)',   // Amarillo claro
              'rgba(75, 192, 192, 0.2)',   // Verde azulado claro
              'rgba(201, 203, 207, 0.2)',  // Gris claro
              'rgba(102, 255, 178, 0.2)',  // Verde menta claro
              'rgba(255, 255, 102, 0.2)',  // Amarillo fuerte claro
              'rgba(255, 102, 153, 0.2)',  // Rosa claro
              'rgba(0, 204, 102, 0.2)'     // Verde oscuro claro
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',   // Rojo claro
              'rgba(54, 162, 235, 1)',   // Azul claro
              'rgba(255, 206, 86, 1)',   // Amarillo claro
              'rgba(75, 192, 192, 1)',   // Verde azulado claro
              'rgba(153, 102, 255, 1)',  // Morado claro
              'rgba(255, 159, 64, 1)',   // Naranja claro
              'rgba(255, 99, 132, 1)',   // Rojo claro
              'rgba(54, 162, 235, 1)',   // Azul claro
              'rgba(255, 206, 86, 1)',   // Amarillo claro
              'rgba(75, 192, 192,1)',   // Verde azulado claro
              'rgba(201, 203, 207, 1)',  // Gris claro
              'rgba(102, 255, 178, 1)',  // Verde menta claro
              'rgba(255, 255, 102, 1)',  // Amarillo fuerte claro
              'rgba(255, 102, 153, 1)',  // Rosa claro
              'rgba(0, 204, 102, 1)'     // Verde oscuro claro
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            duration: 2000,
            easing: 'easeInOutBounce'
          },
          scales: {
            y: {
              beginAtZero: true,                           
              ticks: {
                stepSize: 1, // Incremento de 1 para el eje Y
                //callback: (value: number | string) => ${value} unidades // Añade 'unidades' a los valores del eje Y
              }
            },
          },          
          plugins: {
            title: {
              display: true,
              text: 'Ranking por Cantidad (máx. '+this.cantidadElementos.toString()+' prod.)',
              padding: {
                top: 5,    // Espacio superior
                bottom: 20  // Espacio inferior
              }
            },
            datalabels: {
              anchor: 'end', // Colocar las etiquetas en la parte superior de la barra
              align: 'end',  // Alinear las etiquetas en la parte superior
              formatter: (value: any) => value, // Mostrar el valor de la barra
              color: '#000', // Color del texto (puedes personalizarlo)
              font: {
                weight: 'bold'
              }
            },
            legend: {
              display: false, // Oculta la leyenda
            },
          }
        },
        plugins: [ChartDataLabels] // Activar el plugin de DataLabels
      });
    }
  }
}
