<div fxLayout="row" fxLayoutAlign="space-between start">
    <div mat-dialog-title class="dialog-title">Ambientes - Mesas</div>
    <button mat-icon-button (click)="onClose()">
        <mat-icon>{{ Icons.CLOSE }}</mat-icon>
    </button>
</div>
<mat-divider></mat-divider>
<div class="scrollable" 
(touchstart)="touchStart($event)"
(touchmove)="touchMove($event)"
(touchend)="touchEnd($event)">
    <div *ngIf="isLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="!isLoading"  id="ambMesas" >
        <div *ngFor="let ambiente of ambientesUnicos">
            <div fxLayout="column" fxLayoutGap="10px" style="margin-top: 10px;">
                <div fxLayout="row">
                    <mat-icon>{{Icons.DECK}}</mat-icon>
                    <span class="titulo-accordeon">{{ambiente.toUpperCase()}}</span>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="5px" style="margin-bottom: 5px;">
                    <ng-container *ngFor="let mesa of configuracionAmbiente">
                        <button mat-fab color="primary" type="button" *ngIf="mesa.ambiente===ambiente"
                            matTooltipPosition="above"
                            [ngClass]="mesa.mesa_estado==EstadosMesaType.DISPONIBLE ? 'button-after-click':'button-before-click'"
                            (click)="seleccionarMesa(mesa)"
                            [matTooltip]="(mesa.mesa_estado.replace('_',' ') | titlecase) +(mesa.usuario_modificacion? (' | Gestor:' +mesa.usuario_modificacion | hideMail):'')"
                            [disabled]="
                                    (mesa.mesa_estado!= EstadosMesaType.DISPONIBLE  && 
                                    usuario.correo_electronico!==mesa.usuario_modificacion && !esAdministrador) ||       
                                    (mesa.mesa_estado== EstadosMesaType.OCUPADO) ||
                                    (mesa.mesa_estado== EstadosMesaType.RESERVADO && !esAdministrador)
                                    
                                    ">
                            <mat-icon>{{Icons.TABLE_BAR}}</mat-icon>
                            {{mesa.mesa_numero}}
                        </button>
                    </ng-container>
                </div>
                <div style="margin-top: 10px; margin-bottom: 10px;">
                    <mat-divider></mat-divider>
                </div>
            </div>
        </div>
    </div>
</div>