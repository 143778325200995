<mat-tab-group (selectedTabChange)="onTabChange($event)" mat-align-tabs="center">
  <mat-tab style="max-width: 30px;" label="operaciones">
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">{{Icons.TIMER}}</mat-icon>
      <span class="titulo-tab">Métricas (Turno Actual)</span>
    </ng-template>
    <ng-container *ngIf="selectedIndex === 0">
      <app-operaciones-metricas></app-operaciones-metricas>
    </ng-container>
  </mat-tab>
  <mat-tab style="max-width: 30px;" label="tendProds">
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">{{Icons.BAR_CHART_4_BARS}}</mat-icon>
      <span class="titulo-tab">Ranking De Productos (Turno Actual)</span>
    </ng-template>
    <ng-container *ngIf="selectedIndex === 1">
      <app-tendencia-productos></app-tendencia-productos>
    </ng-container>
  </mat-tab>
  <mat-tab style="max-width: 30px;" label="tendVentas">
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">{{Icons.TIMELINE}}</mat-icon>
      <span class="titulo-tab">Tendencia De Ventas (Histórico)</span>
    </ng-template>
    <ng-container *ngIf="selectedIndex === 2">
      <app-graph-tendencia></app-graph-tendencia>
    </ng-container>
  </mat-tab>
</mat-tab-group>