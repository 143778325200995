import { Injectable } from '@angular/core';
import { GestionMontoDaService } from './gestion-monto.da.service';
import { Turno } from '../models/Turno';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Observable } from 'rxjs';
import { CorreccionEfectivo } from '../models/CorreccionEfectivo';
import { SolicitarCambioType } from 'src/app/shared/types/solicitar-cambio-type';
import { VariacionEfectivo } from '../models/VariacionEfectivo';
import { ItemSidenav } from 'src/app/shared/models/item.model';

@Injectable({
  providedIn: 'root'
})
export class GestionMontoBlService {

  constructor(
      private gestionMontoDaService:GestionMontoDaService,
      private registroService: RegistroService
  ) { }

  existFetchTurno(){
    const idEmpresa = this.registroService.empresa.id;
    const idUsuario = this.registroService.usuario.id;
    this.gestionMontoDaService.fetchTurno(idEmpresa,idUsuario);
    
  }
  existFetchTurnoReporte(dias:number){
    const idEmpresa = this.registroService.empresa.id;
    const idUsuario = this.registroService.usuario.id;
    this.gestionMontoDaService.fetchTurnoReporte(idEmpresa,idUsuario, dias);
    
  }
  existFetchTurnoReporteTodo(){
    const idEmpresa = this.registroService.empresa.id;
    const idUsuario = this.registroService.usuario.id;
    this.gestionMontoDaService.fetchTurnoReporteTodo(idEmpresa,idUsuario);
    
  }
  
  getTurno():Observable<Turno | null | undefined>{
    return this.gestionMontoDaService.turno$;
  }
  getTurnoReporte():Observable<Turno[] | null | undefined>{
    return this.gestionMontoDaService.turnoRpt$;
  }

  getTurnoReporteTodo():Observable<Turno[] | null | undefined>{
    return this.gestionMontoDaService.turnoRptTodo$;
  }

  insertTurno(turno:Turno){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionMontoDaService.insertTurno(turno,idEmpresa,correoUsuario);
  }

  updateCerrarTurno(turno:Turno){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionMontoDaService.updateCerrarTurno(turno,idEmpresa,correoUsuario);
  }

  updateTurnoReingresoMonto(turno:Turno, montoAnterior:number, esAdministrador:boolean, esProcesar:boolean, accion:SolicitarCambioType |null, goOptionSidenavItem: ItemSidenav){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionMontoDaService.updateTurnoReingresoMonto(turno,idEmpresa,correoUsuario,montoAnterior, esAdministrador, esProcesar, accion, goOptionSidenavItem);
  }

  updateTurnoAgregarMonto(turno:Turno, esAdministrador:boolean, goOptionSidenavItem:ItemSidenav){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;    
    return this.gestionMontoDaService.updateTurnoAgregarMonto(turno, idEmpresa,correoUsuario,esAdministrador, goOptionSidenavItem )
  }
  
  updateTurnoRetirarMonto(turno:Turno, esAdministrador:boolean, anotacion:string, goOptionSidenavItem:ItemSidenav){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;    
    return this.gestionMontoDaService.updateTurnoRetirarMonto(turno, idEmpresa,correoUsuario,esAdministrador, anotacion, goOptionSidenavItem )
  }


  updateTurnoProcesarAgregar(turno:Turno, accion:SolicitarCambioType |null){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionMontoDaService.updateTurnoProcesarAgregar(turno,idEmpresa,correoUsuario, accion);
  }

  updateTurnoProcesarRetirar(turno:Turno, accion:SolicitarCambioType |null){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionMontoDaService.updateTurnoProcesarRetirar(turno,idEmpresa,correoUsuario, accion);
  }



}
