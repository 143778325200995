<div fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxFlex="50%" fxFlex.xs="100%">
        <mat-card-header>
            <mat-card-title>Generar Recibo Mensual - Efectuar Corte de Servicio</mat-card-title>
            <mat-card-subtitle>Genera el  mensual de pago para todas las empresas, como obligación de pago</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
            <div  fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center" style="margin-top: 20px;" fxLayoutGap="20px" >
                <app-button [width]="100" text="Generar Recibos Todo" fxFlex="70%" fxFlex.xs="60%" [attr_type]="'button'" (click)="generarRecibos()" ></app-button>
                <app-button [width]="100" text="Ejecutar Corte Pagos Pendientes" fxFlex="70%" fxFlex.xs="60%" [attr_type]="'button'" (click)="efectuarCorteServicio()" ></app-button>                
            </div> 
        </mat-card-content>
    </mat-card>
</div>