import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
declare var grecaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  private siteKey = environment.recaptcha.key;  
  private readyPromise!: Promise<void>;
  constructor(
    
  ) { 
    //console.log('RecaptchaService constructor llamado');

    this.readyPromise = new Promise((resolve, reject) => {
      this.addRecaptchaScript(() => {
        if (typeof grecaptcha !== 'undefined') {
          //console.log('grecaptcha está disponible');
          resolve();
        } else {
          //console.error('grecaptcha no está disponible');
          reject('grecaptcha no está disponible');
        }
      });
    });
  }

  private addRecaptchaScript(callback: () => void) {
    const scriptId = 'recaptcha-script';
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        //console.log('Script de reCAPTCHA cargado correctamente');
        callback();
      };

      script.onerror = () => {
        //console.error('Error al cargar el script de reCAPTCHA');
        callback();
      };

      document.head.appendChild(script);
      //console.log('Script de reCAPTCHA agregado al documento');
    } else {
      //console.log('El script de reCAPTCHA ya está cargado');
      callback();
    }
  }

  executeAction(action: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (grecaptcha) {
        grecaptcha.ready(() => {
          grecaptcha
            .execute(this.siteKey, { action: action })
            .then((token: string) => {
              resolve(token);
            })
            .catch((error: any) => {
              reject(error);
            });
        });
      } else {
        reject('reCAPTCHA no está cargado correctamente.');
      }
    });
  }

}
