<div fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxFlex="50%" fxFlex.xs="100%">
        <mat-card-header>
            <mat-card-title>Efectuar Pago o Reconexión de Servicio</mat-card-title>
            <mat-card-subtitle>Activa la cuenta de la empresa</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
            <form #f="ngForm" (ngSubmit)="buscarEmpresa(f)" >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <mat-form-field appearance="outline" class="control-row" style="padding-top: 10px;">
                        <mat-label>RUC</mat-label>
                        <input type="text" 
                            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_10"
                            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_11"
                            [pattern]="PatternsRegex.onlyIntegerUnsigned" 
                            matInput placeholder="Número de RUC" ngModel
                            name="ruc" #ruc="ngModel" 
                            autocomplete="off" 
                            required>
                        <mat-icon matSuffix>{{Icons.QR_CODE_2}}</mat-icon>
                        <mat-error
                            *ngIf="ruc.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                        <mat-error
                            *ngIf="ruc.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_REQ6_DIGITOS_CODIGO}}</mat-error>
                        <mat-error
                            *ngIf="ruc.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_10}}</mat-error>
                        <mat-error
                            *ngIf="ruc.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_11}}</mat-error>
                    </mat-form-field>
                    <div fxLayout="row" fxLayoutGap="20px" >
                        <button mat-stroked-button [disabled]="f.invalid">
                            <mat-icon>{{Icons.SEARCH}}</mat-icon>
                        </button>
                        <button mat-stroked-button type="button" (click)="limpiar(f)" >
                            <mat-icon>{{Icons.CLEANING}}</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
            <div *ngIf="isLoading"  fxLayout="row" fxLayoutAlign="center center" style="margin-top:10px">
                <app-spinner></app-spinner>
            </div>
            <div *ngIf="!isLoading" fxLayout="column" fxLayoutGap="20px" >
                <span *ngIf="empresa==null && esBuscado" >No se encontró la empresa</span>
                <div *ngIf="empresa!=null">Empresa:&nbsp;<span style="font-weight: bold;">{{empresa.empresa.nombre_razon}}</span></div>                
                <div fxLayout="row" *ngIf="empresa" fxLayoutGap="10px" >                    
                    <div style="margin-top:10px">Estado Faturación:&nbsp;</div>
                    <mat-chip-list>
                        <mat-chip  
                            *ngIf="empresa.recibo==null" 
                            class="chip-pendiente" >Sin Deuda
                        </mat-chip>                        
                        <mat-chip  [@blink]="blinkState"
                            *ngIf="empresa.recibo!=null && empresa.recibo.pendiente_pago"
                            class="chip-rechazado" >Con Deuda
                        </mat-chip>                        
                    </mat-chip-list>
                    <div  *ngIf="empresa.recibo!=null && empresa.recibo.pendiente_pago" matTooltip="Activar Cuenta">
                        <button mat-stroked-button (click)="activarEmpresa()" >
                            <mat-icon>{{Icons.CHECK}}</mat-icon>
                        </button>
                    </div>                 
                </div>              
            </div>
        </mat-card-content>
    </mat-card>
</div>