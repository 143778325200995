import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Auditoria, colections, documentCatalogo, documentProducto, documentsEmpresa } from 'src/app/shared/cons/db.colections';
import { Catalogo } from '../../models/Catalogo';
import { IResumenCatalogo } from '../../models/IResumenCatalogo';
import { Utils } from 'src/app/shared/helpers/utils';
import { deleteField, serverTimestamp } from 'firebase/firestore';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { ITableFiltroCatalogo } from '../../models/ITableCatalogoFiltro';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MsjEstado } from 'src/app/shared/cons/common';
@Injectable({
  providedIn: 'root'
})
export class DialogCatalogoDaService {
  transaccion: TransaccionModel = new TransaccionModel();
  contadorResumenUpdate!:IResumenCatalogo;
  contadorResumenGet!:IResumenCatalogo;
  //busqueda y paginacion
  private filtroCatalogo: ITableFiltroCatalogo = {
    itemsPerPage: 5,
    currentPage: 1,
    nombresFiltro: null,
    codigoFiltro: null,
    estadoFiltro: null,
    nextPage: null,
    previousPage: null,
    minPage: null,
    maxPage: null,
    ordenamiento: 'desc',
  }

  utilCleanFlags(){
    this.filtroCatalogo.firstVisibleDoc = null;
    this.filtroCatalogo.lastVisibleDoc =null;
    this.filtroCatalogo.ordenamiento = 'desc';
  }

  private removeUtilProperties(catalogo:Catalogo): Catalogo {
    delete catalogo.estado;
    delete catalogo.fecha_registro;
    delete catalogo.masElementos;
    delete catalogo.totalRegistros_query;
    delete catalogo.previousPage;
    delete catalogo.nextPage;
    delete catalogo.numeracion;
    delete catalogo.totalRegistrosActivos;
    delete catalogo.totalRegistrosInactivos;
    delete catalogo.totalRegistros_doc;
    delete catalogo.total_productos;
    delete catalogo.ref;
    return catalogo;
  }




  constructor(
    private db: AngularFirestore,
  ) { }

  async getProductoXCodigo(codigoCatalogo:string, idEmpresa:string){
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const queryDoc = await docEmpRef.collection(colections.CATALOGO, ref=>
      ref.where(documentCatalogo.CODIGO_CATALOGO,'==',codigoCatalogo)).get().toPromise();

    if(queryDoc.empty){
      return true;
    }
    else{
      return false;
    }
  }

  getCatalogoResumenCombinado(idEmpresa: string, idUsuario: string, filtroProducto: ITableFiltroCatalogo): Observable<Catalogo[]> {
    const observableResumen = this.getCatalogoResumen(idEmpresa);
    const observableCatalogos = this.getCatalogos(idEmpresa, idUsuario, filtroProducto);

    return combineLatest([observableResumen, observableCatalogos]).pipe(
      map(([catalogoResumen, catalogoList]) => {
        if (catalogoList?.length && catalogoResumen !== null) {
          let nFinal = 0;
          let nInicial = 0;
          if (this.filtroCatalogo.ordenamiento == 'desc') {
            nFinal = this.filtroCatalogo.itemsPerPage * this.filtroCatalogo.currentPage;
            nInicial = nFinal - this.filtroCatalogo.itemsPerPage + 1;
          } else {
            nFinal = catalogoResumen.total_catalogos;
            nInicial = catalogoResumen.total_catalogos - this.filtroCatalogo.itemsPerPage * (this.filtroCatalogo.currentPage - 1);
          }

          catalogoList.forEach((elemento) => {
            elemento.numeracion = nInicial;
            elemento.totalRegistros_doc = catalogoResumen.total_catalogos;
            elemento.totalRegistrosActivos = catalogoResumen.total_catalogos_activos;
            elemento.totalRegistrosInactivos = catalogoResumen.total_catalogos_inactivos;
            nInicial = this.filtroCatalogo.ordenamiento == 'desc' ? nInicial + 1 : nInicial - 1;
          });

          return catalogoList; // Emitir los últimos resultados procesados
        } else {
          // Código para manejar el caso en que la lista esté vacía
          const listaCatalogoUtil: Catalogo[] = [];
          const catalogoUtilitario = new Catalogo();
          catalogoUtilitario.totalRegistros_doc = catalogoResumen.total_catalogos;
          catalogoUtilitario.totalRegistrosActivos = catalogoResumen.total_catalogos_activos;
          catalogoUtilitario.totalRegistrosInactivos = catalogoResumen.total_catalogos_inactivos;
          listaCatalogoUtil.push(catalogoUtilitario);
          return listaCatalogoUtil; // Emitir los resultados procesados
        }
      }),
      catchError(error=>{
            console.error('Error en getColaboradorResumenCombinadoaa:', error);
            return of([]);
      })
    );
  }


  getCatalogoResumen(idEmpresa:string):Observable<IResumenCatalogo>{
    const docEmpresa = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docProductoResumen = docEmpresa.collection(colections.CATALOGO_RESUMEN);

    return docProductoResumen.snapshotChanges()
    .pipe(
      map(resumen=>{
        if(resumen.length>0){
          const datos = resumen[0].payload.doc.data() as IResumenCatalogo;
          datos.id_documento = resumen[0].payload.doc.id;
          return datos;
        }
        else{
          const resumenFict:IResumenCatalogo = {
            total_catalogos: 0,
            total_catalogos_activos: 0,
            total_catalogos_inactivos: 0,
            fecha_modificacion: null,
            id_documento: undefined,
          };
          return resumenFict;
        }

      }),
      catchError(error => {
        console.error('Error en getCatalogoResumen:', error);
        // Puedes retornar un valor por defecto o rethrow del error
        // Aquí estamos rethrowing el error para que cualquier suscriptor pueda manejarlo
        throw error;
      })
    );
  }

  async getCatalogoTodo(idEmpresa:string){
    let listaCatalogoFinal:Catalogo[]=[];
    const colectionCatalogo = this.db.collection(colections.EMPRESA, (ref) =>
      ref.where(Auditoria.es_borrado, '==', false)).doc(idEmpresa)
      .collection(colections.CATALOGO, (ref2)=> ref2.where(Auditoria.es_borrado,'==',false));
    const listaCatalogo = await colectionCatalogo.get().toPromise();
    if(!listaCatalogo.empty){
      listaCatalogo.forEach(cata=>{
        const dataCat = cata.data() as Catalogo;
        dataCat.id = cata.id;
        listaCatalogoFinal.push(dataCat);
      });
      return listaCatalogoFinal;
    }else{
      return null;
    }

  }

  getCatalogos(
    idEmpresa: string,
    idUsuario: string,
    filtroCatalogo: ITableFiltroCatalogo
  ){

    this.filtroCatalogo.itemsPerPage = filtroCatalogo.itemsPerPage;//seteamos los valores
    this.filtroCatalogo.currentPage = filtroCatalogo.currentPage;
    this.filtroCatalogo.nombresFiltro = filtroCatalogo.nombresFiltro;
    this.filtroCatalogo.codigoFiltro = filtroCatalogo.codigoFiltro;
    this.filtroCatalogo.estadoFiltro = filtroCatalogo.estadoFiltro;
    this.filtroCatalogo.nextPage = filtroCatalogo.nextPage;
    this.filtroCatalogo.previousPage = filtroCatalogo.previousPage;
    this.filtroCatalogo.minPage = filtroCatalogo.minPage;
    this.filtroCatalogo.maxPage = filtroCatalogo.maxPage;

    let listaCatalogosLocal: Catalogo[] = [];
    if (this.filtroCatalogo.minPage) {
      this.filtroCatalogo.ordenamiento = 'desc';
    }
    if (this.filtroCatalogo.maxPage) {
      this.filtroCatalogo.ordenamiento = 'asc';
    }

    return this.db.collection(colections.EMPRESA, (ref) =>
    ref.where(Auditoria.es_borrado, '==', false)
      .where(documentsEmpresa.ID_DOC_USUARIO, '==', idUsuario))
    .doc(idEmpresa)
    .collection(colections.CATALOGO, (ref1) => {
      let query = ref1
        .orderBy(documentProducto.AUTO_NUMERICO, this.filtroCatalogo.ordenamiento)
        .where(Auditoria.es_borrado, '==', false);

      if (this.filtroCatalogo.nombresFiltro) {
        const lFiltroNombre = Utils.CadenaToArray(this.filtroCatalogo.nombresFiltro);
        query = query
          .where(documentCatalogo.NOMBRES_COMPLETO, 'array-contains-any', lFiltroNombre)
      }
      if (this.filtroCatalogo.codigoFiltro) {
        query = query
          .where(documentCatalogo.CODIGO_CATALOGO, '==', this.filtroCatalogo.codigoFiltro)
      }
      if (this.filtroCatalogo.estadoFiltro != null) {
        query = query
          .where(Auditoria.es_vigente, '==', this.filtroCatalogo.estadoFiltro)

      }
      if (this.filtroCatalogo.nextPage == true)
      {
        query = query.startAfter(this.filtroCatalogo.lastVisibleDoc)
      }
      //no se implementa retroceso en busqueda
      if (this.filtroCatalogo.previousPage == true)
      {
        query = query.endBefore(this.filtroCatalogo.firstVisibleDoc)
      }

      if (this.filtroCatalogo.previousPage)
      {

        query = query.limitToLast(this.filtroCatalogo.itemsPerPage);
      }
      else{
        query = query.limit(this.filtroCatalogo.itemsPerPage+1);// se suma 1 para traer un elemento más
      }

      return query;
    })
    .snapshotChanges()
    .pipe(
      map((catalogos) => {
        if (catalogos.length > 0) {
          // **para paginación
          this.filtroCatalogo.firstVisibleDoc = catalogos[0].payload.doc;
          const catalogosListaDatos = [...catalogos];

          // para paginacion con busqueda
          let masElementos: boolean = false;
          if (catalogos.length > this.filtroCatalogo.itemsPerPage) {
            this.filtroCatalogo.lastVisibleDoc = catalogos[catalogos.length - 2].payload.doc;
            masElementos = true;
            catalogosListaDatos.pop();
          }
          else {
            //cuando viene de una pagina previa y la pginacion actual será 1
            if (this.filtroCatalogo.previousPage &&
              this.filtroCatalogo.currentPage>1) {
                masElementos = true;
            }else{
              masElementos = false;
            }
            this.filtroCatalogo.lastVisibleDoc = catalogos[catalogos.length - 1].payload.doc;
          }

          // limpiamos el array y generamos numeracion
          listaCatalogosLocal.length = 0;

          catalogosListaDatos.forEach((cata) => {
            const catalogoData = Utils.convertDate({ ...cata.payload.doc.data(), }) as Catalogo;
            // **generando propiedades utilitarias
            catalogoData.estado = catalogoData.es_vigente ? MsjEstado.ACTIVO.toLowerCase() : MsjEstado.INACTIVO.toLowerCase();
            catalogoData.fecha_registro = Utils.ToDateISO(catalogoData.fecha_creacion, false);
            catalogoData.id = cata.payload.doc.id;
            catalogoData.masElementos = masElementos;
            catalogoData.totalRegistros_query = catalogosListaDatos.length; // indica cuántos registros hay por query
            catalogoData.previousPage= this.filtroCatalogo.previousPage?true: undefined;
            catalogoData.nextPage= this.filtroCatalogo.nextPage?true: undefined;
            catalogoData.total_productos = catalogoData.lista_productos.length;

            listaCatalogosLocal.push(catalogoData);
          });

          return listaCatalogosLocal
        } else {
          this.filtroCatalogo.firstVisibleDoc = null;
          this.filtroCatalogo.lastVisibleDoc = null;

          const cataFic: Catalogo = new Catalogo();
          const cataListaFic: Catalogo[] = new Array();
          cataFic.totalRegistros_query=0;
          cataListaFic.push(cataFic);

          return cataListaFic;
        }
      }),
      catchError(error => {
        //console.log(error);
        return of([])
      })
    );
  }

  getCatalogosAutocomplete(
    idEmpresa: string,
    idUsuario: string,
    nombreCatalogo: string
  ){

    let listaCatalogoLocal: Catalogo[] = [];
    this.filtroCatalogo.ordenamiento = 'desc';
    const nombreFiltro = Utils.CadenaToArray(nombreCatalogo);
    return this.db.collection(colections.EMPRESA, (ref) =>
    ref.where(Auditoria.es_borrado, '==', false)
      .where(documentsEmpresa.ID_DOC_USUARIO, '==', idUsuario))
    .doc(idEmpresa)
    .collection(colections.CATALOGO, (ref1) => {
      let query = ref1
        .where(Auditoria.es_borrado, '==', false)
        .where(documentCatalogo.NOMBRES_COMPLETO, 'array-contains-any', nombreFiltro)
        .limit(5)
      return query;
    })
    .get().pipe(map(listaCatalogos=>{
      if(!listaCatalogos.empty){
        listaCatalogos.forEach(catalogo=>{
          const catalogoData = Utils.convertDate({ ...catalogo.data(), }) as Catalogo;
          catalogoData.id = catalogo.id;
          listaCatalogoLocal.push(catalogoData);
        });
       return listaCatalogoLocal;
      }else{
        return listaCatalogoLocal;
      }
    }));
  }

  async insertCatalogo(catalogo:Catalogo, idEmpresa:string, correoUsuario:string){
     // Serialize el objeto producto para la base de datos.
    let isResumenInsert:boolean = false;
    let resumenDocInsert!:IResumenCatalogo;
    let resumenDocUpd!:IResumenCatalogo;
    let resumenDocGet!:IResumenCatalogo;

    const docCatalogo = Utils.SerializeJsonToDb(catalogo);
    docCatalogo.fecha_creacion = serverTimestamp();
    docCatalogo.usuario_creacion= correoUsuario;
    try{
      const txResult = await this.db.firestore.runTransaction(async(transaccion)=>{
        const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
        const docProdRef = docEmpRef.collection(colections.CATALOGO);
        const docResumenProdRef = docEmpRef.collection(colections.CATALOGO_RESUMEN);

        await docResumenProdRef.get().toPromise()
        .then(docResumenRef=>{
          //cuando no existe ningun producto
          if(docResumenRef.empty){
            docCatalogo.auto_numerico=1;

            resumenDocInsert = {
              total_catalogos:1,
              total_catalogos_activos:catalogo.es_vigente?1:0,
              total_catalogos_inactivos:!catalogo.es_vigente?1:0,
              fecha_modificacion: serverTimestamp()
            }
            isResumenInsert=true;
          }
          //cuando existen productos
          else{
            isResumenInsert=false;

            resumenDocGet = Utils.convertDate(docResumenRef.docs[0].data()) as IResumenCatalogo;
            resumenDocGet.id_documento = docResumenRef.docs[0].id;
            const total_docs = resumenDocGet.total_catalogos+1;
            //seteando el total docs al autonumerico
            docCatalogo.auto_numerico = total_docs;
            //seteando nuevos valores
            resumenDocUpd = {
              total_catalogos:total_docs,
              total_catalogos_activos: catalogo.es_vigente? resumenDocGet.total_catalogos_activos+1: resumenDocGet.total_catalogos_activos,
              total_catalogos_inactivos: !catalogo.es_vigente? resumenDocGet.total_catalogos_inactivos+1: resumenDocGet.total_catalogos_inactivos,
              fecha_modificacion: serverTimestamp()
            }
          }
        });

         //seteando valores
         transaccion.set(docProdRef.doc().ref, docCatalogo);
         if(isResumenInsert){
           transaccion.set(docResumenProdRef.doc().ref, resumenDocInsert);
         }else{
           transaccion.update(docResumenProdRef.doc(resumenDocGet.id_documento).ref, resumenDocUpd);
         }

         const newCatalogo = docProdRef.ref.id;
         const newDocResumenCatalogo = docResumenProdRef.doc().ref.id;

         this.transaccion = {
          tx: true,
          data: {
            idCatalogo: newCatalogo,
            idResumenCatalogo: newDocResumenCatalogo
          }
        };
        return this.transaccion;


      });
      return txResult;
    }
    catch(error){
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
  }

  async deleteCatalogo(catalogo:Catalogo, idEmpresa:string){
    try {
      const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
        const docRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
        const docCatalogo = docRef.collection(colections.CATALOGO).doc(catalogo.id);

        //generando tabla resumen, siempre hay un solo documento
        const contadorProductoRef = docRef.collection(colections.CATALOGO_RESUMEN);
        await contadorProductoRef.get().toPromise()
          .then(docSnapshot => {
            if (!docSnapshot.empty) {
              this.contadorResumenGet = Utils.convertDate(docSnapshot.docs[0].data()) as IResumenCatalogo;
              this.contadorResumenGet.id_documento = docSnapshot.docs[0].id;
              let activos = this.contadorResumenGet.total_catalogos_activos;
              let inactivos = this.contadorResumenGet.total_catalogos_inactivos;
              if (catalogo.es_vigente) {
                activos -= 1;
              }
              else {
                inactivos -= 1;
              }

              this.contadorResumenUpdate = {
                total_catalogos: this.contadorResumenGet.total_catalogos - 1,
                total_catalogos_activos: (this.contadorResumenGet.total_catalogos - 1)===0? 0: activos,
                total_catalogos_inactivos: (this.contadorResumenGet.total_catalogos - 1)===0? 0: inactivos
              }
              const contadorResumenUpd = Utils.SerializeJsonToDb(this.contadorResumenUpdate);
              contadorResumenUpd.fecha_actualizacion = serverTimestamp();
              transaction.update(contadorProductoRef.doc(this.contadorResumenGet.id_documento).ref, contadorResumenUpd);
            }
          });

        transaction.delete(docCatalogo.ref);
        this.transaccion = {
          tx: true,
        };
        return this.transaccion;
      });
      return transactionResult;
    }
    catch (error) {
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  async updateCatalogoEstado(idEmpresa:string, correoUsuario:string, catalogo:Catalogo, esActivar:boolean){
    try{
      const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
        const docRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
        const docProducto = docRef.collection(colections.CATALOGO).doc(catalogo.id);

        //genernado tabla resumen, siempre hay un solo documento
        const contadorCatalogoRef = docRef.collection(colections.CATALOGO_RESUMEN);
        await contadorCatalogoRef.get().toPromise()
        .then(docSnapshot=>{
          if(!docSnapshot.empty){
            this.contadorResumenGet = Utils.convertDate(docSnapshot.docs[0].data()) as IResumenCatalogo;
            this.contadorResumenGet.id_documento = docSnapshot.docs[0].id;
            let activos = this.contadorResumenGet.total_catalogos_activos;
            let inactivos = this.contadorResumenGet.total_catalogos_inactivos;
            if (esActivar) {
              activos = activos + 1;
              inactivos = inactivos - 1;
            }
            else {
              activos = activos - 1;
              inactivos = inactivos + 1;
            }

            this.contadorResumenUpdate = {
              total_catalogos: this.contadorResumenGet.total_catalogos,
              total_catalogos_activos: activos,
              total_catalogos_inactivos: inactivos
            }
            const contadorResumenUpd = Utils.SerializeJsonToDb(this.contadorResumenUpdate);
            contadorResumenUpd.fecha_actualizacion = serverTimestamp();
            transaction.update(contadorCatalogoRef.doc(this.contadorResumenGet.id_documento).ref,contadorResumenUpd);
          }
        });

        transaction.update(docProducto.ref, {
          fecha_modificacion : serverTimestamp(),
          usuario_modificacion : correoUsuario,
          es_vigente: esActivar
        });
        const newDocIdSubcoleccion = docProducto.ref.id;

        this.transaccion = {
          tx: true,
          data: {
            idColaborador: newDocIdSubcoleccion,
          }
        };
        return this.transaccion;
      });
      return transactionResult;
    }
    catch(error){
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  async updateCatalogo(catalogo: Catalogo, correoUsuario: string, idEmpresa: string, estadoProductoEdicion: boolean) {
    const catalogoOriginal = { ...this.removeUtilProperties(catalogo) };
    const catalogoUpd = Utils.SerializeJsonToDb(catalogoOriginal);
    catalogoUpd.usuario_modificacion = correoUsuario
    try {
      const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
        const docRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
        const docCatalogo = docRef.collection(colections.CATALOGO).doc(catalogo.id);

        //genernado tabla resumen, siempre hay un solo documento
        const contadorCatalogoRef = docRef.collection(colections.CATALOGO_RESUMEN);
        const resProductoSnaps = await contadorCatalogoRef.get().toPromise();

        //cambiando estado si esta variado
        if (estadoProductoEdicion != catalogo.es_vigente) {
          if (!resProductoSnaps.empty) {
            this.contadorResumenGet = Utils.convertDate(resProductoSnaps.docs[0].data()) as IResumenCatalogo;
            this.contadorResumenGet.id_documento = resProductoSnaps.docs[0].id;
            let activos = this.contadorResumenGet.total_catalogos_activos;
            let inactivos = this.contadorResumenGet.total_catalogos_inactivos;
            if (catalogo.es_vigente) {
              activos = activos + 1;
              inactivos = inactivos - 1;
            }
            else {
              activos = activos - 1;
              inactivos = inactivos + 1;
            }

            this.contadorResumenUpdate = {
              total_catalogos: this.contadorResumenGet.total_catalogos,
              total_catalogos_activos: activos,
              total_catalogos_inactivos: inactivos
            }
            const contadorResumenUpd = Utils.SerializeJsonToDb(this.contadorResumenUpdate);
            contadorResumenUpd.fecha_actualizacion = serverTimestamp();
            transaction.update(contadorCatalogoRef.doc(this.contadorResumenGet.id_documento).ref, contadorResumenUpd);
          }
        }

        transaction.update(docCatalogo.ref, {
          nombre_catalogo: catalogoOriginal.nombre_catalogo,
          nombres_completo:catalogoOriginal.nombres_completo,
          descripcion_catalogo: catalogoOriginal.descripcion_catalogo,
          codigo_productos_anidados_completo: catalogoOriginal.codigo_productos_anidados_completo,
          lista_productos:catalogoOriginal.lista_productos,
          es_vigente:catalogoOriginal.es_vigente,
          usuario_modificacion: correoUsuario,
          fecha_modificacion: serverTimestamp()
        });
        const newDocIdSubcoleccion = docCatalogo.ref.id;
        this.transaccion = {
          tx: true,
          data: {
            idColaborador: newDocIdSubcoleccion,
          }
        };
        return this.transaccion;
      });
      return transactionResult;

    }
    catch (error) {
      this.transaccion = { tx: false, error: error };     
      return this.transaccion;
    }

  }

}
