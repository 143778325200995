import { Component, OnInit } from '@angular/core';
import { SpConsolaService } from 'src/app/shared/services/sp-consola.service';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import * as moment from "moment";
import { Fecha } from '../../gestion-monto/models/Fecha';
import { Utils } from 'src/app/shared/helpers/utils';
import { DatosPlataforma, Msjs } from 'src/app/shared/cons/common';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';

@Component({
  selector: 'app-gestionar-recibo',
  templateUrl: './gestionar-recibo.component.html',
  styleUrls: ['./gestionar-recibo.component.scss']
})
export class GestionarReciboComponent implements OnInit {
  fechaHoy: Fecha = new Fecha();

  constructor(
    private spConsolaService: SpConsolaService,
    private readonly spinnerPageService: SpinnerPageService,
    private uiService:UIService,
    private functionService: FunctionsService,
  ) { }

  ngOnInit(): void {
  }

  efectuarCorteServicio(){
    this.spinnerPageService.show();
    this.spConsolaService.efectuarCorteServicio().then(res=>{
      if(res.tx==true){
        this.spinnerPageService.hide();
        this.uiService.ShowSnackBar(SnackBarType.SUCCESS,'Se efectuó el corte a empresas Falta de Pago');
      }else{
        this.spinnerPageService.hide();
        this.uiService.ShowSnackBar(SnackBarType.ERROR,res.error);
      }
    });
  }
  async generarRecibos(){
    const fechaServer = moment(await this.functionService.GetDateServer())
    .local()
    .format();
    const fechaServerProcesado = Utils.separarFechasHoras(fechaServer);
    this.fechaHoy.anio = fechaServerProcesado[0]; //anio
    this.fechaHoy.mes = fechaServerProcesado[1]; //mes
    this.fechaHoy.dia = fechaServerProcesado[2]; //dia
    this.fechaHoy.hora = fechaServerProcesado[3]; //hora
    this.fechaHoy.minuto = fechaServerProcesado[4]; //minuto
    this.fechaHoy.segundo = fechaServerProcesado[5]; //seg
    const diaFacturacion = DatosPlataforma.DIAS_FACTURACION;
    if(Number(this.fechaHoy.dia)==diaFacturacion){
      this.spinnerPageService.show();
      this.spConsolaService.generarFacturaReciboTodos().then(res=>{
        if(res.tx==true){
          this.spinnerPageService.hide();
          this.uiService.ShowSnackBar(SnackBarType.SUCCESS,'Se generó los recibos correctamente');
        }else{
          this.spinnerPageService.hide();
          this.uiService.ShowSnackBar(SnackBarType.ERROR,res.error);
        }
      });
    }else{
      this.uiService.ShowSnackBar(SnackBarType.ERROR,'La factura se genera cada día 30');
    }

  }

}
