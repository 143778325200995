import { IMenuItems } from "../models/menu-items";
import { Icons } from "./icons";

export class CONFIGURACION{
  public static readonly ROLES_PRIMARCAS =[
    { id:1, denominacion:'Administrador', estado:true, value:'administrador' },
    { id:2, denominacion:'Colaborador', estado:true, value:'colaborador' }
  ];

  public static readonly SEXO =[
    { id:1, denominacion:'Masculino', estado:true, value:'m' },
    { id:2, denominacion:'Femenino', estado:true, value:'f' }
  ];

  public static readonly TIPO_DOCUMENTO =[
    { id:1, denominacion:'DNI', estado:true, value:'dni' },
    { id:2, denominacion:'Pasaporte', estado:true, value:'pasaporte' },
    { id:3, denominacion:'C.E.', estado:true, value:'extranjeria' }
  ];

  public static readonly OPCIONES_AVATAR_USUARIO_ADMINISTRADOR: IMenuItems[]=[
    { id:1, titulo:'Gestionar mis datos', disabled:false, icono:Icons.BADGE, hidden:false},
    { id:2, titulo:'Pausar mi suscripción', disabled:false, icono:Icons.TOGGLE_OFF, hidden:false},
    { id:3, titulo:'Activar mi suscripción', disabled:false, icono:Icons.TOGGLE_ON, hidden:true},
    { id:4, titulo:'Eliminar mi EMPRESA', disabled:false, icono:Icons.WARNING, hidden:false}
  ];

  public static readonly OPCIONES_AVATAR_USUARIO_OPERADOR: IMenuItems[]=[
    { id:5, titulo:'Ver mis datos', disabled:false, icono:Icons.VISIBILITY}
  ];

  public static readonly COOKIE_DATA_US:string='data_us';
  public static readonly COOKIE_DATA_EMP:string='data_emp';
  public static readonly COOKIE_DATA_SES:string='data_ses';
}


export abstract class PATH_API_FILES{
  static readonly USER_IMAGE_URL: string = 'images/user_image/';
  static readonly EMPRESA_IMAGE_URL: string = 'images/empresa_image/';
  static readonly ENCUESTA_IMAGE_URL: string = 'images/encuesta_image/';
  static readonly PRODUCTO_IMAGE_URL: string = 'images/producto_image/';
}
