import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Icons } from 'src/app/shared/cons/icons';

@Component({
  selector: 'app-mod-gestion-restaurant',
  templateUrl: './mod-gestion-restaurant.component.html',
  styleUrls: ['./mod-gestion-restaurant.component.scss']
})
export class ModGestionRestaurantComponent implements OnInit {
  selectedIndex = 0;
  readonly Icons: typeof Icons = Icons;
  constructor() { }

  ngOnInit(): void {
  }
  onTabChange(event: MatTabChangeEvent) {
    this.selectedIndex = event.index; // Actualiza el índice seleccionado
  }

}
