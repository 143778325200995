import { Injectable } from '@angular/core';
import { ReporteType } from '../types/reporte-type';
import { VentaCerradaBlService } from '../components/procesos/services/venta-cerrada-bl.service';
import { Turno } from '../components/gestion-monto/models/Turno';
import { Subject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../helpers/decorators/AutoUnsubscribe';
import { filter, take } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { IReporteVentas } from './models/IReporteVentas';
import { IObjRpt } from './models/IObjRpt';
import { IReportePagos } from './models/IReportePagos';
import { IReporteHistorialProducto } from './models/IReporteHistorialProducto';
import { IReporteHistorialMesa } from './models/IReporteHistorialMesa';
import { IReportePedidosAnulados } from './models/IReportePedidosAnulados';
import { IReportePedidosTraspasoDivision } from './models/IReportePedidosTraspasoDivision';
import { ProcesoType } from '../types/proceso-type';
import { GestionMontoBlService } from '../components/gestion-monto/services/gestion-monto.bl.service';
import { IReporteDepositosRetiros } from './models/IReporteDepositosRetiros';
import { SolicitarCambioType } from '../types/solicitar-cambio-type';
import { Ventas } from '../components/registrar-pedido/models/Ventas';
import { DialogCatalogoBlService } from '../components/procesos/dialog/services/services.bl/dialog-catalogo.bl.service';
import { Catalogo } from '../components/procesos/dialog/models/Catalogo';
import { IReporteCatalogoProducto } from './models/IReporteCatalogoProducto';
import { ListaTipoProducto } from '../cons/common';


@Injectable({
  providedIn: 'root'
})

@AutoUnsubscribe

export class GenerarReporteService {
  readonly ReporteType: typeof ReporteType = ReporteType;
  readonly ProcesoType: typeof SolicitarCambioType = SolicitarCambioType;
  ventasSb!:Subscription;
  procesadoRpt = new Subject<boolean>();
  noResultados:boolean = false;
  readonly ListaTipoProducto: typeof ListaTipoProducto = ListaTipoProducto;
  
  constructor(
    private ventaCerrada: VentaCerradaBlService, 
    private gestionMontoBLService: GestionMontoBlService,
    private catalogoBlService: DialogCatalogoBlService
  ) { }

  selectReporteCatalogos(){
    this.procesadoRpt.next(true);
    let listacatalogo:IReporteCatalogoProducto[] = [];
    this.catalogoBlService.getCatalogoTodo().then(listaCata=>{
      if(listaCata!==null){  
        this.noResultados = false;
        listaCata.forEach(cat=>{
          const milliseconds = cat.fecha_creacion.seconds * 1000 + Math.floor(cat.fecha_creacion.nanoseconds / 1000000);            
          const fechaCat = new Date(milliseconds);
          cat.lista_productos.forEach(prod=>{
            let fechaProdCreacion!:Date;
            if(prod.fecha_creacion){
              fechaProdCreacion = new Date(prod.fecha_creacion);
            }                      

            const catObj: IReporteCatalogoProducto={
              codigo_catalogo:cat.codigo_catalogo,
              nombre_catalogo:cat.nombre_catalogo,
              descripcion_catalogo:cat.descripcion_catalogo,
              fecha_creacion_catalogo:fechaCat.getDay()+'/'+fechaCat.getMonth()+'/'+fechaCat.getFullYear(),
              usuario_creacion_catalogo:cat.usuario_creacion.split('@')[0],
              cantidad_productos: cat.lista_productos.length,
              codigo_producto:prod.codigo_producto,
              nombre_producto:prod.nombre_producto,
              descripcion_producto:prod.descripcion_producto,
              precio_unitario:prod.precio_unitario,
              stock_ilimitado:prod.stock_ilimitado?'si':'no',
              cantidad_producto:!prod.stock_ilimitado? prod.cantidad_stock!.toString():'-',
              tipo_producto:ListaTipoProducto.listaTipoProducto.filter(tipo=>tipo.codigo_producto==prod.tipo_producto)[0].nombre_producto,
              tiene_personalizaciones:prod.tiene_caracteristicas?'si':'no',
              tiene_subproductos:prod.tiene_subproductos?'si':'no',
              usuario_creacion_producto:prod.usuario_creacion.split('@')[0],              
              ...(fechaProdCreacion && {fecha_creacion_producto: fechaProdCreacion.getDay()+'/'+fechaProdCreacion.getMonth()+'/'+fechaProdCreacion.getFullYear()})               
            }
            listacatalogo.push(catObj)
          });        

        });
        const objRpt:IObjRpt={
          data_reporte:listacatalogo,
          nombre_reporte:'Reporte_Catalogo/Productos',
          nombre_sheets:'Catalogo-Productos'
        }
        if(objRpt!==null){
          this.procesadoRpt.next(false);
          this.exportToExcel(objRpt);
        }
      }else{        
        this.noResultados = true;
        this.procesadoRpt.next(false); 
      }
    })

  }

  selectReporte(event:any, turno:Turno | null , fechaInicial:Date | null, fechaFinal:Date | null){
    switch(event){
      // reporte de ventas
      case 1:{
        this.procesadoRpt.next(true);
        if(turno?.auto_numerico!=null){
          this.generarReporteVentasGeneral(turno);
        }else{
          if(fechaInicial!=null &&fechaFinal!=null){
            this.gestionMontoBLService.getTurnoHistorico(fechaInicial,fechaFinal).then(turnos=>{
              if(turnos && turnos!.length>0){
                let listaIdsTurnos:string[] =[];
                turnos!.forEach(turnX=>{listaIdsTurnos.push(turnX.id!);});
                this.ventaCerrada.getVentasHistorico(listaIdsTurnos).then(listaVentas=>{
                  if(listaVentas && listaVentas.length>0){
                    const objRpt = this.generarReporteVentasGeneralProceso(listaVentas);            
                    this.exportToExcel(objRpt);
                  }
                  else{
                    this.noResultados = true;
                    this.procesadoRpt.next(false);
                  }
                });
              }
              else{
                this.noResultados = true;
                this.procesadoRpt.next(false);
              }
              
            });           
          }
        }        
        break;        
      }
      case 2:{
        this.procesadoRpt.next(true);
        if(turno?.auto_numerico!=null){
          this.generarReportePagosGeneral(turno);
        }else{
          if(fechaInicial!=null &&fechaFinal!=null){
            this.gestionMontoBLService.getTurnoHistorico(fechaInicial,fechaFinal).then(turnos=>{
              if(turnos && turnos!.length>0){
                let listaIdsTurnos:string[] =[];
                turnos!.forEach(turnX=>{listaIdsTurnos.push(turnX.id!);});
                this.ventaCerrada.getVentasHistorico(listaIdsTurnos).then(listaVentas=>{
                  if(listaVentas && listaVentas.length>0){
                    const objRpt = this.generarReportePagosGeneralProceso(listaVentas);          
                    this.exportToExcel(objRpt);
                  }
                  else{
                    this.noResultados = true;
                    this.procesadoRpt.next(false);
                  }
                });
              }
              else{
                this.noResultados = true;
                this.procesadoRpt.next(false);
              }
              
            }); 
          }
        }        
        break;
      }
      case 3:{
        this.procesadoRpt.next(true);
        if(turno?.auto_numerico!=null){
          this.generarReporteVentasHistorialMesa(turno);
        }else{
          if(fechaInicial!=null &&fechaFinal!=null){
            this.gestionMontoBLService.getTurnoHistorico(fechaInicial,fechaFinal).then(turnos=>{
              if(turnos && turnos!.length>0){
                let listaIdsTurnos:string[] =[];
                turnos!.forEach(turnX=>{listaIdsTurnos.push(turnX.id!);});
                this.ventaCerrada.getVentasHistorico(listaIdsTurnos).then(listaVentas=>{
                  if(listaVentas && listaVentas.length>0){
                    const objRpt = this.generarReporteVentasHistorialMesaProceso(listaVentas);          
                    this.exportToExcel(objRpt);
                  }
                  else{
                    this.noResultados = true;
                    this.procesadoRpt.next(false);
                  }
                });
              }
              else{
                this.noResultados = true;
                this.procesadoRpt.next(false);
              }
              
            }); 
          }
        }
        
        break;
      }
      case 4:{
        this.procesadoRpt.next(true);
        if(turno?.auto_numerico!=null){
          this.generarReporteVentasHistorialProductos(turno);
        }else{
          if(fechaInicial!=null &&fechaFinal!=null){
            this.gestionMontoBLService.getTurnoHistorico(fechaInicial,fechaFinal).then(turnos=>{
              if(turnos && turnos!.length>0){
                let listaIdsTurnos:string[] =[];
                turnos!.forEach(turnX=>{listaIdsTurnos.push(turnX.id!);});
                this.ventaCerrada.getVentasHistorico(listaIdsTurnos).then(listaVentas=>{
                  if(listaVentas && listaVentas.length>0){
                    const objRpt = this.generarReporteVentasHistorialProductosProceso(listaVentas);          
                    this.exportToExcel(objRpt);
                  }
                  else{
                    this.noResultados = true;
                    this.procesadoRpt.next(false);
                  }
                });
              }
              else{
                this.noResultados = true;
                this.procesadoRpt.next(false);
              }
              
            }); 
          }
        }
       
        break;
      }
      case 5:{
        this.procesadoRpt.next(true);
        if(turno?.auto_numerico!=null){
          this.generarReportePedidosAnulados(turno);
        }else{
          if(fechaInicial!=null &&fechaFinal!=null){
            this.gestionMontoBLService.getTurnoHistorico(fechaInicial,fechaFinal).then(turnos=>{
              if(turnos && turnos!.length>0){
                let listaIdsTurnos:string[] =[];
                turnos!.forEach(turnX=>{listaIdsTurnos.push(turnX.id!);});
                this.ventaCerrada.getVentasHistorico(listaIdsTurnos).then(listaVentas=>{
                  if(listaVentas && listaVentas.length>0){
                    const objRpt = this.generarReportePedidosAnuladosProceso(listaVentas);
                    if(objRpt!==null){
                      this.exportToExcel(objRpt);
                    }else{
                      this.noResultados = true;
                      this.procesadoRpt.next(false);  
                    }                    
                  }
                  else{
                    this.noResultados = true;
                    this.procesadoRpt.next(false);
                  }
                });
              }
              else{
                this.noResultados = true;
                this.procesadoRpt.next(false);
              }
              
            }); 
          }
        }        
        break;
      }
      case 6:{
        this.procesadoRpt.next(true);
        if(turno?.auto_numerico!=null){
          this.generarReportePedidosTraspasoDivision(turno);
        }else{
          if(fechaInicial!=null &&fechaFinal!=null){
            this.gestionMontoBLService.getTurnoHistorico(fechaInicial,fechaFinal).then(turnos=>{
              if(turnos && turnos.length>0){
                let listaIdsTurnos:string[] =[];
                turnos!.forEach(turnX=>{listaIdsTurnos.push(turnX.id!);});
                this.ventaCerrada.getVentasHistorico(listaIdsTurnos).then(listaVentas=>{
                  if(listaVentas && listaVentas.length>0){
                    const objRpt = this.generarReportePedidosTraspasoDivisionProceso(listaVentas);
                    if(objRpt!==null){
                      this.exportToExcel(objRpt);
                    }else{
                      this.noResultados = true;
                      this.procesadoRpt.next(false);  
                    }                    
                  }
                  else{
                    this.noResultados = true;
                    this.procesadoRpt.next(false);
                  }
                });
              }
              else{
                this.noResultados = true;
                this.procesadoRpt.next(false);
              }
              
            }); 
          }
        }        
        break;
      }
      case 7:{
        this.procesadoRpt.next(true);
        if(turno?.auto_numerico!=null){
          this.generarReporteDepositosRetiros(turno);
        }else{
          if(fechaInicial!=null &&fechaFinal!=null){
            this.gestionMontoBLService.getTurnoHistorico(fechaInicial,fechaFinal).then(turnos=>{
              if(turnos && turnos!.length>0){
                const objRpt = this.generarReporteDepositosRetirosProceso(turnos!);
                if(objRpt!==null){
                  this.exportToExcel(objRpt);
                }else{
                  this.noResultados = true;
                  this.procesadoRpt.next(false);  
                }  
              }
              else{
                this.noResultados = true;
                this.procesadoRpt.next(false);
              }
              
            }); 
          }
        }        
        break;
      }
    }
  }

  generarReporteDepositosRetiros(turno:Turno | null){
    this.noResultados = false;
    const listaTurno:Turno[]=[];
    listaTurno.push(turno!);
    this.ventasSb?.unsubscribe();
    const objRpt = this.generarReporteDepositosRetirosProceso(listaTurno);
    if(objRpt!==null){
      this.exportToExcel(objRpt);
    }else{
      this.noResultados = true;
      this.procesadoRpt.next(false); 
    }
    
  }

  generarReporteDepositosRetirosProceso(listaTurno:Turno[]){
    let dataReportDepositoRetiro:IReporteDepositosRetiros[]=[];
    //ordenando del mas reciente al mas antiguo
    listaTurno.sort((a,b)=>b.auto_numerico - a.auto_numerico);  
      listaTurno.forEach(turn=>{
        let turnoElem:IReporteDepositosRetiros={
          codigo_turno:turn.codigo_turno,        
          monto_variacion : turn.monto_inicial_efectivo,
          fecha_variacion : turn.fecha_key.dia+'/'+turn.fecha_key.mes+'/'+turn.fecha_key.anio,
          hora_variacion : turn.fecha_key.hora+':'+turn.fecha_key.minuto+':'+turn.fecha_key.segundo,
          usuario_creacion_variacion: turn.usuario_creacion.split('@')[0],                
          proceso:ProcesoType.INICIALIZAR,
          estado_variacion:SolicitarCambioType.APERTURA,
              
        }
        dataReportDepositoRetiro.push(JSON.parse(JSON.stringify(turnoElem)));

        if((turn.montos_aniadidos && turn.montos_aniadidos.length>0)){
          turn.montos_aniadidos!.forEach(monto=>{
            let turnoAdd:IReporteDepositosRetiros={
              codigo_turno:turn.codigo_turno,          
              monto_variacion : monto.monto,
              fecha_variacion : monto.fecha_key.dia+'/'+monto.fecha_key.mes+'/'+monto.fecha_key.anio,
              hora_variacion : monto.fecha_key.hora+':'+monto.fecha_key.minuto+':'+monto.fecha_key.segundo,
              ...(monto.usuario_creacion! && {usuario_creacion_variacion:monto.usuario_creacion.split('@')[0]}),
              ...(monto.usuario_aprobacion! && {usuario_aprobacion_variacion:monto.usuario_aprobacion?.split('@')[0]}),
              ...(monto.anotacion! && {comentario_variacion:monto.anotacion}),
              proceso:monto.proceso,
              estado_variacion:monto.estado_cambio
            }
                    
            
            dataReportDepositoRetiro.push(JSON.parse(JSON.stringify(turnoAdd)));
          });
        
        }

        if((turn.montos_retirados && turn.montos_retirados.length>0)){
          turn.montos_retirados!.forEach(monto=>{
            let turnoRet:IReporteDepositosRetiros={
              codigo_turno:turn.codigo_turno,            
              monto_variacion : monto.monto * -1,
              fecha_variacion : monto.fecha_key.dia+'/'+monto.fecha_key.mes+'/'+monto.fecha_key.anio,
              hora_variacion : monto.fecha_key.hora+':'+monto.fecha_key.minuto+':'+monto.fecha_key.segundo,
              ...(monto.usuario_creacion! && {usuario_creacion_variacion:monto.usuario_creacion.split('@')[0]}),
              ...(monto.usuario_aprobacion! && {usuario_aprobacion_variacion:monto.usuario_aprobacion?.split('@')[0]}),
              ...(monto.anotacion! && {comentario_variacion:monto.anotacion}),
              proceso:monto.proceso,          
              estado_variacion:monto.estado_cambio 
            }
                    
            
            dataReportDepositoRetiro.push(JSON.parse(JSON.stringify(turnoRet)));
          });
        
        }              
      });
      if(dataReportDepositoRetiro.length==0){
        this.noResultados = true;
        this.procesadoRpt.next(false);
        return null;
      }
      const objRpt:IObjRpt={
        data_reporte:dataReportDepositoRetiro,
        nombre_reporte:'Reporte_DepositosRetiros',
        nombre_sheets:'Depositos-Retiros'
      }
      return objRpt;
  }

  generarReportePedidosTraspasoDivision(turno:Turno | null){
    this.noResultados = false;
    const listaTurno:string[]=[];
    listaTurno.push(turno!.id!);
    this.ventaCerrada.existFetchVentas(listaTurno);
    this.ventasSb?.unsubscribe();
    this.ventasSb = this.ventaCerrada.getVentasXIDTurno().pipe(
      filter((listaVentas) => listaVentas !== undefined),take(1)).subscribe((listaVentas) => {          
      if (listaVentas) {      
        const objRpt = this.generarReportePedidosTraspasoDivisionProceso(listaVentas);
        
        if(objRpt!==null){
          this.exportToExcel(objRpt);
        }else{
          this.noResultados = true;
          this.procesadoRpt.next(false); 
        }        
        
      }else{
        this.noResultados = true;
        this.procesadoRpt.next(false);        
      }
    })

  }

  generarReportePedidosTraspasoDivisionProceso(listaVentas:Ventas[]){
    let dataReportVentas:IReportePedidosTraspasoDivision[]=[];
    //generando datos reporte
    listaVentas.sort((a,b)=>a.auto_numerico - b.auto_numerico);
    listaVentas.forEach(item=>{
      item.pedido!.historial_mesa!.sort((a,b)=>a.auto_numerico - b.auto_numerico);  
    });
 

    listaVentas.forEach(item => {
      if(item.pedido!.proceso== ProcesoType.DIVIDIR_CUENTA_ORIGEN || 
        item.pedido!.proceso== ProcesoType.DIVIDIR_CUENTA_DESTINO ||
        item.pedido!.proceso== ProcesoType.TRASPASO_PRODUCTO_ORIGEN ||
        item.pedido!.proceso== ProcesoType.TRASPASO_PRODUCTO_DESTINO
      ){
        const listaPedidos = item.pedido!.historial_producto?.filter(
          prod=>prod.proceso ==ProcesoType.DIVIDIR_CUENTA_ORIGEN ||
          prod.proceso ==ProcesoType.DIVIDIR_CUENTA_DESTINO ||
          prod.proceso ==ProcesoType.TRASPASO_PRODUCTO_ORIGEN ||
          prod.proceso ==ProcesoType.TRASPASO_PRODUCTO_DESTINO)

        listaPedidos!.forEach(producto => {            
          const dataReporte: IReportePedidosTraspasoDivision = {
            codigo_turno: item.codigo_turno,
            codigo_pedido: item.pedido!.codigo_pedido,
            fecha_creacion_pedido: item.fecha_key.dia+'/'+item.fecha_key.mes+'/'+item.fecha_key.anio,
            hora_creacion_pedido: item.fecha_key.hora+':'+item.fecha_key.minuto+':'+item.fecha_key.segundo,
            mesas_completo: item.pedido!.mesas_completo.join(','),
            igv_pedido: item.pedido!.igv,
            ...(item.pedido!.servicio_igv && { igv_servicio: item.pedido!.servicio_igv }),
            ...(item.pedido!.servicio_subtotal && { subtotal_servicio: item.pedido!.servicio_subtotal }),
            ...(item.pedido!.servicio_total && { total_servicio: item.pedido!.servicio_total }),
            subtotal_pedido: item.pedido!.sub_total,
            total_pedido:  Number((Math.floor( item.pedido!.total * 100) / 100).toFixed(2)),
            total_tipos_productos_pedidos: item.pedido!.total_productos_pedidos,
            usuario_creacion_pedido:item.pedido!.usuario_creacion_busqueda.split('@')[0],
            //para pedidos que nacen como incidencia
            ...(item.pedido!.proceso! &&{es_pedido_division_cuenta_origen: item.pedido!.proceso==ProcesoType.DIVIDIR_CUENTA_ORIGEN?'Si':'No',}),
            ...(item.pedido!.proceso! &&{es_pedido_division_cuenta_destino: item.pedido!.proceso==ProcesoType.DIVIDIR_CUENTA_DESTINO?'Si':'No',}),
            ...(item.pedido!.proceso! &&{es_pedido_traspaso_producto_origen: item.pedido!.proceso==ProcesoType.TRASPASO_PRODUCTO_ORIGEN?'Si':'No',}),
            ...(item.pedido!.proceso! &&{es_pedido_traspaso_producto_destino: item.pedido!.proceso==ProcesoType.TRASPASO_PRODUCTO_DESTINO?'Si':'No',}),                
            //producto
            cantidad_producto: producto.cantidad_producto,
            nombre_producto: producto.nombre_producto,              
            codigo_producto: producto.codigo_producto,               
            proceso_producto:producto.proceso!,
            usuario_creacion:producto.usuario_creacion.split('@')[0],
            fecha_creacion_producto: producto.fecha.dia+'/'+producto.fecha.mes+'/'+producto.fecha.anio,
            hora_creacion_producto: producto.fecha.hora+':'+producto.fecha.minuto+':'+producto.fecha.segundo,                
          }
          dataReportVentas.push(dataReporte);
        });
      }         
    });
    this.ventasSb?.unsubscribe();

    //en caso no se tengan pedidos anulados
    if(dataReportVentas.length==0){
      return null;
    }
    const objRpt:IObjRpt={
      data_reporte:dataReportVentas,
      nombre_reporte:'Reporte_PedidosTraspasoDivision',
      nombre_sheets:'Traspaso-Division'
    }
    return objRpt;
  }

  generarReportePedidosAnulados(turno:Turno | null){
    this.noResultados = false;
    const listaTurno:string[]=[];
    listaTurno.push(turno!.id!);   

    this.ventaCerrada.existFetchVentas(listaTurno);
    this.ventasSb?.unsubscribe();
    this.ventasSb = this.ventaCerrada.getVentasXIDTurno().pipe(
      filter((listaVentas) => listaVentas !== undefined),take(1)).subscribe((listaVentas) => {          
      if (listaVentas) {       
        const objRpt = this.generarReportePedidosAnuladosProceso(listaVentas);
  
        if(objRpt!==null){
          this.exportToExcel(objRpt);
        }else{
          this.noResultados = true;
          this.procesadoRpt.next(false); 
        }        
      }else{
        this.noResultados = true;
        this.procesadoRpt.next(false);        
      }
    })

  }

  generarReportePedidosAnuladosProceso(listaVentas:Ventas[]){
    let dataReportVentas:IReportePedidosAnulados[]=[];
    //generando datos reporte
    listaVentas.sort((a,b)=>a.auto_numerico - b.auto_numerico);
    listaVentas.forEach(item=>{
      item.pedido!.historial_mesa!.sort((a,b)=>a.auto_numerico - b.auto_numerico);  
    });
 
    listaVentas.forEach(item => {
      if(item.pedido!.es_pedido_anulado || item.pedido!.es_pedido_incidencia ){
        item.pedido!.lista_producto!.forEach(producto => {            
          const dataReporte: IReportePedidosAnulados = {
            codigo_turno: item.codigo_turno,
            codigo_pedido: item.pedido!.codigo_pedido,
            fecha_creacion_pedido: item.fecha_key.dia+'/'+item.fecha_key.mes+'/'+item.fecha_key.anio,
            hora_creacion_pedido: item.fecha_key.hora+':'+item.fecha_key.minuto+':'+item.fecha_key.segundo,
            mesas_completo: item.pedido!.mesas_completo.join(','),
            igv_pedido: item.pedido!.igv,
            ...(item.pedido!.servicio_igv && { igv_servicio: item.pedido!.servicio_igv }),
            ...(item.pedido!.servicio_subtotal && { subtotal_servicio: item.pedido!.servicio_subtotal }),
            ...(item.pedido!.servicio_total && { total_servicio: item.pedido!.servicio_total }),
            subtotal_pedido: item.pedido!.sub_total,
            total_pedido:  Number((Math.floor( item.pedido!.total * 100) / 100).toFixed(2)),
            total_tipos_productos_pedidos: item.pedido!.total_productos_pedidos,
            usuario_creacion_pedido:item.pedido!.usuario_creacion_busqueda.split('@')[0],
            //para pedidos que nacen como incidencia
            ...(item.pedido!.es_pedido_incidencia &&{es_pedido_incidencia: item.pedido!.es_pedido_incidencia?'Si':'No',}),
            ...(item.pedido!.usuario_incidencia &&{pedido_incidencia_asignado_a: item.pedido!.usuario_incidencia.correo_electronico.split('@')[0],}),
            //producto
            cantidad_producto: producto.cantidad_producto,
            nombre_producto: producto.nombre_producto,
            tipo_producto:producto.tipo_producto,
            codigo_producto: producto.codigo_producto,
            //codigo_grupo_pedido: producto.codigo_ticket,
            precio_unitario_producto:  Number((Math.floor(producto.precio_unitario * 100) / 100).toFixed(2)),
            usuario_creacion_producto:producto.usuario_creacion.split('@')[0],
            fecha_creacion_producto: producto.fecha_key.dia+'/'+producto.fecha_key.mes+'/'+producto.fecha_key.anio,
            hora_creacion_producto: producto.fecha_key.hora+':'+producto.fecha_key.minuto+':'+producto.fecha_key.segundo,
            precio_total_producto:  Number((Math.floor(producto.precio_total * 100) / 100).toFixed(2)),              
            igv_valor_producto: producto.igv_valor_producto,
            es_anulado:item.pedido!.es_pedido_anulado?'Si':'No',
            es_incidencia:item.pedido!.es_pedido_incidencia?'Si':'No',
            usuario_aprobacion:item.usuario_creacion.split('@')[0]          
          }
          dataReportVentas.push(dataReporte);
        });
      }         
    });
    this.ventasSb?.unsubscribe();

    //en caso no se tengan pedidos anulados
    if(dataReportVentas.length==0){
      this.noResultados = true;
      this.procesadoRpt.next(false);
      return null;
    }
    const objRpt:IObjRpt={
      data_reporte:dataReportVentas,
      nombre_reporte:'Reporte_PedidosAnulados',
      nombre_sheets:'Anulados'
    }
    return objRpt;
  }

  generarReporteVentasHistorialMesa(turno:Turno | null){
    this.noResultados = false;
    const listaTurno:string[]=[];
    listaTurno.push(turno!.id!);  
    this.ventaCerrada.existFetchVentas(listaTurno);
    this.ventasSb?.unsubscribe();
    this.ventasSb = this.ventaCerrada.getVentasXIDTurno().pipe(
      filter((listaVentas) => listaVentas !== undefined),take(1)).subscribe((listaVentas) => {          
      if (listaVentas) {
        //ordenando historial        
       const objRpt = this.generarReporteVentasHistorialMesaProceso(listaVentas);        
        this.exportToExcel(objRpt);
      }else{
        this.noResultados = true;
        this.procesadoRpt.next(false);
        
      }
    })

  }

  generarReporteVentasHistorialMesaProceso(listaVentas:Ventas[]){
    let dataReportPagos:IReporteHistorialMesa[]=[];
    listaVentas.sort((a,b)=>a.auto_numerico - b.auto_numerico);
    listaVentas.forEach(item=>{
      item.pedido!.historial_mesa!.sort((a,b)=>a.auto_numerico - b.auto_numerico);  
    });
 
    //generando datos reporte
    listaVentas.forEach(item => {
      item.pedido!.historial_mesa!.forEach(historial => {
        const dataReporte: IReporteHistorialMesa = {
          //pedido
          codigo_turno: item.codigo_turno,
          codigo_pedido: item.pedido!.codigo_pedido,
          fecha_creacion_pedido: item.fecha_key.dia+'/'+item.fecha_key.mes+'/'+item.fecha_key.anio,
          hora_creacion_pedido: item.fecha_key.hora+':'+item.fecha_key.minuto+':'+item.fecha_key.segundo,
          mesas_completo: item.pedido!.mesas_completo.join(','),              
          usuario_creacion_pedido: item.pedido!.usuario_creacion_busqueda.split('@')[0],
          //para pedidos que nacen como incidencia
          ...(item.pedido!.es_pedido_incidencia && { es_pedido_incidencia: item.pedido!.es_pedido_incidencia ? 'Si' : 'No', }),
          ...(item.pedido!.usuario_incidencia && { pedido_incidencia_asignado_a: item.pedido!.usuario_incidencia.correo_electronico.split('@')[0], }),
          //historial
          ambiente: historial.ambiente.toUpperCase(),
          mesa_numero:historial.mesa_numero,
          mesa_estado:historial.mesa_estado.toUpperCase(),
          accion_mesa:historial.accion_mesa.toUpperCase(),
          ...(historial!.estado_cambio && { estado_cambio: historial!.estado_cambio.toUpperCase() }),
          fecha_creacion:historial.fecha_key!.dia+'/'+historial.fecha_key!.mes+'/'+historial.fecha_key!.anio,
          hora_creacion:historial.fecha_key!.hora+':'+historial.fecha_key!.minuto+':'+historial.fecha_key!.segundo,              
          usuario_creacion:historial.usuario_creacion.split('@')[0],
          ...(historial!.proceso && { proceso: historial!.proceso }),
          //modificacion                 
          ...(historial!.usuario_aprobacion && { usuario_aprobacion: historial!.usuario_aprobacion.split('@')[0] }),
        }
        dataReportPagos.push(dataReporte);
      });
    });
    const objRpt:IObjRpt={
      data_reporte:dataReportPagos,
      nombre_reporte:'Reporte_HistorialMesa',
      nombre_sheets:'HistorialMesa'
    }
    this.ventasSb?.unsubscribe();
    return objRpt;
  }

  generarReporteVentasHistorialProductos(turno:Turno | null){
    this.noResultados = false;
    const listaTurno:string[]=[];
    listaTurno.push(turno!.id!);
    

    this.ventaCerrada.existFetchVentas(listaTurno);
    this.ventasSb?.unsubscribe();
    this.ventasSb = this.ventaCerrada.getVentasXIDTurno().pipe(
      filter((listaVentas) => listaVentas !== undefined), take(1)).subscribe((listaVentas) => {          
      if (listaVentas) {
        const objRpt= this.generarReporteVentasHistorialProductosProceso(listaVentas);    
        this.exportToExcel(objRpt);
      }else{
        this.noResultados = true;
        this.procesadoRpt.next(false);        
      }
    })

  }

  generarReporteVentasHistorialProductosProceso(listaVentas:Ventas[]){
    let dataReportPagos:IReporteHistorialProducto[]=[];
    //ordenando historial        
    listaVentas.sort((a,b)=>a.auto_numerico - b.auto_numerico);
    listaVentas.forEach(item=>{
      item.pedido!.historial_mesa!.sort((a,b)=>a.auto_numerico - b.auto_numerico);  
    });
    
    //generando datos reporte
    listaVentas.forEach(item => {
      item.pedido!.historial_producto!.forEach(historial => {
        const dataReporte: IReporteHistorialProducto = {
          //pedido
          codigo_turno: item.codigo_turno,
          codigo_pedido: item.pedido!.codigo_pedido,
          fecha_creacion_pedido: item.fecha_key.dia+'/'+item.fecha_key.mes+'/'+item.fecha_key.anio,
          hora_creacion_pedido: item.fecha_key.hora+':'+item.fecha_key.minuto+':'+item.fecha_key.segundo,
          mesas_completo: item.pedido!.mesas_completo.join(','),              
          usuario_creacion_pedido: item.pedido!.usuario_creacion_busqueda.split('@')[0],
          //para pedidos que nacen como incidencia
          ...(item.pedido!.es_pedido_incidencia && { es_pedido_incidencia: item.pedido!.es_pedido_incidencia ? 'Si' : 'No', }),
          ...(item.pedido!.usuario_incidencia && { pedido_incidencia_asignado_a: item.pedido!.usuario_incidencia.correo_electronico.split('@')[0], }),
          //historial
          nombre_producto: historial.nombre_producto,
          codigo_producto:historial.codigo_producto,
          codigo_grupo:historial.codigo_ticket,
          cantidad_producto:historial.cantidad_producto,
          estado_producto:historial.estado_producto.toUpperCase(),    
          fecha_estado_producto:historial.fecha.dia+'/'+historial.fecha.mes+'/'+historial.fecha.anio,
          hora_estado_producto:historial.fecha.hora+':'+historial.fecha.minuto+':'+historial.fecha.segundo,              
          usuario_creacion_estado:historial.usuario_creacion.split('@')[0],
          //modificacion
          ...(historial!.es_modificado && { es_modificado: historial!.es_modificado?'Si':'No' }),
          ...(historial!.cantidad_producto_reducido && { cantidad_producto_reducido: historial!.cantidad_producto_reducido! }),
          ...(historial!.es_eliminado && { es_eliminado: historial!.es_eliminado?'Si':'No' }),
          ...(historial!.estado_cambio && { estado_cambio: historial!.estado_cambio!.toUpperCase() }),
          ...(historial!.proceso && { proceso: historial!.proceso!.toUpperCase() }),       
          ...(historial!.usuario_aprobacion && { usuario_aprobacion: historial!.usuario_aprobacion.split('@')[0] }),
        }
        dataReportPagos.push(dataReporte);
      });
    });
    const objRpt:IObjRpt={
      data_reporte:dataReportPagos,
      nombre_reporte:'Reporte_HistorialProducto',
      nombre_sheets:'HistorialProducto'
    }
    this.ventasSb?.unsubscribe();
    return objRpt;
  }

  generarReportePagosGeneral(turno:Turno | null){
    this.noResultados = false;
    const listaTurno:string[]=[];
    listaTurno.push(turno!.id!);


    this.ventaCerrada.existFetchVentas(listaTurno);
    this.ventasSb?.unsubscribe();
    this.ventasSb = this.ventaCerrada.getVentasXIDTurno().pipe(
      filter((listaVentas) => listaVentas !== undefined), take(1)).subscribe((listaVentas) => {          
      if (listaVentas) { 
        //generando datos reporte
        const objRpt = this.generarReportePagosGeneralProceso(listaVentas);     
        this.exportToExcel(objRpt);
      }
      else{
        this.noResultados = true;
        this.procesadoRpt.next(false);        
      }
    })

  }

  generarReportePagosGeneralProceso(listaVentas:Ventas[]){
    let dataReportPagos:IReportePagos[]=[];

    listaVentas.sort((a,b)=>a.auto_numerico - b.auto_numerico);
    listaVentas.forEach(item=>{
      item.pedido!.historial_mesa!.sort((a,b)=>a.auto_numerico - b.auto_numerico);  
    });
 
    listaVentas.forEach(item => {
      item.pedido!.lista_pago_total!.forEach(pago => {
        const dataReporte: IReportePagos = {
          //pedido
          codigo_turno: item.codigo_turno,
          codigo_pedido: item.pedido!.codigo_pedido,
          fecha_creacion_pedido: item.fecha_key.dia+'/'+item.fecha_key.mes+'/'+item.fecha_key.anio,
          hora_creacion_pedido: item.fecha_key.hora+':'+item.fecha_key.minuto+':'+item.fecha_key.segundo,
          mesas_completo: item.pedido!.mesas_completo.join(','),
          igv_pedido: item.pedido!.igv,
          ...(item.pedido!.servicio_igv && { igv_servicio: item.pedido!.servicio_igv }),
          ...(item.pedido!.servicio_subtotal && { subtotal_servicio: item.pedido!.servicio_subtotal }),
          ...(item.pedido!.servicio_total && { total_servicio: item.pedido!.servicio_total }),
          subtotal_pedido: item.pedido!.sub_total,
          total_pedido:  Number((Math.floor( item.pedido!.total * 100) / 100).toFixed(2)),
          total_tipos_productos_pedidos: item.pedido!.total_productos_pedidos,
          usuario_creacion_pedido: item.pedido!.usuario_creacion_busqueda.split('@')[0],
          //para pedidos que nacen como incidencia
          ...(item.pedido!.es_pedido_incidencia && { es_pedido_incidencia: item.pedido!.es_pedido_incidencia ? 'Si' : 'No', }),
          ...(item.pedido!.usuario_incidencia && { pedido_incidencia_asignado_a: item.pedido!.usuario_incidencia.correo_electronico.split('@')[0], }),
          //pagos
          monto: Number(pago.monto!),
          codigo_tipo_pago:pago.tipo_pago.codigo_medio,
          nombre_medio_pago:pago.tipo_pago.nombre_medio,                  
          usuario_creacion_pago:pago.usuario_creacion_busqueda,
          fecha_creacion_pago:pago.fecha_key.dia+'/'+pago.fecha_key.mes+'/'+pago.fecha_key.anio,
          hora_creacion_pago:pago.fecha_key.hora+':'+pago.fecha_key.minuto+':'+pago.fecha_key.segundo,         
        }
        dataReportPagos.push(dataReporte);
      });
    });       
    const objRpt:IObjRpt={
      data_reporte:dataReportPagos,
      nombre_reporte:'Reporte_Pagos',
      nombre_sheets:'pagos'
    }
    this.ventasSb?.unsubscribe();
    return objRpt;
  }

  generarReporteVentasGeneral(turno:Turno | null){
    this.noResultados = false;
    const listaTurno:string[]=[];
    listaTurno.push(turno!.id!);
  

    this.ventaCerrada.existFetchVentas(listaTurno);
    this.ventasSb?.unsubscribe();
    this.ventasSb = this.ventaCerrada.getVentasXIDTurno().pipe(
      filter((listaVentas) => listaVentas !== undefined), take(1)).subscribe((listaVentas) => {          
      if (listaVentas) {       
        const objRpt = this.generarReporteVentasGeneralProceso(listaVentas);      
        this.exportToExcel(objRpt);
      }else{
        this.noResultados = true;
        this.procesadoRpt.next(false);        
      }
    })

  }

  generarReporteVentasGeneralProceso(listaVentas:Ventas[]){
    //generando datos reporte
    let dataReportVentas:IReporteVentas[]=[];

    listaVentas.sort((a,b)=>a.auto_numerico - b.auto_numerico);
    listaVentas.forEach(item=>{
      item.pedido!.historial_mesa!.sort((a,b)=>a.auto_numerico - b.auto_numerico);  
    });
 
    listaVentas.forEach(item => {
      item.pedido!.lista_producto!.forEach(producto => {
        const dataReporte: IReporteVentas = {
          codigo_turno: item.codigo_turno,
          codigo_pedido: item.pedido!.codigo_pedido,
          fecha_creacion_pedido: item.fecha_key.dia+'/'+item.fecha_key.mes+'/'+item.fecha_key.anio,
          hora_creacion_pedido: item.fecha_key.hora+':'+item.fecha_key.minuto+':'+item.fecha_key.segundo,
          mesas_completo: item.pedido!.mesas_completo.join(','),
          igv_pedido: item.pedido!.igv,
          ...(item.pedido!.servicio_igv && { igv_servicio: item.pedido!.servicio_igv }),
          ...(item.pedido!.servicio_subtotal && { subtotal_servicio: item.pedido!.servicio_subtotal }),
          ...(item.pedido!.servicio_total && { total_servicio: item.pedido!.servicio_total }),
          subtotal_pedido: item.pedido!.sub_total,
          total_pedido:  Number((Math.floor( item.pedido!.total * 100) / 100).toFixed(2)),
          total_tipos_productos_pedidos: item.pedido!.total_productos_pedidos,
          usuario_creacion_pedido:item.pedido!.usuario_creacion_busqueda.split('@')[0],
          //para pedidos que nacen como incidencia
          ...(item.pedido!.es_pedido_incidencia &&{es_pedido_incidencia: item.pedido!.es_pedido_incidencia?'Si':'No',}),
          ...(item.pedido!.usuario_incidencia &&{pedido_incidencia_asignado_a: item.pedido!.usuario_incidencia.correo_electronico.split('@')[0],}),
          
          //producto
          cantidad_producto: producto.cantidad_producto,
          nombre_producto: producto.nombre_producto,
          tipo_producto:producto.tipo_producto,
          codigo_producto: producto.codigo_producto,
          //codigo_grupo_pedido: producto.codigo_ticket,
          precio_unitario_producto:  Number((Math.floor(producto.precio_unitario * 100) / 100).toFixed(2)),
          usuario_creacion_producto:producto.usuario_creacion.split('@')[0],
          fecha_creacion_producto: producto.fecha_key.dia+'/'+producto.fecha_key.mes+'/'+producto.fecha_key.anio,
          hora_creacion_producto: producto.fecha_key.hora+':'+producto.fecha_key.minuto+':'+producto.fecha_key.segundo,
          precio_total_producto:  Number((Math.floor(producto.precio_total * 100) / 100).toFixed(2)),              
          igv_valor_producto: producto.igv_valor_producto,
          //descuentos e incidencias
          descuento_producto: producto.descuento?'Si':'No',
          porcentaje_descuento_producto: producto.descuento? producto.descuento.porcentaje_descuento:0,
          cantidad_unidades_afectas_descuento:producto.descuento?producto.descuento.cantidad_producto:0,
          comentario_descuento:producto.descuento?producto.descuento.comentario? producto.descuento.comentario:'-':'-',
          incidencia_producto:producto.incidencia?'Si':'No',
          cantidad_unidades_afectas_incidencia: producto.incidencia? producto.incidencia.cantidad_producto:0,
          comentario_incidencia: producto.incidencia?producto.incidencia.comentario? producto.incidencia.comentario:'-':'-',
        }
        dataReportVentas.push(dataReporte);
      });
    }); 
    const objRpt:IObjRpt={
      data_reporte:dataReportVentas,
      nombre_reporte:'Reporte_Ventas',
      nombre_sheets:'ventas'
    }
    this.ventasSb?.unsubscribe();
    return objRpt;
  }

  exportToExcel(objRpt: IObjRpt): void {
    (() => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(objRpt.data_reporte);
      const workbook: XLSX.WorkBook = { Sheets: { [objRpt.nombre_sheets]: worksheet }, SheetNames: [objRpt.nombre_sheets] };   
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });   
      this.saveAsExcelFile(excelBuffer, objRpt.nombre_reporte);
    })();
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    this.procesadoRpt.next(false);
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, `${fileName}_export_${new Date().getTime()}.xlsx`);    
  }
}
