import { Component, Input, OnInit } from '@angular/core';
import { Icons } from '../../cons/icons';
import { IIndicadorResumen } from './models/IIndicadorResumen';

@Component({
  selector: 'app-indicador-resumen',
  templateUrl: './indicador-resumen.component.html',
  styleUrls: ['./indicador-resumen.component.scss']
})
export class IndicadorResumenComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  @Input() title:string='';
  @Input() icon:string='';
  @Input() qyt:number=0;
  @Input() showDecimal:boolean = false;
  @Input() isGray:boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
