import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DatosPlataforma } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { CEmpresa } from '../../dialog/models/cempresa.model';
import { EstadosComprobanteElectronicoEmpresa } from 'src/app/shared/types/estado-comprobante-electronico-empresa-type';
import dataUbigeo from '../../../cons/ubigeo-inei.json';
import dataDepartamento from '../../../cons/departamento-inei.json';
import * as Enumerable from 'linq-es2015';
import { toArray } from 'rxjs-compat/operator/toArray';
import { serverTimestamp, Timestamp } from '@angular/fire/firestore';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-gestion-comprobante-electronico',
  templateUrl: './gestion-comprobante-electronico.component.html',
  styleUrls: ['./gestion-comprobante-electronico.component.scss']
})
export class GestionComprobanteElectronicoComponent implements OnInit {
  readonly DatosPlataforma: typeof DatosPlataforma = DatosPlataforma;
  readonly EstadosComprobanteElectronicoEmpresa: typeof EstadosComprobanteElectronicoEmpresa = EstadosComprobanteElectronicoEmpresa;
  readonly Icons: typeof Icons = Icons;
  empresa:CEmpresa= new CEmpresa();

  lDepartamento: any[] = new Array<any>();
  lProvincia: any[] = new Array<any>();
  lDistrito: any[] = new Array<any>();
  lUbigeo: any[] = new Array<any>();

  departamento:string='';
  provincia:string='';
  distrito:string='';


  constructor(
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer,
    private registroService: RegistroService
  ) {
    this.matIconRegistry.addSvgIcon(
      'icon-wath-black', // Nombre del ícono para usar en el template
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/icons/whats-black.svg') 
    );
   }

  ngOnInit(): void {
    dataDepartamento.forEach((dep) => {
      this.lDepartamento.push(dep);
    });
    dataUbigeo.forEach((ubigeo) => {
      this.lUbigeo.push(ubigeo);
    });

    if(this.registroService.empresa){
      this.empresa = this.registroService.empresa as CEmpresa;
      if(this.empresa.comprobante_electronico!=undefined){
        const originalDate = (this.empresa.comprobante_electronico.fecha_alta_pse as Timestamp).toDate();        
        // Extraer día, mes y año
        let day = originalDate.getDate();
        let month = originalDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
        let year = originalDate.getFullYear();

        // Asegurarse de que el día y el mes tengan dos dígitos
        let dayString = day < 10 ? '0' + day : day.toString();
        let monthString = month < 10 ? '0' + month : month.toString();
        // Formatear la fecha en dd/MM/aaaa
        this.empresa.comprobante_electronico.fecha_alta_pse= `${day}/${month}/${year}`;
      }
      this.cargarUbigeo();

    }
  }

  cargarUbigeo(){    
    //departamento
    const codigoDepartamento = this.empresa.codigo_departamento.substr(0, 2);
    const dep = Enumerable.asEnumerable(this.lDepartamento).Where((dep)=>dep.codigoID== this.empresa.codigo_departamento).Select((x)=>x).First();
    this.departamento = dep.nombre;
    //provincia    
    this.lProvincia = Enumerable.asEnumerable(this.lUbigeo)
    .Where((prov) => prov.codDepartamento == codigoDepartamento)
    .Distinct((x) => x.codProvincia)
    .Select((x) => x)
    .ToArray();    
    const prov = Enumerable.asEnumerable(this.lProvincia).Where((prov)=>prov.codProvincia==this.empresa.codigo_provincia).Select((x)=>x).First();
    this.provincia = prov.provincia;
    //distrito
    this.lDistrito = Enumerable.asEnumerable(this.lUbigeo)
      .Where((prov) =>
          prov.codProvincia == this.empresa.codigo_provincia &&
          prov.codDepartamento == codigoDepartamento
      ).Distinct((x) => x.codDistrito)
      .Select((x) => x)
      .ToArray();
  
    const dist = Enumerable.asEnumerable(this.lDistrito).Where((prov)=>prov.codDistrito==this.empresa.codigo_distrito).Select((x)=>x).First();    
    this.distrito = dist.distrito;
  }

  abrirWathsAppLink(){
    const msj = "REQUERIMIENTO CONFIGURACION COMPROBANTE ELECTRONICO";
    const telefono = DatosPlataforma.NUMERO_SOPORTE;
    const url = `https://wa.me/${telefono}?text=${encodeURIComponent(msj)}`;
    window.open(url, '_blank');
  }

}
