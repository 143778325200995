import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { SpinnerPageService } from '../../../spinner-page/spinner-page.service';
import { NgForm } from '@angular/forms';
import {
  CriteriosBusqueda, EventoPaginacion, Length_Database,
  MsjEstado, Msjs, Msjs_Validations, PatternsRegex, SistOperation,
  TableTypes, TamanioPagina
} from 'src/app/shared/cons/common';
import { Table } from '../../../table/table.model';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Subscription } from 'rxjs';
import { TableService } from '../../../table/table.service';
import { PaginationService } from '../../../pagination/pagination.service';
import { ITableFiltroProducto } from '../models/ITableFiltroProducto';
import { DialogProductoBlService } from '../services/services.bl/dialog-producto.bl.service';
import { Producto } from '../models/Producto';
import { ITableEvento } from '../../../table/ITableEvento';
import { CBuscar } from '../../../buscar/CBuscar';
import { BuscarService } from '../../../buscar/buscar.service';
import { Catalogo } from '../models/Catalogo';
import { Utils } from 'src/app/shared/helpers/utils';
import { IProducto } from '../models/IProducto';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { DialogCatalogoBlService } from '../services/services.bl/dialog-catalogo.bl.service';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';


@Component({
  selector: 'app-dialog-catalogo',
  templateUrl: './dialog-catalogo.component.html',
  styleUrls: ['./dialog-catalogo.component.scss']
})
@AutoUnsubscribe
export class DialogCatalogoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly Msjs: typeof Msjs = Msjs;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly SistOperation: typeof SistOperation = SistOperation;
  dataTable: Table = new Table();
  listaProductoSb!: Subscription;
  tipoTabla: string = TableTypes.AGREGAR_CATALOGO;
  titleModal: string = '';
  isLoading: boolean = false;
  esModoEdicion: boolean = false;
  esModoVer: boolean = false;
  paginacionProductoSb = new Subscription();
  agregarSb = new Subscription();
  isExpanded: boolean = false;
  isExpandedCatalogoProducto:boolean=true;
  isExpandedProductos:boolean=true;
  buscar: CBuscar = new CBuscar();
  btnBuscarSb = new Subscription();
  btnLimpiarSb= new Subscription();
  catalogo: Catalogo = new Catalogo();
  modalSb = new Subscription();
  fechaHoy!:string;
  estadoCatalogoEdicion:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado: any,
    private dialogRef: MatDialogRef<DialogCatalogoComponent>,
    private uiService: UIService,
    private readonly spinnerPageService: SpinnerPageService,
    private registroService: RegistroService,
    private tableService: TableService,
    private paginacionService: PaginationService,
    private DialogProductoBLService: DialogProductoBlService,
    private buscarService: BuscarService,
    private catalogoBLService: DialogCatalogoBlService,
    private functionService: FunctionsService,
  ) {
    //definimos las propiedades de la tabla
    this.dataTable.modoXs = ['numeracion', 'nombre_producto', 'codigo_producto', 'operaciones'];
    this.dataTable.modoM = ['numeracion', 'nombre_producto', 'codigo_producto', 'operaciones'];
    this.dataTable.modoLg = ['numeracion', 'nombre_producto', 'codigo_producto', 'operaciones'];
    this.dataTable.columnaEstado = 'estado';
    this.dataTable.columnaImagen = 'imagen';
    this.dataTable.columnaEstiloEstado = 'estado';
    this.dataTable.pageSizeOptions = TamanioPagina.LISTA_TAMANIOS
    this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[0];// tamaño de pagina
    this.dataTable.currentPage = 1; //pagina inicial
    this.dataTable.nextPage = null;
    this.dataTable.previousPage = null;
    this.dataTable.columnaChip = undefined;
    this.dataTable.operaciones = [SistOperation.AGREGAR];
    this.dataTable.filtro[0] = null; //filtro nombres
    this.dataTable.filtro[1] = null; //filtro codigo
    this.dataTable.filtro[2] = null; //filtro estado
    this.dataTable.minPage = null;//cambia ordenamiendo descendente
    this.dataTable.maxPage = null;//cambia ordenamiendo ascendente
  }

  async ngOnInit(): Promise<void> {
    switch (this.datoPasado.operacion) {
      case SistOperation.NUEVO: {
        this.titleModal = 'Nuevo Catálogo';
        if (this.registroService.empresa) {
          this.generarTabla();
          this.cargarBusqueda();
          this.catalogo.es_vigente = true;
        }
        break;
      }
      case SistOperation.EDITAR: {
        this.titleModal = 'Editar Catálogo';
        if (this.registroService.empresa) {
          this.generarTabla();
          this.cargarBusqueda();
          this.cargarDatosEdicion();
          this.fechaHoy = await this.functionService.GetDateServer() ;

        }
        this.esModoEdicion = true;
        break;

      }
      case SistOperation.VER: {
        this.esModoVer = true;

        this.titleModal = 'Ver Catálogo';
        this.esModoEdicion = true;
        this.cargarDatosEdicion();
      }
    }

    //paginacion de tabla
    this.paginacionProductoSb = this.paginacionService.btnPagination$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        switch (callEvento.data.opcion) {
          case EventoPaginacion.PAGINA_PRIMERA: {
            this.dataTable.minPage = true
            this.dataTable.maxPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
            break;
          }
          case EventoPaginacion.PAGINA_ANTERIOR: {
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = true;
            this.dataTable.currentPage = this.dataTable.currentPage - 1;
            this.dataTable.maxPage = null;
            this.dataTable.minPage = null;
            break;
          }
          case EventoPaginacion.PAGINA_SIGUIENTE: {
            this.dataTable.nextPage = true;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = this.dataTable.currentPage + 1;
            this.dataTable.maxPage = null;
            this.dataTable.minPage = null;
            break;
          }
          case EventoPaginacion.PAGINA_ULTIMA: {
            this.dataTable.maxPage = true
            this.dataTable.minPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
            break;
          }
          case EventoPaginacion.CAMBIO_TAMANIO_PAGINA: {
            this.dataTable.maxPage = null
            this.dataTable.minPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
            this.dataTable.pageSize = callEvento.data.tamanio;
            break;
          }
          default: {
            this.dataTable.maxPage = null
            this.dataTable.minPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
          }
        }
        this.generarTabla();

      }
    });

    //busqueda
    this.btnBuscarSb = this.buscarService.goBuscar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.dataTable.filtro[0] = null; //filtro nombres
        this.dataTable.filtro[1] = null; //filtro nro documento
        this.dataTable.filtro[2] = null; //filtro estado
        this.dataTable.minPage = null;
        this.dataTable.maxPage = null;
        this.dataTable.nextPage = null;
        this.dataTable.previousPage = null;
        this.dataTable.currentPage = 1;
        this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[0];//tamaño de pagina
        const data: CBuscar = callEvento.data;

        switch (data.criterio_seleccionado) {
          case CriteriosBusqueda.nombres: {
            this.dataTable.filtro[0] = data.valor_criterio; //filtro nombres
            break;
          }
          case CriteriosBusqueda.codigo: {
            this.dataTable.filtro[1] = data.valor_criterio; //CODIGO PRODUCTO
            break;
          }
          case CriteriosBusqueda.estado: {
            this.dataTable.filtro[2] = data.estado_seleccionado == MsjEstado.ACTIVO ? true : false; //filtro estado
            break;
          }
          default: {
            this.dataTable.filtro[0] = null; //filtro nombres
            this.dataTable.filtro[1] = null; //codigo
            this.dataTable.filtro[2] = null; //filtro estado
          }
        }


        this.generarTabla();
      }
    });

    //limpiar
    this.btnLimpiarSb = this.buscarService.goLimpiar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.dataTable.filtro[0] = null, //filtro nombres
          this.dataTable.filtro[1] = null, //filtro codigo
          this.dataTable.filtro[2] = null, //filtro estado
          this.dataTable.currentPage = 1;
        this.dataTable.nextPage = null;
        this.dataTable.previousPage = null;
        this.dataTable.minPage = null;
        this.dataTable.maxPage = null;
        this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[0];//tamaño de pagina
        this.generarTabla();
      }
    });

    this.agregarSb = this.tableService.btnAgregar$.subscribe((callEvento:ITableEvento)=>{
      if(this.tipoTabla == callEvento.key){
        let productoSelecionado:Producto = {...callEvento.data as Producto};
        //verificamos si se repite un producto
        const productoRepetido = this.catalogo.lista_productos.filter(prod=>prod.codigo_producto===productoSelecionado.codigo_producto);
        if(productoRepetido.length>0){
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_DOBLE_PRODUCTO, 3000);
          return;
        }

        productoSelecionado = this.removeUtilsProducto(productoSelecionado);     
        this.catalogo.lista_productos.push(productoSelecionado);
        this.catalogo.codigo_productos_anidados_completo?.push(productoSelecionado.codigo_producto);
      }
    });

  }

  cargarDatosEdicion(){
    this.catalogo = new Catalogo();
    this.catalogo ={...this.datoPasado.data as Catalogo};
    this.estadoCatalogoEdicion = this.catalogo.es_vigente;
  }

  removerProducto(producto:IProducto){
    const listaDepurada = this.catalogo.lista_productos.filter(prod=>prod.codigo_producto!=producto.codigo_producto);
    this.catalogo.lista_productos = [...listaDepurada];
    const listaIDsDepurada = this.catalogo.codigo_productos_anidados_completo.filter(id=>id!=producto.codigo_producto);
    this.catalogo.lista_productos = [...listaDepurada];
  }

  removeUtilsProducto(producto:Producto){
    delete producto.estado;
    delete producto.fecha_registro;
    delete producto.masElementos;
    delete producto.totalRegistros_query;
    delete producto.previousPage;
    delete producto.nextPage;
    delete producto.numeracion;
    delete producto.totalRegistrosActivos;
    delete producto.totalRegistrosInactivos;
    delete producto.totalRegistros_doc;
    delete producto.stock_ilimitado_label;
    delete producto.tiene_subproductos_label;
    delete producto.cantidad_imagenes;
    delete producto.total_stock;
    delete producto.lista_imagen;
    delete producto.ref;
    return producto;

  }

  generarCodigoCatalogo(){
    if(this.datoPasado.operacion==SistOperation.NUEVO){
      this.catalogo.codigo_catalogo = Utils.generarCodigoRandom(6);
    }

  }

  onClose() {
    this.uiService.closeDialogCatalogo();
  }

  cargarBusqueda() {
    this.buscar.lista_criterio = [CriteriosBusqueda.nombres, CriteriosBusqueda.codigo, CriteriosBusqueda.estado];
    this.buscar.key = this.tipoTabla;
    this.buscar.lista_estado = [MsjEstado.ACTIVO, MsjEstado.INACTIVO];
  }

  onSubmit(f: NgForm) {
    if (f.valid) {
      this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon: Icons.QUESTION_MARK })
        .subscribe(async result => {
          if (result) {
            this.spinnerPageService.show();
            this.catalogo.nombres_completo = Utils.sanitizarNombre(this.catalogo.nombre_catalogo);

            //no se permite sin productos anidados
            if (this.catalogo.lista_productos.length == 0) {
              this.spinnerPageService.hide();
              this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_SIN_PRODUCTO, 5000);
              return;
            }

            if (!this.esModoEdicion) {

              //comprobar existencia de codigo de producto
              const noExisteCodigo: boolean = await this.catalogoBLService.getCatalogoXCodigo(this.catalogo.codigo_catalogo);
              if (!noExisteCodigo) {
                this.spinnerPageService.hide();
                const msj = Msjs.MSJ_CODIGO_EXISTENTE.replace('$', 'catálogo')
                this.uiService.ShowSnackBar(SnackBarType.WARNING, msj, 5000);
                return;
              }

              this.catalogoBLService.insertCatalogo(this.catalogo).then(tx => {
                if (tx.tx) {
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
                //para retornar el valor y recargar la tabla princial siempre en cuando la pagina sea mayor a uno
                this.dialogRef.close({tx_reload:true});
              })
            }
            else {

              this.catalogoBLService.updateCatalogo(
                this.catalogo, this.estadoCatalogoEdicion
              ).then(res => {
                if (res.tx) {
                  this.uiService.closeDialogCatalogo();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 7000);
                  this.spinnerPageService.hide();
                } else {
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
                }
              });

            }

          }
        });
    }

  }

  Limpiar(f: NgForm) {
    if(this.datoPasado.operacion==SistOperation.NUEVO){
      this.catalogo = new Catalogo();
    }else{
      const codigoCatalogo = this.catalogo.codigo_catalogo;
      this.catalogo = new Catalogo();
      this.catalogo.codigo_catalogo = codigoCatalogo;
    }

  }

  generarTabla() {
    //para no almacenar los observables de paginas previas y solo escuchar lo quee sta en curso
    if (this.listaProductoSb) {
      this.listaProductoSb.unsubscribe();
    }

    this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: true });//loading tabla
    const tablaFiltro: ITableFiltroProducto = {
      itemsPerPage: this.dataTable.pageSize,
      currentPage: this.dataTable.currentPage,
      nombresFiltro: this.dataTable.filtro[0],
      codigoFiltro: this.dataTable.filtro[1],
      estadoFiltro: this.dataTable.filtro[2],
      nextPage: this.dataTable.nextPage,
      previousPage: this.dataTable.previousPage,
      minPage: this.dataTable.minPage,
      maxPage: this.dataTable.maxPage,
    }

    this.listaProductoSb = this.DialogProductoBLService.getProductoResumenCombinado(
      tablaFiltro////click boton fin
    ).subscribe((productos: Producto[]) => {
      if (productos[0].totalRegistros_query != undefined && productos[0].totalRegistros_query > 0) {
        this.dataTable.data = [...productos];
        //total de registros en la bd para mostrar paginacion
        this.dataTable.totalRows = productos[0].totalRegistros_query;
      }
      else {
        if (tablaFiltro.currentPage > 1) {
          this.dataTable.currentPage = tablaFiltro.currentPage - 1;
          this.dataTable.nextPage = false;
          this.generarTabla();
        }
        else {
          this.dataTable.data = new Array();
          this.dataTable.totalRows = 0;
        }
      }

      this.tableService.loadDataTable$.next(this.tipoTabla);//refrescamos el objeto tabla
      this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: false });//loading tabla
    }, error => {
      console.error('Error en el Observable:', error);
    },
      () => {
        //cuando se completa el observable
      });
  }






}
