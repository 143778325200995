<div fxLayout="row" fxLayoutAlign="space-between start">
    <div fxHide.lt-sm fxShow.gt-sm mat-dialog-title class="dialog-title" fxLayout="row" fxLayout.lt-sm="column">
        <div fxLayout.xs="column">
            <span *ngIf="tipo_pago==TipoPagoType.TOTAL">
                Pago Total:
            </span>
            <span *ngIf="tipo_pago==TipoPagoType.PARCIAL" fxLayout.xs="row">
                <span>Pago Parcial</span>
                <div fxShow.xs fxHide.gt-xs>:{{pedido.codigo_pedido}}</div>
            </span>
            <span fxShow.xs fxHide.gt-xs>
                Mesas: {{pedido.mesas_asignado}}
            </span>
            <span fxShow.xs fxHide.gt-xs>
                Usuario: {{pedido.usuario_creacion_busqueda}}
            </span>
            <span fxShow.xs fxHide.gt-xs>
                Registro: {{pedido.fecha_registro}}
            </span>
        </div>
        <div style="margin-top: 2px; margin-left: 10px;" fxLayout="row" fxHide.lt-sm fxShow.gt-xs>
            <div fxLayout="row" matTooltip="Codigo de pedido">
                <mat-icon class="mat-18" matTooltip="Codigo de pedido">{{Icons.STICKY}}</mat-icon>
                <div style="margin-left: 5px; margin-top: -3px;">
                    {{pedido.codigo_pedido}}
                </div>
            </div>
            &nbsp;&nbsp;
            <div fxLayout="row" matTooltip="Mesas">
                <mat-icon class="mat-18" matTooltip="Mesas">{{Icons.TABLE_BAR}}</mat-icon>
                <div style="margin-left: 5px; margin-top: -3px;">
                    {{pedido.mesas_asignado}}
                </div>
            </div>
            &nbsp;&nbsp;
            <div fxLayout="row" matTooltip="Usuario">
                <mat-icon class="mat-18" matTooltip="Usuario apertura">{{Icons.PERSON}}</mat-icon>
                <div style="margin-left: 5px; margin-top: -3px;">
                    {{pedido.usuario_creacion_busqueda}}
                </div>
            </div>
            &nbsp;&nbsp;
            <div fxLayout="row" matTooltip="Fecha">
                <mat-icon class="mat-18" matTooltip="Fecha de apertura">{{Icons.CALENDAR_MONTH}}</mat-icon>
                <div style="margin-left: 5px; margin-top: -3px;">
                    {{pedido.fecha_registro}}
                </div>
            </div>
        </div>

    </div>
    <div fxShow.lt-sm fxHide.gt-sm>
        <div fxLayout.xs="column">
            <span *ngIf="tipo_pago==TipoPagoType.TOTAL">
                <span style="font-weight: bold;">Pago Total Pedido: </span>{{pedido.codigo_pedido}}
            </span>
            <span *ngIf="tipo_pago==TipoPagoType.PARCIAL" fxLayout.xs="row">
                <span style="font-weight: bold;">Pago Parcial</span>
                <div>:{{pedido.codigo_pedido}}</div>
            </span>
            <span>
                <span style="font-weight: bold;">Mesas: </span>{{pedido.mesas_asignado}} | <span
                    style="font-weight: bold;">Usuario:</span>{{pedido.usuario_creacion_busqueda}}
            </span>
            <span>
                <span style="font-weight: bold;">Registro: </span>{{pedido.fecha_registro}}
            </span>
        </div>
    </div>
    <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"
        [attr_disabled]="isLoading"></app-button>
</div>
<mat-divider></mat-divider>
<ng-container *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
    <div style="margin-top: 40px;">
        <app-spinner></app-spinner>
    </div>
</ng-container>
<div *ngIf="mediosAceptados===null" fxLayout="column" fxLayoutAlign="center center">
    <h3>No se tienen medios de pago configurados.</h3>
</div>
<div *ngIf="mediosAceptados!==null && !isLoading" [ngClass.gt-sm]="{'container-gt-sm':true}"
    [ngClass.lt-sm]="{'container-lt-sm':true}" fxLayout.lt-sm="column" fxLayout.gt-sm="row">
    <div fxFlex.lt-sm="100%" fxFlex.gt-sm="65%">
        <ng-container *ngIf="!isLoading && mediosAceptados!=null">
            <mat-tab-group mat-align-tabs="start" scrollDistance="5">
                <ng-container *ngIf="cEfectivo && cEfectivo!.codigo_medio!=''">
                    <mat-tab label="Efectivo">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly center"
                            style="margin-top: 10px;">
                            <div fxLayout="column" class="container-inputs">
                                <mat-form-field class="fields-inputs" appearance="outline">
                                    <mat-label>Monto</mat-label>
                                    <input type="text" ngModel [(ngModel)]="efectivoPago.monto" matInput
                                        placeholder="S/" name="monto" ngModel #efectivo="ngModel"
                                        [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned" minlength="1" maxlength="8"
                                        (blur)="completarDecimal($event)" (focus)="seleccionarTodo($event)"
                                        (keydown)="($event.keyCode < 48 || ($event.keyCode > 57 && $event.keyCode < 96) ||
                                        $event.keyCode > 105) &&
                                        [8, 9, 37, 38, 39, 40, 46, 110, 190].indexOf($event.keyCode) === -1 ? $event.preventDefault() : null">
                                </mat-form-field>
                                <div style="margin-bottom: 20px;">
                                    <mat-divider></mat-divider>
                                </div>
                                <div style="width: 100px;">
                                    <app-button [width]="100" text="Agregar"
                                        (click)="agregarMonto(ListaMediosPago.listaMediosPago[0].codigo_medio)"></app-button>
                                </div>
                            </div>
                            <div fxHide.lt-md fxShow.gt-sm>
                                <app-teclado-numerico-virtual
                                    [key]="TableTypes.CALC_EFECTIVO"></app-teclado-numerico-virtual>
                            </div>
                        </div>
                    </mat-tab>
                </ng-container>
                <ng-container *ngIf="cTarjeta && cTarjeta!.codigo_medio!=''">
                    <mat-tab label="Tarjeta">
                        <div fxLayout="column" fxLayoutAlign="start center">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px"
                                fxLayoutAlign="space-around center">
                                <div fxLayout="column" class="container-inputs">
                                    <mat-form-field class="fields-inputs" appearance="outline">
                                        <mat-label>Monto</mat-label>
                                        <input type="text" ngModel [(ngModel)]="tarjetaPago.monto" matInput
                                            placeholder="S/" name="monto" ngModel
                                            [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned" minlength="1"
                                            maxlength="8" (blur)="completarDecimal($event)"
                                            (focus)="seleccionarTodo($event)"
                                            (keydown)="($event.keyCode < 48 || ($event.keyCode > 57 && $event.keyCode < 96) ||
                                            $event.keyCode > 105) &&
                                            [8, 9, 37, 38, 39, 40, 46, 110, 190].indexOf($event.keyCode) === -1 ? $event.preventDefault() : null">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="fields-inputs">
                                        <mat-label>Comentario</mat-label>
                                        <textarea matInput ngModel [(ngModel)]="tarjetaPago.comentario"></textarea>
                                    </mat-form-field>
                                    <div style="margin-bottom: 20px;">
                                        <mat-divider></mat-divider>
                                    </div>
                                    <div style="width: 100px;">
                                        <app-button [width]="100" text="Agregar"
                                            (click)="agregarMonto(ListaMediosPago.listaMediosPago[1].codigo_medio)"></app-button>
                                    </div>
                                </div>
                                <div fxHide.lt-md fxShow.gt-sm>
                                    <app-teclado-numerico-virtual
                                        [key]="TableTypes.CALC_TARJETA"></app-teclado-numerico-virtual>
                                </div>
                            </div>
                            <div fxHide.lt-md fxShow.gt-sm style="margin-top: -20px;">
                                <app-teclado-char-virtual [key]="TableTypes.CHAR_TARJETA"></app-teclado-char-virtual>
                            </div>
                        </div>
                    </mat-tab>
                </ng-container>
                <ng-container *ngIf="cYape && cYape!.codigo_medio!=''">
                    <mat-tab label="Yape">
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px"
                                fxLayoutAlign="space-around center">
                                <div fxLayout="column" class="container-inputs">
                                    <mat-form-field class="fields-inputs" appearance="outline">
                                        <mat-label>Monto</mat-label>
                                        <input type="text" matInput placeholder="S/" name="monto" ngModel
                                            [(ngModel)]="yapePago.monto"
                                            [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned" minlength="1"
                                            maxlength="8" (blur)="completarDecimal($event)"
                                            (focus)="seleccionarTodo($event)"
                                            (keydown)="($event.keyCode < 48 || ($event.keyCode > 57 && $event.keyCode < 96) ||
                                            $event.keyCode > 105) &&
                                            [8, 9, 37, 38, 39, 40, 46, 110, 190].indexOf($event.keyCode) === -1 ? $event.preventDefault() : null">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="fields-inputs">
                                        <mat-label>Comentario</mat-label>
                                        <textarea matInput ngModel [(ngModel)]="yapePago.comentario"></textarea>
                                    </mat-form-field>
                                    <div style="margin-bottom: 20px;">
                                        <mat-divider></mat-divider>
                                    </div>
                                    <div style="width: 100px;">
                                        <app-button [width]="100" text="Agregar"
                                            (click)="agregarMonto(ListaMediosPago.listaMediosPago[3].codigo_medio)"></app-button>
                                    </div>
                                </div>
                                <div fxHide.lt-md fxShow.gt-sm>
                                    <app-teclado-numerico-virtual
                                        [key]="TableTypes.CALC_YAPE"></app-teclado-numerico-virtual>
                                </div>
                            </div>
                            <div fxHide.lt-md fxShow.gt-sm style="margin-top: -20px;">
                                <app-teclado-char-virtual [key]="TableTypes.CHAR_YAPE"></app-teclado-char-virtual>
                            </div>
                        </div>
                    </mat-tab>
                </ng-container>
                <ng-container *ngIf="cPlin && cPlin!.codigo_medio!=''">
                    <mat-tab label="Plin">
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px"
                                fxLayoutAlign="space-around center">
                                <div fxLayout="column" class="container-inputs">
                                    <mat-form-field class="fields-inputs" appearance="outline">
                                        <mat-label>Monto</mat-label>
                                        <input type="text" matInput placeholder="S/" name="monto" ngModel
                                            [(ngModel)]="plinPago.monto"
                                            [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned" minlength="1"
                                            maxlength="8" (blur)="completarDecimal($event)"
                                            (focus)="seleccionarTodo($event)"
                                            (keydown)="($event.keyCode < 48 || ($event.keyCode > 57 && $event.keyCode < 96) ||
                                            $event.keyCode > 105) &&
                                            [8, 9, 37, 38, 39, 40, 46, 110, 190].indexOf($event.keyCode) === -1 ? $event.preventDefault() : null">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="fields-inputs">
                                        <mat-label>Comentario</mat-label>
                                        <textarea matInput ngModel [(ngModel)]="plinPago.comentario"></textarea>
                                    </mat-form-field>
                                    <div style="margin-bottom: 20px;">
                                        <mat-divider></mat-divider>
                                    </div>
                                    <div style="width: 100px;">
                                        <app-button [width]="100" text="Agregar"
                                            (click)="agregarMonto(ListaMediosPago.listaMediosPago[4].codigo_medio)"></app-button>
                                    </div>
                                </div>
                                <div fxHide.lt-md fxShow.gt-sm>
                                    <app-teclado-numerico-virtual
                                        [key]="TableTypes.CALC_PLIN"></app-teclado-numerico-virtual>
                                </div>
                            </div>
                            <div fxHide.lt-md fxShow.gt-sm style="margin-top: -20px;">
                                <app-teclado-char-virtual [key]="TableTypes.CHAR_PLIN"></app-teclado-char-virtual>
                            </div>
                        </div>
                    </mat-tab>
                </ng-container>
                <ng-container *ngIf="cTransferencia && cTransferencia!.codigo_medio!=''">
                    <mat-tab label="Transferencia">
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px"
                                fxLayoutAlign="space-around center">
                                <div fxLayout="column" class="container-inputs">
                                    <mat-form-field class="fields-inputs" appearance="outline">
                                        <mat-label>Monto</mat-label>
                                        <input type="text" matInput placeholder="S/" name="monto" ngModel
                                            [(ngModel)]="transferenciaPago.monto"
                                            [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned" minlength="1"
                                            maxlength="8" (blur)="completarDecimal($event)"
                                            (focus)="seleccionarTodo($event)"
                                            (keydown)="($event.keyCode < 48 || ($event.keyCode > 57 && $event.keyCode < 96) ||
                                            $event.keyCode > 105) &&
                                            [8, 9, 37, 38, 39, 40, 46, 110, 190].indexOf($event.keyCode) === -1 ? $event.preventDefault() : null">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="fields-inputs">
                                        <mat-label>Comentario</mat-label>
                                        <textarea matInput ngModel
                                            [(ngModel)]="transferenciaPago.comentario"></textarea>
                                    </mat-form-field>
                                    <div style="margin-bottom: 20px;">
                                        <mat-divider></mat-divider>
                                    </div>
                                    <div style="width: 100px;">
                                        <app-button [width]="100" text="Agregar"
                                            (click)="agregarMonto(ListaMediosPago.listaMediosPago[2].codigo_medio)"></app-button>
                                    </div>
                                </div>
                                <div fxHide.lt-md fxShow.gt-sm>
                                    <app-teclado-numerico-virtual
                                        [key]="TableTypes.CALC_TRANSFERENCIA"></app-teclado-numerico-virtual>
                                </div>

                            </div>
                            <div fxHide.lt-md fxShow.gt-sm style="margin-top: -20px;">
                                <app-teclado-char-virtual
                                    [key]="TableTypes.CHAR_TRANSFERENCIA"></app-teclado-char-virtual>
                            </div>
                        </div>
                    </mat-tab>
                </ng-container>
                <ng-container *ngIf="aspectosGenerales$ | async as aspectosGenerales">
                    <ng-container *ngIf="!aspectosGenerales.incluir_servicio"></ng-container>
                    <mat-tab label="Propina">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="space-evenly center">
                            <div fxLayout="column" class="container-inputs">
                                <mat-form-field class="fields-inputs" appearance="outline">
                                    <mat-label>Monto</mat-label>
                                    <input type="text" matInput placeholder="S/" name="monto" ngModel
                                        [(ngModel)]="propinaPago.monto"
                                        [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned" minlength="1" maxlength="8"
                                        (blur)="completarDecimal($event)" (focus)="seleccionarTodo($event)"
                                        (keydown)="($event.keyCode < 48 || ($event.keyCode > 57 && $event.keyCode < 96) ||
                                    $event.keyCode > 105) &&
                                    [8, 9, 37, 38, 39, 40, 46, 110, 190].indexOf($event.keyCode) === -1 ? $event.preventDefault() : null">
                                </mat-form-field>
                                <div style="margin-bottom: 20px;">
                                    <mat-divider></mat-divider>
                                </div>
                                <div style="width: 100px;">
                                    <app-button [width]="100" text="Agregar"
                                        (click)="agregarMonto(ListaMediosPago.listaMediosPago[5].codigo_medio)"></app-button>
                                </div>
                            </div>
                            <div fxHide.lt-md fxShow.gt-sm style="margin-top: -20px;">
                                <app-teclado-numerico-virtual
                                    [key]="TableTypes.CALC_PROPINA"></app-teclado-numerico-virtual>
                            </div>

                        </div>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>
        </ng-container>
    </div>
    <div fxFlex.lt-sm="100%" fxFlex.gt-sm="35%">
        <mat-tab-group mat-align-tabs="center">
            <ng-container *ngIf="tipo_pago==TipoPagoType.TOTAL">
                <mat-tab label="Cuenta">
                    <div fxLayoutAlign="center center">
                        <div fxLayout="column">
                            <!-- titulo -->
                            <div fxLayout="row" fxLayoutAlign="space-between center"
                                class="ancho-comanda--titulo-total">
                                <div>TOTAL:</div>
                                <div>S/&nbsp;{{pedido.total | number:'1.2-2'}}</div>
                            </div>
                            <!-- subtitulo -->
                            <div fxLayout="column">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div>SubTotal:</div>
                                    <div>&nbsp;{{pedido.sub_total| number:'1.2-2' }}</div>
                                </div>
                                <div fxLayout="row" *ngIf="incluirServicio" fxLayoutAlign="space-between center">
                                    <div>Servicio&nbsp;({{tasaServicio}}%):</div>
                                    <div>&nbsp;{{pedido.servicio_subtotal| number:'1.2-2'}}</div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div><span *ngIf="pedido.servicio_subtotal">Total&nbsp;</span>{{siglaImpuesto}}&nbsp;({{tasaImpuesto}}%):
                                    </div>
                                    <div>&nbsp;{{pedido.igv| number:'1.2-2'}}</div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <!-- subtitulo mesa -->
                            <!-- <div fxLayout="column" class="ancho-comanda" >
                                <mat-card-subtitle fxLayout="row" style="margin-top: 10px;" fxLayoutAlign="space-between center" >
                                    <div fxLayout="row">
                                        <mat-icon class="mat-18" matTooltip="Mesas">{{Icons.TABLE_BAR}}</mat-icon>
                                        <div style="margin-left: 5px; margin-top: 5px;">
                                            {{pedido.mesas_asignado}}
                                        </div>
                                    </div>
                                    <div fxLayout="row">
                                        <mat-icon class="mat-18" matTooltip="Usuario apertura">{{Icons.PERSON}}</mat-icon>
                                        <div style="margin-left: 5px; margin-top: 5px;">
                                            {{pedido.usuario_creacion_busqueda}}
                                        </div>
                                    </div>
                                </mat-card-subtitle>
                                <mat-card-subtitle fxLayout="row">
                                    <mat-icon class="mat-18" matTooltip="Fecha de apertura">{{Icons.CALENDAR_MONTH}}</mat-icon>
                                    <div style="margin-left: 5px; margin-top: 5px;">
                                        {{pedido.fecha_registro}}
                                    </div>
                                </mat-card-subtitle>    
                            </div> -->

                            <!-- detalle -->
                            <div fxLayout="column" style="height: auto; overflow: visible;" class="ancho-comanda">
                                <div *ngIf="lista_pago_cuenta.length==0" class="ancho-comanda--subtitulo-sin-pago">No se
                                    tiene pagos.</div>
                                <div [ngClass]="esPantallaXs?'container-pagos--xs':'container-pagos'">
                                    <mat-chip-list [aria-orientation]="'vertical'">
                                        <mat-chip
                                            [matTooltip]="pago.comentario==null?'Sin comentario':pago.comentario==undefined?'Sin comentario':pago.comentario"
                                            style="background-color: #888888; color:white"
                                            *ngFor="let pago of lista_pago_cuenta; let i=index" removable
                                            (removed)="removerPago(pago, i)">
                                            <div fxLayout="row">
                                                <div>
                                                    <span>S/ &nbsp;{{pago.monto | number:'1.2-2'}}</span>
                                                    &nbsp;
                                                </div>
                                                <div>
                                                    <span>{{pago.tipo_pago.nombre_medio}}</span>
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <button matChipRemove>
                                                <mat-icon>{{Icons.CANCEL}}</mat-icon>
                                            </button>
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>

                                <div fxLayout="column">
                                    <mat-divider></mat-divider>
                                    <h2 class="subTotal">Pagado: S/ &nbsp;{{subTotal | number:'1.2-2'}}</h2>
                                    <h3 class="restante">Restante: S/ &nbsp;{{restantePago | number:'1.2-2'}} <span
                                            *ngIf="tieneExcedente">(Excede)</span></h3>
                                </div>
                                <div>
                                    <mat-divider></mat-divider>
                                </div>
                            </div>
                            <!-- botones -->
                            <div fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="space-between center"
                                class="ancho-comanda" style="margin-top: 20px;">
                                <app-button
                                    *ngIf="(subTotal >= pedido.total && tieneEfectivo) || (subTotal === pedido.total) "
                                    [width]="100" text="Pagar" (click)="pagarCuenta()"></app-button>
                                <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" (click)="limpiar()"
                                    color="gray"></app-button>
                                <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
                                    (click)="limpiar()"></app-button>
                                <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray"
                                    (click)="cancelar()"></app-button>
                                <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL"
                                    (click)="cancelar()"></app-button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Productos">
                    <div fxLayoutGap="10px" fxLayout="column" class="ancho-comanda">
                        <ul style="list-style-type: none;">
                            <div style="margin-left: -40px;">
                                <li *ngFor="let prod of listaProductoPedido" style="margin-top:10px;">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" style="font-size: 10pt;">
                                        <div>
                                            <span>{{prod.cantidad_producto}}</span>
                                        </div>
                                        <div>
                                            &nbsp;
                                            <span>{{prod.nombre_producto}}</span>
                                        </div>
                                        <div>
                                            &nbsp;
                                            <span>(P.U. {{prod.precio_unitario | number:'1.2-2'}})</span>
                                        </div>
                                        <div *ngIf="prod.tiene_descuento"  [matTooltip]="prod.descuento!.motivo" >
                                            <span [@blink]="blinkState" class="custom-chip-aceptado">-{{prod.descuento?.porcentaje_descuento}}%&nbsp;(x{{prod.descuento?.cantidad_producto}})</span>
                                        </div>
                                        <div *ngIf="prod.tiene_incidencia" [matTooltip]="prod.incidencia!.motivo" >
                                        <span [@blink]="blinkState"  class="custom-chip-rechazado">(i)&nbsp;x{{prod.incidencia!.cantidad_producto}}</span>                                          
                                        </div>
                                        <div class="precio-total">
                                            &nbsp;
                                            {{prod.precio_total | number:'1.2-2'}}
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                        <mat-divider></mat-divider>
                        <div fxLayout="column" fxLayoutAlign="space-between end" fxLayoutGap="5px" class="divider">
                            <div fxLayout="row" class="ancho-comanda--subtitulo-total">
                                <div class="etiquetas-montos">Sub Total:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                <div class="montos">S/ {{this.pedido.sub_total| number:'1.2-2' }}</div>
                            </div>
                            <div *ngIf="pedido.servicio_total" fxLayout="row" class="ancho-comanda--subtitulo-total" >
                                <div class="montos-subs">Servicio ({{aspectosGenerales.tasa_servicio }}%):&nbsp;&nbsp;&nbsp;&nbsp;S/ {{this.pedido.servicio_subtotal| number:'1.2-2' }}</div>                                              
                                
                            </div>
                            <div fxLayout="row" class="ancho-comanda--subtitulo-total">
                                <div class="montos-subs">
                                    <span *ngIf="aspectosGenerales.incluir_servicio">Total</span>&nbsp;{{aspectosGenerales.sigla_impuesto}}({{aspectosGenerales.tasa_impuesto}}%):&nbsp;&nbsp;&nbsp;&nbsp;S/ {{this.pedido.igv| number:'1.2-2' }}
                                </div>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div fxLayout="row" class="divider" fxLayoutAlign="end center">
                            <div>
                                <h2>TOTAL:&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                            </div>
                            <div class="montos">
                                <h2> S/ {{this.pedido.total| number:'1.2-2' }}</h2>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </ng-container>
            <mat-tab *ngIf="tipo_pago==TipoPagoType.PARCIAL" label="Pago Parcial">
                <div fxLayoutAlign="center center">
                    <mat-card fxFlex.lt-lg="92%" fxFlex="95%" style="min-width: 220px; max-width: 300px;">
                        <mat-card-subtitle fxLayout="column">
                            <div fxLayout="row" fxLayoutAlign="space-between center"
                                style="font-weight: bold; font-size: 14pt;">
                                <div>TOTAL:</div>
                                <div>&nbsp;{{totalInformativo | number:'1.2-2' }}</div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div>SubTotal:</div>
                                <div>&nbsp;{{subTotalInformativo | number:'1.2-2' }}</div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div>{{siglaImpuesto}}&nbsp;({{tasaImpuesto}}%):</div>
                                <div>&nbsp;{{igvInformativo| number:'1.2-2'}}</div>
                            </div>
                            <div fxLayout="row" *ngIf="incluirServicio" fxLayoutAlign="space-between center">
                                <div>Servicio&nbsp;({{tasaServicio}}%):</div>
                                <div>&nbsp;{{servicioInformativo| number:'1.2-2'}}</div>
                            </div>
                        </mat-card-subtitle>
                        <mat-divider></mat-divider>
                        <div class="scrollable" mat-dialog-content style="max-height: 100px;">
                            <ul style="list-style-type: none; ">
                                <li *ngFor="let prod of listaProductoPedido"
                                    style="margin-top:10px;margin-left: -50px;">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                                        <div fxLayout="row" style="font-size: 10pt;">
                                            <div style="margin-left: 10px;">
                                                <span>{{prod.cantidad_producto}}</span>
                                                &nbsp;
                                            </div>
                                            <div>
                                                <span>{{prod.nombre_producto}}</span>
                                                &nbsp;
                                            </div>
                                            <div fxShow.gt-xs fxHide.xs>
                                                <span>(P.U. {{prod.precio_unitario | number:'1.2-2'}})</span>
                                                &nbsp;
                                            </div>
                                            <div *ngIf="prod.tiene_descuento" style="margin-top: -5px;"
                                                [matTooltip]="prod.descuento!.motivo">
                                                <mat-chip-list>
                                                    <mat-chip [@blink]="blinkState"
                                                        class="chip-aprobado">-{{prod.descuento?.porcentaje_descuento}}%&nbsp;(x{{prod.descuento?.cantidad_producto}})</mat-chip>
                                                </mat-chip-list>
                                            </div>
                                            <div *ngIf="prod.tiene_incidencia" style="margin-top: -5px;"
                                                [matTooltip]="prod.incidencia!.motivo">
                                                <mat-chip-list>
                                                    <mat-chip [@blink]="blinkState" class="chip-rechazado">
                                                        <mat-icon class="mat-18">{{Icons.INFO}}</mat-icon>
                                                        &nbsp;x{{prod.incidencia!.cantidad_producto}}
                                                    </mat-chip>
                                                </mat-chip-list>
                                            </div>
                                        </div>
                                        <div class="precio-total">
                                            {{prod.precio_total | number:'1.2-2'}}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <mat-divider></mat-divider>
                        <div *ngIf="lista_pago_cuenta.length==0">
                            <h3>No se tiene pagos</h3>
                        </div>
                        <div fxLayout="column" fxLayoutGap="10px" mat-dialog-content
                            style="padding-top: 10px; margin-bottom:10px; max-height: 170px;" class="scrollable">
                            <mat-chip-list>
                                <mat-chip
                                    [matTooltip]="pago.comentario==null?'Sin comentario':pago.comentario==undefined?'Sin comentario':pago.comentario"
                                    style="background-color: #888888; color:white" selected
                                    *ngFor="let pago of lista_pago_cuenta; let i=index" removable
                                    (removed)="removerPago(pago, i)">
                                    <div fxLayout="row">
                                        <div>
                                            <span>S/ &nbsp;{{pago.monto | number:'1.2-2'}}</span>
                                            &nbsp;
                                        </div>
                                        <div>
                                            <span>{{pago.tipo_pago.nombre_medio}}</span>
                                            &nbsp;
                                        </div>
                                    </div>
                                    <button matChipRemove>
                                        <mat-icon>{{Icons.CANCEL}}</mat-icon>
                                    </button>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                        <div fxLayout="column">
                            <mat-divider></mat-divider>
                            <h2 class="subTotal">Pagado: S/ &nbsp;{{subTotal | number:'1.2-2'}}</h2>
                            <h3 class="restante">Restante: S/ &nbsp;{{restantePago | number:'1.2-2'}} <span
                                    *ngIf="tieneExcedente">(excedente)</span></h3>
                            <div>
                                <mat-divider></mat-divider>
                            </div>
                        </div>
                        <mat-card-actions fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="space-between center">

                            <app-button *ngIf="totalInformativo==subTotal || tieneEfectivo" [width]="100" text="Pagar"
                                (click)="pagarCuentaParcial()"></app-button>
                            <!-- <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" (click)="limpiar()" color="gray"></app-button> -->
                            <app-button *ngIf="totalInformativo==subTotal || tieneEfectivo" type="outline"
                                color="gray-b0" [icon_fig]="Icons.CLEANING" (click)="limpiar()"></app-button>
                            <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray"
                                (click)="cancelar()"></app-button>
                            <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL"
                                (click)="cancelar()"></app-button>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>