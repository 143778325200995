import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Msjs_Validations } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { IProducto } from '../../../procesos/dialog/models/IProducto';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-listar-caracteristicas-into',
  templateUrl: './listar-caracteristicas-into.component.html',
  styleUrls: ['./listar-caracteristicas-into.component.scss']
})
export class ListarCaracteristicasIntoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  isLoading:boolean=false;
  listaCaracteristicas:string[]=[];
  listaCaracteristicasSelect:string[]=[];
  producto!:IProducto;
  caracteristicaSeleccionada:string | null ='' ;
  hiddenSelect:boolean = false;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  @ViewChild('mySelect') mySelect!: MatSelect; 
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<ListarCaracteristicasIntoComponent>,
    private uiService:UIService,
  ) { }

  ngOnInit(): void {
    this.producto = this.datoPasado.dato as IProducto;
    if(this.producto.lista_caracteristicas)
    this.listaCaracteristicas = this.producto.lista_caracteristicas;
  }

  onClose(){
    this.dialogRef.close({});
  }

  seleccionarCaracteristica(){
    if(this.caracteristicaSeleccionada!=null){
      this.listaCaracteristicasSelect.push(this.caracteristicaSeleccionada!);
      this.caracteristicaSeleccionada=null;
      this.mySelect.value = null;  
      if(this.listaCaracteristicasSelect.length==this.producto.cantidad_caracteristicas_seleccionables!){
        this.hiddenSelect = true;
      }
      else{
        this.hiddenSelect = false;
      }
    }    
  }
  removerCaracteristicaPedido(index:number){
    this.listaCaracteristicasSelect.splice(index,1);
    this.hiddenSelect = false;
  }

  onSubmit(f:NgForm){
    if(f.valid){
      this.dialogRef.close({lista_caracteristicas : this.listaCaracteristicasSelect});  
    }

  }

  Limpiar(){
    this.caracteristicaSeleccionada =null;
    if(this.mySelect){
      this.mySelect.value = null;
    }    
    this.listaCaracteristicasSelect=[];
    this.hiddenSelect = false;
  }

}

