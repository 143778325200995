import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { GestionMontoBlService } from '../../gestion-monto/services/gestion-monto.bl.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { ListarAmbienteMesaService } from '../../listar-ambiente-mesa/listar-ambiente-mesa.service';
import { GestionAmbientesBlService } from '../../procesos/gestion-ambientes-mesas/services/services.bl/gestion-ambientes.bl.service';
import { IAmbienteMesa } from '../../procesos/gestion-ambientes-mesas/models/IAmbienteMesa';
import { EstadosMesaType } from 'src/app/shared/types/estado-mesa-type';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { UIService } from 'src/app/shared/services/ui.services';
import { ListaTipoProducto, Msjs } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { PedidoBlService } from '../../registrar-pedido/services/services.bl/pedido.bl.service';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { Turno } from '../../gestion-monto/models/Turno';
import { Pedido } from '../../registrar-pedido/models/Pedido';
import { TipoConsumibleType } from 'src/app/shared/types/tipo-consumible';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-operaciones-metricas',
  templateUrl: './operaciones-metricas.component.html',
  styleUrls: ['./operaciones-metricas.component.scss']
})

@AutoUnsubscribe

export class OperacionesMetricasComponent implements OnInit, OnDestroy {
  gestionMontoSb!: Subscription;
  deSelMesa = new Subscription();
  configSb = new Subscription();
  isLoading: boolean = false;
  configuracionAmbiente: IAmbienteMesa[] = [];
  configuracionAmbienteFinal: IAmbienteMesa[] = [];
  ambienteMayorTB!: IAmbienteMesa;
  ambienteMayorTC!: IAmbienteMesa;
  ambienteMayorTUP!: IAmbienteMesa;
  ambientesUnicos: string[] = [];
  mesasSeleccionadasLocal: IAmbienteMesa[] = [];
  readonly Icons: typeof Icons = Icons;
  readonly EstadosMesaType: typeof EstadosMesaType = EstadosMesaType;
  esAdministrador: boolean = false;
  usuario: any;
  existeConfig: boolean = false;
  listaProductoSb!: Subscription;
  turno: Turno = new Turno();
  pedidoProductos: Pedido[] | null = null;
  intervalo!: NodeJS.Timeout;
  constructor(
    private gestionMontoBlService: GestionMontoBlService,
    private registroService: RegistroService,
    private gestionAmbienteBLService: GestionAmbientesBlService,
    private pedidoBLService: PedidoBlService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    //intervalo para actualizar el timer cada minuto
    this.intervalo = setInterval(() => {
      if (this.configuracionAmbiente.length > 0) {
        this.actualizarTimer();
      }
    }, 60000);//cada minuto

    this.isLoading = true;
    if (this.registroService.empresa && this.registroService.usuario) {
      this.usuario = this.registroService.usuario;
      if (this.registroService.esAdministrador) {
        this.esAdministrador = true;
      }
      this.gestionMontoBlService.existFetchTurno();
      this.gestionMontoSb?.unsubscribe();
      this.gestionMontoSb = this.gestionMontoBlService.getTurno().pipe(
        filter(turno => turno !== undefined// comparacion estricta para que diferencie los undefined de los nulls
        )).subscribe(turn => {
          if (turn) {
            this.turno = turn;
            this.gestionAmbienteBLService.existFetchAmbienteMesa();
            this.configSb?.unsubscribe();
            this.configSb = this.gestionAmbienteBLService.getAmbienteMesa().subscribe(configAmb => {
              if (configAmb) {
                this.configuracionAmbiente = [];
                this.ambientesUnicos = [];
                this.configuracionAmbiente = configAmb;
                this.existeConfig = true;
                //extrayendo ambientes
                let ambientesUnicos = new Set<string>();
                this.configuracionAmbiente.forEach(amb_mesa => {
                  ambientesUnicos.add(amb_mesa.ambiente);
                });

                const ambientesUnicosArray = Array.from(ambientesUnicos);
                const orderAmbMesa: any[] = [];
                ambientesUnicosArray.forEach(ambiente => {
                  const amb_orden = configAmb.filter(amb_mesa1 => amb_mesa1.ambiente == ambiente)[0];
                  orderAmbMesa.push({
                    ambiente: ambiente,
                    orderAmbMesa: amb_orden.orden
                  });
                });
                orderAmbMesa.sort((a, b) => {
                  return a.orderAmbMesa - b.orderAmbMesa
                }//para orden descendente invertir el orden
                );
                orderAmbMesa.forEach(obj => {
                  this.ambientesUnicos.push(obj.ambiente);
                });
                //ordenando por dos criterios
                this.configuracionAmbiente.sort((a, b) => {
                  if (a.orden < b.orden) return -1;
                  if (a.orden > b.orden) return 1;

                  if (a.mesa_numero < b.mesa_numero) return -1;
                  if (a.mesa_numero > b.mesa_numero) return 1;

                  return 0; // Los objetos son iguales en términos de ordenación
                });
                //cuando ocurre un cambio para mostrar el mensaje en caso de liberar mesa
                this.getPedidosProductosTodoXListaEstados(turn.id!);


              }
              else {
                this.isLoading = false;
              }



            });
          }
          else {
            this.isLoading = false;
          }
        });
    }
  }

  actualizarTimer() {
    this.configuracionAmbienteFinal.forEach(ambiente => {     
      if (ambiente.lista_productos && ambiente.lista_productos.length > 0) {
        const prodBarra = ambiente.lista_productos.filter(prod => Utils.getTipoProducto(true).includes(prod.tipo_producto))[0];//bebida
        const prodCocina = ambiente.lista_productos.filter(prod => Utils.getTipoProducto(false).includes(prod.tipo_producto))[0];//comida
        if (prodBarra) {
          const fechaProdBarra = prodBarra.fecha_key;
          const fechaCreacionProdBarra = fechaProdBarra.anio + '-' + fechaProdBarra.mes + '-' + fechaProdBarra.dia + 'T' + fechaProdBarra.hora + ':' + fechaProdBarra.minuto + ':' + fechaProdBarra.segundo;
          let fechaHoraCreacionVar = new Date(fechaCreacionProdBarra);
          let fechaActual = new Date();
          let diffMilis = fechaActual.getTime() - fechaHoraCreacionVar.getTime();
          let minutosTranscurridos = Math.floor(diffMilis / (1000 * 60));
          ambiente.TB = minutosTranscurridos;
        } else {
          delete ambiente.TB;
        }

        if (prodCocina) {
          const fechaProdCocina = prodCocina.fecha_key;
          const fechaCreacionProdBarra = fechaProdCocina.anio + '-' + fechaProdCocina.mes + '-' + fechaProdCocina.dia + 'T' + fechaProdCocina.hora + ':' + fechaProdCocina.minuto + ':' + fechaProdCocina.segundo;
          let fechaHoraCreacionVar = new Date(fechaCreacionProdBarra);
          let fechaActual = new Date();
          let diffMilis = fechaActual.getTime() - fechaHoraCreacionVar.getTime();
          let minutosTranscurridos = Math.floor(diffMilis / (1000 * 60));
          ambiente.TC = minutosTranscurridos;
        } else {
          delete ambiente.TC;
        }    
        
      } else {
        delete ambiente.TB;
        delete ambiente.TC;
        
        
      }
      if(ambiente.fecha_pedido){
        const fechaPedido = ambiente.fecha_pedido!;
        const fechaCreacionPedido = fechaPedido.anio + '-' + fechaPedido.mes + '-' + fechaPedido.dia + 'T' + fechaPedido.hora + ':' + fechaPedido.minuto + ':' + fechaPedido.segundo;
        let fechaHoraCreacionVar = new Date(fechaCreacionPedido);
        let fechaActual = new Date();
        let diffMilis = fechaActual.getTime() - fechaHoraCreacionVar.getTime();
        let minutosTranscurridosPedido = Math.floor(diffMilis / (1000 * 60));
        ambiente.TUP = minutosTranscurridosPedido;
      }
      else{
        delete ambiente.TUP;
      }       
    }); 

    const nuevoObjRef = JSON.parse(JSON.stringify(this.configuracionAmbienteFinal)) as IAmbienteMesa[];
    //console.log(nuevoObjRef);

    this.ambienteMayorTB = nuevoObjRef.reduce((max, obj) => {
      // Verificar si el objeto actual tiene MC y si es mayor al max actual
      if (obj.TB !== undefined && (max.TB === undefined || obj.TB! > max.TB)) {
        return obj;
      }
      return max;
    }, nuevoObjRef[0]);

    this.ambienteMayorTC = nuevoObjRef.reduce((max, obj) => {
      // Verificar si el objeto actual tiene MC y si es mayor al max actual
      if (obj.TC !== undefined && (max.TC === undefined || obj.TC! > max.TC)) {
        return obj;
      }
      return max;
    }, nuevoObjRef[0]);

    this.ambienteMayorTUP = nuevoObjRef.reduce((max, obj) => {
      // Verificar si el objeto actual tiene MC y si es mayor al max actual
      if (obj.TUP !== undefined && (max.TUP === undefined || obj.TUP! > max.TUP)) {
        return obj;
      }
      return max;
    }, nuevoObjRef[0]);  // Inicializamos con el primer objeto de la lista
  }

  getPedidosProductosTodoXListaEstados(idTurno: string) {
    this.listaProductoSb?.unsubscribe();
    this.listaProductoSb = this.pedidoBLService.getPedidosProductosTodoXListaEstados(idTurno)
      .subscribe(listaPedidos => {
        if (listaPedidos) {          
          this.pedidoProductos = listaPedidos;
          this.pedidoProductos.forEach(pedido => {

            let pedidoIndice: any[] = []
            const resultadoX = this.configuracionAmbiente.map((ambiente, index) => {
              if (pedido.mesas_completo.includes(ambiente.mesa_numero.toString())) {
                pedidoIndice.push({ ambiente, index })
                return { ambiente, index };  // Devuelve el objeto ambiente y el índice
              }
              return null;  // Si no coincide, devolver null
            }).filter(result => result !== null);  // Filtrar los valores null

            if (resultadoX && resultadoX.length > 0) {
              resultadoX.forEach(res => {
                if(pedido.lista_producto && pedido.lista_producto.length > 0){
                  this.configuracionAmbiente[res!.index].lista_productos = pedido.lista_producto!;
                  this.configuracionAmbiente[res!.index].fecha_pedido = pedido.lista_producto![pedido.lista_producto!.length - 1].fecha_key; // el ultimo fecha del prod
                }else{
                  //cuando no trae productos y tiene el estado de pedido terminado
                  
                  delete this.configuracionAmbiente[res!.index].lista_productos;
                  this.configuracionAmbiente[res!.index].fecha_pedido = pedido.fecha_modificacion;//la fecha que modifico el ultimo estado se toma como pedido
                }
                
              });

            }
          });

          this.configuracionAmbienteFinal = [];
          this.configuracionAmbienteFinal = JSON.parse(JSON.stringify(this.configuracionAmbiente));
          this.actualizarTimer();
          this.isLoading = false;
          this.cdRef.detectChanges();
        } else {
          this.isLoading = false;
        }
      });
  }

  ngOnDestroy() {
    this.listaProductoSb?.unsubscribe();
  }
}
