import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { Fecha } from "../../gestion-monto/models/Fecha";
import { Pedido } from "./Pedido";
import { IRespuestaReseller } from "src/app/shared/services/models/IRespuestaReseller";

export class Ventas implements AuditoriaSet{
    auto_numerico:number=0;
    fecha_key:Fecha = new Fecha();    
    pedido?:Pedido = new Pedido();
    usuario_creacion: any;
    comprobante_electronico?: IRespuestaReseller;
    usuario_modificacion?: any;
    fecha_creacion?: any;
    fecha_modificacion?: any;
    es_borrado: boolean=false;
    es_vigente: boolean=true;
    id_turno?:string;
    //
    id?:string;
}