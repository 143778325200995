import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { MediosPago } from "../../procesos/gestion-medios-pago/models/MediosPago";
import { Fecha } from "../../gestion-monto/models/Fecha";

export class PagoCuenta{
      monto:number | null=null;
      tipo_pago: MediosPago= new MediosPago();
      comentario?:string | null;           
      usuario_creacion: any;
      usuario_creacion_busqueda:string='';      
      fecha_key: Fecha = new Fecha();
      exedente?:number;
}