<div style="max-width: 300px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div mat-dialog-title class="dialog-title">Mesas Ocupadas
        </div>        
        <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"></app-button>
    </div>
    <mat-divider></mat-divider>
    <div style="margin-top: 10px;">
        <span>Seleccione la mesa:</span>
    </div>    
    <div style="margin-top: 5px;margin-bottom: 5px;">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <mat-form-field style="min-width:250px">
            <mat-label>Mesa </mat-label>
            <mat-select [(value)]="mesaSel" required>         
              <mat-option *ngFor="let item of listaMesaOcupada" [value]="item.codigo_pedido">
                <div fxLayout="row" >
                    <div  fxLayout="row" *ngFor="let elem of item.lista_mesa; let i=index" >{{elem}}
                        <span *ngIf="i+1< item.lista_mesa.length" >-</span>
                    </div>
                    
                    <span *ngIf="item.comentario_ambiente_mesa_derivado" > -{{item.comentario_ambiente_mesa_derivado}}</span>
                </div>
              </mat-option>          
            </mat-select>  
        </mat-form-field>
    </div>    
</div>
</div>