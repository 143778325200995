import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Icons } from '../../cons/icons';
import { Table } from '../table/table.model';
import { Pedido } from '../registrar-pedido/models/Pedido';
import { Turno } from '../gestion-monto/models/Turno';
import { CriteriosBusqueda, MsjEstado, Msjs, SistOperation, TableTypes, TamanioPagina } from '../../cons/common';
import { Subject, Subscription, of } from 'rxjs';
import { TableService } from '../table/table.service';
import { PedidoBlService } from '../registrar-pedido/services/services.bl/pedido.bl.service';
import { RegistroService } from '../../services/registro.service';
import { ITablePedidosFiltro } from '../registrar-pedido/services/models/ITablePedidosFiltro';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { CBuscar } from '../buscar/CBuscar';
import { IProductoTicket } from '../registrar-pedido/models/IProductoTicket';
import { Utils } from '../../helpers/utils';
import { GestionMontoBlService } from '../gestion-monto/services/gestion-monto.bl.service';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Fecha } from '../gestion-monto/models/Fecha';
import { ITableEvento } from '../table/ITableEvento';
import { FunctionsService } from '../../services/functions.service';
import { UIService } from '../../services/ui.services';
import * as moment from "moment";
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { TipoPagoType } from '../../types/tipo-pago-type';
import { BuscarService } from '../buscar/buscar.service';
import { SnackBarType } from '../../types/snackbar-type';
import { AspectosGenerales } from '../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { IRespuestaReseller } from '../../services/models/IRespuestaReseller';
import { SendPersona } from '../../services/models/sendPersona';
import { IGetAPIRUC } from '../../services/models/IGetAPIRUC';
import { MediaObserver } from '@angular/flex-layout';
@Component({
  selector: 'app-gestion-caja-cobro',
  templateUrl: './gestion-caja-cobro.component.html',
  styleUrls: ['./gestion-caja-cobro.component.scss']
})

@AutoUnsubscribe

export class GestionCajaCobroComponent implements OnInit {
  tipoTabla: string = TableTypes.LISTAR_PEDIDOS_CAJA;
  readonly TableTypes: typeof TableTypes = TableTypes;

  tieneTurnoAperturado: boolean = false;
  isLoading: boolean = false;
  isExpanded: boolean = false;
  readonly Icons: typeof Icons = Icons;
  listadoMesas: number[] = new Array();
  dataTable: Table = new Table();
  pedidos: Pedido[] = [];
  turno: Turno = new Turno();
  listaPedidoSb!: Subscription;  
  buscar: CBuscar = new CBuscar();
  turnoSb!:Subscription;
  btnImprimirSb!: Subscription;
  btnPagoTotalSb!: Subscription;
  btnPagoParcialSb!:Subscription;
  btnDividirCuentaSb!:Subscription;

  //impresion
  empresa!: any;
  logoEmpresa!: string;
  usuarioImprimir!: string;
  pedidoImprimir: Pedido = new Pedido();
  totalProductoImprimir: number = 0;
  totalItemsImprimir: number = 0;
  listaProductoPedido!: IProductoTicket[];
  listaMesasImprimir: string = '';
  esAdministrador: boolean = false;
  fechaHoy: Fecha = new Fecha();
  private unsubscribe$ = new Subject<void>();
  btnVerSb!:Subscription;
  btnBuscarSb!: Subscription;

  //print boleta
  aspectosGenerales = new AspectosGenerales();
  tipoComprobante:number=0;
  showPrintSubs!:Subscription;
  printComprobanteSb!:Subscription;
  result!:IRespuestaReseller;
  usuarioSend: SendPersona = new SendPersona();
  rucResponse: IGetAPIRUC | null=null;
  esPantallaXs: boolean = false;
  mediaSub!: Subscription
  tamanioPantalla: string = '';

  constructor(    
    private tableService: TableService,
    private pedidoBlService: PedidoBlService,
    private registroService: RegistroService,
    private spinnerPageService: SpinnerPageService,
    private gestionMontoBLService: GestionMontoBlService,
    private cdRef: ChangeDetectorRef,
    private functionService: FunctionsService,
    private uiService: UIService,
    private buscarService: BuscarService,
    private mediaObserver: MediaObserver,
  ) { 
      //definimos las propiedades de la tabla
      this.dataTable.modoXs = ['numeracion', 'codigo_pedido', 'estado_pedido_vigente','mesas_asignado','operaciones'];
      this.dataTable.modoM = ['numeracion', 'codigo_pedido', 'estado_pedido_vigente', 'total_productos_pedidos','fecha_registro','mesas_asignado', 'descuento','incidencia','pedido_incidencia','operaciones'];
      this.dataTable.modoLg = ['numeracion', 'codigo_pedido', 'estado_pedido_vigente', 'total_productos_pedidos','mesas_asignado','total_string','fecha_registro', 'usuario_registro','descuento','incidencia','pedido_incidencia', 'operaciones'];
      this.dataTable.columnaEstado = 'estado_pedido_vigente';
      this.dataTable.columnaImagen = '';
      this.dataTable.columnaEstiloEstado = 'estado_pedido_vigente';
      this.dataTable.pageSizeOptions = TamanioPagina.LISTA_TAMANIOS
      this.dataTable.pageSize =  TamanioPagina.LISTA_TAMANIOS[10];// tamaño de pagina
      this.dataTable.currentPage = 1; //pagina inicial
      this.dataTable.nextPage = null;
      this.dataTable.previousPage = null;
      this.dataTable.columnaChip = undefined;
      this.dataTable.operaciones = [SistOperation.DIVIDIR_CUENTA, SistOperation.PAGO_PARCIAL, SistOperation.PAGO_TOTAL,SistOperation.VER];
      this.dataTable.filtro[0] = null; //filtro codigo
      this.dataTable.filtro[1] = null;
      this.dataTable.filtro[2] = null; //filtro usuario
      //this.dataTable.filtro[3] = null; //filtro mesas
      this.dataTable.filtro[4] = null; //filtro estadoPedidoVigenteFiltro
      this.dataTable.minPage = null;//cambia ordenamiendo descendente
      this.dataTable.maxPage = true;//cambia ordenamiendo ascendente

  }

  ngOnInit(): void {
    // Suscribirse a los cambios en el tamaño de pantalla
    this.mediaSub = this.mediaObserver.asObservable().subscribe((changes) => {
      this.tamanioPantalla = changes[0].mqAlias;
      this.redimensionaPantalla();
    });

    this.spinnerPageService.show();
    //solo para impresion
    if (this.registroService.empresa && this.registroService.usuario) {
      this.empresa = this.registroService.empresa;
      if(this.empresa.logo_empresa){
        this.logoEmpresa = Utils.ObjecToListToArray(this.empresa?.logo_empresa)[0].path_image;
      }      
      this.usuarioImprimir = this.registroService.usuario.correo_electronico;
    }
    this.cargarBusqueda();

    if (this.registroService.empresa) {
      this.esAdministrador = this.registroService.esAdministrador;
        
      this.dataTable.usuarioAdministrador = this.registroService.esAdministrador;
      this.isLoading = true;
      //consultando existencia de turno
      this.gestionMontoBLService.existFetchTurno();
      this.turnoSb = this.gestionMontoBLService
        .getTurno()
        .pipe(filter((turno) => turno !== undefined))
        .subscribe((turno) => {
          if (turno && turno.id) {
            this.isLoading = false;
            this.tieneTurnoAperturado = true;
            this.turno = turno;
            //generando la tabla de pedidos que pertenecen al turno
            this.generarTabla(turno.id);
          } else {
            this.isLoading = false;
            this.tieneTurnoAperturado = false;
          }
        });
    }

    //boton ver pedido
    this.btnVerSb = this.tableService.btnVer$.subscribe(
      (callEvento: ITableEvento) => {
        if (this.tipoTabla == callEvento.key) {
          const pedido = callEvento.data as Pedido;
          if (this.turno.id && pedido.id) {
            this.spinnerPageService.show();
            this.uiService.showDialoVerPedido({
              dato: pedido,
              turno: this.turno,
            });
          }
        }
      }
    );

    this.btnPagoTotalSb = this.tableService.btnPagoTotal$.subscribe(
      (callEvento: ITableEvento) => {
        if (this.tipoTabla == callEvento.key) {
          const pedido = callEvento.data as Pedido;
          if (this.turno.id && pedido.id) {
            this.spinnerPageService.show();
            this.uiService.showDialogCajaCobro({
              dato: pedido,
              turno: this.turno,
              es_pago_total: true,
              tipo_pago: TipoPagoType.TOTAL,
            });
          }
        }
      }
    );

    this.btnPagoParcialSb = this.tableService.btnPagoParcial$.subscribe(
      (callEvento: ITableEvento) => {
        if (this.tipoTabla == callEvento.key) {
          const pedido = callEvento.data as Pedido;         
          if (this.turno.id && pedido.id) {
            this.spinnerPageService.show();
            this.uiService.showDialogGestionPagoParcial({
              dato: pedido,
              turno: this.turno,
              es_pago_total: false,
            });
          }
        }
      }
    );

    this.btnDividirCuentaSb = this.tableService.btnPagoDividirCuenta$.subscribe(
      (callEvento: ITableEvento) => {
        if (this.tipoTabla == callEvento.key) {
          if(callEvento.data){
            const filaPedido = callEvento.data as Pedido
            if(filaPedido.es_pedido_incidencia){
              this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs.MSJ_ERROR_DIVIDIR_PEDIDO_INCIDENCIA,3000);
              return;
            }
            if(filaPedido.es_ambiente_mesa_derivado!=undefined && filaPedido.es_ambiente_mesa_derivado==true){
              this.uiService.ShowSnackBar(
                SnackBarType.ERROR,
                Msjs.MSJ_ERROR_DIVIDIR_PEDIDO,
                5000
              );
              return;
            }
          }
          const pedido = callEvento.data as Pedido;
          if (this.turno.id && pedido.id) {
            this.spinnerPageService.show();
            this.uiService.showDialogTraspasoProductos({dato:pedido, turno:this.turno, esDividir:true});
          }
        }
      }
    );

    //recibe los parametros de retorno desde el dialog de emision-boleta-venta
    this.showPrintSubs= this.uiService.printComprobanteSendParams$.subscribe(parametros=>{
      if(parametros){
        this.tipoComprobante = Number(parametros.tipoComprobante);
        if(this.tipoComprobante==1){
          this.pedidoImprimir = parametros.pedido;
          this.listaProductoPedido = parametros.listaProductoPedido;
          this.listaMesasImprimir = parametros.listaMesasImprimir;
          this.aspectosGenerales = parametros.aspectosGenerales as AspectosGenerales;
          this.fechaHoy = parametros.fechaHoy;       
          this.tipoComprobante = parametros.tipoComprobante;
          this.result = parametros.result as IRespuestaReseller;
          this.usuarioSend = parametros.usuarioSend;   
        }else if(this.tipoComprobante==2){
          this.pedidoImprimir = parametros.pedido;
          this.listaProductoPedido = parametros.listaProductoPedido;
          this.listaMesasImprimir = parametros.listaMesasImprimir;
          this.aspectosGenerales = parametros.aspectosGenerales as AspectosGenerales;
          this.fechaHoy = parametros.fechaHoy;       
          this.tipoComprobante = parametros.tipoComprobante;
          this.result = parametros.result as IRespuestaReseller;
          this.rucResponse = parametros.rucResponse as IGetAPIRUC; 
        }           
        this.cdRef.detectChanges();        
      }
    });

    //viene desde emision-comprobante-electronico
    this.printComprobanteSb = this.uiService.printComprobante$.subscribe(flag=>{
      //decide si imprime o no comprobante
      if(flag){
        this.uiService.printComanda$.next(this.tipoTabla);
      }        
    });

    this.btnImprimirSb = this.tableService.btnImprimir$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((callEvento: ITableEvento) => {
          if (
            this.tipoTabla === callEvento.key &&
            this.turno.id &&
            callEvento.data.id
          ) {
            this.pedidoImprimir = callEvento.data as Pedido;
            this.spinnerPageService.show();
            return this.pedidoBlService.getProductosXPedido(
              this.turno.id,
              callEvento.data.id
            );
          } else {
            // Si las condiciones no se cumplen, devuelve un observable que no emite nada.
            return of(null);
          }
        })
      )
      .subscribe(async (listaProds) => {
        this.spinnerPageService.hide();
        if (listaProds) {
          this.listaProductoPedido = JSON.parse(JSON.stringify(listaProds));
          this.totalProductoImprimir = this.listaProductoPedido.reduce((acc, prod) => acc + prod.cantidad_producto,0) || 0;
          this.totalItemsImprimir = this.listaProductoPedido.length;
          this.tipoComprobante = 3 //////cambiar por types
          this.listaMesasImprimir = Utils.generarMesasFila(this.pedidoImprimir.mesas_completo);
          await this.cargarFechas();
          this.cdRef.detectChanges();
          this.uiService.printComanda$.next(this.tipoTabla);
        }
      });

    //busqueda
    this.btnBuscarSb = this.buscarService.goBuscar$.subscribe(
      (callEvento: ITableEvento) => {
        if (this.tipoTabla == callEvento.key) {
          this.dataTable.filtro[0] = null; //filtro codigo
          //this.dataTable.filtro[1] = null; //filtro estado
          this.dataTable.filtro[2] = null; //filtro usuario

          this.dataTable.minPage = null;
          this.dataTable.maxPage = null;
          this.dataTable.nextPage = null;
          this.dataTable.previousPage = null;
          this.dataTable.currentPage = 1;
          this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[1]; //tamaño de pagina
          const data: CBuscar = callEvento.data;

          switch (data.criterio_seleccionado) {
            case CriteriosBusqueda.codigo: {
              this.dataTable.filtro[0] = data.valor_criterio; //filtro codigo
              break;
            }
            case CriteriosBusqueda.estado: {
              this.dataTable.filtro[1] =
                data.estado_seleccionado == MsjEstado.ABIERTO ? true : false; //CODIGO estado
              break;
            }
            case CriteriosBusqueda.usuario_creacion: {
              this.dataTable.filtro[2] = data.valor_criterio; // usuario
              break;
            }
            // case CriteriosBusqueda.mesa: {
            //   this.dataTable.filtro[3] = data.valor_criterio;// mesas
            //   break;
            // }
            default: {
              this.dataTable.filtro[0] = null; //filtro codigo
              this.dataTable.filtro[1] = null; //filtro estado
              this.dataTable.filtro[2] = null; //filtro usuario
              this.dataTable.filtro[3] = null; //filtro mesas
              this.dataTable.filtro[4] = null; //filtro estadoPedidoVigenteFiltro
            }
          }

          if (this.turno.id) {
            this.generarTabla(this.turno.id);
          }
        }
      }
    );
  }
  redimensionaPantalla() {
    if (this.tamanioPantalla === 'xs') {
      this.esPantallaXs = true;
    } else {
      this.esPantallaXs = false;
    }
  }


  async cargarFechas() {
    const fechaServer = moment(await this.functionService.GetDateServer()).local().format();
    const fechaServerProcesado = Utils.separarFechasHoras(fechaServer);
    this.fechaHoy.anio = fechaServerProcesado[0];//anio
    this.fechaHoy.mes = fechaServerProcesado[1];//mes
    this.fechaHoy.dia = fechaServerProcesado[2];//dia
    this.fechaHoy.hora = fechaServerProcesado[3];//hora
    this.fechaHoy.minuto = fechaServerProcesado[4];//minuto
    this.fechaHoy.segundo = fechaServerProcesado[5];//seg  
  }


  restablecerEstado(){
    this.dataTable.filtro[0] = null;
    this.dataTable.filtro[1] = null;
    this.dataTable.filtro[2] = null;
    this.dataTable.filtro[3] = null;
    this.dataTable.filtro[4] = null;   
    if(this.turno.id){
      this.generarTabla(this.turno.id);
    }
    this.isExpanded = false;
  }


  panelExpandedChange(event: any) {
    this.isExpanded = event;
  }
  
  cargarBusqueda() {
    this.buscar.lista_criterio = [CriteriosBusqueda.codigo];

    if (this.registroService.usuario && this.registroService.esAdministrador) {
      this.buscar.lista_criterio.push(CriteriosBusqueda.usuario_creacion);
    }  
    
    this.buscar.key = this.tipoTabla;
    this.buscar.lista_estado = [MsjEstado.ABIERTO, MsjEstado.CERRADO];
  }

  seleccionarMesa(mesa:number){
    this.dataTable.filtro[0] = null;
    this.dataTable.filtro[1] = null;
    this.dataTable.filtro[2] = null;
    this.dataTable.filtro[3] = mesa.toString();
    this.dataTable.filtro[4] = null;
   
    if(this.turno.id){
      this.generarTabla(this.turno.id);
    }    
  }

  generarTabla(idTurno:string){
    this.listaPedidoSb?.unsubscribe();
    this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: true });//loading tabla
    const tablaFiltro: ITablePedidosFiltro={
      codigoFiltro: this.dataTable.filtro[0], //codigo
      estadoFiltro: this.dataTable.filtro[1], //estado
      usuarioFiltro: this.dataTable.filtro[2], //usuario
      mesas: this.dataTable.filtro[3], //mesas
      estadoPedidoVigenteFiltro: this.dataTable.filtro[4], //estadoPedidoVigenteFiltro
      minPage: this.dataTable.minPage,
      maxPage: this.dataTable.maxPage,

    }

    const esPagar:boolean = true;

    this.listaPedidoSb = this.pedidoBlService.getPedidos(
      this.registroService.empresa.id,
      tablaFiltro,
      idTurno,
      false,
      esPagar,
      false,//esIncidencia
      false,//esIndividualizado

    ).subscribe((pedidos: Pedido[] |null)=>{
      if(pedidos!=null && pedidos.length>0 && pedidos[0].codigo_pedido!= '' ){
        this.dataTable.data =[];
        this.dataTable.data = [...pedidos];
      }else{
        this.dataTable.data = [];
      }
      //genera filtro mesas
      this.listadoMesas = new Array();
      if(pedidos!=null){
        pedidos.forEach(pedido=>{
          pedido.mesas_completo.forEach(mesas=>{
            // solo para mesas existentes
            if( !isNaN(parseFloat(mesas)) && isFinite(Number(mesas))){
              this.listadoMesas.push(Number(mesas));
            }
            
          });
        });
      }

      this.listadoMesas = Array.from(new Set(this.listadoMesas));
      this.listadoMesas.sort((a, b) => a - b); //ordenamos las mesas


      this.tableService.loadDataTable$.next(this.tipoTabla);//refrescamos el objeto tabla
      this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: false });//loading tabla

      this.spinnerPageService.hide();//cerrado del ngOnInit
    });

  }

}
