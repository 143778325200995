import { Component, OnInit } from '@angular/core';
import { IIndicadorResumen } from '../indicador-resumen/models/IIndicadorResumen';
import { Icons } from '../../cons/icons';
import { VentaCerradaBlService } from '../procesos/services/venta-cerrada-bl.service';
import { EMPTY, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { RegistroService } from '../../services/registro.service';
import { concatMap, filter } from 'rxjs/operators';
import { GestionMontoBlService } from '../gestion-monto/services/gestion-monto.bl.service';
import { Ventas } from '../registrar-pedido/models/Ventas';
import { MediosPagoBlService } from '../procesos/gestion-medios-pago/services/medios-pago.bl.service';
import { MediosAceptados } from '../procesos/gestion-medios-pago/models/MediosAceptados';
import { ListaMediosPago } from '../../cons/common';
import { GestionAspectosBlService } from '../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { AspectosGenerales } from '../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { Turno } from '../gestion-monto/models/Turno';
import { SolicitarCambioType } from '../../types/solicitar-cambio-type';
import { ProcesoType } from '../../types/proceso-type';

@Component({
  selector: 'app-estado-caja',
  templateUrl: './estado-caja.component.html',
  styleUrls: ['./estado-caja.component.scss'],
})
@AutoUnsubscribe
export class EstadoCajaComponent implements OnInit {
  listaIndicadoresEconomicos: IIndicadorResumen[] = [];
  listaIndicadoresCantidad: IIndicadorResumen[] = [];
  TiposIndicadoresCantidad: any[] = [
    { title: 'Cantidad de ventas', icon: Icons.ALARM_SMART_WAKE },
    { title: 'Cantidad de Dsctos', icon: Icons.ARROW_SPLIT },
    { title: 'Cantidad de Incidencias', icon: Icons.ARROW_RIGHT },
  ];
  ventasSb!: Subscription;
  gestionMontoSb!: Subscription;
  mediosSb!: Subscription;
  mediosAceptados: MediosAceptados | null = null;
  readonly ListaMediosPago: typeof ListaMediosPago = ListaMediosPago;
  isLoading: boolean = false;
  configTasasSb!:Subscription;
  aspectosGenerales: AspectosGenerales = new AspectosGenerales();
  turno:Turno = new Turno();
  totalEfectivoAnadido:number=0;
  totalEfectivoRetirado:number=0;
  totalEfectivoDisponibleCaja:number =0;
  readonly SolicitarCambioType: typeof SolicitarCambioType = SolicitarCambioType;
  readonly ProcesoType: typeof ProcesoType = ProcesoType;
  
  constructor(
    private ventaCerrada: VentaCerradaBlService,
    private registroService: RegistroService,
    private gestionMontoBlService: GestionMontoBlService,
    private mediosBLService: MediosPagoBlService,
    private gestionAspectosBL: GestionAspectosBlService,
  ) {}

  ngOnInit(): void {
    if (this.registroService.empresa) {
      this.isLoading = true;
      this.cargarTotales();
    }
  }

  cargarTotales(){
  this.limpiarVariables();
  //recuperar aspectos generales    
  this.gestionAspectosBL.existFetchTasas();                
  this.configTasasSb?.unsubscribe();
  this.configTasasSb= this.gestionAspectosBL.getTasas().pipe(filter((aspectos)=>aspectos!==undefined)).subscribe(asp=>{
    if(asp){
        this.aspectosGenerales = asp;        
    }
  });
  this.gestionMontoBlService.existFetchTurno();
  this.gestionMontoSb?.unsubscribe();
  this.gestionMontoSb = this.gestionMontoBlService.getTurno().subscribe((turn) => {
    if (turn) {
      this.turno = turn;    

      if(this.turno.montos_aniadidos && this.turno.montos_aniadidos.length>0){
        const totalesAnadidos= this.turno.montos_aniadidos!.filter(item=>
          item.estado_cambio===SolicitarCambioType.APROBADO &&
          item.proceso===ProcesoType.CAMBIAR_ANADIR_MONTO &&
          item.es_vigente
          );
        this.totalEfectivoAnadido=0;
        totalesAnadidos.forEach(monto=>{                
          this.totalEfectivoAnadido+= Number(monto.monto);
        });
      }
      if(this.turno.montos_retirados && this.turno.montos_retirados.length>0){
        const totalesRetirados= this.turno.montos_retirados!.filter(item=>
          item.estado_cambio===SolicitarCambioType.APROBADO &&
          item.proceso===ProcesoType.CAMBIAR_RETIRAR_MONTO &&
          item.es_vigente
          );
        this.totalEfectivoRetirado=0;
        totalesRetirados.forEach(monto=>{
          this.totalEfectivoRetirado+= Number(monto.monto);
        });
      }
      this.getTotalEfectivo();

      //recuperando ventas      
      this.ventasSb?.unsubscribe();
      this.ventasSb = this.ventaCerrada.getVentas(this.turno.id!).pipe(filter((ventas) => ventas !== undefined)).subscribe((ventas) => {
        this.isLoading = false;
        if (ventas) {
          //recuperando medios pago
          this.mediosBLService.existFecthMedios();          
          this.mediosSb?.unsubscribe();
          this.mediosSb = this.mediosBLService.getTasas().subscribe((configAmb) => {
              if (configAmb) {
                this.mediosAceptados = configAmb;
                this.calcularIndicadoresEconomicos(this.mediosAceptados!, ventas);                       
              }
          });
          
        }else{
          this.limpiarVariables();
        }   
      });
      
    }
    else{
      this.limpiarVariables();      
      this.ventasSb?.unsubscribe();
    }
  });  
  }

  limpiarVariables(){
    this.totalEfectivoDisponibleCaja=0;
    this.listaIndicadoresEconomicos=[];
    this.listaIndicadoresCantidad=[];
    this.totalEfectivoAnadido=0;
    this.totalEfectivoRetirado=0;
    this.totalEfectivoDisponibleCaja =0;
    this.turno = new Turno();
  }

  getTotalEfectivo(){
    this.totalEfectivoDisponibleCaja=0;
    const inicial = Number(this.turno!.monto_inicial_efectivo) || 0;
    const anadido = Number(this.totalEfectivoAnadido) || 0;
    const retirado = Number(this.totalEfectivoRetirado) || 0;
    this.totalEfectivoDisponibleCaja = (inicial+anadido)-retirado;    
  }


  calcularIndicadoresEconomicos(
    mediosAceptados: MediosAceptados,
    listaVentas: Ventas[]
  ) {
    this.listaIndicadoresEconomicos = [];
    this.listaIndicadoresCantidad = [];
    let tEfectivoDisponible: IIndicadorResumen = {
      title: 'Efectivo Disponible',
      icon: Icons.POINT_OF_SALE,
      qyt: 0,
      activo: true,
      isGray:false
    };
    let tVentas: IIndicadorResumen = {
      title: 'Total Acum. Venta',
      icon: Icons.SAVINGS,
      qyt: 0,
      activo: true,
      isGray:false
    };
    let tEfectivo: IIndicadorResumen = {
      title: 'Efectivo Ventas',
      icon: Icons.PAYMENTS,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let tTarjeta: IIndicadorResumen = {
      title: 'Tarjetas Ventas',
      icon: Icons.CREDIT_CARD,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let tTransferencia: IIndicadorResumen = {
      title: 'Transferencia Ventas',
      icon: Icons.LOCAL_ATM,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let tYape: IIndicadorResumen = {
      title: 'Yape Ventas',
      icon: Icons.QR_CODE_2,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let tPlin: IIndicadorResumen = {
      title: 'Plin Ventas',
      icon: Icons.QR_CODE_SCANNER,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let tPropina: IIndicadorResumen = {
      title: 'Propina',
      icon: Icons.PAID,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let tServicio: IIndicadorResumen = {
      title: 'Servicio',
      icon: Icons.SENTIMENT_NEUTRAL,
      qyt: 0,
      activo: false,
      isGray:true
    };

    //cuantificadores
    let cVentas: IIndicadorResumen = {
      title: 'Cantidad de Ventas',
      icon: Icons.FORMAT_LIST_NUMBERED,
      qyt: 0,
      activo: false,
      isGray:false
    };
    let cDscto: IIndicadorResumen = {
      title: 'Cantidad de Dsctos',
      icon: Icons.PERCENT,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let cIncidencia: IIndicadorResumen = {
      title: 'Cantidad de Incidencias',
      icon: Icons.INFO,
      qyt: 0,
      activo: false,
      isGray:true
    };


    listaVentas.forEach((ventaX) => {
      //no se consideran pedidos anulados
      if(ventaX.pedido!.es_pedido_anulado){
        return;
      }
      ventaX.pedido!.lista_pago_total!.forEach((pago) => {        
        switch (pago.tipo_pago.codigo_medio) {
          //efectivo
          case ListaMediosPago.listaMediosPago[0].codigo_medio: {
            if(pago.exedente!=undefined){
              tEfectivo.qyt += Number(pago.monto!) -(Number(pago.exedente)*-1);
              tVentas.qyt += Number(pago.monto!) - (Number(pago.exedente)*-1);
            }else{
              tEfectivo.qyt += Number(pago.monto!);
              tVentas.qyt += Number(pago.monto!);
            }
            
            tEfectivo.activo = true;
            break;
          }
          //tarjeta
          case ListaMediosPago.listaMediosPago[1].codigo_medio: {
            tTarjeta.qyt += Number(pago.monto!);
            tVentas.qyt += Number(pago.monto!);
            tTarjeta.activo = true;
            break;
          }
          //transferencia
          case ListaMediosPago.listaMediosPago[2].codigo_medio: {
            tTransferencia.qyt += Number(pago.monto!);
            tVentas.qyt += Number(pago.monto!);
            tTransferencia.activo = true;
            break;
          }
          //yape
          case ListaMediosPago.listaMediosPago[3].codigo_medio: {
            tYape.qyt += Number(pago.monto!);
            tVentas.qyt += Number(pago.monto!);
            tYape.activo = true;
            break;
          }
          //plin
          case ListaMediosPago.listaMediosPago[4].codigo_medio: {
            tPlin.qyt += Number(pago.monto!);
            tVentas.qyt += Number(pago.monto!);
            tPlin.activo = true;
            break;
          }
          //propina
          case ListaMediosPago.listaMediosPago[5].codigo_medio: {
            tPropina.qyt += Number(pago.monto!);
            tPropina.activo = true;
            break;
          }
          default: {
          }
        }
      });
      if(ventaX.pedido!.servicio_total){
        tServicio.qyt+= ventaX.pedido!.servicio_total;
        tServicio.activo = true;
      }
      //indicadores cuantificados
      if(ventaX.pedido?.tiene_descuento){
        cDscto.qyt +=1
      }
      if(ventaX.pedido?.tiene_incidencia || ventaX.pedido?.es_pedido_incidencia){
        cIncidencia.qyt +=1
      }
      
    });
    this.mediosAceptados?.lista_medios_pago.forEach(medio=>{
      switch (medio.codigo_medio) {
        //efectivo
        case ListaMediosPago.listaMediosPago[0].codigo_medio: {          
          tEfectivo.activo = true;
          break;
        }
        //tarjeta
        case ListaMediosPago.listaMediosPago[1].codigo_medio: {          
          tTarjeta.activo = true;
          break;
        }
        //transferencia
        case ListaMediosPago.listaMediosPago[2].codigo_medio: {          
          tTransferencia.activo = true;
          break;
        }
        //yape
        case ListaMediosPago.listaMediosPago[3].codigo_medio: {          
          tYape.activo = true;
          break;
        }
        //plin
        case ListaMediosPago.listaMediosPago[4].codigo_medio: {          
          tPlin.activo = true;
          break;
        }
        //propina
        case ListaMediosPago.listaMediosPago[5].codigo_medio: {          
          tPropina.activo = true;
          break;
        }
        default: {
        }
      }
    });

    tEfectivoDisponible.qyt = (tEfectivo.qyt+this.totalEfectivoDisponibleCaja);
    this.listaIndicadoresEconomicos.push(tEfectivoDisponible);
    if (tVentas.activo) this.listaIndicadoresEconomicos.push(tVentas);
    if (tEfectivo.activo) this.listaIndicadoresEconomicos.push(tEfectivo);
    if (tTarjeta.activo) this.listaIndicadoresEconomicos.push(tTarjeta);
    if (tTransferencia.activo) this.listaIndicadoresEconomicos.push(tTransferencia);
    if (tYape.activo) this.listaIndicadoresEconomicos.push(tYape);
    if (tPlin.activo) this.listaIndicadoresEconomicos.push(tPlin);
    //if (tPropina.activo) this.listaIndicadoresEconomicos.push(tPropina);
    //if (tServicio.activo) this.listaIndicadoresEconomicos.push(tServicio);

    //indicadores cuantificados
    cVentas.qyt = listaVentas.length;
    this.listaIndicadoresCantidad.push(cVentas);
    this.listaIndicadoresCantidad.push(cDscto);
    this.listaIndicadoresCantidad.push(cIncidencia);


  }
}
