<div style="margin-top: 20px;" fxLayout="column" fxLayoutAlign="space-between center">
    <div *ngIf="isLoading">
        <app-spinner></app-spinner>
    </div>

    <div    
    [ngClass.gt-sm]="{'container-gt-sm':true}" 
    [ngClass.lt-sm]="{'container-lt-sm':true}"
    [ngClass.gt-md]="{'container-gt-md':true}"   
        fxLayout="row" fxLayoutAlign="center center" 
        *ngIf="lineChartData.datasets.length==0">
        <span>No se tiene datos.</span>
    </div>

    <div *ngIf="!isLoading" 
        [ngClass.gt-sm]="{'container-gt-sm':true}" 
        [ngClass.lt-sm]="{'container-lt-sm':true}"
        [ngClass.gt-md]="{'container-gt-md':true}" 
        fxLayout="column">

        <div fxLayout="column"  *ngIf="lineChartData.datasets.length>0">
            <div fxLayoutAlign="center center" style="margin-bottom: 10px;" >
                <span>Ventas (Últimos {{cantidadSel}} turnos) </span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">               
                <mat-form-field style="width: 100px;">            
                    <mat-label>Turnos</mat-label>
                    <mat-select (selectionChange)="cambiarDias()"  [(ngModel)]="cantidadSel"  >
                        <ng-container *ngFor="let cant of cantidadDias">
                            <mat-option  [value]="cant" >{{cant}}</mat-option>
                        </ng-container>                        
                    </mat-select>
                </mat-form-field>
                <div  *ngIf="lineChartData.datasets.length>0" fxLayout="column" [matTooltip]="'Promedio de las ventas('+cantidadSel+' turnos)'">
                    <div fxLayout="row">
                        <div>
                            S/&nbsp;
                        </div>
                        <span style="font-size:32pt">{{valorMedio | number:'1.2-2'}}</span>
                    </div>
                  
                    <div style="margin-top: 10px; margin-left: 15px;">
                        <span>Media({{cantidadSel}}&nbsp;turnos)</span>
                    </div>
                    
                </div>
            </div>            
        </div>       
        
        <canvas 
        *ngIf="lineChartData.datasets.length>0"
        baseChart        
        [data]="lineChartData"
        [options]="lineChartOptions"
        [type]="lineChartType"
        (chartHover)="chartHovered($event)"
        (chartClick)="chartClicked($event)">
        </canvas>    
        <div  *ngIf="lineChartData.datasets.length>0" fxLayout="row" fxLayoutAlign="space-around center" style="margin-top: 20px;">
            <div fxLayout="column">
                <div fxLayout="row">
                    <div>
                        S/&nbsp;
                    </div>
                    <span style="font-size:32pt">{{ventaMinima | number:'1.2-2' }}</span>
                </div>
              
                <div style="margin-top: 10px; margin-left: 15px;">
                    <span>
                        Venta Mínima {{listaTurno[indiceVentaMinima].fecha_key.dia+'/'+
                        listaTurno[indiceVentaMinima].fecha_key.mes+'/'+
                        listaTurno[indiceVentaMinima].fecha_key.anio}} 
                        ({{cantidadSel}}&nbsp;turnos)</span>
                </div>
                
            </div>

            <div fxLayout="column">
                <div fxLayout="row">
                    <div>
                        S/&nbsp;
                    </div>
                    <span style="font-size:32pt">{{ventaMaxima | number:'1.2-2'}}</span>
                </div>
              
                <div style="margin-top: 10px; margin-left: 15px;">
                    <span>
                        Venta Máxima {{listaTurno[indiceVentaMaxima].fecha_key.dia+'/'+
                        listaTurno[indiceVentaMaxima].fecha_key.mes+'/'+
                        listaTurno[indiceVentaMaxima].fecha_key.anio}} 
                        ({{cantidadSel}}&nbsp;turnos)</span>
                </div>
                
            </div>
        </div>
    </div>
</div>
