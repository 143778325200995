import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { Icons } from 'src/app/shared/cons/icons';
import { Pedido } from '../../registrar-pedido/models/Pedido';
import { Turno } from '../../gestion-monto/models/Turno';
import { IProductoTicket } from '../../registrar-pedido/models/IProductoTicket';
import { Subscription } from 'rxjs';
import { PedidoBlService } from '../../registrar-pedido/services/services.bl/pedido.bl.service';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { EstadosProductoType } from 'src/app/shared/types/estado-producto-type';
import { blinkAnimation } from 'src/app/shared/animations/animations';
import { AspectosGenerales } from '../../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { GestionAspectosBlService } from '../../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { Length_Database, ListaTipoProducto, Msjs, Msjs_Validations, PatternsRegex, TableTypes } from 'src/app/shared/cons/common';
import { ListarAmbienteMesaService } from '../../listar-ambiente-mesa/listar-ambiente-mesa.service';
import { IAmbienteMesa } from '../../procesos/gestion-ambientes-mesas/models/IAmbienteMesa';
import { Fecha } from '../../gestion-monto/models/Fecha';
import * as moment from "moment";
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { AccionMesaType } from 'src/app/shared/types/accion-mesa-type';
import { NgForm } from '@angular/forms';
import { EstadosMesaType } from 'src/app/shared/types/estado-mesa-type';
import { MediaObserver } from '@angular/flex-layout';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { GestionAmbientesBlService } from '../../procesos/gestion-ambientes-mesas/services/services.bl/gestion-ambientes.bl.service';
import { TipoOperacionProductoCompuesto } from 'src/app/shared/types/tipo-operacion-prod-comp-type';
import { ICaracteristicaProducto } from '../../registrar-pedido/models/CaracteristicaProducto';
import { EstadosProducto } from '../../registrar-pedido/models/EstadoProducto';
import { EstadosPedidoType } from 'src/app/shared/types/estado-pedido-type';
import { EstadoPedido } from '../../registrar-pedido/models/EstadoPedido';
import { ProcesoType } from 'src/app/shared/types/proceso-type';
import { IAmbienteMesaDerivado } from '../../procesos/gestion-ambientes-mesas/models/IAmbienteMesaDerivado';
import { IPedidoMesa } from '../../listar-ambiente-mesa-ocupado-dialog/models/IPedidoMesa';

@Component({
  selector: 'app-traspaso-productos',
  templateUrl: './traspaso-productos.component.html',
  styleUrls: ['./traspaso-productos.component.scss'],
  animations: [blinkAnimation],
})
@AutoUnsubscribe
export class TraspasoProductosComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  listadoMesas:IPedidoMesa[]=[];
  esMesaExistente:boolean = false;
  pedidoOcupado:Pedido = new Pedido();//variable para cargar valoresd e pedido de mesa ocupada
  listaPedidos:Pedido[]=[];
  pedido = new Pedido();
  pedidoTraspaso = new Pedido();
  turno = new Turno();
  isLoading: boolean = false;
  listaProductoPedido: IProductoTicket[] = [];
  listaProductoPedidoTraspaso: IProductoTicket[] = [];
  listaProductoOcupado: IProductoTicket[] = [];
  totalProductos: number = 0;
  totalProductosTraspaso: number = 0;
  idTurno: string = '';
  listaProductoSb!: Subscription;
  readonly EstadosProductoType: typeof EstadosProductoType =
    EstadosProductoType;
  blinkState: string = 'inactive';
  configTasasSb!: Subscription;
  listaProductoOcupadoSb!:Subscription;
  aspectosGenerales!: AspectosGenerales;
  isDisabledMesa: boolean = false;
  tipoTabla: string=TableTypes.TRASPASAR_PEDIDO;
  selecMesaSb = new Subscription();
  fechaHoy: Fecha = new Fecha();
  readonly EstadosMesaType: typeof EstadosMesaType = EstadosMesaType;
  esPantallaXs: boolean = false;
  tamanioPantalla: string = '';
  mediaSub!: Subscription;
  modalSb!:Subscription;
  startY: number = 0;
  endY: number = 0;
  //
  historialVariacionMesaTraspaso:IAmbienteMesa[]=[];
  historialVariacionMesaOriginal:IAmbienteMesa[]=[];
  historialVariacionProductoTraspaso:EstadosProducto[]=[];
  historialVariacionProductoOriginal:EstadosProducto[]=[];
  historialVariacionPedidoOriginal:EstadoPedido = new EstadoPedido();
  historialVariacionPedidoTraspaso:EstadoPedido = new EstadoPedido();
  esDividir: boolean = false;
  comentarioMesaDerivado:string='';

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado: any,
    private dialogRef: MatDialogRef<TraspasoProductosComponent>,
    private readonly spinnerPageService: SpinnerPageService,
    private uiService: UIService,
    private pedidoBLService: PedidoBlService,
    private gestionAspectosBL: GestionAspectosBlService,
    private listarAmbienteMesaService: ListarAmbienteMesaService,
    private functionService: FunctionsService,
    private registroService: RegistroService,
    private mediaObserver: MediaObserver,
    private gestionAmbienteBLService: GestionAmbientesBlService
  ) {}

  ngOnInit() {

    this.pedido = JSON.parse(JSON.stringify(this.datoPasado.dato)) as Pedido;
    this.esDividir = this.datoPasado.esDividir;

    if(this.esDividir){
      //generar pedido derivado
      this.generarPedidoDerivadoDividido();
    }
    else{
      this.listaPedidos = this.datoPasado.lista_pedidos as Pedido[];
    }
  
    this.turno = this.datoPasado.turno as Turno;
    if (this.turno.id && this.pedido.id) {
      this.isLoading = true;
      this.cargarProductosXPedido(this.turno.id, this.pedido.id);
      this.cargarTasas();
    }
    this.totalProductos = 0;
    this.listaProductoPedido = [];
    this.spinnerPageService.hide();


    // Suscribirse a los cambios en el tamaño de pantalla
    this.mediaSub = this.mediaObserver.asObservable().subscribe((changes) => {
      this.tamanioPantalla = changes[0].mqAlias;
      this.redimensionaPantalla();
    });

    this.selecMesaSb = this.listarAmbienteMesaService.seleccionMesa$.subscribe(async (mesaAmbMesaSel) => {
        if (mesaAmbMesaSel.tipoTabla == this.tipoTabla) {          

          //creando nuevo objeto
          let mesaAmbMesa: IAmbienteMesa = JSON.parse(
            JSON.stringify(mesaAmbMesaSel.mesaAmbMesa)
          );

          //deseleccionado la mesa
          if (mesaAmbMesa.mesa_estado == EstadosMesaType.DISPONIBLE) {
            //filtrando la mesa
            const mesaExiste = this.pedidoTraspaso.ambiente_mesa.filter(
              (ambMesa) =>
                (ambMesa.ambiente != mesaAmbMesa.ambiente &&
                  ambMesa.mesa_numero == mesaAmbMesa.mesa_numero) ||
                (ambMesa.ambiente == mesaAmbMesa.ambiente &&
                  ambMesa.mesa_numero !== mesaAmbMesa.mesa_numero)
            );
            this.pedidoTraspaso.ambiente_mesa = mesaExiste;

            if (this.pedidoTraspaso.ambiente_mesa.length == 0) {
              this.pedidoTraspaso = new Pedido();
              this.listaProductoPedidoTraspaso = [];
           
            }          
            return;
          }

          await this.cargarFechas();
          this.generarNuevoPedido();

          //cuando se agrega la nueva mesa
          mesaAmbMesa.fecha_key = {
            anio: this.fechaHoy.anio,
            mes: this.fechaHoy.mes,
            dia: this.fechaHoy.dia,
            hora: this.fechaHoy.hora,
            minuto: this.fechaHoy.minuto,
            segundo: this.fechaHoy.segundo,
          };
          mesaAmbMesa.usuario_creacion =
            this.registroService.usuario.correo_electronico;
          mesaAmbMesa.es_vigente = true;
          mesaAmbMesa.accion_mesa = AccionMesaType.ANIADIR; //cuando añade una mesa
          mesaAmbMesa.auto_numerico = Utils.generaMesHoraAutonumerico(
            this.fechaHoy
          );
          this.pedidoTraspaso.ambiente_mesa.push(mesaAmbMesa);
        }
      }
    );
    //animacion
    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 800); // Cambia el estado cada 500ms  
  }

  async generarPedidoDerivadoDividido(){
    await this.cargarFechas();
    this.generarMesaDerivadaDividido();
    this.generarNuevoPedido();   
  }

  mostrarMesasOcupadas(){
    this.listadoMesas=[];
    this.esDividir = false;
    if(this.listaPedidos.length>0){
      this.listaPedidos.forEach((pedidox:Pedido)=>{
        if(pedidox.codigo_pedido!= this.pedido.codigo_pedido){
          let listaMesas:number[]=[];
          pedidox.ambiente_mesa.forEach(mesa=>{
            listaMesas.push(mesa.mesa_numero);         
          });
          const pedidoMesaElem:IPedidoMesa = {
            codigo_pedido:pedidox.codigo_pedido,
            lista_mesa: listaMesas,
            ...(pedidox.es_ambiente_mesa_derivado ==true &&{comentario_ambiente_mesa_derivado: pedidox.comentario_ambiente_mesa_derivado})          
          }
          this.listadoMesas.push(pedidoMesaElem); 
        }              
      });

      //ordenando bidimensionalmente en base al primer elemento
      this.listadoMesas.forEach(elementoArra=>{
        elementoArra.lista_mesa.sort((a,b)=>a-b);
      });

      const mesaSeleccionado = this.uiService.showDialogMesaOcupada({lista_mesa:this.listadoMesas});
      mesaSeleccionado.afterClosed().subscribe((data)=>{        
        if(data.mesa_seleccionada){
          this.pedidoOcupado = this.listaPedidos.filter(pedido=>pedido.codigo_pedido==data.mesa_seleccionada)[0];
          this.esMesaExistente = true;                   

          //continuar con cargar todas las productos del pedido anterior
          if (this.turno.id && this.pedido.id) {
            this.isLoading = true;
            this.cargarProductosXPedidoOcupado(this.turno.id, this.pedidoOcupado.id!);            
          }

        }
      });
    }

  }

  generarMesaDerivadaDividido(){    
    this.pedidoTraspaso.ambiente_mesa = this.pedido.ambiente_mesa;    
    //se genera la marca de una mesa derivada    
    this.pedidoTraspaso.es_ambiente_mesa_derivado=true;
    
  }

  cargarProductosXPedido(idTurno: string, idPedido: string) {
    this.listaProductoSb?.unsubscribe();
    this.listaProductoSb = this.pedidoBLService.getProductosXPedido(idTurno, idPedido).subscribe((listaProds) => {
        if (listaProds) {
          this.totalProductos = 0;
          this.listaProductoPedido = [];
          this.spinnerPageService.hide();
          this.listaProductoPedido = JSON.parse(JSON.stringify(listaProds));
          this.listaProductoPedido.forEach((prod) => {
            this.totalProductos += prod.cantidad_producto;
          });         
        }
      });
  }

  cargarProductosXPedidoOcupado(idTurno: string, idPedido: string) {
    this.listaProductoOcupadoSb?.unsubscribe();
    this.isLoading = false;
    this.listaProductoOcupadoSb = this.pedidoBLService.getProductosXPedido(idTurno, idPedido).subscribe((listaProds) => {      
        if (listaProds) {
          this.isLoading = false;          
          this.listaProductoOcupado = [];         
          this.listaProductoOcupado = JSON.parse(JSON.stringify(listaProds));                 
        }
      });
  }

  cargarTasas() {
    this.gestionAspectosBL.existFetchTasas();
    this.configTasasSb?.unsubscribe();
    this.configTasasSb = this.gestionAspectosBL.getTasas().subscribe(async (aspGen) => {
        if (aspGen) {
          this.isLoading = false;
          this.aspectosGenerales = aspGen;         
        }
      });
  }

  cambioValorComentario(valor:string){
    this.pedidoTraspaso.mesas_completo = [];
    this.comentarioMesaDerivado = valor;
    this.pedidoTraspaso.comentario_ambiente_mesa_derivado! = valor;    
  }

  calcularTotalTicket() {
    //pedido original
    this.pedido.total = 0;
    this.pedido.sub_total = 0;
    this.pedido.igv = 0;

    //pedido nuevo traspaso a mesa existente
    if(this.esMesaExistente && !this.esDividir ){
      this.pedidoOcupado.total = 0;
      this.pedidoOcupado.sub_total = 0;
      this.pedidoOcupado.igv = 0;
    }
    //pedido traspaso a nueva mesa o dividir mesa
    else{
      this.pedidoTraspaso.total = 0;
      this.pedidoTraspaso.sub_total = 0;
      this.pedidoTraspaso.igv = 0;
    }
    

    //pedido original
    for (const producto of (this.listaProductoPedido ??= [])) {
      producto.precio_total = 0;
      producto.precio_total =
        Number(producto.cantidad_producto) * Number(producto.precio_unitario);
      this.pedido.total += Number(producto.precio_total);
    }
    //pedido traspaso
    for (const producto of (this.listaProductoPedidoTraspaso ??= [])) {
      producto.precio_total = 0;
      producto.precio_total = Number(producto.cantidad_producto) * Number(producto.precio_unitario);
      //pedido nuevo traspaso a mesa existente
      if(this.esMesaExistente && !this.esDividir ){
        this.pedidoOcupado.total+=Number(producto.precio_total);
      }
      //pedido traspaso a nueva mesa o dividir mesa
      else{
        this.pedidoTraspaso.total += Number(producto.precio_total);
      }      
    }
    //añadiendo el monto del producto existente en caso de ser mesa ocupada
    if(this.esMesaExistente && !this.esDividir ){
      this.listaProductoOcupado.forEach(prod=>{
        this.pedidoOcupado.total+=Number(prod.precio_total);
      });
    }

    //calculando el igv pedido original
    const montos = Utils.calcularTotalTicket(
      this.aspectosGenerales.incluir_servicio,
      this.aspectosGenerales.tasa_impuesto,
      this.pedido.total,
      Number(this.aspectosGenerales.tasa_servicio)
    );

    this.pedido.igv = montos[0]; //IGV,
    this.pedido.sub_total = montos[1]; //subTotal
    if (this.aspectosGenerales.incluir_servicio) {
      this.pedido.servicio_total = montos[2]; //tasaServicioCalculado
      this.pedido.servicio_subtotal=montos[3];//subtotal servicio
      this.pedido.servicio_igv=montos[4];
    }    
    this.pedido.total = montos[5]; // pedido redondeado


    //calculando el igv pedido traspasado
    const montosT = Utils.calcularTotalTicket(
      this.aspectosGenerales.incluir_servicio,
      this.aspectosGenerales.tasa_impuesto,
      this.esMesaExistente &&!this.esDividir? this.pedidoOcupado.total : this.pedidoTraspaso.total ,
      Number(this.aspectosGenerales.tasa_servicio)
    );

    //pedido nuevo traspaso a mesa existente
    if(this.esMesaExistente && !this.esDividir ){
      this.pedidoOcupado.igv = montosT[0]; //IGV,
    }
    //pedido traspaso a nueva mesa o dividir mesa
    else{
      this.pedidoTraspaso.igv = montosT[0]; //IGV,
    }
      
    if (this.aspectosGenerales.incluir_servicio) {
        //pedido nuevo traspaso a mesa existente
        if(this.esMesaExistente && !this.esDividir ){
          this.pedidoOcupado.servicio_total = montosT[2]; //tasaServicioCalculado
          this.pedidoOcupado.servicio_subtotal = montosT[3]; //
          this.pedidoOcupado.servicio_igv = montosT[4]; //
        }
        //pedido traspaso a nueva mesa o dividir mesa
        else{
          this.pedidoTraspaso.servicio_total = montosT[2]; //tasaServicioCalculado
          this.pedidoTraspaso.servicio_subtotal = montosT[3]; //tasaServicioCalculado
          this.pedidoTraspaso.servicio_igv = montosT[4]; //tasaServicioCalculado
        }
      
    }
    if(this.esMesaExistente && !this.esDividir ){
      this.pedidoOcupado.sub_total = montosT[1]; //subTotal
      this.pedidoOcupado.total = montosT[5]; // pedido redondeado
    }
    else{
      this.pedidoTraspaso.sub_total = montosT[1]; //subTotal
      this.pedidoTraspaso.total = montosT[5]; // pedido redondeado
    }

  }

  obtenerEstadoPedidoActualizado(listaProducto:IProductoTicket[]){
    const tamanioLista = listaProducto.length;
    let estadoResult: EstadosPedidoType=EstadosPedidoType.PENDIENTE;
    const pendiente = listaProducto.filter(prod=>prod.estado_producto_vigente==EstadosProductoType.PENDIENTE).length;
    const enProceso = listaProducto.filter(prod=>prod.estado_producto_vigente==EstadosProductoType.EN_PROCESO).length;
    const terminado = listaProducto.filter(prod=>prod.estado_producto_vigente==EstadosProductoType.TERMINADO).length;
    const rechazado = listaProducto.filter(prod=>prod.estado_producto_vigente==EstadosProductoType.RECHAZADO).length;
    const anulado = listaProducto.filter(prod=>prod.estado_producto_vigente==EstadosProductoType.ANULADO).length;
    const pendienteRevision = listaProducto.filter(prod=>prod.estado_producto_vigente==EstadosProductoType.PENDIENTE_REVISION).length;
    if(tamanioLista==pendiente){
      estadoResult= EstadosPedidoType.PENDIENTE;
    }
    if(enProceso>=1){
      estadoResult= EstadosPedidoType.EN_PROCESO;
    }
    if(terminado==tamanioLista){
      estadoResult= EstadosPedidoType.TERMINADO;
    }
    if(rechazado==tamanioLista){
      estadoResult= EstadosPedidoType.RECHAZADO;
    }
    if(anulado==tamanioLista){
      estadoResult= EstadosPedidoType.ANULADO;
    }
    if(pendienteRevision>=1){
      estadoResult= EstadosPedidoType.PENDIENTE_REVISION;
    }
    return estadoResult;
  } 

  async onSubmit(f: NgForm) {
    if(f.valid){
      if (this.listaProductoPedidoTraspaso.length > 0 || this.listaProductoPedido.length >= 0) {
        this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon: Icons.QUESTION_MARK}).subscribe(async result=>{
          if(result){

            this.spinnerPageService.show();
            //seteando estados resumen  de bebida y comida en pedido ORIGINAL
            let tieneBebida: boolean = false;
            let tieneComida: Boolean = false;
            this.listaProductoPedido.forEach((producto) => {
              //para producto tipo combo [6]
              if (producto.tipo_producto === ListaTipoProducto.listaTipoProducto[6].codigo_producto) {
                producto.lista_caract_seleccionada?.forEach((caract) => {
                  if ( Utils.getTipoProducto(true).includes(caract.tipo_producto)) {
                    tieneBebida = true;
                  }
                  if ( Utils.getTipoProducto(false).includes(caract.tipo_producto)) {
                    tieneComida = true;
                  }
                });
              }                
              //producto que no es combo
              else {
                if (!tieneBebida) {
                  const tieneBebidaSel = this.listaProductoPedido.filter((prod) =>
                      Utils.getTipoProducto(true).includes(prod.tipo_producto)
                  );
                  if (tieneBebida != undefined && tieneBebidaSel.length > 0) {
                    tieneBebida = true;
                  }
                }
                if (!tieneComida) {
                  const tieneComidaSel = this.listaProductoPedido.filter((prod) =>
                      Utils.getTipoProducto(false).includes(prod.tipo_producto));
                  if (tieneComida != undefined && tieneComidaSel.length > 0) {
                    tieneComida = true;
                  }
                }
              }
            });

            if (tieneBebida) {
              this.pedido.estado_pedido_vigente_bebida = EstadosProductoType.PENDIENTE;
            }
            if (tieneComida) {
              this.pedido.estado_pedido_vigente_comida = EstadosProductoType.PENDIENTE;
            }
            //**/

             //**seteando estados resumen  de bebida y comida en pedido TRASLADO
             let tieneBebidaTraslado: boolean = false;
             let tieneComidaTraslado: Boolean = false;
             this.listaProductoPedidoTraspaso.forEach((producto) => {
               //producto tipo combo
               if (producto.tipo_producto === ListaTipoProducto.listaTipoProducto[6].codigo_producto) 
                 { producto.lista_caract_seleccionada?.forEach((caract) => {
                   if ( Utils.getTipoProducto(true).includes(caract.tipo_producto)) {
                     tieneBebidaTraslado = true;
                   }
                   if ( Utils.getTipoProducto(false).includes( caract.tipo_producto )) {
                     tieneComidaTraslado = true;
                   }
                 });
               }
               //producto no es combo
               else {
                 if (!tieneBebidaTraslado) {
                   const tieneBebidaSel = this.listaProductoPedidoTraspaso.filter((prod) =>
                       Utils.getTipoProducto(true).includes(prod.tipo_producto));

                   if ( tieneBebidaTraslado != undefined && tieneBebidaSel.length > 0 ) {
                     tieneBebidaTraslado = true;
                   }
                 }
                 if (!tieneComidaTraslado) {
                   const tieneComidaSel = this.listaProductoPedidoTraspaso.filter((prod) =>
                       Utils.getTipoProducto(false).includes( prod.tipo_producto )
                     );
                   if ( tieneComidaTraslado != undefined && tieneComidaSel.length > 0
                   ) {
                     tieneComidaTraslado = true;
                   }
                 }
               }
             });


            //mesa existente            
            if(this.esMesaExistente){
              await this.cargarFechas();
              if(this.listaProductoOcupado.length==0){
                this.uiService.ShowSnackBar( SnackBarType.ERROR, Msjs.MSJ_LISTA_PRODUCTO_ERROR, 3000);
                return;
              }
              //determinando estados iniciales de bebida y / o comida, seran actualizados en el acceso a datos
              if (tieneBebidaTraslado) {
                this.pedidoOcupado.estado_pedido_vigente_bebida = EstadosProductoType.PENDIENTE;
              }

              if (tieneComidaTraslado) { 
                this.pedidoOcupado.estado_pedido_vigente_comida = EstadosProductoType.PENDIENTE;
              }
              //**/      

              //generando historial de producto TRASLADO
              this.listaProductoPedidoTraspaso.forEach((producto) => {
                this.historialVariacionProductoTraspaso.push({
                  fecha: this.fechaHoy,
                  usuario_creacion: this.registroService.usuario.correo_electronico,
                  estado_producto: producto.estado_producto_vigente,
                  es_vigente: true,
                  auto_numerico: Utils.generaMesHoraAutonumerico(this.fechaHoy),
                  codigo_ticket: producto.codigo_ticket,
                  cantidad_producto: producto.cantidad_producto,
                  nombre_producto: producto.nombre_producto,
                  codigo_producto: producto.codigo_producto,
                  reducir_cantidad: producto.reducir_cantidad,
                  proceso: this.esDividir? ProcesoType.DIVIDIR_CUENTA_DESTINO: ProcesoType.TRASPASO_PRODUCTO_DESTINO
                });
              });

              //generando historial de producto ORIGINAL
              //SE TOMA EL PEDIDO TRASPASO COMO VARIACION ORIGINAL, YA QUE SERA LO QUE SE VA TRASLADAR
              this.listaProductoPedidoTraspaso.forEach((producto) => {
                this.historialVariacionProductoOriginal.push({
                  fecha: this.fechaHoy,
                  usuario_creacion: this.registroService.usuario.correo_electronico,
                  estado_producto: producto.estado_producto_vigente,
                  es_vigente: true,
                  auto_numerico: Utils.generaMesHoraAutonumerico(this.fechaHoy),
                  codigo_ticket: producto.codigo_ticket,
                  cantidad_producto: producto.cantidad_producto,
                  nombre_producto: producto.nombre_producto,
                  codigo_producto: producto.codigo_producto,
                  reducir_cantidad: producto.reducir_cantidad,
                  proceso: this.esDividir? ProcesoType.DIVIDIR_CUENTA_ORIGEN: ProcesoType.TRASPASO_PRODUCTO_ORIGEN
                });
              });

              //generando historial de pedido TRASLADO
              this.historialVariacionPedidoTraspaso.es_vigente = true;
              this.historialVariacionPedidoTraspaso.estado_pedido = this.obtenerEstadoPedidoActualizado(this.listaProductoPedidoTraspaso); //obtiene su estado del pedido original
              this.historialVariacionPedidoTraspaso.fecha_key = this.fechaHoy;
              this.historialVariacionPedidoTraspaso.usuario_creacion = this.registroService.usuario.correo_electronico;
              this.historialVariacionPedidoTraspaso.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
              this.historialVariacionPedidoTraspaso.proceso =  this.esDividir ? ProcesoType.DIVIDIR_CUENTA_DESTINO : ProcesoType. TRASPASO_PRODUCTO_DESTINO;

              //complementando pedido TRASLADO
              //this.pedidoOcupado.es_vigente = true;
              //el estado producto vigente se llena en el acceso a datos
              //this.pedidoTraspaso.estado_pedido_vigente =  this.historialVariacionPedidoTraspaso.estado_pedido;
              this.pedidoOcupado.total_productos_pedidos =  this.listaProductoPedidoTraspaso.length + this.pedidoOcupado.total_productos_pedidos;                            
              this.pedidoOcupado.proceso = this.esDividir ? ProcesoType.DIVIDIR_CUENTA_DESTINO : ProcesoType.TRASPASO_PRODUCTO_DESTINO;
        
              //generando historial de pedido ORIGINAL
              this.historialVariacionPedidoOriginal.es_vigente = true;
              this.historialVariacionPedidoOriginal.estado_pedido = this.obtenerEstadoPedidoActualizado(this.listaProductoPedido);
              this.historialVariacionPedidoOriginal.fecha_key = this.fechaHoy;
              this.historialVariacionPedidoOriginal.usuario_creacion = this.registroService.usuario.correo_electronico;
              this.historialVariacionPedidoOriginal.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);//anterior this.pedido.fecha_key
              this.historialVariacionPedidoOriginal.proceso =  this.esDividir ? ProcesoType.DIVIDIR_CUENTA_ORIGEN: ProcesoType.TRASPASO_PRODUCTO_ORIGEN;

              this.pedido.proceso =  this.esDividir ? ProcesoType.DIVIDIR_CUENTA_ORIGEN: ProcesoType.TRASPASO_PRODUCTO_ORIGEN;
                        
              //generando historial de mesa TRASLADO
              this.historialVariacionMesaTraspaso = this.pedidoTraspaso.ambiente_mesa;
              this.historialVariacionMesaTraspaso.forEach((mesa) => {
                mesa.proceso = this.esDividir ? ProcesoType.DIVIDIR_CUENTA_DESTINO : ProcesoType.TRASPASO_PRODUCTO_DESTINO;
              });

              //generando historial de mesa ORIGINAL
              this.historialVariacionMesaOriginal = this.pedido.ambiente_mesa;
              this.historialVariacionMesaOriginal.forEach((mesa) => {
                mesa.proceso = this.esDividir ? ProcesoType.DIVIDIR_CUENTA_ORIGEN : ProcesoType.TRASPASO_PRODUCTO_ORIGEN;
              });

              //complementando pedido ORIGINAL
              this.pedido.es_vigente = true;

              //el estado producto vigente se llena en el acceso a datos
              //this.pedido.estado_pedido_vigente = this.historialVariacionPedidoOriginal.estado_pedido;
              this.pedido.total_productos_pedidos = this.listaProductoPedido.length;
              this.pedido.usuario_creacion_busqueda = this.registroService.usuario.correo_electronico.split('@')[0];
              
              //flags de pago TRASLADO
              const pagoParcialTraslado = this.listaProductoPedidoTraspaso.filter((prod) => prod.es_pagado ).length;
              //this.pedidoTraspaso.tiene_pago_total = false;// si tiene pago total, ya no hay traslado
              this.pedidoOcupado.tiene_pago_parcial = pagoParcialTraslado > 0 ? true : false;

              //flags de pago ORIGINAL
              const pagoParcialOriginal = this.listaProductoPedido.filter( (prod) => prod.es_pagado).length;
              //this.pedido.tiene_pago_total = false; // si tiene pago total, ya no hay traslado
              this.pedido.tiene_pago_parcial = pagoParcialOriginal > 0 ? true : false;

            }
            //mesa nueva
            else{
              //determinando estados iniciales de bebida y / o comida, seran actualizados en el acceso a datos
              if (tieneBebidaTraslado) {
                this.pedidoTraspaso.estado_pedido_vigente_bebida = EstadosProductoType.PENDIENTE;
              }

              if (tieneComidaTraslado) { 
                this.pedidoTraspaso.estado_pedido_vigente_comida = EstadosProductoType.PENDIENTE;
              }
              //**/

              //cambiando estado a la mesa TRASLADO
              this.pedidoTraspaso.ambiente_mesa.forEach((ambMesa) => {
                ambMesa.mesa_estado = EstadosMesaType.OCUPADO;
                this.pedido.mesas_completo.push(ambMesa.mesa_numero.toString());
              });

              //generando historial de mesa TRASLADO
              this.historialVariacionMesaTraspaso = JSON.parse(JSON.stringify(this.pedido.ambiente_mesa));
              this.historialVariacionMesaTraspaso.forEach((mesa) => {
                mesa.proceso = this.esDividir ? ProcesoType.DIVIDIR_CUENTA_DESTINO: ProcesoType.TRASPASO_PRODUCTO_DESTINO;
              });
      
              //generando historial de mesa ORIGINAL
              this.historialVariacionMesaOriginal = JSON.parse(JSON.stringify(this.pedido.ambiente_mesa));
              this.historialVariacionMesaOriginal.forEach((mesa) => {
                mesa.proceso = this.esDividir ? ProcesoType.DIVIDIR_CUENTA_ORIGEN : ProcesoType.TRASPASO_PRODUCTO_ORIGEN;
              });
     
              
              //generando historial de producto TRASLADO
              this.listaProductoPedidoTraspaso.forEach((producto) => {
                this.historialVariacionProductoTraspaso.push({
                  fecha: this.fechaHoy,
                  usuario_creacion: this.registroService.usuario.correo_electronico,
                  estado_producto: producto.estado_producto_vigente,
                  es_vigente: true,
                  auto_numerico: Utils.generaMesHoraAutonumerico(this.fechaHoy),
                  codigo_ticket: producto.codigo_ticket,
                  cantidad_producto: producto.cantidad_producto,
                  nombre_producto: producto.nombre_producto,
                  codigo_producto: producto.codigo_producto,
                  reducir_cantidad: producto.reducir_cantidad,
                  proceso: this.esDividir ? ProcesoType.DIVIDIR_CUENTA_DESTINO : ProcesoType.TRASPASO_PRODUCTO_DESTINO,
                });
              });

              //generando historial de producto ORIGINAL
              //SE TOMA EL PEDIDO TRASPASO COMO VARIACION ORIGINAL, YA QUE SERA LO QUE SE VA TRASLADAR
              this.listaProductoPedidoTraspaso.forEach((producto) => {
                this.historialVariacionProductoOriginal.push({
                  fecha: this.fechaHoy,
                  usuario_creacion: this.registroService.usuario.correo_electronico,
                  estado_producto: producto.estado_producto_vigente,
                  es_vigente: true,
                  auto_numerico: Utils.generaMesHoraAutonumerico(this.fechaHoy),
                  codigo_ticket: producto.codigo_ticket,
                  cantidad_producto: producto.cantidad_producto,
                  nombre_producto: producto.nombre_producto,
                  codigo_producto: producto.codigo_producto,
                  reducir_cantidad: producto.reducir_cantidad,
                  proceso: this.esDividir ? ProcesoType.DIVIDIR_CUENTA_ORIGEN : ProcesoType.TRASPASO_PRODUCTO_ORIGEN,
                });
              });

              //generando historial de pedido TRASLADO
              this.historialVariacionPedidoTraspaso.es_vigente = true;
              this.historialVariacionPedidoTraspaso.estado_pedido = this.obtenerEstadoPedidoActualizado(this.listaProductoPedidoTraspaso); //obtiene su estado del pedido original
              this.historialVariacionPedidoTraspaso.fecha_key = this.fechaHoy; // no se pone la misma fecha del pedido original porque es historial
              this.historialVariacionPedidoTraspaso.usuario_creacion = this.registroService.usuario.correo_electronico;
              this.historialVariacionPedidoTraspaso.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
              this.historialVariacionPedidoTraspaso.proceso = this.esDividir? ProcesoType.DIVIDIR_CUENTA_DESTINO : ProcesoType.TRASPASO_PRODUCTO_DESTINO;

              //complementando pedido TRASLADO
              this.pedidoTraspaso.es_vigente = true;
              //el estado producto vigente se llena en el acceso a datos
              //this.pedidoTraspaso.estado_pedido_vigente =  this.historialVariacionPedidoTraspaso.estado_pedido;
              this.pedidoTraspaso.total_productos_pedidos =  this.listaProductoPedidoTraspaso.length;
              this.pedidoTraspaso.usuario_creacion_busqueda = this.registroService.usuario.correo_electronico.split('@')[0];
              this.pedidoTraspaso.auto_numerico =  Utils.generaMesHoraAutonumerico(this.pedido.fecha_key);
              this.pedidoTraspaso.proceso = this.esDividir ? ProcesoType.DIVIDIR_CUENTA_DESTINO : ProcesoType.TRASPASO_PRODUCTO_DESTINO;
              this.pedidoTraspaso.ambiente_mesa.forEach((mesa) => {
                this.pedidoTraspaso.mesas_completo.push(mesa.mesa_numero.toString());
              });

              //generando las mesas_completo
              if (this.esDividir) {
                this.pedidoTraspaso.mesas_completo.push(
                  this.comentarioMesaDerivado
                );
              }

              //generando historial de pedido ORIGINAL
              this.historialVariacionPedidoOriginal.es_vigente = true;
              this.historialVariacionPedidoOriginal.estado_pedido = this.obtenerEstadoPedidoActualizado(this.listaProductoPedido);
              this.historialVariacionPedidoOriginal.fecha_key = this.fechaHoy; // no se pone la misma fecha del pedido original porque es historial 
              this.historialVariacionPedidoOriginal.usuario_creacion = this.registroService.usuario.correo_electronico;
              this.historialVariacionPedidoOriginal.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
              this.historialVariacionPedidoOriginal.proceso = this.esDividir ? ProcesoType.DIVIDIR_CUENTA_ORIGEN : ProcesoType.TRASPASO_PRODUCTO_ORIGEN;

              //complementando pedido ORIGINAL
              this.pedido.es_vigente = true;
              this.pedido.proceso =  this.esDividir ? ProcesoType.DIVIDIR_CUENTA_ORIGEN: ProcesoType.TRASPASO_PRODUCTO_ORIGEN;
              
              //el estado producto vigente se llena en el acceso a datos
              //this.pedido.estado_pedido_vigente = this.historialVariacionPedidoOriginal.estado_pedido;
              this.pedido.total_productos_pedidos = this.listaProductoPedido.length;
              this.pedido.usuario_creacion_busqueda = this.registroService.usuario.correo_electronico.split('@')[0];
              this.pedido.auto_numerico = Utils.generaMesHoraAutonumerico( this.pedido.fecha_key ); // se pone la misma fecha del pedido original para converar posicion atencion

              //flags de pago TRASLADO
              const pagoParcialTraslado =
                this.listaProductoPedidoTraspaso.filter((prod) => prod.es_pagado ).length;
              //this.pedidoTraspaso.tiene_pago_total = false;// si tiene pago total, ya no hay traslado
              this.pedidoTraspaso.tiene_pago_parcial = pagoParcialTraslado > 0 ? true : false;

              //flags de pago ORIGINAL
              const pagoParcialOriginal = this.listaProductoPedido.filter( (prod) => prod.es_pagado).length;
              //this.pedido.tiene_pago_total = false; // si tiene pago total, ya no hay traslado
              this.pedido.tiene_pago_parcial = pagoParcialOriginal > 0 ? true : false;
            }        
                         
            //insertando el pedido traslado
            this.pedidoBLService.insertPedidoTraspaso(
              this.pedidoTraspaso, 
              this.turno.id!, 
              this.listaProductoPedidoTraspaso,
              this.historialVariacionPedidoTraspaso,
              this.historialVariacionMesaTraspaso,
              this.historialVariacionProductoTraspaso,
              this.pedido,
              this.listaProductoPedido,
              this.historialVariacionMesaOriginal,
              this.historialVariacionPedidoOriginal,
              this.historialVariacionProductoOriginal,
              this.esDividir,
              this.esMesaExistente,
              this.pedidoOcupado
            )
              .then((tx) => {
                if (tx.tx) {
                  const msj = ' Pedido: ' + this.pedido.codigo_pedido;
                  this.uiService.ShowSnackBar(
                    SnackBarType.SUCCESS,
                    Msjs.MSJ_GUARDADO_OK + msj,
                    5000
                  );
                  this.spinnerPageService.hide();
                  this.onClose();
                } else {
                  this.uiService.ShowSnackBar(
                    SnackBarType.ERROR,
                    tx.error,
                    3000
                  );
                  this.spinnerPageService.hide();
                }
            });
  
          }else{
            return;
          }
        });
      }
    }   
    else{
      this.uiService.ShowSnackBar(
        SnackBarType.ERROR,
        Msjs.MSJ_ERROR_FORMULARIO,
        3000
      );
    }

  }

  async traspasarProducto(index:number) {
    //validando que un producto para traslado no tenga descuento ni incidencia
    if(this.listaProductoPedido[index].tiene_descuento==true){
      this.uiService.ShowSnackBar(
        SnackBarType.ERROR,
        Msjs.MSJ_SE_TIENE_DSCTO,
        3000
      );
      return;
    }
    if(this.listaProductoPedido[index].tiene_incidencia==true){
      this.uiService.ShowSnackBar(
        SnackBarType.ERROR,
        Msjs.MSJ_SE_TIENE_INCIDENCIA,
        3000
      );
      return;
    }
    //validando que se quede minimo un producto de un tipo de producto
    if(this.listaProductoPedido.length==1 && this.listaProductoPedido[index].cantidad_producto==1){
      this.uiService.ShowSnackBar(
        SnackBarType.ERROR,
        Msjs_Validations.MSJ_MINIMO_CANTIDAD_PRODUCTO,
        3000
      );
      return;
    }
    //validando que se tenga una mesa seleccionada
    if(this.pedidoTraspaso.ambiente_mesa.length==0 && !this.esMesaExistente ){
      this.uiService.ShowSnackBar(
        SnackBarType.ERROR,
        Msjs.MSJ_SIN_MESA,
        3000
      );
      return;
    }
    //validando que no se haya traspasado previamente el producto
    if (this.listaProductoPedidoTraspaso.length > 0) {
      const objOriginalProd = this.listaProductoPedido[index];
      let existe = this.listaProductoPedidoTraspaso.find(x => x.codigo_producto == objOriginalProd.codigo_producto &&
        x.codigo_ticket == objOriginalProd.codigo_ticket)
      if (existe) {
        this.uiService.ShowSnackBar(
          SnackBarType.ERROR,
          Msjs_Validations.MSJ_MINIMO_PRODUCTO_TRASLADADO,
          3000
        );
        return;
      }
    }

    //seleccionando el producto a traspasar
    const elemento = JSON.parse(JSON.stringify(this.listaProductoPedido[index])) as IProductoTicket;

    //si tiene mas de un producto
    if(elemento.cantidad_producto>1){

      //si el producto tiene caracteristicas adicionales
      if(elemento.lista_caract_seleccionada!=undefined){

        //se muestra el modal de producto compuesto
        const prodCompuesto = await this.uiService.showDialogProductoCompuesto({
          producto: elemento,
          tipo_pago: TipoOperacionProductoCompuesto.TRASPASO,
          cantidad_sel:elemento.cantidad_producto,
          esVer:false
        });
        
        //procesamos la respuesta del modal
        prodCompuesto.afterClosed().subscribe((result) => {

          if(result.lista_seleccionados.length==0 && result.esTodo==false){
            this.uiService.ShowSnackBar(
              SnackBarType.ERROR,
              Msjs.MSJ_SIN_PRODUCTO,
              3000
            );
          }
          else{

            //se seleccionaron todos los productos
            if(result.esTodo==true){
              this.listaProductoPedidoTraspaso.push(elemento);
              this.listaProductoPedido.splice(index,1);
              
              this.calcularTotalProductosTodosCantidad();
              this.calcularTotalTicket();
            }
            //se seleccionaron algunos produtos
            else {

              let prodTicketxTraslado: IProductoTicket = JSON.parse(JSON.stringify(elemento)) as IProductoTicket;
              prodTicketxTraslado.cantidad_producto = 0;
              delete prodTicketxTraslado.lista_caract_seleccionada;
              delete prodTicketxTraslado.lista_caract_seleccionada_individualizada;

              let grupo_caracteristicas: ICaracteristicaProducto[] = []
              //seleccionamos las caracteristicas seleccionadas
              result.lista_seleccionados.forEach((codigo: string) => {
                prodTicketxTraslado.cantidad_producto += 1;
                const caracteristicasXGrupo: ICaracteristicaProducto[] = elemento.lista_caract_seleccionada_individualizada?.filter(carac => carac.grupo_producto === codigo)!;
                caracteristicasXGrupo.forEach(objCaract => {
                  grupo_caracteristicas.push(objCaract);
                });
              });
              //igualamos al objeto
              prodTicketxTraslado.lista_caract_seleccionada_individualizada = grupo_caracteristicas;
              //totalizamos las caracteristicas
              prodTicketxTraslado.lista_caract_seleccionada = Utils.totalizadorCaracteristicaProducto(grupo_caracteristicas);


              //seteamos el nuevo producto
              this.listaProductoPedidoTraspaso.push(prodTicketxTraslado);
              //limpiamos el producto original

              let grupo_caracteristicasOrign: ICaracteristicaProducto[] = []
              result.lista_seleccionados.forEach((codigo: string) => {
                this.listaProductoPedido[index].cantidad_producto -= 1;
                const caracteristicasXGrupoOrig: ICaracteristicaProducto[] = this.listaProductoPedido[index].lista_caract_seleccionada_individualizada?.filter(carac => carac.grupo_producto != codigo)!;
                caracteristicasXGrupoOrig.forEach(objCaract => {
                  grupo_caracteristicasOrign.push(objCaract);
                });
              });
              delete this.listaProductoPedido[index].lista_caract_seleccionada_individualizada;
              this.listaProductoPedido[index].lista_caract_seleccionada_individualizada = grupo_caracteristicasOrign;
              this.listaProductoPedido[index].lista_caract_seleccionada = Utils.totalizadorCaracteristicaProducto(grupo_caracteristicasOrign);

              this.calcularTotalProductosTodosCantidad();
              this.calcularTotalTicket();
            }                     
          }
        });

      }

      //si el producto no tiene caracteristicas adicionales
      else{        
        //mostramos el dialogo de producto simple
        const prodSimple = await this.uiService.showDialogProductoSimple({
          producto: elemento,
          tipo_pago: TipoOperacionProductoCompuesto.TRASPASO,
          cantidad_sel: elemento.cantidad_producto
        });

        prodSimple.afterClosed().subscribe((result) => {
          if(result.cantidadSeleccionada==0){
            this.uiService.ShowSnackBar(
              SnackBarType.ERROR,
              Msjs.MSJ_SIN_PRODUCTO,
              3000
            );
          }else{
            //aqui reducimos la cantidad al producto simple
            let nuevoElemento  = JSON.parse(JSON.stringify(elemento)) as IProductoTicket;
            const cantidadRestante = nuevoElemento.cantidad_producto - Number(result.cantidadSeleccionada);
            if(cantidadRestante==0){
              this.listaProductoPedidoTraspaso.push(elemento);
              this.listaProductoPedido.splice(index,1);
              this.calcularTotalProductosTodosCantidad();
              this.calcularTotalTicket();
            }
            else{
              this.listaProductoPedido[index].cantidad_producto = cantidadRestante;
              nuevoElemento.cantidad_producto = result.cantidadSeleccionada;
              this.listaProductoPedidoTraspaso.push(nuevoElemento);
              this.calcularTotalProductosTodosCantidad();
              this.calcularTotalTicket();
            }
          }
        });
      }
    }
    // es solo una unidad de producto, indistitantemente que sea simple o compuesto
    else{
      this.listaProductoPedidoTraspaso.push(elemento);
      this.listaProductoPedido.splice(index,1);      
      this.calcularTotalProductosTodosCantidad();
      this.calcularTotalTicket();
    }
  }

  calcularTotalProductosTodosCantidad(){
    //total de traspaso
    this.totalProductosTraspaso =0;
    this.listaProductoPedidoTraspaso.forEach((prod) => {
      this.totalProductosTraspaso += prod.cantidad_producto;
    });

    //total original
    this.totalProductos =0;
    this.listaProductoPedido.forEach((prod) => {
      this.totalProductos += prod.cantidad_producto;
    });
    
   
  }

  unificarProductoReturn(elemento:IProductoTicket, index:number, indiceReturn:number){
    elemento.lista_caract_seleccionada_individualizada!.forEach(objCaract => {
      this.listaProductoPedido[index].lista_caract_seleccionada_individualizada?.push(objCaract);
    });
    this.listaProductoPedido[index].cantidad_producto+=elemento.cantidad_producto;
    this.listaProductoPedido[index].lista_caract_seleccionada= 
      Utils.totalizadorCaracteristicaProducto(this.listaProductoPedido[index].lista_caract_seleccionada_individualizada!);
    this.listaProductoPedidoTraspaso.splice(indiceReturn,1);
  }

  traspasarProductoReturn(index:number){
    let esProductoCompuesto:boolean = false;
    const elemento = JSON.parse(JSON.stringify(this.listaProductoPedidoTraspaso[index])) as IProductoTicket;
    if(elemento.lista_caract_seleccionada!= undefined){
      esProductoCompuesto = true;
    }
    //buscando producto dentro de un mismo grupo de pedido
    const grupo = this.listaProductoPedido.filter(prod=>prod.codigo_ticket==elemento.codigo_ticket && prod.codigo_producto==elemento.codigo_producto);   
    //existe grupo
    if(grupo!= undefined && grupo.length>0 ){     
      for(let i=0; i< this.listaProductoPedido.length; i++){
        if(this.listaProductoPedido[i].codigo_ticket==elemento.codigo_ticket &&
          this.listaProductoPedido[i].codigo_producto==elemento.codigo_producto
        ){
          if(esProductoCompuesto){
            this.unificarProductoReturn(elemento, i, index);            
          }
          else{
            this.listaProductoPedido[i].cantidad_producto += elemento.cantidad_producto;
            this.listaProductoPedidoTraspaso.splice(index,1);            
          }
         break;
        }
      }  
    }
    //no existe grupo
    else{
      this.listaProductoPedido.push(elemento);
      this.listaProductoPedidoTraspaso.splice(index,1);
    }    
    //calculando el total de proudctos(cantidad)
    this.calcularTotalProductosTodosCantidad();
    this.calcularTotalTicket();
  }

  redimensionaPantalla() {
    if (this.tamanioPantalla === 'xs') {
      this.esPantallaXs = true;
    } else {
      this.esPantallaXs = false;
    }
  }

  liberarMesa(config: IAmbienteMesa){
    let indice: number = -1;
    let mesaAmbiente!: IAmbienteMesa;
    this.pedidoTraspaso.ambiente_mesa.forEach((ambMesa, index) => {
      if (
        ambMesa.ambiente === config.ambiente &&
        ambMesa.mesa_numero === config.mesa_numero
      ) {
        indice = index;
        mesaAmbiente = ambMesa;
        mesaAmbiente.mesa_estado = EstadosMesaType.DISPONIBLE;
      }
    });
    //deselecionar mesa
    
    this.pedidoTraspaso.ambiente_mesa.splice(indice, 1);
    this.gestionAmbienteBLService.updateEstadoMesa(mesaAmbiente);
    if (this.pedidoTraspaso.ambiente_mesa.length == 0) {                    
      this.pedidoTraspaso = new Pedido();
      this.listaProductoPedidoTraspaso = [];
      this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MENSAJE_CONFIRMACION_CONFIRMAR_LIBERAR_MESA, 3000);
    }
  }

  removerMesa(config: IAmbienteMesa) {
    let indice: number = -1;
    let mesaAmbiente!: IAmbienteMesa;
    this.modalSb = this.uiService
      .showModalQuestions({
        title: Msjs.TITULO_CONFIRMACION,
        message: Msjs.MENSAJE_CONFIRMACION_ELIMINAR_MESA,
        icon: Icons.QUESTION_MARK,
      })
      .subscribe((result) => {
        if (result) {        
          this.liberarMesa(config);
        }
      });
  }

  removerMesaOcupado(config: IAmbienteMesa) {
    this.esMesaExistente = false;
    this.pedidoOcupado= new Pedido();
    this.listaProductoPedidoTraspaso = [];
  }

  verPedidoOcupado(){
    this.spinnerPageService.show();
    this.uiService.showDialoVerPedido({dato:this.pedidoOcupado, turno:this.turno});
  }

  generarNuevoPedido(){
    this.pedidoTraspaso.fecha_key = this.pedido.fecha_key;// se pone la misma fecha para garantizar que se atienda en el mismo tiempo
    this.pedidoTraspaso.codigo_pedido = Utils.generarCodigoRandom(6);
    this.pedidoTraspaso.usuario_creacion = this.registroService.usuario.correo_electronico;
    this.pedidoTraspaso.proceso = this.esDividir? ProcesoType.DIVIDIR_CUENTA_DESTINO: ProcesoType.TRASPASO_PRODUCTO_DESTINO
    this.pedidoTraspaso.codigo_pedido_origen = this.pedido.codigo_pedido;
  }

  async cargarFechas() {
    const fechaServer = moment(await this.functionService.GetDateServer()).local().format();
    const fechaServerProcesado = Utils.separarFechasHoras(fechaServer);
    this.fechaHoy.anio = fechaServerProcesado[0]; //anio
    this.fechaHoy.mes = fechaServerProcesado[1]; //mes
    this.fechaHoy.dia = fechaServerProcesado[2]; //dia
    this.fechaHoy.hora = fechaServerProcesado[3]; //hora
    this.fechaHoy.minuto = fechaServerProcesado[4]; //minuto
    this.fechaHoy.segundo = fechaServerProcesado[5]; //seg    
  }
  
  nuevoPedido() {
    this.esMesaExistente = false;
    this.esDividir = false;
    this.uiService.showModalListarAmbienteMesaD({esEditar:false, tipoTabla:this.tipoTabla});
  }

  onClose() {
    this.dialogRef.close();
  }

  Limpiar() {
    this.cancelarPedido();
  }

  cancelarPedido() {
    if (this.pedidoTraspaso.ambiente_mesa.length > 0) {
      for (const config of this.pedidoTraspaso.ambiente_mesa) {
        this.liberarMesa(config);
      }
    }
    this.onClose();
  }

  touchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
  }

  touchMove(event: TouchEvent) {
    this.endY = event.touches[0].clientY;
  }

  touchEnd(event: TouchEvent) {
    if (this.startY > this.endY + 30) {
      //console.log('Deslizado hacia arriba');
      // Acción específica al deslizar hacia arriba
    } else if (this.startY < this.endY - 30) {
      //console.log('Deslizado hacia abajo');
      // Acción específica al deslizar hacia abajo
    }
  }

}
