<div [ngClass.gt-sm]="{'container-gt-sm':true}" 
     [ngClass.lt-sm]="{'container-lt-sm':true}">
    <form #frmRuc="ngForm" (ngSubmit)="buscarPorRUC(frmRuc)" >
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-top: 10px;">
            <mat-form-field appearance="outline" class="control-row" style="padding-top: 10px;">
                <mat-label>RUC</mat-label>
                <input type="text" 
                    [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
                    [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_11"
                    [pattern]="PatternsRegex.onlyIntegerUnsigned" 
                    matInput placeholder="Número de RUC" ngModel
                    name="ruc" #ruc="ngModel" 
                    autocomplete="off"
                    (keypress) ="onlyNumbers($event)"
                    (keydown)="onKeydown($event, frmRuc)"
                    required>
                <mat-icon matSuffix>{{Icons.BADGE}}</mat-icon>
                <mat-error
                    *ngIf="ruc.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                <mat-error
                    *ngIf="ruc.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_REQ6_DIGITOS_CODIGO}}</mat-error>
                <mat-error
                    *ngIf="ruc.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8}}</mat-error>
                <mat-error
                    *ngIf="ruc.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_11}}</mat-error>
            </mat-form-field>
            <div fxLayout="row" fxLayoutGap="20px" >
                <button mat-stroked-button [disabled]="frmRuc.invalid">
                    <mat-icon>{{Icons.SEARCH}}</mat-icon>
                </button>
                <button mat-stroked-button type="button" (click)="limpiarBusqueda(frmRuc)" >
                    <mat-icon>{{Icons.CLEANING}}</mat-icon>
                </button>
            </div>
        </div>
    </form>
    <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 20px;" >
        <div fxLayout="row" fxLayout.xs="column">
            <div fxLayout="row" fxFlex="50%">
                <div class="titulo">Pedido:&nbsp;</div>                
                <div>{{pedido.codigo_pedido}}</div>
            </div>
            <div fxLayout="row" fxFlex="50%">
                <div class="titulo">Mesa(s):&nbsp;</div>                
                <div>{{pedido.mesas_completo}}</div>
            </div>
                       
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxLayout="row" fxFlex="50%" >
                <div class="titulo" >Total:&nbsp;</div>                
                <div>S/ {{pedido.total | number:'1.2-2' }}</div>
            </div> 
            <div fxLayout="row" fxFlex="50%">
                <div class="titulo">Impuesto IGV:&nbsp;</div>                
                <div>S/ {{pedido.igv | number:'1.2-2'}}</div>
            </div> 
        </div>
        <div *ngIf="pedido.servicio_total" fxLayout="row" fxLayout.xs="column">
            <div fxLayout="row" fxFlex="50%" >
                <div class="titulo" >Servicio:&nbsp;</div>                
                <div>S/ {{pedido.servicio_total | number:'1.2-2'}}</div>
            </div>           
        </div>
        <mat-divider></mat-divider>        
    </div>
    <div *ngIf="!isLoading && esBuscado && (rucResponse?.ruc=='')  " fxLayout="column" fxLayoutGap="20px" style="margin-top: 20px;" >
        <span>No se encontró la empresa</span>
        <mat-divider></mat-divider>
    </div>
    <div *ngIf="isLoading" style="margin-top: 20px;">
        <app-progress-bar></app-progress-bar>
    </div>

    <div *ngIf="rucResponse!=null && esBuscado && !isLoading" fxLayout="column" fxLayoutGap="10px" style="margin-top: 20px; margin-bottom: 20px;" >
        <div fxLayout="row" fxLayout.xs="column">
            <div fxLayout="row" fxFlex="50%">
                <div class="titulo">RUC:&nbsp;</div>                
                <div>{{rucResponse.ruc}}</div>
            </div> 
            <div fxLayout="row" fxFlex="50%" >
                <div class="titulo" >Razón Social:&nbsp;</div>                
                <div>{{rucResponse.razonSocial}}</div>
            </div>            
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxLayout="row" fxFlex="50%">
                <div class="titulo">Condición:&nbsp;</div>                
                <div>{{rucResponse.condicion}}</div>
            </div>
            <div fxLayout="row" fxFlex="50%">
                <div class="titulo">Estado:&nbsp;</div>                
                <div>{{rucResponse.estado}}</div>
            </div> 
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxLayout="row" fxFlex="50%">
                <div class="titulo">Dirección:&nbsp;</div>                
                <div>{{rucResponse.direccion}}</div>
            </div>
            <div fxLayout="row" fxFlex="50%">
                <div class="titulo">Ubigeo:&nbsp;</div>                
                <div>{{rucResponse.ubigeo}}</div>
            </div> 
        </div>
        <mat-divider></mat-divider>
        <div  fxLayout="row" fxLayoutAlign="center center" style="margin-top: 20px;">          
            <app-button [width]="100" text="Emitir Factura" [attr_type]="'button'" (click)="emitirFacturaAutomatico()" ></app-button>    
        </div>
    </div>  

</div>