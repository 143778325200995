import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA } from "@angular/material/dialog"
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { SnackBarType } from '../../../types/snackbar-type';
import { Msjs, Msjs_Validations, SistType} from '../../../cons/common';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-captcha-codigo',
  templateUrl: './captcha-codigo.component.html',
  styleUrls: ['./captcha-codigo.component.scss']
})
export class CaptchaCodigoComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private uiService:UIService,
    private functionService:FunctionsService,
  ){}

  CLOSE:string = Icons.CLOSE;
  CANCEL:string = Icons.CANCEL;
  counter: number=180;
  disabled_counter:boolean=true;
  btnText:string = "";
  codigoRecibido:number=0;
  reenvio_mensaje_antes:boolean = false;
  //constantes
  readonly Msjs_Validations:typeof Msjs_Validations = Msjs_Validations;
  onlyNumbers = Utils.InputOnlyNumbers;
  etapa:number=0;



  ngOnInit(): void {
    this.codigoRecibido = Number(this.datoPasado.codigo);
    this.etapa =Number(this.datoPasado.etapa);
    this.startTimerSMS();

  }

  startTimerSMS(){

    let iterator = setInterval(()=>{
      this.counter--;
      let textBt = `Reenviar código (${this.counter}) seg.`;
      this.btnText = textBt
      if(this.counter===0){
        clearInterval(iterator);
        this.disabled_counter=false;
        this.btnText="Reenviar código";
      }
    },1000);
  }

  onSubmit(form:NgForm){
    let flag = Number(form.value.codigoInput)===this.codigoRecibido? true:false;
    flag?(
      this.uiService.CloseModalCaptchaCodigo(),
      this.uiService.showModalLogin({operacion:SistType.REGISTRO}),
      //this.uiService.showModalSignup(),
      this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_CODIGO_OK_NUEVO_USUARIO)
    ):
    (
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_CODIGO_ERROR)
    )
  }

  onClose(){
    this.uiService.CloseModalCaptchaCodigo();
  }

  onSendSMS(){
    if(!this.reenvio_mensaje_antes){
      this.reenvio_mensaje_antes = true;
      this.functionService.SendMailGoDaddySMTPRegister(this.datoPasado.correo).then(resultado=>{
        this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MSJ_ENVIO_EMAIL);
        this.codigoRecibido = resultado.code;     
      });
    }else{
      this.uiService.CloseModalCaptchaCodigo();
      this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_ENVIO_CORREO_ELECTRONICO);
    }

  }

}
