<div fxLayout="column" fxLayoutGap="10px">
    <div style="padding-top: 10px; padding-left: 10px;" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign.xs="center center" fxLayoutAlign="start center">
        <app-button [width]="100" text="Generar"[attr_type]="'button'" (click)="selectReporte()" ></app-button>
    </div>
    
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-card style="min-width: 95%;">
            <mat-card-title>{{descripcionReportes[7].titulo}}</mat-card-title>
            <mat-card-subtitle>{{descripcionReportes[7].descripcion}}</mat-card-subtitle>
        </mat-card>    
    </div>
</div>
