import { EstadosPedidoType } from "src/app/shared/types/estado-pedido-type";
import { Fecha } from "../../gestion-monto/models/Fecha";
import { ProcesoType } from "src/app/shared/types/proceso-type";
import { AccionMesaType } from "src/app/shared/types/accion-mesa-type";

export class EstadoPedido{
    fecha_key:Fecha = new Fecha();
    usuario_creacion:string='';
    fecha_creacion?:any;
    usuario_aprobacion?:string;
    estado_pedido: EstadosPedidoType = EstadosPedidoType.PENDIENTE;
    proceso: ProcesoType = ProcesoType.INICIALIZAR;    
    anotacion?:string;
    es_vigente: boolean = false;
    auto_numerico:number=0
    //
    id?:string
}