<div style="margin-top: 20px;" fxLayout="column" fxLayoutAlign="space-between center">
    <div *ngIf="isLoading">
        <app-spinner></app-spinner>
    </div>
    <div    
    [ngClass.gt-sm]="{'container-gt-sm':true}" 
    [ngClass.lt-sm]="{'container-lt-sm':true}"
    [ngClass.gt-md]="{'container-gt-md':true}"   
        fxLayout="row" fxLayoutAlign="center center" 
        *ngIf="listaProductos.length==0">
        <span>No se tiene datos.</span>
    </div>
    <div *ngIf="!isLoading"fxLayout="column">        
        <div   
            [ngClass.gt-sm]="{'container-gt-sm':true}" 
            [ngClass.lt-sm]="{'container-lt-sm':true}"
            [ngClass.gt-md]="{'container-gt-md':true}" style="margin-top: 20px;" >
            <canvas #barCanvas></canvas>
        </div>
        
    </div>

</div>
