export class colections {
  public static readonly USUARIO: string = "tran.usuario";
  public static readonly EMPRESA: string = "tran.empresa";
  public static readonly SIST_MODULOS: string = "sist.modulos";
  public static readonly COLABORADOR: string = "colaborador";
  public static readonly COLABORADOR_RESUMEN: string = "colaborador_resumen";
  public static readonly USUARIO_COLABORADOR: string = "tran.usuario_colaborador";
  public static readonly ENCUESTA: string = "tran.encuesta";
  public static readonly FACTURACION: string = "tran.facturacion";
  public static readonly ACTIVE_SESION: string = "sist.active_sesion";
  public static readonly RECIBO: string = "tran.recibo";
  public static readonly PRODUCTO: string = "tran.producto";
  public static readonly PRODUCTO_RESUMEN: string = "producto_resumen";
  public static readonly CATALOGO: string = "tran.catalogo";
  public static readonly CATALOGO_RESUMEN: string = "catalogo_resumen";
  public static readonly CONFIGURACION_AMBIENTE: string = "configuracion_ambiente";//TODO: proximo a ser eliminado
  public static readonly PEDIDO_PRODUCTOS: string = "tran.pedido_productos";
  public static readonly HISTORIAL_MESA: string = "tran.historial_mesa";
  public static readonly HISTORIAL_PRODUCTO: string = "tran.historial_producto";
  public static readonly HISTORIAL_PEDIDO: string = "tran.historial_pedido";
  public static readonly AMBIENTE_MESA: string = "ambiente_mesa";
  public static readonly CONFIGURACION_TASAS: string = 'configuracion_tasas';
  public static readonly CONFIGURACION_MEDIOS_PAGO: string = 'configuracion_medios_pago';
  public static readonly TURNO: string = "tran.turno";
  public static readonly PEDIDO: string = "tran.pedido";
  public static readonly PRE_PAGO: string = "tran.pre_pago";
  public static readonly PEDIDO_RESUMEN: string = "pedido_resumen";
  public static readonly MENSAJE: string = "tran.mensaje";
  public static readonly CONFIGURACION_GENERAL: string = "sist.configuracion";
  public static readonly EXTERNO: string = "tran.externo";
  public static readonly CARTA_DIGITAL: string = "tran.carta_digital";
  public static readonly COMPROBANTE_ELECTRONICO: string = 'tran.comprobante_electronico';
  public static readonly VENTAS: string = 'tran.ventas'
  public static readonly REPORTE_VENTAS: string = 'tran.reporte_ventas'
}

export class documentMensaje {
  public static readonly ID_REF: string = 'id_ref';
  public static readonly ESTADO_CAMBIO: string = 'estado_cambio';
  public static readonly PROCESO: string = 'proceso';
}

export class documentEncuesta {
  public static readonly ID_REF: string = 'id_ref';
  public static readonly FECHA_CREACION: string = 'fecha_creacion';
  public static readonly ES_PUBLICADO: string = 'es_publicado';
  public static readonly ES_BORRADO: string = 'es_borrado';
}


export class documentTurno {
  public static readonly ES_ABIERTO: string = 'es_abierto';
  public static readonly FECHA_CREACION: string = 'fecha_creacion'
}

export class documentCatalogo {
  public static readonly CODIGO_CATALOGO: string = 'codigo_catalogo';
  public static readonly NOMBRE_CATALOGO: string = 'nombre_catalogo';
  public static readonly AUTO_NUMERICO: string = 'auto_numerico';
  public static readonly NOMBRES_COMPLETO: string = 'nombres_completo';
  public static readonly CODIGOS_COMPLETO: string = 'codigos_completo';
  public static readonly ID_PRODUCTOS_ANIDADOS_COMPLETO: string = 'id_productos_anidados_completo';
  public static readonly CODIGO_PRODUCTOS_ANIDADOS_COMPLETO: string = 'codigo_productos_anidados_completo';
}
export class documentProducto {
  public static readonly CODIGO_PRODUCTO: string = 'codigo_producto';
  public static readonly NOMBRE_PRODUCTO: string = 'nombre_producto';
  public static readonly AUTO_NUMERICO: string = 'auto_numerico';
  public static readonly NOMBRES_COMPLETO: string = 'nombres_completo';
  public static readonly CODIGOS_COMPLETO: string = 'codigos_completo';
  public static readonly ID_PRODUCTOS_ANIDADOS_COMPLETO: string = 'id_productos_anidados_completo';
}

export class documentActiveSession {
  public static readonly ID_USUARIO_CUENTA: string = 'id_usuario_cuenta';
  public static readonly TOKEN_SESSION: string = 'token_session';
}

export class documentsUsuario {
  public static readonly ID_USUARIO_CUENTA: string = 'id_usuario_cuenta';
}
export class documentColaborador {
  public static readonly MODULO_ROL: string = 'modulo_rol';
  public static readonly MODULO_SELECCIONADO: string = 'modulo_seleccionado';
  public static readonly NOMBRES_COMPLETO: string = 'nombres_completo';
  public static readonly NOMBRES: string = 'nombres';
  public static readonly FECHA_CREACION: string = 'fecha_creacion';
  public static readonly NUMERO_DOCUMENTO: string = 'numero_documento';
  public static readonly ES_VIGENTE: string = 'es_vigente';
  public static readonly ES_BORRADO: string = 'es_borrado';
  public static readonly AUTO_NUMERICO: string = 'auto_numerico';
  public static readonly ID_USUARIO_CUENTA: string = 'id_usuario_cuenta';
}

export class documentsUsuarioColaborador {
  public static readonly CORREO_ELECTRONICO: string = 'correo_electronico';
  public static readonly FECHA_CREACION: string = 'fecha_creacion';
  public static readonly ID_USUARIO_CUENTA: string = 'id_usuario_cuenta';
  public static readonly ID_EMPRESA: string = 'id_empresa';
}
export class documentsExterno {
  public static readonly ID_EMPRESA: string = 'id_empresa';
  public static readonly RUTA_PARCIAL: string = 'ruta_parcial';

}
export class documentsEmpresa {
  public static readonly ID_USUARIO_CUENTA: string = 'id_usuario_cuenta';
  public static readonly ID_DOC_USUARIO: string = 'id_doc_usuario';
  public static readonly RUC: string = 'ruc';
  public static readonly ES_BORRADO: string = 'es_borrado';
  public static readonly ES_SIN_PAGO: string = 'es_sin_pago';
  public static readonly ES_VIGENTE: string = 'es_vigente';
}
export class Auditoria {
  public static readonly fecha_creacion: string = 'fecha_creacion';
  public static readonly fecha_actualizacion: string = 'fecha_actualizacion';
  public static readonly es_vigente: string = 'es_vigente';
  public static readonly es_borrado: string = 'es_borrado';
  public static readonly usuario_creacion: string = 'usuario_creacion';
  public static readonly usuario_modificacion: string = 'usuario_modificacion';
}

export class documentPedido {
  public static readonly es_vigente: string = 'es_vigente';
  public static readonly es_borrado: string = 'es_borrado';
  public static readonly usuario_creacion: string = 'usuario_creacion';
  public static readonly USUARIO_CREACION_BUSQUEDA: string = 'usuario_creacion_busqueda';
  public static readonly CORREO_ELECTRONICO: string = 'correo_electronico';
  public static readonly FECHA_CREACION: string = 'fecha_creacion';
  public static readonly AUTO_NUMERICO: string = 'auto_numerico';
  public static readonly MESAS_COMPLETO: string = 'mesas_completo';
  public static readonly CODIGO_PEDIDO: string = 'codigo_pedido';
  public static readonly ESTADO_PEDIDO_VIGENTE: string = 'estado_pedido_vigente';
  public static readonly ESTADO_PEDIDO_VIGENTE_BEBIDA: string = 'estado_pedido_vigente_bebida';
  public static readonly ESTADO_PEDIDO_VIGENTE_COMIDA: string = 'estado_pedido_vigente_comida';
  public static readonly LISTA_PAGO_TOTAL: string = 'lista_pago_total';
  public static readonly TIENE_PAGO_TOTAL: string = 'tiene_pago_total';
  public static readonly LISTA_PAGO_PARCIAL: string = 'lista_pago_parcial';
  public static readonly TIENE_PAGO_PARCIAL: string = 'tiene_pago_parcial';
  public static readonly TIENE_INCIDENCIA: string = 'tiene_incidencia';
  public static readonly ES_PEDIDO_ANULADO: string = 'es_pedido_anulado';
  public static readonly USUARIO_CREACION: string = 'usuario_creacion';
}
export class documentPedidoProductos {
  public static readonly AUTO_NUMERICO: string = 'auto_numerico';
  public static readonly CODIGO_PRODUCTO: string = 'codigo_producto';
  public static readonly ESTADO_PRODUCTO_VIGENTE: string = 'estado_producto_vigente';
  public static readonly TIPO_PRODUCTO: string = 'tipo_producto';
  public static readonly FECHA_CREACION: string = 'fecha_creacion';

}
export class documentRecibo {
  public static readonly PENDIENTE_PAGO: string = 'pendiente_pago';
}

export class documentReporteVentas {
  public static readonly ID_TURNO: string = 'id_turno';
  public static readonly FECHA_CREACION: string = 'fecha_creacion';
}

/*clases*/


