<div fxLayout="row" fxLayoutAlign="space-between start">
    <div mat-dialog-title class="dialog-title">{{titleModal}}</div>
    <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()" [attr_disabled]="isLoading" ></app-button>
</div>
<mat-divider></mat-divider>
<form #f="ngForm" (ngSubmit)="agregarFormulario(f)">
    <mat-dialog-content class="scrollable" style="width: 250px; height: 380px;">
        <section>
            <div fxLayout="column" style="margin-top: 10px;">
                <div fxLayout="row" fxLayoutGap="10px" >
                    <mat-form-field style="width:250px" >
                        <mat-label>Titulo</mat-label>
                        <input 
                            type="text" 
                            matInput 
                            placeholder="enunciado" 
                            required 
                            minlength="5" 
                            maxlength="50"
                            name="txtPreguntaInput" 
                            ngModel 
                            [(ngModel)]="tituloPregunta"                            
                            #txtPreguntaInput="ngModel"  
                            [disabled]="modoOperacion ==SistOperation.VER">
                            <mat-error
                                *ngIf="txtPreguntaInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>                            
                            <mat-error
                                *ngIf="txtPreguntaInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
                            <mat-error
                                *ngIf="txtPreguntaInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_5}}</mat-error>            
                    </mat-form-field>
                </div>
                <div fxLayout="column">
                    <app-upload
                    [multipleFiles]="false"
                    [requiredFileType]="fileType"
                    label="Logo"
                    [key]="keyLogo"
                    [max_size_width_height]="sizeMaxHeightWidth"
                    [disabled]="disabled_controls"
                    [is_edit]="esModoEdicion"
                    [images_uploaded]="files_uploadedLogo"
                    ></app-upload>
                    <div style="margin-top: 10px; margin-bottom: 10px;" >
                        <mat-divider></mat-divider>
                    </div>
                    
                    <app-upload
                    [multipleFiles]="false"
                    [requiredFileType]="fileType"
                    label="Imagen de fondo"
                    [key]="keyFondo"
                    [max_size_width_height]="sizeMaxHeightWidth"
                    [disabled]="disabled_controls"
                    [is_edit]="esModoEdicion"
                    [images_uploaded]="files_uploadedFondo"
                    ></app-upload>
                </div>
            </div>
        </section>
    </mat-dialog-content>
    <mat-card-actions div fxLayout="row" [fxLayoutAlign]="modoOperacion==SistOperation.VER? 'end center':'space-between center'" >
        <app-button [width]="100" text="Agregar" 
           *ngIf="modoOperacion !=SistOperation.VER" [attr_disabled]="(f.invalid)"  [attr_type]="'submit'" ></app-button>
        <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray" *ngIf="modoOperacion !=SistOperation.VER"
            (click)="cancelarPregunta()"></app-button>
        <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL" *ngIf="modoOperacion !=SistOperation.VER"
            (click)="cancelarPregunta()"></app-button>    
    </mat-card-actions>
</form>
