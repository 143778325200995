import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { Pedido } from "../../registrar-pedido/models/Pedido";
import { Fecha } from "./Fecha";
import { VariacionEfectivo } from "./VariacionEfectivo";
import { CorreccionEfectivo } from "./CorreccionEfectivo";
export class Turno implements AuditoriaSet {
  auto_numerico:number=0;
  es_abierto:boolean=true;
  monto_inicial_efectivo:number=0;
  correccion_monto_inicial?:CorreccionEfectivo[];
  montos_aniadidos?:VariacionEfectivo[];
  montos_retirados?:VariacionEfectivo[];
  fecha_key:Fecha = new Fecha();
  lista_pedido?:Pedido;
  es_borrado: boolean=false;
  es_vigente: boolean=false;
  usuario_creacion: any;
  usuario_modificacion?: any;
  fecha_creacion?: any;
  fecha_modificacion?: any;
  monto_final_recaudado_turno?:number;
  //
  id?:string;
  fecha_registro?:string;
}
