<div fxLayout="row" fxLayoutAlign="space-between start" class="header-dialog" >
  <div mat-dialog-title fxLayout="row" class="dialog-title">
    Registrarme
    <div class="title-step">
      ({{contadorIndice}} de 3)
    </div>
  </div>
  <app-button type="icon" color="gray" [icon_fig]="CLOSE" (click)="onClose()" ></app-button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
    <section *ngIf="!esValidoResultado">
      <h4>Realiza la operación:</h4>
    </section>
    <section *ngIf="isLoading" >
      <app-spinner></app-spinner>
    </section>
    <section *ngIf="!isLoading">
      <form fxLayout="column" #f="ngForm" (ngSubmit)="onSubmit(f)">
        <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutGap.xs="0px" *ngIf="visibleOperacion" >
          <mat-form-field fxFlex="50%">
            <mat-label>
              <span class="label-captcha">{{lCaptcha[0]}} {{lCaptcha[3]}} {{lCaptcha[1]}}</span>
            </mat-label>
            <input
              class="input-captcha"
              type="text"
              matInput
              placeholder="Resuelva"
              ngModel
              name="operacion"
              disabled="true" >
          </mat-form-field>
          <mat-form-field >
            <input
              class="input-captcha"
              type="text"
              matInput
              placeholder="Resultado"
              ngModel
              pattern="[0-9]*"
              name="resultado"
              #resultadoInput="ngModel"
              required
              (keypress) ="onlyNumbers($event)"                          
              >
            <mat-error *ngIf="resultadoInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="resultadoInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
          </mat-form-field>
          <div>
            <app-button [width]="100" text="Validar" [attr_disabled]="f.invalid" [attr_type]="'button'" (click)="validarResultado(f)" ></app-button>
          </div>
        </div>
        <mat-form-field [hintLabel]="Msjs_Validations.MSJ_VAL_REQ_CORREO_ELECTRONICO" *ngIf="esValidoResultado" style="min-width: 300px;" >
          <mat-label>Correo electrónico</mat-label>
          <input
            class="input-captcha"
            type="text"
            matInput
            ngModel
            name="email"            
            required            
            maxlength="50"
            [pattern]="PatternsRegex.onlyEmail"            
            #emailInput="ngModel"            
            (change)="tieneEmail($event, f)"
            >

            <mat-error *ngIf="emailInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="emailInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_EMAIL}}</mat-error>            
            <mat-error *ngIf="emailInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_MAX50_DIGITOS}}</mat-error>
        </mat-form-field>
           
        <div class="actions-dialog" fxLayout="row" fxLayoutAlign="space-between end" fxFlex *ngIf="!incorrectoCel" >
          <app-button [width]="100" text="Continuar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="f.invalid" [attr_type]="'submit'" ></app-button>
          <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray" (click)="onClose()" ></app-button>
          <app-button fxShow.xs fxHide  type="outline" color="gray-b0" [icon_fig]="CANCEL" (click)="onClose()" ></app-button>
        </div>
      </form>
    </section>
  </mat-dialog-content>
