<mat-card
    [ngClass.gt-xs]="{'container-gt-xs':true}" 
    [ngClass.xs]="{'container-xs':true}"
    >
    <mat-card-content fxLayout="column" >
        <div fxLayout="row">                       
            <div>
                <mat-icon>{{icon}}</mat-icon>
            </div>
            <div>
                <span [ngClass]="{'titulo--gray': isGray, 'titulo': !isGray}" >{{title}}</span>
            </div>
        </div>
      
        <div style="margin-top: 10px; margin-bottom: 10px;">
            <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around center" >
            <span  [ngClass]="{'titulo--gray': isGray, 'titulo': !isGray}" *ngIf="showDecimal" style="font-size: 16pt;" >S/ </span>
            <span  [ngClass]="{'titulo--gray': isGray, 'titulo': !isGray}" *ngIf="!showDecimal"  class="cantidad">{{qyt}}</span>      
            <span  [ngClass]="{'titulo--gray': isGray, 'titulo': !isGray}" *ngIf="showDecimal"class="cantidad">{{qyt |  number:'1.2-2'}}</span>        
        </div>       
        
    </mat-card-content>
</mat-card>
