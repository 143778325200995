import { Injectable } from '@angular/core';
import { EmpresaDAService } from 'src/app/shared/components/services/services.da/empresa.da.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { CColaborador } from '../../models/ccolaborador.model';
import { GestionColaboradorDaService } from '../services.da/gestion-colaborador.da.service';
import { IRolAcceso, ItemSidenav } from 'src/app/shared/models/item.model';
import { IImageUploaded } from 'src/app/shared/components/upload/IImageUploaded';
import { Utils } from 'src/app/shared/helpers/utils';
import { ITableFiltroColaborador } from '../../models/ITableFiltroColaborador';

@Injectable({
  providedIn: 'root'
})
export class GestionColaboradorBlService {

  constructor(

    private registroService:RegistroService,
    private gestionColaboradorDAService:GestionColaboradorDaService

  ) { }

  cambiarContraseniaColaborador(colaborador:CColaborador, nuevoPassword:string){
    return this.gestionColaboradorDAService.cambiarContraseniaColaborador(
      colaborador,
      nuevoPassword,
      this.registroService.empresa.id,
      this.registroService.usuario.id
      );
  }

  validarPasswordAdminitradorEmpresa(password:string){
    return this.gestionColaboradorDAService.validarPasswordAdminitradorEmpresa(password);
  }

  cerrarSesionAdministrador(){
    return this.gestionColaboradorDAService.cerrarSesionAdministrador();
  }

  validarCorreoColaborador(correo:string){
    return this.gestionColaboradorDAService.validarCorreoColaborador(correo);
  }

  getColaboradorResumen(){
    return this.gestionColaboradorDAService.getColaboradorResumen(this.registroService.empresa.id);
  }

  getColaboradores(filtroColaborador:ITableFiltroColaborador
     ){
    return this.gestionColaboradorDAService.getColaboradores(
      this.registroService.empresa.id, this.registroService.usuario.id, filtroColaborador
      );
  }

  getColaborarResumenCombinado(filtroColaborador:ITableFiltroColaborador
    ){
   return this.gestionColaboradorDAService.getColaborarResumenCombinado(
     this.registroService.empresa.id, this.registroService.usuario.id, filtroColaborador
     );
 }

  getColaboradorXModuloRol(rol:IRolAcceso){
    const idEmpresa = this.registroService.empresa.id;
    return this.gestionColaboradorDAService.getColaboradorXModuloRol(rol,idEmpresa)
  }

  //devuelve todos los trabajadores del modulo
  getColaboradorXModulo(opcion:ItemSidenav){
    return this.gestionColaboradorDAService.getColaboradorXModulo(opcion,this.registroService.empresa.id)
  }


  insertColaborador(colaborador:CColaborador, files_upload:File[], pathFileAPI:string){

    return this.gestionColaboradorDAService.insertColaborador(
      this.registroService.empresa,
      this.registroService.usuario.correo_electronico,
      colaborador,
      files_upload,
      pathFileAPI )
  }

  updateColaborador( colaboradorAnterior:CColaborador, colaborador:CColaborador, files_upload:File[], files_upload_anterior: IImageUploaded[], pathFileAPI:string, estadoColaboradorEdicion:boolean){

    return this.gestionColaboradorDAService.updateColaborador(this.registroService.empresa.id,
      this.registroService.usuario.correo_electronico,
      colaboradorAnterior,
      colaborador,
      files_upload,
      files_upload_anterior,
      pathFileAPI,
      estadoColaboradorEdicion
      )

  }

  updateActivarColaborador(colaborador:CColaborador, esActivar:boolean){
    return this.gestionColaboradorDAService.updateActivarColaborador(this.registroService.empresa.id,
      this.registroService.usuario.correo_electronico,
      colaborador,
      esActivar
      );
  }
  deleteColaborador( colaborador: CColaborador, pathFileAPI:string) {
    return this.gestionColaboradorDAService.deleteColaborador(
      this.registroService.empresa.id,
      colaborador,
      pathFileAPI
    )
  }
  utilCleanFlags(){
    return this.gestionColaboradorDAService.utilCleanFlags();
  }

}
