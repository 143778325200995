import { Component, Inject, OnInit } from '@angular/core';
import { Icons } from 'src/app/shared/cons/icons';
import { TipoPregunta } from '../models/tipo-pregunta';
import { Msjs_Validations, PatternsRegex, SistOperation } from 'src/app/shared/cons/common';
import { UIService } from 'src/app/shared/services/ui.services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from 'src/app/shared/helpers/utils';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-tipo-pregunta-binaria',
  templateUrl: './tipo-pregunta-binaria.component.html',
  styleUrls: ['./tipo-pregunta-binaria.component.scss']
})
export class TipoPreguntaBinariaComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  isLoading:boolean=false;
  titleModal:string='';
  tipoPregunta:TipoPregunta = new TipoPregunta();
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  modoOperacion: SistOperation = SistOperation.VER;
  readonly SistOperation: typeof SistOperation = SistOperation;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  codigo:number =0;
  preguntaEditar:TipoPregunta = new TipoPregunta();

  constructor(
    private uiService: UIService,
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef: MatDialogRef<TipoPreguntaBinariaComponent>,
  ) { }

  ngOnInit(): void {
    this.titleModal = this.datoPasado.data.tipo_pregunta.titulo;
    this.modoOperacion = this.datoPasado.data.modoOperacion;
    this.codigo = this.datoPasado.data.tipo_pregunta.codigo;
    if(this.modoOperacion==SistOperation.EDITAR){
      this.preguntaEditar = this.datoPasado.data.preguntaEditar;
      this.tipoPregunta.pregunta = this.preguntaEditar.pregunta;      
    }
  }


  onClose(){
    this.dialogRef.close();
  }

  agregarPregunta(f:NgForm){
    if(f.valid){
      if(this.modoOperacion==SistOperation.NUEVO){
        this.tipoPregunta.codigo_pregunta = Utils.generarCodigoRandom(6);
      }else{
        this.tipoPregunta.codigo_pregunta = this.preguntaEditar.codigo_pregunta;
      }        
      this.tipoPregunta.tipo_pregunta = this.codigo;    
      this.dialogRef.close(this.tipoPregunta);
    }

  }
  cancelarPregunta(){
    this.dialogRef.close();
  }

}
