<div fxLayout="row" fxLayoutAlign="space-between start">
    <div mat-dialog-title class="dialog-title" fxLayout="row"  fxLayout.lt-sm="column">
        Emisión Comprobante Electrónico
    </div>
    <app-button *ngIf="!loading" type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"></app-button>
</div>
<mat-divider></mat-divider>
<div>
    <div *ngIf="loading" fxLayout="column" fxLayoutGap="20px" >
        <div  fxLayout="row" fxLayoutAlign="center center" style="margin-top: 10px;" [@blink]="blinkState" >
            <h2>No cierre la ventana</h2>
        </div>
        <app-spinner></app-spinner>
    </div>
    <div *ngIf="!loading" >
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="Boleta de Venta">
                <app-emision-boleta-venta></app-emision-boleta-venta>
            </mat-tab>
            <mat-tab label="Factura">
                <app-emision-factura></app-emision-factura>            
            </mat-tab>
        </mat-tab-group>
    </div> 
</div>