import { EstadosProductoType } from "src/app/shared/types/estado-producto-type";
import { Fecha } from "../../gestion-monto/models/Fecha";
import { SolicitarCambioType } from "src/app/shared/types/solicitar-cambio-type";
import { ProcesoType } from "src/app/shared/types/proceso-type";

export class EstadosProducto{
    fecha:Fecha = new Fecha();
    fecha_creacion?:any;
    usuario_creacion:string='';
    usuario_aprobacion?:string;
    estado_producto: EstadosProductoType = EstadosProductoType.PENDIENTE;
    anotacion?:string;
    es_vigente: boolean = false;
    auto_numerico:number=0
    codigo_ticket:string='';
    cantidad_producto:number=0;
    nombre_producto:string='';
    codigo_producto:string='';
    cantidad_producto_reducido?:number;
    es_eliminado?:boolean
    es_modificado?:boolean;
    estado_cambio?:SolicitarCambioType;
    proceso?: ProcesoType;
    reducir_cantidad:boolean = false;
    //
    id?:string
}