import { Injectable } from '@angular/core';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { collectionGroup, Timestamp } from '@angular/fire/firestore';
import { Pedido } from '../../models/Pedido';
import { Auditoria, colections, documentCatalogo, documentMensaje, documentPedido, documentPedidoProductos, documentsEmpresa, documentTurno } from 'src/app/shared/cons/db.colections';
import { Utils } from 'src/app/shared/helpers/utils';
import { DocumentData, FieldValue, deleteField, serverTimestamp } from 'firebase/firestore';
import { IProductoTicket } from '../../models/IProductoTicket';
import { Producto } from '../../../procesos/dialog/models/Producto';
import { IResumenProducto } from '../../../procesos/dialog/models/IResumenProducto';
import { Catalogo } from '../../../procesos/dialog/models/Catalogo';
import { EstadosMesaType } from 'src/app/shared/types/estado-mesa-type';
import { ITablePedidosFiltro } from '../models/ITablePedidosFiltro';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, combineLatest, forkJoin, from, of } from 'rxjs';
import { ResumenPedido } from '../../models/ResumenPedido';
import { EstadosPedidoType } from 'src/app/shared/types/estado-pedido-type';
import { SolicitarCambioType } from 'src/app/shared/types/solicitar-cambio-type';
import { ProcesoType } from 'src/app/shared/types/proceso-type';
import { EstadoPedido } from '../../models/EstadoPedido';
import { Fecha } from '../../../gestion-monto/models/Fecha';
import { IMesaAmbiente } from '../../models/IMesaAmbiente';
import { EstadosProductoType } from 'src/app/shared/types/estado-producto-type';
import { Mensaje } from '../../../gestion-monto/models/Mensaje';
import { AccionMesaType } from 'src/app/shared/types/accion-mesa-type';
import { IAmbienteMesa } from '../../../procesos/gestion-ambientes-mesas/models/IAmbienteMesa';
import { EstadosProducto } from '../../models/EstadoProducto';
import { TipoConsumibleType } from 'src/app/shared/types/tipo-consumible';
import { ListaTipoProducto } from 'src/app/shared/cons/common';
import { PagoCuenta } from '../../models/PagoCuenta';
import { IPagoCuenta } from '../../models/IPagoCuenta';
import { IPrePagoCuenta } from '../../models/IPrePagoCuenta';
import { IDescuentoProducto } from '../../models/IDescuentoProducto';
import { ItemSidenav } from 'src/app/shared/models/item.model';
import { Ventas } from '../../models/Ventas';
import { IRespuestaReseller } from 'src/app/shared/services/models/IRespuestaReseller';
import { IGetAPIRUC } from 'src/app/shared/services/models/IGetAPIRUC';
import { SendPersona } from 'src/app/shared/services/models/sendPersona';
import { EstadosComprobanteElectronicoType } from 'src/app/shared/types/estado-comprobante-electronico-type';


@Injectable({
  providedIn: 'root',
})
export class PedidoDaService {
  transaccion: TransaccionModel = new TransaccionModel();

  //busqueda y paginacion
  private filtroPedido: ITablePedidosFiltro = {
    mesas: null,
    codigoFiltro: null,
    estadoFiltro: null,
    usuarioFiltro: null,
    ordenamiento: 'asc',
    estadoPedidoVigenteFiltro: null,
    minPage: null,
    maxPage: null,
  };
  utilCleanFlags() {
    this.filtroPedido.ordenamiento = 'asc';
  }

  private removeUtilProperties(pedido: Pedido): Pedido {
    delete pedido.fecha_registro;
    delete pedido.id;
    delete pedido.numeracion;
    delete pedido.totalRegistros_query;
    delete pedido.totalRegistrosPendiente;
    delete pedido.totalRegistrosEnProceso;
    delete pedido.totalRegistrosTerminado;
    delete pedido.totalRegistrosAnulado;
    delete pedido.totalRegistrosPendienteRevision;
    delete pedido.mesas_asignado;
    delete pedido.usuario_registro;
    delete pedido.lista_producto;
    delete pedido.total_string;
    delete pedido.tiempo_transcurrido_desde_creacion;
    delete pedido.totalRegistrosPendienteRevision;
    delete pedido.totalRegistrosPendienteReabrir;
    delete pedido.last_doc;
    delete pedido.emision_comprobante;
    delete pedido.descuento;
    delete pedido.incidencia;
    delete pedido.pedido_incidencia;
    return pedido;
  }

  constructor(private db: AngularFirestore) { }

  getFechaActual() {
    const fecha = Timestamp.now().toDate();
    return fecha.toJSON();
  }

  determinarEstadoPedidoParaComidaBebida(
    listaProductoPedido: IProductoTicket[],
    pedido: Pedido
  ) {
    //determinando estados de comida y bebida a nivel de pedido

    //Utils.getTipoProducto:true<--bebida
    if (pedido.estado_pedido_vigente_bebida != undefined) {
      const totalProd = listaProductoPedido.filter(
        (prod) =>
          Utils.getTipoProducto(true).includes(prod.tipo_producto) &&
          ((prod.estado_producto_vigente_bebida != undefined &&
            Utils.getProductoCombo().includes(prod.tipo_producto)) ||
            !Utils.getProductoCombo().includes(prod.tipo_producto))
      );

      const prodPendiente = listaProductoPedido?.filter(
        (prod) =>
          (prod.estado_producto_vigente == EstadosProductoType.PENDIENTE &&
            Utils.getTipoProducto(true).includes(prod.tipo_producto) &&
            prod.estado_producto_vigente_bebida != undefined) ||
          (prod.estado_producto_vigente_bebida &&
            prod.estado_producto_vigente_bebida ==
            EstadosProductoType.EN_PROCESO)
      );

      const prodProceso = listaProductoPedido?.filter(
        (prod) =>
          (prod.estado_producto_vigente == EstadosProductoType.EN_PROCESO &&
            Utils.getTipoProducto(true).includes(prod.tipo_producto)) ||
          (prod.estado_producto_vigente_bebida &&
            prod.estado_producto_vigente_bebida ==
            EstadosProductoType.EN_PROCESO)
      );

      const prodTerminado = listaProductoPedido?.filter(
        (prod) =>
          (prod.estado_producto_vigente == EstadosProductoType.TERMINADO &&
            Utils.getTipoProducto(true).includes(prod.tipo_producto)) ||
          (prod.estado_producto_vigente_bebida &&
            prod.estado_producto_vigente_bebida ==
            EstadosProductoType.TERMINADO)
      );

      //determinando estado de la bebida
      switch (pedido.estado_pedido_vigente_bebida) {
        case EstadosProductoType.PENDIENTE: {
          if (
            totalProd?.length === prodProceso?.length ||
            (prodPendiente && prodPendiente.length >= 0)
          ) {
            pedido.estado_pedido_vigente_bebida =
              EstadosProductoType.EN_PROCESO;
          }
          break;
        }
        case EstadosProductoType.EN_PROCESO: {
          if (
            totalProd?.length === prodTerminado?.length ||
            (prodProceso && prodProceso.length === 0)
          ) {
            pedido.estado_pedido_vigente_bebida = EstadosProductoType.TERMINADO;
          }
          break;
        }
        default: {
          pedido.estado_pedido_vigente_bebida = EstadosProductoType.PENDIENTE;
        }
      }
    }

    //Utils.getTipoProducto:false<--comida
    if (pedido.estado_pedido_vigente_comida != undefined) {
      const totalProd = listaProductoPedido.filter(
        (prod) =>
          Utils.getTipoProducto(false).includes(prod.tipo_producto) &&
          ((prod.estado_producto_vigente_comida != undefined &&
            Utils.getProductoCombo().includes(prod.tipo_producto)) ||
            !Utils.getProductoCombo().includes(prod.tipo_producto))
      );

      const prodPendiente = listaProductoPedido?.filter(
        (prod) =>
          (prod.estado_producto_vigente == EstadosProductoType.PENDIENTE &&
            Utils.getTipoProducto(false).includes(prod.tipo_producto) &&
            prod.estado_producto_vigente_comida != undefined) ||
          (prod.estado_producto_vigente_comida &&
            prod.estado_producto_vigente_comida ==
            EstadosProductoType.EN_PROCESO)
      );

      const prodProceso = listaProductoPedido?.filter(
        (prod) =>
          (prod.estado_producto_vigente == EstadosProductoType.EN_PROCESO &&
            Utils.getTipoProducto(false).includes(prod.tipo_producto)) ||
          (prod.estado_producto_vigente_comida &&
            prod.estado_producto_vigente_comida ==
            EstadosProductoType.EN_PROCESO)
      );

      const prodTerminado = listaProductoPedido?.filter(
        (prod) =>
          (prod.estado_producto_vigente == EstadosProductoType.TERMINADO &&
            Utils.getTipoProducto(false).includes(prod.tipo_producto)) ||
          (prod.estado_producto_vigente_comida &&
            prod.estado_producto_vigente_comida ==
            EstadosProductoType.TERMINADO)
      );

      //determinando estado de la bebida
      switch (pedido.estado_pedido_vigente_comida) {
        case EstadosProductoType.PENDIENTE: {
          if (
            totalProd?.length === prodProceso?.length ||
            (prodPendiente && prodPendiente.length >= 0)
          ) {
            pedido.estado_pedido_vigente_comida =
              EstadosProductoType.EN_PROCESO;
          }
          break;
        }
        case EstadosProductoType.EN_PROCESO: {
          if (
            totalProd?.length === prodTerminado?.length ||
            (prodProceso && prodProceso.length === 0)
          ) {
            pedido.estado_pedido_vigente_comida = EstadosProductoType.TERMINADO;
          }
          break;
        }
        default: {
          pedido.estado_pedido_vigente_comida = EstadosProductoType.PENDIENTE;
        }
      }
    }
    return JSON.parse(JSON.stringify(pedido)) as Pedido;
  }

  /*Reglas para determinar el estado de un pedido*/
  determinarEstadoPedido(listaProductoPedido: IProductoTicket[]) {
    let estadoInicial = EstadosPedidoType.PENDIENTE;
    let totalProductos: number = listaProductoPedido.length;
    const pendiente = listaProductoPedido.filter(
      (producto) =>
        producto.estado_producto_vigente == EstadosProductoType.PENDIENTE
    ).length;
    const enProceso = listaProductoPedido.filter(
      (producto) =>
        producto.estado_producto_vigente == EstadosProductoType.EN_PROCESO
    ).length;
    const terminado = listaProductoPedido.filter(
      (producto) =>
        producto.estado_producto_vigente == EstadosProductoType.TERMINADO
    ).length;
    const rechazado = listaProductoPedido.filter(
      (producto) =>
        producto.estado_producto_vigente == EstadosProductoType.RECHAZADO
    ).length;
    const anulado = listaProductoPedido.filter(
      (producto) =>
        producto.estado_producto_vigente == EstadosProductoType.ANULADO
    ).length;
    const pendienteRevision = listaProductoPedido.filter(
      (producto) =>
        producto.estado_producto_vigente ==
        EstadosProductoType.PENDIENTE_REVISION
    ).length;

    totalProductos = totalProductos - anulado;

    //todos los productos pendientes
    if (pendiente == totalProductos) {
      estadoInicial = EstadosPedidoType.PENDIENTE;
    }
    //minimo un producto en proceso o minimo un producto terminado
    if (enProceso >= 1 || terminado >= 1) {
      estadoInicial = EstadosPedidoType.EN_PROCESO;
    }
    //todos los productos terminados
    if (terminado == totalProductos) {
      estadoInicial = EstadosPedidoType.TERMINADO;
    }
    //todos los productos anulados
    if (anulado == totalProductos) {
      estadoInicial = EstadosPedidoType.ANULADO;
    }
    //minimo un producto en revision
    if (pendienteRevision >= 1) {
      if (pendiente == totalProductos) {
        estadoInicial = EstadosPedidoType.PENDIENTE;
      } else if (enProceso >= 1 || terminado >= 1) {
        estadoInicial = EstadosPedidoType.EN_PROCESO;
      } else if (terminado == totalProductos) {
        estadoInicial = EstadosPedidoType.TERMINADO;
      } else {
        estadoInicial = EstadosPedidoType.PENDIENTE; //cuando se aprueba o desaprueba
      }
    }

    return estadoInicial;
  }

  async anularPedido(
    idEmpresa: string,
    correoUsuario: string,
    pedido: Pedido,
    idTurno: string,
    historialPedido: EstadoPedido,
    fechaHoy:Fecha
  ) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(pedido.id);
    const docHistorialPedidoRef = docPedidoRef.collection(colections.HISTORIAL_PEDIDO).doc();
    const docReporteVentas = docEmpRef.collection(colections.REPORTE_VENTAS).doc();

    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        //INSERTANDO HISTORIAL DE PEDIDO
        const docHistorialPedido = Utils.SerializeJsonToDb(historialPedido);
        docHistorialPedido.fecha_creacion = serverTimestamp();
        //docHistorialPedido.estado_vigente = EstadosPedidoType.PENDIENTE
        transaccion.set(docHistorialPedidoRef.ref, docHistorialPedido);

        //ACTUALIZANDO PEDIDO
        transaccion.update(docPedidoRef.ref, {
          usuario_modificacion: correoUsuario,
          estado_pedido_vigente: pedido.estado_pedido_vigente, //pedido anulado
          es_pedido_anulado: pedido.es_pedido_anulado,
          fecha_modificacion: serverTimestamp(),
        });


        //INSERTANDO PEDIDO ANULADO PARA REPORTES DE PEDIDOS ANULADOS
        const pedidoCopy = JSON.parse(JSON.stringify(pedido)) as Pedido;

        //recuperando datos del pedido para objeto plano de reporte de ventas
        const listaProductos = await docPedidoRef.collection(colections.PEDIDO_PRODUCTOS).get().toPromise();
        const lista_producto: IProductoTicket[] = [];
        if (!listaProductos.empty) {
          listaProductos.docs.forEach(documentoProd => {
            const productoPed = documentoProd.data() as IProductoTicket;
            productoPed.id = documentoProd.id;
            productoPed.fecha_creacion = Timestamp.fromDate(productoPed.fecha_creacion.toDate());// por revisar
            lista_producto.push(productoPed);
          });

        }

        //recuperando datos de historial de producto para reporte de ventas
        const listaHistProductos = await docPedidoRef.collection(colections.HISTORIAL_PRODUCTO).get().toPromise();
        const historial_producto: EstadosProducto[] = [];
        if (!listaHistProductos.empty) {
          listaHistProductos.docs.forEach(docHistProd => {
            const histProd = docHistProd.data() as EstadosProducto;
            histProd.id = docHistProd.id;
            histProd.fecha_creacion = Timestamp.fromDate(histProd.fecha_creacion.toDate());
            historial_producto.push(histProd);
          });
          pedidoCopy.historial_producto = historial_producto;
        }

        // recuperando datos historial de pedido para reporte de ventas
        const listaHistPedido = await docPedidoRef.collection(colections.HISTORIAL_PEDIDO).get().toPromise();
        const historial_pedido: EstadoPedido[] = [];
        if (!listaHistPedido.empty) {
          listaHistPedido.docs.forEach(docHistPedido => {
            const histPedido = docHistPedido.data() as EstadoPedido;
            histPedido.id = docHistPedido.id;
            histPedido.fecha_creacion = Timestamp.fromDate(histPedido.fecha_creacion.toDate());
            historial_pedido.push(histPedido);
          });
          pedidoCopy.historial_pedido = historial_pedido;
        }

        // recuperando historial mesa para reporte de ventas        
        const listaHistMesa = await docPedidoRef.collection(colections.HISTORIAL_MESA).get().toPromise();
        const historial_mesa: IAmbienteMesa[] = [];
        if (!listaHistMesa.empty) {
          listaHistMesa.docs.forEach(docHistMesa => {
            const histMesa = docHistMesa.data() as IAmbienteMesa;
            histMesa.id = docHistMesa.id;
            let fechaCreacion = histMesa?.fecha_creacion;
            histMesa.fecha_creacion = Timestamp.fromDate(histMesa.fecha_creacion.toDate());
            historial_mesa.push(histMesa);
          });
          pedidoCopy.historial_mesa = historial_mesa;
        }

        //generando objeto de pago  para reportes
        const pedidoInsReporteNew = this.removeUtilProperties(pedidoCopy);
        pedidoInsReporteNew.lista_producto = lista_producto;
        const pedidoInsReporte = JSON.parse(JSON.stringify(pedidoInsReporteNew)) as Pedido;

        pedidoInsReporte.usuario_creacion = correoUsuario;//usuario que cierra el pedido
        pedidoInsReporte.tiene_pago_total = true;

        //NO SE TIENE PAGO TOTAL PARA UN PEDIDO QUE ES ANULADO
        //pedidoInsReporte.lista_pago_total = lista_pago_total;
        pedidoInsReporte.id = pedidoCopy.id!;
        //generando objeto pago
        const ventaIns: Ventas = new Ventas();
        ventaIns.auto_numerico = Utils.generaFechaAutonumerico(fechaHoy);
        ventaIns.fecha_key = fechaHoy;
        ventaIns.pedido = pedidoInsReporte;
        ventaIns.usuario_creacion = correoUsuario;
        ventaIns.es_vigente = true;
        ventaIns.es_borrado = false;

        const ventaInsNew = Utils.SerializeJsonToDb(ventaIns);
        ventaInsNew.fecha_creacion = serverTimestamp();

        //insertando resumen de pedido anulado en el reporte
        ventaInsNew.id_turno = idTurno;
        transaccion.set(docReporteVentas.ref, ventaInsNew);

        //liberar mesa TODO
        pedidoCopy.ambiente_mesa.forEach(mesa => {
          const docMesa = docEmpRef.collection(colections.AMBIENTE_MESA).doc(mesa.id!);
          transaccion.update(docMesa.ref, {
            mesa_estado: EstadosMesaType.DISPONIBLE,
            usuario_modificacion: deleteField() //liberando la mesa
          });
        });


        this.transaccion.tx = true;
        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  async updateAgregarProductoPedido(
    pedido: Pedido,
    idEmpresa: string,
    idTurno: string,
    correoUsuario: string,
    historialVariacionPedido: EstadoPedido,
    historialVariacionProducto: EstadosProducto[],
    listaProductoPedido: IProductoTicket[]
  ): Promise<TransaccionModel> {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef
      .collection(colections.PEDIDO)
      .doc(pedido.id);
    const docHistorialPedidoRef = docPedidoRef
      .collection(colections.HISTORIAL_PEDIDO)
      .doc();

    //para actualizar cantidades de productos
    const docProductoRef = docEmpRef.collection(colections.PRODUCTO);
    const docProductoResumenRef = docEmpRef.collection(
      colections.PRODUCTO_RESUMEN
    );
    let nuevaCantidadProducto: number = 0;

    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        //INSERTANDO HISTORIAL DE PEDIDO
        const docHistorialPedido = Utils.SerializeJsonToDb(historialVariacionPedido);
        docHistorialPedido.fecha_creacion = serverTimestamp();
        transaccion.set(docHistorialPedidoRef.ref, docHistorialPedido);

        //INSERTANDO HISTORIAL DE PRODUCTO
        historialVariacionProducto.forEach((hprod) => {
          const historialProd: EstadosProducto = JSON.parse(
            JSON.stringify(hprod)
          );
          const docHistorialProdRef = docPedidoRef
            .collection(colections.HISTORIAL_PRODUCTO)
            .doc();
          const docHistorialProd = Utils.SerializeJsonToDb(historialProd);
          docHistorialProd.fecha_creacion = serverTimestamp();
          transaccion.set(docHistorialProdRef.ref, docHistorialProd);
        });

        //INSERTANDO LOS PRODUCTOS DEL PEDIDO
        listaProductoPedido.forEach((prod) => {
          const docProductoPedidoRef = docPedidoRef
            .collection(colections.PEDIDO_PRODUCTOS)
            .doc();
          const docPedidoProducto = Utils.SerializeJsonToDb(prod);
          docPedidoProducto.fecha_creacion = serverTimestamp();
          transaccion.set(docProductoPedidoRef.ref, docPedidoProducto);
        });

        let estadoPedido: EstadosPedidoType = EstadosPedidoType.PENDIENTE;
        if (
          pedido.estado_pedido_vigente == EstadosPedidoType.PENDIENTE_REVISION
        ) {
          estadoPedido = EstadosPedidoType.PENDIENTE_REVISION;
        }

        //ACTUALIZANDO PEDIDO
        transaccion.update(docPedidoRef.ref, {
          usuario_modificacion: correoUsuario,
          estado_pedido_vigente: estadoPedido,
          total_productos_pedidos: pedido.total_productos_pedidos,
          total: pedido.total,
          sub_total: pedido.sub_total,
          igv: pedido.igv,
          fecha_modificacion: serverTimestamp(), //para campos opcionales de estados generales de tipo de producto
          ...(pedido.estado_pedido_vigente_bebida !== undefined && {
            estado_pedido_vigente_bebida: pedido.estado_pedido_vigente_bebida,
          }),
          ...(pedido.estado_pedido_vigente_comida !== undefined && {
            estado_pedido_vigente_comida: pedido.estado_pedido_vigente_comida,
          }),
          ...(pedido.anotacion !== undefined && {
            anotacion: pedido.anotacion,
          }),
        });

        //ACTUALIZANDO CANTIDADES DE PRODUCTOS PARA STOCK LIMITADO
        for (const productoTicket of listaProductoPedido) {
          if (productoTicket.reducir_cantidad) {
            //recuperando producto
            const prodID = productoTicket.id_producto;
            let producto: Producto = new Producto();

            const docProducto = await docProductoRef
              .doc(prodID)
              .get()
              .toPromise();
            if (docProducto) {
              producto = docProducto.data() as Producto;
              producto.id = docProducto.id;

              //actualizando producto
              nuevaCantidadProducto =
                Number(producto.cantidad_stock) -
                Number(productoTicket.cantidad_producto);
              transaccion.update(docProductoRef.doc(prodID).ref, {
                cantidad_stock: nuevaCantidadProducto,
                es_vigente: nuevaCantidadProducto <= 0 ? false : true,
                usuario_actualizacion: correoUsuario,
              });

              //si ya no quedan mas productos
              if (nuevaCantidadProducto == 0) {
                //recuperando resumen
                let resumenProducto: IResumenProducto;
                const docResumen = await docProductoResumenRef
                  .doc()
                  .get()
                  .toPromise();

                if (docResumen.exists) {
                  resumenProducto = docResumen.data() as IResumenProducto;
                  resumenProducto.id_documento = docResumen.id;
                  let nuevaCantidadActivos =
                    Number(resumenProducto.total_productos_activos) - 1;
                  let nuevaCantidadInactivos =
                    Number(resumenProducto.total_productos_inactivos) - 1;

                  //actualizando resumen
                  transaccion.update(
                    docProductoResumenRef.doc(docResumen.id).ref,
                    {
                      total_productos_activos: nuevaCantidadActivos,
                      total_productos_inactivos: nuevaCantidadInactivos,
                    }
                  );
                }
              }

              ///ACTUALIZANDO cantidades en  CATALOGOS para stock limitado

              const docCatalogoRef = docEmpRef.collection(
                colections.CATALOGO,
                (ref) =>
                  ref
                    .where(Auditoria.es_borrado, '==', false)
                    .where(
                      documentCatalogo.CODIGO_PRODUCTOS_ANIDADOS_COMPLETO,
                      'array-contains',
                      productoTicket.codigo_producto
                    )
              );

              const catalogos = await docCatalogoRef.get().toPromise();
              if (!catalogos.empty) {
                catalogos.forEach((catalog) => {
                  const catalogData = catalog.data() as Catalogo;
                  catalogData.id = catalog.id;

                  catalogData.lista_productos.forEach((productoCat) => {
                    if (
                      productoCat.codigo_producto ==
                      productoTicket.codigo_producto
                    ) {
                      productoCat.cantidad_stock = nuevaCantidadProducto; //viene del calculo anterio de producto
                      (productoCat.es_vigente =
                        nuevaCantidadProducto <= 0 ? false : true),
                        (productoCat.usuario_modificacion = correoUsuario);
                    }
                  });

                  //actualizando catalogo
                  transaccion.update(
                    docEmpRef
                      .collection(colections.CATALOGO)
                      .doc(catalogData.id).ref,
                    {
                      lista_productos: catalogData.lista_productos,
                      usuario_modificacion: correoUsuario,
                      fecha_modificacion: serverTimestamp(),
                    }
                  );
                });
              }
            }
          }
        }
        this.transaccion.tx = true;
        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  async updateVariacionProducto(
    pedido: Pedido,
    idEmpresa: string,
    idTurno: string,
    correoUsuario: string,
    esOk: boolean,
    historialVariacionPedido: EstadoPedido,
    historialVariacionProducto: EstadosProducto[],
    productosVariados: IProductoTicket[]
  ): Promise<TransaccionModel> {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef
      .collection(colections.PEDIDO)
      .doc(pedido.id);
    const docHistorialPedidoRef = docPedidoRef
      .collection(colections.HISTORIAL_PEDIDO)
      .doc(pedido.id);

    let confMsj: any;
    let mensajeDocs: any;

    //recuperando los productos en tiempo real para determinar el estado
    let productosPedido: IProductoTicket[] = [];

    const productosPed = await docPedidoRef
      .collection(colections.PEDIDO_PRODUCTOS)
      .get()
      .toPromise();
    if (!productosPed.empty) {
      productosPed.docs.forEach((prdPedido) => {
        const prodTicket = prdPedido.data() as IProductoTicket;
        prodTicket.id = prdPedido.id;
        productosPedido.push(prodTicket);
      });
    }
    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        //INSERTANDO HISTORIAL DE PEDIDO
        const docHistorialPedido = Utils.SerializeJsonToDb(historialVariacionPedido);
        //docHistorialPedido.estado_vigente = this.determinarEstadoPedido(productosPedido),
        docHistorialPedido.fecha_creacion = serverTimestamp();
        transaccion.set(docHistorialPedidoRef.ref, docHistorialPedido);

        // INSERTANDO HISTORIAL DE PRODUCTO
        await Promise.all(
          historialVariacionProducto.map(async (hprod) => {
            const historialMesa: EstadosProducto = JSON.parse(JSON.stringify(hprod));
            const docHistorialProdRef = docPedidoRef.collection(colections.HISTORIAL_PRODUCTO).doc();
            const docHistorialProd = Utils.SerializeJsonToDb(historialMesa);
            docHistorialProd.fecha_creacion = serverTimestamp();
            transaccion.set(docHistorialProdRef.ref, docHistorialProd);
          })
        );

        //confirma la variacion
        if (esOk) {
          //ACTUALIZANDO PRODUCTOS
          await Promise.all(
            productosVariados.map(async (prod) => {
              const refProductoPedido = docPedidoRef
                .collection(colections.PEDIDO_PRODUCTOS)
                .doc(prod.id);

              //verificando que es stock de producto limitado(reducir indica si se debía reducir la cantidad)
              //en este caso se tiene que retornar las unidades que se estan devolviendo
              if (prod.reducir_cantidad) {
                //ACTUALIZANDO CANTIDADES DE PRODUCTOS
                const refProducto = docEmpRef
                  .collection(colections.PRODUCTO)
                  .doc(prod.id_producto);
                const productoDoc = await refProducto.get().toPromise();
                let productoEsVigente: boolean = false;

                let cantidadFinal = 0;
                let prodUPD: Producto;
                if (productoDoc.exists) {
                  prodUPD = productoDoc.data() as Producto;
                  prodUPD.id = productoDoc.id;
                  cantidadFinal = prod.es_modificado
                    ? Number(prodUPD.cantidad_stock) +
                    Number(prod.cantidad_producto_reducido)
                    : Number(prodUPD.cantidad_stock) + prod.cantidad_producto;

                  //producto vigente?
                  if (prodUPD.es_vigente) {
                    productoEsVigente = true;
                  }

                  //actualizando la cantidad de producto
                  transaccion.update(refProducto.ref, {
                    cantidad_stock: cantidadFinal,
                    es_vigente: true,
                    fecha_modificacion: serverTimestamp(),
                    usuario_modificacion: correoUsuario,
                  });
                }

                ///ACTUALIZANDO CANTIDADES DE PRODUCTOS EN  CATALOGOS (PARA STOCK LIMITADO)
                const docCatalogoRef = docEmpRef.collection(
                  colections.CATALOGO,
                  (ref) =>
                    ref
                      .where(Auditoria.es_borrado, '==', false)
                      .where(
                        documentCatalogo.CODIGO_PRODUCTOS_ANIDADOS_COMPLETO,
                        'array-contains',
                        prod.codigo_producto
                      )
                );
                const catalogos = await docCatalogoRef.get().toPromise();

                if (!catalogos.empty) {
                  catalogos.forEach((catalog) => {
                    const catalogData = catalog.data() as Catalogo;
                    catalogData.id = catalog.id;

                    catalogData.lista_productos.forEach((productoCat) => {
                      if (productoCat.codigo_producto == prod.codigo_producto) {
                        productoCat.cantidad_stock = cantidadFinal;
                        productoCat.es_vigente = true;
                        productoCat.usuario_modificacion = correoUsuario;
                      }
                    });

                    //actualizando catalogo
                    transaccion.update(
                      docEmpRef
                        .collection(colections.CATALOGO)
                        .doc(catalogData.id).ref,
                      {
                        lista_productos: catalogData.lista_productos,
                        usuario_modificacion: correoUsuario,
                        fecha_modificacion: serverTimestamp(),
                      }
                    );
                  });
                }

                //ACTUALIZANDO RESUMEN DE PRODUCTO, EN CASO DE QUE EL PRODUCTO NO ESTE VIGENTE
                if (!productoEsVigente) {
                  let resumenProducto: IResumenProducto;
                  const docProductoResumenRef = docEmpRef.collection(
                    colections.PRODUCTO_RESUMEN
                  );
                  const docResumen = await docProductoResumenRef
                    .doc()
                    .get()
                    .toPromise();
                  if (docResumen.exists) {
                    resumenProducto = docResumen.data() as IResumenProducto;
                    resumenProducto.id_documento = docResumen.id;
                    //si el producto no es vigente, ya que se va a retornar unidades, entonces se activa una unidad en el catalogo
                    let nuevaCantidadActivos = !productoEsVigente
                      ? Number(resumenProducto.total_productos_activos) + 1
                      : Number(resumenProducto.total_productos_activos);
                    let nuevaCantidadInactivos = !productoEsVigente
                      ? Number(resumenProducto.total_productos_inactivos) - 1
                      : Number(resumenProducto.total_productos_inactivos);

                    //actualizando resumen
                    transaccion.update(
                      docProductoResumenRef.doc(docResumen.id).ref,
                      {
                        total_productos_activos: nuevaCantidadActivos,
                        total_productos_inactivos: nuevaCantidadInactivos,
                      }
                    );
                  }
                }
              }
              //#endregion

              if (prod.es_modificado) {
                transaccion.update(refProductoPedido.ref, {
                  es_modificado: deleteField(),
                  proceso: deleteField(),
                  cantidad_producto_reducido: deleteField(),
                  estado_cambio: deleteField(),
                  cantidad_producto: prod.cantidad_producto,
                  precio_total: prod.precio_total,
                  usuario_modificacion: correoUsuario,
                  estado_producto_vigente: prod.estado_producto_vigente,
                  ...(prod.lista_caract_seleccionada_individualizada_anterior !==
                    undefined && {
                    lista_caract_seleccionada_individualizada_anterior:
                      deleteField(),
                  }),
                });
              }
              if (prod.es_eliminado) {
                transaccion.delete(refProductoPedido.ref);
              }
            })
          );

          //actualizando pedido
          transaccion.update(docPedidoRef.ref, {
            usuario_modificacion: correoUsuario,
            total_productos_pedidos: pedido.total_productos_pedidos,
            estado_pedido_vigente: this.determinarEstadoPedido(productosPedido),
            ivg: pedido.igv,
            sub_total: pedido.sub_total,
            total: pedido.total,
            fecha_modificacion: serverTimestamp(),
            //para campos opcionales de estados generales de tipo de producto
            ...(pedido.estado_pedido_vigente_bebida !== undefined && {
              estado_pedido_vigente_bebida: pedido.estado_pedido_vigente_bebida,
            }),
            ...(pedido.estado_pedido_vigente_comida !== undefined && {
              estado_pedido_vigente_comida: pedido.estado_pedido_vigente_comida,
            }),
          });
        }
        //rechaza variacion
        else {
          //ACTUALIZANDO PRODUCTOS
          productosVariados.forEach((prod) => {
            const refProductoPedido = docPedidoRef
              .collection(colections.PEDIDO_PRODUCTOS)
              .doc(prod.id);

            if (prod.es_modificado) {
              transaccion.update(refProductoPedido.ref, {
                es_modificado: deleteField(),
                proceso: deleteField(),
                cantidad_producto_reducido: deleteField(),
                estado_cambio: deleteField(),
                usuario_modificacion: correoUsuario,
                estado_producto_vigente: prod.estado_producto_vigente,
                ...(prod.lista_caract_seleccionada !== undefined && {
                  lista_caract_seleccionada: prod.lista_caract_seleccionada,
                }),
                ...(prod.lista_caract_seleccionada_individualizada !==
                  undefined && {
                  lista_caract_seleccionada_individualizada:
                    prod.lista_caract_seleccionada_individualizada,
                }),
                ...(prod.lista_caract_seleccionada_individualizada_anterior !==
                  undefined && {
                  lista_caract_seleccionada_individualizada_anterior:
                    deleteField(),
                }),
              });
            }

            if (prod.es_eliminado) {
              transaccion.update(refProductoPedido.ref, {
                es_eliminado: deleteField(),
                proceso: deleteField(),
                estado_cambio: deleteField(),
                estado_producto_vigente: prod.estado_producto_vigente,
                usuario_modificacion: correoUsuario,
              });
            }
          });

          //actualizando pedido
          transaccion.update(docPedidoRef.ref, {
            usuario_modificacion: correoUsuario,
            estado_pedido_vigente: this.determinarEstadoPedido(productosPedido),
            fecha_modificacion: serverTimestamp(),
          });
        }

        //recuperando mensaje para eliminarlo
        confMsj = await docEmpRef
          .collection(colections.MENSAJE, (ref2) =>
            ref2
              .where(documentMensaje.ID_REF, '==', pedido.id)
              .where(
                documentMensaje.ESTADO_CAMBIO,
                '==',
                SolicitarCambioType.SOLICITADO
              )
              .where(documentMensaje.PROCESO, '==', ProcesoType.VARIAR_PRODUCTO)
          )
          .get()
          .toPromise();
        mensajeDocs = confMsj.docs.map((doc: { ref: any }) => doc.ref);
        //eliminando el mensaje
        if (mensajeDocs.length > 0) {
          transaccion.delete(mensajeDocs[0]); //siempre existirá un solo documento
        }

        this.transaccion.tx = true;
        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  async updateVariacionMesa(
    pedido: Pedido,
    idEmpresa: string,
    idTurno: string,
    correoUsuario: string,
    esOk: boolean,
    fecha: Fecha,
    historialVariacionMesa: IAmbienteMesa[],
    historialVariacionPedido: EstadoPedido
  ) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.AMBIENTE_MESA);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(pedido.id);
    const docHistorialPedidoRef = docPedidoRef.collection(colections.HISTORIAL_PEDIDO).doc();

    let confMsj: any;
    let mensajeDocs: any;

    //recuperando los productos en tiempo real para determinar el estado
    let productosPedido: IProductoTicket[] = [];

    const productosPed = await docPedidoRef.collection(colections.PEDIDO_PRODUCTOS).get().toPromise();
    if (!productosPed.empty) {
      productosPed.docs.forEach((prdPedido) => {
        const prodTicket = prdPedido.data() as IProductoTicket;
        prodTicket.id = prdPedido.id;
        productosPedido.push(prodTicket);
      });
    }
    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        //confirma la variacion
        if (esOk) {
          /*(c) actualizando estado mesa */
          pedido.ambiente_mesa.forEach((amb_mesa) => {
            //cuando se añade una mesa
            if (
              amb_mesa.estado_cambio == SolicitarCambioType.APROBADO &&
              amb_mesa.mesa_estado == EstadosMesaType.OCUPADO &&
              amb_mesa.accion_mesa &&
              amb_mesa.accion_mesa == AccionMesaType.ANIADIR
            ) {
              transaccion.update(docConfigRef.doc(amb_mesa.id).ref, {
                mesa_estado: EstadosMesaType.OCUPADO,
                fecha_modificacion: serverTimestamp(),
                usuario_modificacion: correoUsuario,
              });
            }
            //cuando se elimina una mesa
            historialVariacionMesa.forEach((amb_mesa) => {
              if (
                amb_mesa.estado_cambio == SolicitarCambioType.APROBADO &&
                amb_mesa.mesa_estado == EstadosMesaType.DISPONIBLE &&
                amb_mesa.es_vigente &&
                amb_mesa.accion_mesa == AccionMesaType.QUITAR
              ) {
                transaccion.update(docConfigRef.doc(amb_mesa.id).ref, {
                  mesa_estado: EstadosMesaType.DISPONIBLE,
                  fecha_modificacion: serverTimestamp(),
                  usuario_modificacion: correoUsuario,
                });
              }
            });
          });
        }
        //rechaza variacion
        else {
          historialVariacionMesa.forEach((amb_mesa) => {
            //cuando se añade una mesa
            if (
              amb_mesa.estado_cambio == SolicitarCambioType.RECHAZADO &&
              amb_mesa.mesa_estado == EstadosMesaType.RESERVADO &&
              amb_mesa.es_vigente &&
              amb_mesa.accion_mesa == AccionMesaType.ANIADIR
            ) {
              transaccion.update(docConfigRef.doc(amb_mesa.id).ref, {
                mesa_estado: EstadosMesaType.DISPONIBLE,
                fecha_modificacion: serverTimestamp(),
                usuario_modificacion: correoUsuario,
              });
            } else {
              //DEJAR ASI
              //en el caso de quitar no se actualizan las mesas, porque mantienen su estado (ocupado)
              //solo se actualiza el estado de la mesa de pedido
            }
          });
        }
        //actualizando pedido mesas
        transaccion.update(docPedidoRef.ref, {
          ambiente_mesa: pedido.ambiente_mesa,
          fecha_modificacion: serverTimestamp(),
          usuario_modificacion: correoUsuario,
          estado_pedido_vigente: this.determinarEstadoPedido(productosPedido),
          mesas_completo: pedido.mesas_completo,
        });

        //insertando historial
        historialVariacionMesa.forEach((amb_mesa) => {
          const historial: IAmbienteMesa = JSON.parse(JSON.stringify(amb_mesa));
          delete historial.fecha_modificacion;
          const docHistorialMesaRef = docPedidoRef
            .collection(colections.HISTORIAL_MESA)
            .doc();
          const docHistorialMesa = Utils.SerializeJsonToDb(historial);
          docHistorialMesa.fecha_creacion = serverTimestamp();
          transaccion.set(docHistorialMesaRef.ref, docHistorialMesa);
        });

        //INSERTANDO HISTORIAL PEDIDO
        const docHistorialPedido = Utils.SerializeJsonToDb(historialVariacionPedido);
        docHistorialPedido.fecha_creacion = serverTimestamp();
        transaccion.set(docHistorialPedidoRef.ref, docHistorialPedido);

        //recuperando mensaje para eliminarlo
        confMsj = await docEmpRef
          .collection(colections.MENSAJE, (ref2) =>
            ref2
              .where(documentMensaje.ID_REF, '==', pedido.id)
              .where(
                documentMensaje.ESTADO_CAMBIO,
                '==',
                SolicitarCambioType.SOLICITADO
              )
              .where(
                documentMensaje.PROCESO,
                '==',
                ProcesoType.CAMBIAR_VARIAR_MESA
              )
          )
          .get()
          .toPromise();
        mensajeDocs = confMsj.docs.map((doc: { ref: any }) => doc.ref);
        //eliminando el mensaje
        if (mensajeDocs.length > 0) {
          transaccion.delete(mensajeDocs[0]); //siempre existirá un solo documento
        }

        this.transaccion.tx = true;
        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  async updatePedido(
    pedido: Pedido,
    idEmpresa: string,
    idTurno: string,
    correoUsuario: string,
    esEditarMesa: boolean,
    esEditarProducto: boolean,
    esAdministrador: boolean,
    historialVariacionMesa: IAmbienteMesa[],
    historialVariacionProducto: EstadosProducto[],
    historialVariacionPedido: EstadoPedido,
    listaProductoEliminado: IProductoTicket[],
    goOptionSidenav: ItemSidenav
  ): Promise<TransaccionModel> {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(pedido.id);
    const docConfigRef = docEmpRef.collection(colections.AMBIENTE_MESA);
    const docHistorialPedidoRef = docPedidoRef.collection(colections.HISTORIAL_PEDIDO).doc();
    const mensajeRef = docEmpRef.collection(colections.MENSAJE).doc();

    //recuperando los productos en tiempo real para determinar el estado
    let productosPedido: IProductoTicket[] = [];
    let productos_variados: IProductoTicket[] = [];

    if (pedido.lista_producto) {
      productos_variados = pedido.lista_producto.filter(
        (prod) => prod.es_eliminado || prod.es_modificado
      );
    }

    if (esAdministrador) {
      const productosPed = await docPedidoRef
        .collection(colections.PEDIDO_PRODUCTOS).get().toPromise();
      if (!productosPed.empty) {
        productosPed.docs.forEach((prdPedido) => {
          const prodTicket = prdPedido.data() as IProductoTicket;
          prodTicket.id = prdPedido.id;
          productosPedido.push(prodTicket);
        });
      }
    }

    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        //insertando historial
        /*inserta mensaje si no es administrador, para aprobacion del pedido*/
        if (!esAdministrador) {
          if (esEditarMesa) {
            const mesas_revision = pedido.ambiente_mesa.filter(
              (amb_mesa) =>
                amb_mesa.estado_cambio == SolicitarCambioType.SOLICITADO &&
                amb_mesa.proceso == ProcesoType.CAMBIAR_VARIAR_MESA &&
                amb_mesa.es_vigente
            );
            let listaMesas: string[] = new Array();
            let listaAccion: string[] = new Array();
            mesas_revision.forEach((mesas) => {
              listaMesas.push(mesas.mesa_numero.toString());
              listaAccion.push(mesas.accion_mesa);
            });
            //generando mensaje;

            const mensaje: Mensaje = {
              es_vigente: true,
              estado_cambio: SolicitarCambioType.SOLICITADO,
              id_ref: pedido.id ? pedido.id : '',
              usuario_creacion: correoUsuario,
              proceso: ProcesoType.CAMBIAR_VARIAR_MESA,
              fecha_creacion: serverTimestamp(),
              es_borrado: false,
              anterior_existente: Utils.generarMesasFila(pedido.mesas_completo),
              actual_pedido: Utils.generarMesasFila(listaMesas),
              accion: Utils.generarMesasFila(listaAccion),
              modulo: goOptionSidenav.modulo!
            };
            transaccion.set(mensajeRef.ref, mensaje);

            //ACTUALIZANDO pedido
            transaccion.update(docPedidoRef.ref, {
              ambiente_mesa: pedido.ambiente_mesa,
              estado_pedido_vigente: pedido.estado_pedido_vigente,
              usuario_modificacion: correoUsuario,
              fecha_modificacion: serverTimestamp(),
            });
          }
          if (esEditarProducto) {
            let productosReducidos: string[] = new Array();
            let productosEliminados: string[] = new Array();
            productos_variados.forEach((producto) => {
              if (producto.es_eliminado) {
                productosEliminados.push(producto.nombre_producto);
              }
              if (producto.es_modificado) {
                productosReducidos.push(producto.nombre_producto);
              }
            });

            const strines = Utils.generarProductosVariados(productos_variados);
            //generando mensaje;
            const mensaje: Mensaje = {
              es_vigente: true,
              estado_cambio: SolicitarCambioType.SOLICITADO,
              id_ref: pedido.id ? pedido.id : '',
              usuario_creacion: correoUsuario,
              proceso: ProcesoType.VARIAR_PRODUCTO,
              fecha_creacion: serverTimestamp(),
              es_borrado: false,
              anterior_existente: strines[0],
              actual_pedido: strines[1],
              modulo: goOptionSidenav.modulo!
            };
            transaccion.set(mensajeRef.ref, mensaje);

            //ACTUALIZANDO PRODUCTOS
            productos_variados.forEach((prod) => {
              const refProducto = docPedidoRef.collection(colections.PEDIDO_PRODUCTOS).doc(prod.id);
              if (prod.es_modificado) {
                
                transaccion.update(refProducto.ref, {
                  es_modificado: true,
                  proceso: prod.proceso,
                  cantidad_producto_reducido: prod.cantidad_producto_reducido,
                  estado_cambio: prod.estado_cambio,
                  usuario_modificacion: correoUsuario,
                  estado_producto_vigente:
                    EstadosProductoType.PENDIENTE_REVISION,
                  ...(prod.lista_caract_seleccionada !== undefined && {
                    lista_caract_seleccionada: prod.lista_caract_seleccionada,
                  }),
                  ...(prod.lista_caract_seleccionada_individualizada !==
                    undefined && {
                    lista_caract_seleccionada_individualizada:
                      prod.lista_caract_seleccionada_individualizada,
                  }),
                  ...(prod.lista_caract_seleccionada_individualizada_anterior !==
                    undefined && {
                    lista_caract_seleccionada_individualizada_anterior:
                      prod.lista_caract_seleccionada_individualizada_anterior,
                  }),
                });
              }
              if (prod.es_eliminado) {
                transaccion.update(refProducto.ref, {
                  es_eliminado: true,
                  proceso: prod.proceso,
                  estado_cambio: prod.estado_cambio,
                  usuario_modificacion: prod.usuario_modificacion,
                  estado_producto_vigente:
                    EstadosProductoType.PENDIENTE_REVISION,
                });
              }
            });
            //actualizando estado pedido
            transaccion.update(docPedidoRef.ref, {
              usuario_modificacion: correoUsuario,
              estado_pedido_vigente: EstadosPedidoType.PENDIENTE_REVISION,
              fecha_modificacion: serverTimestamp(),
            });
          }
        }
        //es administrador
        else {
          if (esEditarMesa) {
            /*(c) actualizando estado mesa */
            pedido.ambiente_mesa.forEach((amb_mesa) => {
              //cuando se añade una mesa
              if (
                amb_mesa.estado_cambio == SolicitarCambioType.APROBADO &&
                amb_mesa.mesa_estado == EstadosMesaType.OCUPADO &&
                amb_mesa.accion_mesa &&
                amb_mesa.accion_mesa == AccionMesaType.ANIADIR
              ) {
                transaccion.update(docConfigRef.doc(amb_mesa.id).ref, {
                  mesa_estado: EstadosMesaType.OCUPADO,
                  fecha_modificacion: serverTimestamp(),
                  usuario_modificacion: correoUsuario,
                });
              }
            });
            //cuando se elimnan mesas
            historialVariacionMesa.forEach((amb_mesa) => {
              if (
                amb_mesa.estado_cambio == SolicitarCambioType.APROBADO &&
                amb_mesa.mesa_estado == EstadosMesaType.DISPONIBLE &&
                amb_mesa.es_vigente &&
                amb_mesa.accion_mesa == AccionMesaType.QUITAR
              ) {
                //actualizacion de mesa
                transaccion.update(docConfigRef.doc(amb_mesa.id).ref, {
                  mesa_estado: EstadosMesaType.DISPONIBLE,
                  fecha_modificacion: serverTimestamp(),
                  usuario_modificacion: correoUsuario,
                });
              }
            });

            //ACTUALIZANDO pedido
            transaccion.update(docPedidoRef.ref, {
              ambiente_mesa: pedido.ambiente_mesa,
              mesas_completo: pedido.mesas_completo,
              estado_pedido_vigente: this.determinarEstadoPedido(productosPedido),
              usuario_modificacion: correoUsuario,
              fecha_modificacion: serverTimestamp(),
            });
          }
          if (esEditarProducto) {
            await Promise.all(
              listaProductoEliminado.map(async (prod) => {
                const refProductoPed = docPedidoRef
                  .collection(colections.PEDIDO_PRODUCTOS)
                  .doc(prod.id);

                //#region update producto catalogo

                if (prod.es_eliminado) {
                  transaccion.delete(refProductoPed.ref);
                }

                if (prod.reducir_cantidad) {
                  const refProd = docEmpRef
                    .collection(colections.PRODUCTO)
                    .doc(prod.id_producto);
                  const productosPed = await refProd.get().toPromise();
                  let productoEsVigente: boolean = false;

                  let cantidadFinal = 0;
                  let prodUPD: Producto;
                  if (productosPed.exists) {
                    prodUPD = productosPed.data() as Producto;
                    prodUPD.id = productosPed.id;

                    cantidadFinal =
                      Number(prodUPD.cantidad_stock) + prod.cantidad_producto;

                    //producto vigente?
                    if (prodUPD.es_vigente) {
                      productoEsVigente = true;
                    }

                    //actualizando la cantidad de producto
                    transaccion.update(refProd.ref, {
                      cantidad_stock: cantidadFinal,
                      es_vigente: true,
                      fecha_modificacion: serverTimestamp(),
                      usuario_modificacion: correoUsuario,
                    });
                  }

                  ///ACTUALIZANDO cantidades en  CATALOGOS para stock limitado
                  const docCatalogoRef = docEmpRef.collection(
                    colections.CATALOGO,
                    (ref) =>
                      ref
                        .where(Auditoria.es_borrado, '==', false)
                        .where(
                          documentCatalogo.CODIGO_PRODUCTOS_ANIDADOS_COMPLETO,
                          'array-contains',
                          prod.codigo_producto
                        )
                  );
                  const catalogos = await docCatalogoRef.get().toPromise();
                  if (!catalogos.empty) {
                    catalogos.forEach((catalog) => {
                      const catalogData = catalog.data() as Catalogo;
                      catalogData.id = catalog.id;

                      catalogData.lista_productos.forEach((productoCat) => {
                        if (
                          productoCat.codigo_producto == prod.codigo_producto
                        ) {
                          productoCat.cantidad_stock = cantidadFinal;
                          productoCat.es_vigente = true;
                          productoCat.usuario_modificacion = correoUsuario;
                        }
                      });

                      //actualizando catalogo
                      transaccion.update(
                        docEmpRef
                          .collection(colections.CATALOGO)
                          .doc(catalogData.id).ref,
                        {
                          lista_productos: catalogData.lista_productos,
                          usuario_modificacion: correoUsuario,
                          fecha_modificacion: serverTimestamp(),
                        }
                      );
                    });
                  }

                  //ACTUALIZANDO RESUMEN DE PRODUCTO, EN CASO DE QUE EL PRODUCTO NO ESTE VIGENTE
                  if (!productoEsVigente) {
                    let resumenProducto: IResumenProducto;
                    const docProductoResumenRef = docEmpRef.collection(
                      colections.PRODUCTO_RESUMEN
                    );
                    const docResumen = await docProductoResumenRef
                      .doc()
                      .get()
                      .toPromise();
                    if (docResumen.exists) {
                      resumenProducto = docResumen.data() as IResumenProducto;
                      resumenProducto.id_documento = docResumen.id;
                      //si el producto no es vigente, ya que se va a retornar unidades, entonces se activa una unidad en el catalogo
                      let nuevaCantidadActivos = !productoEsVigente
                        ? Number(resumenProducto.total_productos_activos) + 1
                        : Number(resumenProducto.total_productos_activos);
                      let nuevaCantidadInactivos = !productoEsVigente
                        ? Number(resumenProducto.total_productos_inactivos) - 1
                        : Number(resumenProducto.total_productos_inactivos);

                      //actualizando resumen
                      transaccion.update(
                        docProductoResumenRef.doc(docResumen.id).ref,
                        {
                          total_productos_activos: nuevaCantidadActivos,
                          total_productos_inactivos: nuevaCantidadInactivos,
                        }
                      );
                    }
                  }
                }
                //#endregion
              })
            );

            if (pedido.lista_producto) {
              await Promise.all(
                pedido.lista_producto.map(async (prod) => {
                  //pedido.lista_producto?.forEach(async prod => {
                  const refProducto = docPedidoRef
                    .collection(colections.PEDIDO_PRODUCTOS)
                    .doc(prod.id);
                  if (prod.es_modificado) {
                    transaccion.update(refProducto.ref, {
                      cantidad_producto: prod.cantidad_producto,
                      precio_total: prod.precio_total,
                      precio_unitario: prod.precio_unitario,
                      usuario_modificacion: correoUsuario,
                      ...(prod.lista_caract_seleccionada !== undefined && {
                        lista_caract_seleccionada:
                          prod.lista_caract_seleccionada,
                      }),
                      ...(prod.lista_caract_seleccionada_individualizada !==
                        undefined && {
                        lista_caract_seleccionada_individualizada:
                          prod.lista_caract_seleccionada_individualizada,
                      }),
                    });

                    //#region update producto, catalogo
                    if (prod.reducir_cantidad) {
                      const refEmpresa = docEmpRef
                        .collection(colections.PRODUCTO)
                        .doc(prod.id_producto);
                      const productosPed = await refEmpresa.get().toPromise();
                      let cantidadFinal = 0;
                      let prodUPD: Producto;
                      if (productosPed.exists) {
                        prodUPD = productosPed.data() as Producto;
                        prodUPD.id = productosPed.id;

                        cantidadFinal =
                          Number(prodUPD.cantidad_stock) +
                          Number(prod.cantidad_producto_reducido);

                        //actualizando la cantidad de producto
                        transaccion.update(refEmpresa.ref, {
                          cantidad_stock: cantidadFinal,
                          es_vigente: true,
                          fecha_modificacion: serverTimestamp(),
                          usuario_modificacion: correoUsuario,
                        });
                      }

                      ///ACTUALIZANDO cantidades en  CATALOGOS para stock limitado
                      const docCatalogoRef = docEmpRef.collection(
                        colections.CATALOGO,
                        (ref) =>
                          ref
                            .where(Auditoria.es_borrado, '==', false)
                            .where(
                              documentCatalogo.CODIGO_PRODUCTOS_ANIDADOS_COMPLETO,
                              'array-contains',
                              prod.codigo_producto
                            )
                      );
                      const catalogos = await docCatalogoRef.get().toPromise();
                      if (!catalogos.empty) {
                        catalogos.forEach((catalog) => {
                          const catalogData = catalog.data() as Catalogo;
                          catalogData.id = catalog.id;

                          catalogData.lista_productos.forEach((productoCat) => {
                            if (
                              productoCat.codigo_producto ==
                              prod.codigo_producto
                            ) {
                              productoCat.cantidad_stock = cantidadFinal;
                              productoCat.es_vigente = true;
                              productoCat.usuario_modificacion = correoUsuario;
                            }
                          });

                          //actualizando catalogo
                          transaccion.update(
                            docEmpRef
                              .collection(colections.CATALOGO)
                              .doc(catalogData.id).ref,
                            {
                              lista_productos: catalogData.lista_productos,
                              usuario_modificacion: correoUsuario,
                              fecha_modificacion: serverTimestamp(),
                            }
                          );
                        });
                      }
                    }
                    //#endregion
                  }
                })
              );
            }

            //ACTUALIZANDO pedido
            if (pedido.lista_producto) {
              transaccion.update(docPedidoRef.ref, {
                total_productos_pedidos: pedido.total_productos_pedidos,
                total: pedido.total,
                sub_total: pedido.sub_total,
                igv: pedido.igv,
                ...(pedido.servicio_total !== undefined && {
                  servicio_total: pedido.servicio_total,
                  servicio_subtotal: pedido.servicio_total,
                  servicio_igv: pedido.servicio_igv
                }),
                usuario_modificacion: correoUsuario,
                estado_pedido_vigente:
                  this.determinarEstadoPedido(productosPedido),
                fecha_modificacion: serverTimestamp(),
                //para campos opcionales de estados generales de tipo de producto en pedido
                ...(pedido.estado_pedido_vigente_bebida !== undefined && {
                  estado_pedido_vigente_bebida:
                    pedido.estado_pedido_vigente_bebida,
                }),
                ...(pedido.estado_pedido_vigente_comida !== undefined && {
                  estado_pedido_vigente_comida:
                    pedido.estado_pedido_vigente_comida,
                }),
              });
            }
          }
        }

        //INSERTANDO HISTORIAL PEDIDO tanto para mesa o producto
        const docHistorialPedido = Utils.SerializeJsonToDb(
          historialVariacionPedido
        );
        docHistorialPedido.fecha_creacion = serverTimestamp();
        transaccion.set(docHistorialPedidoRef.ref, docHistorialPedido);

        if (esEditarMesa) {
          //INSERTANDO HISTORIAL MESA
          historialVariacionMesa.forEach((amb_mesa) => {
            const historial: IAmbienteMesa = JSON.parse(
              JSON.stringify(amb_mesa)
            );
            delete historial.fecha_modificacion;
            const docHistorialMesaRef = docPedidoRef
              .collection(colections.HISTORIAL_MESA).doc();

            const docHistorialMesa = Utils.SerializeJsonToDb(historial);
            docHistorialMesa.fecha_creacion = serverTimestamp();
            transaccion.set(docHistorialMesaRef.ref, docHistorialMesa);
          });
        }
        if (esEditarProducto) {
          //INSERTANDO HISTORIAL DE PRODUCTO
          historialVariacionProducto.forEach((hprod) => {
            const historialMesa: EstadosProducto = JSON.parse(
              JSON.stringify(hprod)
            );
            const docHistorialProdRef = docPedidoRef
              .collection(colections.HISTORIAL_PRODUCTO).doc();
            const docHistorialProd = Utils.SerializeJsonToDb(historialMesa);
            docHistorialProd.fecha_creacion = serverTimestamp();
            transaccion.set(docHistorialProdRef.ref, docHistorialProd);
          });
        }

        const newPedidoId = docPedidoRef.ref.id;
        this.transaccion = {
          tx: true,
          data: {
            idPedido: newPedidoId,
          },
        };

        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
  }

  async insertPedidoTraspaso(
    pedidoTraspaso: Pedido,
    idEmpresa: string,
    idTurno: string,
    usuario: string,
    listaProductoPedidoTraspaso: IProductoTicket[],
    historialVariacionPedidoTraspaso: EstadoPedido,
    historialVariacionMesaTraspaso: IAmbienteMesa[],
    historialVariacionProductoTraspaso: EstadosProducto[],
    //
    pedidoOriginal: Pedido,    
    listaProductoPedidoOriginal: IProductoTicket[],
    historialVariacionMesaOriginal: IAmbienteMesa[],
    historialVariacionPedidoOriginal: EstadoPedido,
    historialVariacionProductoOriginal: EstadosProducto[],
    esDividir: boolean,
    esMesaExistente: boolean,
    pedidoOcupado: Pedido,
  ) {
    //PARAMETROS NUEVO PEDIDO
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc();

    //PARAMETROS PEDIDO ORIGINAL
    const docEmpRefOri = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRefOri = docEmpRefOri.collection(colections.TURNO).doc(idTurno);
    const docPedidoRefOri = docTurnoRefOri.collection(colections.PEDIDO).doc(pedidoOriginal.id);
    //const docHistorialPedidoRefOri = docPedidoRefOri.collection(colections.HISTORIAL_PEDIDO).doc();

    let productosPedido: IProductoTicket[] = [];
    let listaProductoParaTraslado: IProductoTicket[] = [];
    let listaProductoOriginal: IProductoTicket[] = [];

    //recuperando productos del pedido original para poder obtener los ESTADOS ACTUALIZADOS
    //en la capa anterior podrian variar los estados
    const productosPed = await docPedidoRefOri.collection(colections.PEDIDO_PRODUCTOS).get().toPromise();
    if (!productosPed.empty) {
      productosPed.docs.forEach((prdPedido) => {
        const prodTicket = prdPedido.data() as IProductoTicket;
        prodTicket.id = prdPedido.id;
        productosPedido.push(prodTicket);
      });
    }

    //lista productos trasladados
    listaProductoParaTraslado = productosPedido.filter((prod) =>
      listaProductoPedidoTraspaso.some(
        (p) =>
          p.codigo_ticket === prod.codigo_ticket &&
          p.codigo_producto === prod.codigo_producto
      )
    );

    try {
      //creamos la transaccion
      const txResult = await this.db.firestore.runTransaction(async (transaccion) => {
        //traspaso a nueva mesa
        if (!esMesaExistente) {
          pedidoTraspaso.estado_pedido_vigente = this.determinarEstadoPedido(listaProductoParaTraslado);
          const pedidoTrasladoTMP = this.determinarEstadoPedidoParaComidaBebida(listaProductoParaTraslado, pedidoTraspaso);

          //seteando nuevo estado a pedido TRASPASO tambien para comidas y bebidas
          if (pedidoTraspaso.estado_pedido_vigente_bebida != undefined) {
            pedidoTraspaso.estado_pedido_vigente_bebida = pedidoTrasladoTMP.estado_pedido_vigente_bebida;
          }
          if (pedidoTraspaso.estado_pedido_vigente_comida != undefined) {
            pedidoTraspaso.estado_pedido_vigente_comida = pedidoTrasladoTMP.estado_pedido_vigente_comida;
          }
        }
        //traspaso a mesa existente
        else {
          pedidoOcupado.estado_pedido_vigente = this.determinarEstadoPedido(listaProductoParaTraslado);
          const pedidoTrasladoTMP = this.determinarEstadoPedidoParaComidaBebida(listaProductoParaTraslado, pedidoOcupado);

          //seteando nuevo estado a pedido TRASPASO tambien para comidas y bebidas
          if (pedidoOcupado.estado_pedido_vigente_bebida != undefined) {
            pedidoOcupado.estado_pedido_vigente_bebida = pedidoTrasladoTMP.estado_pedido_vigente_bebida;
          }
          if (pedidoOcupado.estado_pedido_vigente_comida != undefined) {
            pedidoOcupado.estado_pedido_vigente_comida = pedidoTrasladoTMP.estado_pedido_vigente_comida;
          }
        }


        //se discrimina si es destino mesa ocuapada o mesa nueva
        historialVariacionPedidoTraspaso.estado_pedido = esMesaExistente ? pedidoOcupado.estado_pedido_vigente : pedidoTraspaso.estado_pedido_vigente;

        if (!esMesaExistente) {
          let docPedido = Utils.SerializeJsonToDb(pedidoTraspaso);
          docPedido.fecha_creacion = serverTimestamp();

          //INSERTANDO PEDIDO TRASPASO (MESA NUEVA)
          transaccion.set(docPedidoRef.ref, docPedido);
        }
        else {
          const pedidoOriginalTMP = this.determinarEstadoPedidoParaComidaBebida(listaProductoPedidoOriginal, pedidoOcupado);
          const docPedidoRefInsert = docTurnoRef.collection(colections.PEDIDO).doc(pedidoOcupado.id);

          //ACTUALIZANDO PEDIDO TRASPASO EXISTENTE(MESA OCUPADO)
          transaccion.update(docPedidoRefInsert.ref, {
            total_productos_pedidos: pedidoOcupado.total_productos_pedidos,
            total: pedidoOcupado.total,
            sub_total: pedidoOcupado.sub_total,
            igv: pedidoOcupado.igv,
            ...(pedidoOcupado.servicio_total !== undefined && {
              servicio_total: pedidoOcupado.servicio_total,
              servicio_subtotal: pedidoOcupado.servicio_subtotal,
              servicio_igv: pedidoOcupado.servicio_igv,

            }),
            usuario_modificacion: usuario,
            estado_pedido_vigente: this.determinarEstadoPedido(listaProductoPedidoOriginal),
            fecha_modificacion: serverTimestamp(),
            //para campos opcionales de estados generales de tipo de producto en pedido
            ...(pedidoOcupado.estado_pedido_vigente_bebida !== undefined && { estado_pedido_vigente_bebida: pedidoOriginalTMP.estado_pedido_vigente_bebida }),
            ...(pedidoOcupado.estado_pedido_vigente_comida !== undefined && { estado_pedido_vigente_comida: pedidoOriginalTMP.estado_pedido_vigente_comida }),
          });
        }


        //INSERTANDO LOS PRODUCTOS DEL PEDIDO TRASPASO MESA NUEVA O MESA EXISTENTE
        const idPedido = esMesaExistente ? pedidoOcupado.id! : docPedidoRef.ref.id;

        listaProductoPedidoTraspaso.forEach((prod) => {
          const docProductoPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(idPedido).collection(colections.PEDIDO_PRODUCTOS).doc();

          //actualizando estado real del producto
          const productoCEstadoActual = listaProductoParaTraslado.filter((p) => p.codigo_producto == prod.codigo_producto &&
            p.codigo_ticket == prod.codigo_ticket)[0];

          //si el producto es combo
          if (productoCEstadoActual.tipo_producto === ListaTipoProducto.listaTipoProducto[6].codigo_producto) {
            //si tiene bebida
            if (productoCEstadoActual.estado_producto_vigente_bebida != undefined) {
              prod.estado_producto_vigente_bebida = productoCEstadoActual.estado_producto_vigente_bebida;
            }
            //si tiene comida
            if (productoCEstadoActual.estado_producto_vigente_comida != undefined) {
              prod.estado_producto_vigente_comida = productoCEstadoActual.estado_producto_vigente_comida;
            }
          }

          //seteando el estado actual del producto
          prod.estado_producto_vigente = productoCEstadoActual.estado_producto_vigente;
          //insertando el producto con estado actualizado
          const docPedidoProducto = Utils.SerializeJsonToDb(prod);
          docPedidoProducto.fecha_creacion = serverTimestamp();
          transaccion.set(docProductoPedidoRef.ref, docPedidoProducto);
        });

        //INSERTANDO HISTORIAL DE MESA TRASPASO
        historialVariacionMesaTraspaso.forEach((mesa) => {
          const historial: IAmbienteMesa = JSON.parse(JSON.stringify(mesa));
          delete historial.fecha_modificacion;
          const docHistorialMesaRef = docTurnoRef.collection(colections.PEDIDO).doc(idPedido).collection(colections.HISTORIAL_MESA).doc();
          const docHistorialMesa = Utils.SerializeJsonToDb(historial);
          docHistorialMesa.fecha_creacion = serverTimestamp();
          transaccion.set(docHistorialMesaRef.ref, docHistorialMesa);
        });
        //INSERTANDO HISTORIAL MESA ORIGINAL
        historialVariacionMesaOriginal.forEach((mesa) => {
          const historial: IAmbienteMesa = JSON.parse(JSON.stringify(mesa));
          delete historial.fecha_modificacion;
          const docHistorialMesaRef = docTurnoRef.collection(colections.PEDIDO).doc(pedidoOriginal.id!).collection(colections.HISTORIAL_MESA).doc();
          const docHistorialMesa = Utils.SerializeJsonToDb(historial);
          docHistorialMesa.fecha_creacion = serverTimestamp();
          transaccion.set(docHistorialMesaRef.ref, docHistorialMesa);
        });


        //INSERTANDO HISTORIAL DE PRODUCTO
        historialVariacionProductoTraspaso.forEach((produ) => {
          const docHistorialProdRef = docTurnoRef.collection(colections.PEDIDO).doc(idPedido).collection(colections.HISTORIAL_PRODUCTO).doc();

          //actualizando estado real del producto
          const productoCEstadoActual = listaProductoParaTraslado.filter((p) => p.codigo_producto == produ.codigo_producto &&
            p.codigo_ticket == produ.codigo_ticket
          )[0];

          //seteando el estado actual del producto
          produ.estado_producto = productoCEstadoActual.estado_producto_vigente;
          const docHistorialProd = Utils.SerializeJsonToDb(produ);
          docHistorialProd.fecha_creacion = serverTimestamp();
          transaccion.set(docHistorialProdRef.ref, docHistorialProd);
        });

        //INSERTANDO HISTORIAL DE PEDIDO TRASPASO
        const docHistorialPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(idPedido).collection(colections.HISTORIAL_PEDIDO).doc();
        historialVariacionPedidoTraspaso.estado_pedido = this.determinarEstadoPedido(listaProductoParaTraslado);
        const docHistorialPed = Utils.SerializeJsonToDb(historialVariacionPedidoTraspaso);
        docHistorialPed.fecha_creacion = serverTimestamp();
        transaccion.set(docHistorialPedidoRef.ref, docHistorialPed);

        //ACTUALIZANDO ESTADO DE MESAS: EN_PROCESO a OCUPADO (TRASPASO), si no es dividir
        //SI ES DIVIDIR SOLO DERIVA LA MESA
        if (!esDividir) {
          if (!esMesaExistente) {
            pedidoTraspaso.ambiente_mesa.forEach((amb_mesa) => {
              if (amb_mesa.id) {
                const docConfigAmbRef = docEmpRef.collection(colections.AMBIENTE_MESA);
                transaccion.update(docConfigAmbRef.doc(amb_mesa.id).ref, {
                  mesa_estado: EstadosMesaType.OCUPADO,
                  usuario_modificacion: usuario,
                  fecha_modificacion: serverTimestamp(),
                });
              }
            });
          }
        }

        //ACTUALIZANDO PEDIDO ORIGINAL
        //actualizando cantidades de productos modificados
        productosPedido.forEach((prodOr) => {
          let prodExiste: IProductoTicket[] | null = null
          prodExiste = listaProductoPedidoOriginal.filter((prodTr) => prodTr.codigo_producto == prodOr.codigo_producto &&
            prodTr.codigo_ticket == prodOr.codigo_ticket
          );
          if (prodExiste.length > 0) {
            //si las cantidades son distintas se actualiza
            if (prodOr.cantidad_producto !== prodExiste![0].cantidad_producto) {
              const refProductoPedUpd = docTurnoRef.collection(colections.PEDIDO).doc(pedidoOriginal.id)
                .collection(colections.PEDIDO_PRODUCTOS).doc(prodOr.id!);

              transaccion.update(refProductoPedUpd.ref, {
                cantidad_producto: prodExiste[0]!.cantidad_producto,
                precio_total: prodExiste[0].precio_total,
                usuario_modificacion: usuario,
                ...(prodExiste[0].lista_caract_seleccionada_individualizada !== undefined && { lista_caract_seleccionada_individualizada: prodExiste[0].lista_caract_seleccionada_individualizada, }),
                ...(prodExiste[0].lista_caract_seleccionada !== undefined && { lista_caract_seleccionada: prodExiste[0].lista_caract_seleccionada, }),
              });
            }
          }
          else {
            //eliminado de productos trasladados completamente
            prodExiste = listaProductoPedidoTraspaso.filter(
              (prodTr) =>
                prodTr.codigo_producto == prodOr.codigo_producto &&
                prodTr.codigo_ticket == prodOr.codigo_ticket
            );
            if (prodExiste.length > 0) {
              //eliminamos
              const refProductoPedDel = docTurnoRef.collection(colections.PEDIDO).doc(pedidoOriginal.id)
                .collection(colections.PEDIDO_PRODUCTOS).doc(prodOr.id!);
              transaccion.delete(refProductoPedDel.ref);
            }
          }
        });

        const pedidoOriginalTMP = this.determinarEstadoPedidoParaComidaBebida(listaProductoPedidoOriginal, pedidoOriginal);

        //ACTUALIZANDO pedido
        transaccion.update(docPedidoRefOri.ref, {
          total_productos_pedidos: pedidoOriginal.total_productos_pedidos,
          total: pedidoOriginal.total,
          sub_total: pedidoOriginal.sub_total,
          igv: pedidoOriginal.igv,
          ...(pedidoOriginal.servicio_total !== undefined && {
            servicio_total: pedidoOriginal.servicio_total,
            servicio_subtotal: pedidoOriginal.servicio_subtotal,
            servicio_igv: pedidoOriginal.servicio_igv,
          }),
          usuario_modificacion: usuario,
          estado_pedido_vigente: this.determinarEstadoPedido(listaProductoPedidoOriginal),
          fecha_modificacion: serverTimestamp(),
          //para campos opcionales de estados generales de tipo de producto en pedido
          ...(pedidoOriginal.estado_pedido_vigente_bebida !== undefined && { estado_pedido_vigente_bebida: pedidoOriginalTMP.estado_pedido_vigente_bebida }),
          ...(pedidoOriginal.estado_pedido_vigente_comida !== undefined && { estado_pedido_vigente_comida: pedidoOriginalTMP.estado_pedido_vigente_comida }),
        });

        //INSERTAR HISTORIAL PEDIDO ORIGINAL
        const docHistorialPedidoOriginalRef = docTurnoRef.collection(colections.PEDIDO).doc(pedidoOriginal.id).collection(colections.HISTORIAL_PEDIDO).doc();
        historialVariacionPedidoOriginal.estado_pedido = this.determinarEstadoPedido(listaProductoParaTraslado);
        const docHistorialPedOriginal = Utils.SerializeJsonToDb(historialVariacionPedidoOriginal);
        docHistorialPedOriginal.fecha_creacion = serverTimestamp();
        transaccion.set(docHistorialPedidoOriginalRef.ref, docHistorialPedOriginal);

        //INSERTANDO HISTORIAL DE PRODUCTO ORIGINAL
        historialVariacionProductoOriginal.forEach((produ) => {
          const docHistorialProdRefOrig = docTurnoRef.collection(colections.PEDIDO)
            .doc(pedidoOriginal.id).collection(colections.HISTORIAL_PRODUCTO).doc();
          const docHistorialProd = Utils.SerializeJsonToDb(produ);
          docHistorialProd.fecha_creacion = serverTimestamp();
          transaccion.set(docHistorialProdRefOrig.ref, docHistorialProd);
        });

        this.transaccion.tx = true;
        return this.transaccion;
      }
      );

      return txResult;
    } catch (error) {
      this.transaccion = { tx: false, error: error };
      //console.log(error);
      return this.transaccion;
    }
  }

  async insertPedido(
    pedido: Pedido,
    idEmpresa: string,
    idTurno: string,
    usuario: string,
    listaProductoPedido: IProductoTicket[],
    historialVariacionPedido: EstadoPedido,
    historialVariacionMesa: IAmbienteMesa[],
    historialVariacionProducto: EstadosProducto[]
  ) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc();

    //para actualizar cantidades de productos
    const docProductoRef = docEmpRef.collection(colections.PRODUCTO);
    const docProductoResumenRef = docEmpRef.collection(
      colections.PRODUCTO_RESUMEN
    );

    let nuevaCantidadProducto: number = 0;

    try {
      //creamos la transaccion
      const txResult = await this.db.firestore.runTransaction(
        async (transaccion) => {
          let docPedido = Utils.SerializeJsonToDb(pedido);
          docPedido.fecha_creacion = serverTimestamp();

          //INSERTANDO PEDIDO
          transaccion.set(docPedidoRef.ref, docPedido);

          //INSERTANDO LOS PRODUCTOS DEL PEDIDO
          const idPedido = docPedidoRef.ref.id;
          listaProductoPedido.forEach((prod) => {
            const docProductoPedidoRef = docTurnoRef
              .collection(colections.PEDIDO)
              .doc(idPedido)
              .collection(colections.PEDIDO_PRODUCTOS)
              .doc();
            const docPedidoProducto = Utils.SerializeJsonToDb(prod);
            docPedidoProducto.fecha_creacion = serverTimestamp();
            transaccion.set(docProductoPedidoRef.ref, docPedidoProducto);
          });

          //INSERTANDO HISTORIAL DE MESA
          historialVariacionMesa.forEach((mesa) => {
            const historial: IAmbienteMesa = JSON.parse(JSON.stringify(mesa));
            delete historial.fecha_modificacion;
            const docHistorialMesaRef = docTurnoRef
              .collection(colections.PEDIDO)
              .doc(idPedido)
              .collection(colections.HISTORIAL_MESA)
              .doc();

            const docHistorialMesa = Utils.SerializeJsonToDb(historial);
            docHistorialMesa.fecha_creacion = serverTimestamp();
            transaccion.set(docHistorialMesaRef.ref, docHistorialMesa);
          });

          //INSERTANDO HISTORIAL DE PRODUCTO
          historialVariacionProducto.forEach((produ) => {
            const docHistorialProdRef = docTurnoRef
              .collection(colections.PEDIDO)
              .doc(idPedido)
              .collection(colections.HISTORIAL_PRODUCTO)
              .doc();
            const docHistorialProd = Utils.SerializeJsonToDb(produ);
            docHistorialProd.fecha_creacion = serverTimestamp();
            transaccion.set(docHistorialProdRef.ref, docHistorialProd);
          });

          //INSERTANDO HISTORIAL DE PEDIDO
          const docHistorialPedidoRef = docTurnoRef
            .collection(colections.PEDIDO)
            .doc(idPedido)
            .collection(colections.HISTORIAL_PEDIDO)
            .doc();
          const docHistorialPed = Utils.SerializeJsonToDb(
            historialVariacionPedido
          );
          docHistorialPed.fecha_creacion = serverTimestamp();
          transaccion.set(docHistorialPedidoRef.ref, docHistorialPed);

          //ACTUALIZANDO ESTADO DE MESAS: EN_PROCESO a OCUPADO
          pedido.ambiente_mesa.forEach((amb_mesa) => {
            if (amb_mesa.id) {
              const docConfigAmbRef = docEmpRef.collection(
                colections.AMBIENTE_MESA
              );
              transaccion.update(docConfigAmbRef.doc(amb_mesa.id).ref, {
                mesa_estado: EstadosMesaType.OCUPADO,
                usuario_modificacion: usuario,
                fecha_modificacion: serverTimestamp(),
              });
            }
          });

          //ACTUALIZANDO cantidades de productos para stock limitado
          for (const productoTicket of listaProductoPedido) {
            if (productoTicket.reducir_cantidad) {
              //recuperando producto
              const prodID = productoTicket.id_producto;
              let producto: Producto = new Producto();

              const docProducto = await docProductoRef
                .doc(prodID)
                .get()
                .toPromise();
              if (docProducto) {
                producto = docProducto.data() as Producto;
                producto.id = docProducto.id;

                //actualizando producto
                nuevaCantidadProducto =
                  Number(producto.cantidad_stock) -
                  Number(productoTicket.cantidad_producto);
                transaccion.update(docProductoRef.doc(prodID).ref, {
                  cantidad_stock: nuevaCantidadProducto,
                  es_vigente: nuevaCantidadProducto <= 0 ? false : true,
                  usuario_actualizacion: usuario,
                });

                //si ya no quedan mas productos
                if (nuevaCantidadProducto == 0) {
                  //recuperando resumen
                  let resumenProducto: IResumenProducto;
                  const docResumen = await docProductoResumenRef
                    .doc()
                    .get()
                    .toPromise();

                  if (docResumen.exists) {
                    resumenProducto = docResumen.data() as IResumenProducto;
                    resumenProducto.id_documento = docResumen.id;
                    let nuevaCantidadActivos =
                      Number(resumenProducto.total_productos_activos) - 1;
                    let nuevaCantidadInactivos =
                      Number(resumenProducto.total_productos_inactivos) - 1;

                    //actualizando resumen
                    transaccion.update(
                      docProductoResumenRef.doc(docResumen.id).ref,
                      {
                        total_productos_activos: nuevaCantidadActivos,
                        total_productos_inactivos: nuevaCantidadInactivos,
                      }
                    );
                  }
                }

                ///ACTUALIZANDO cantidades en  CATALOGOS para stock limitado

                const docCatalogoRef = docEmpRef.collection(
                  colections.CATALOGO,
                  (ref) =>
                    ref
                      .where(Auditoria.es_borrado, '==', false)
                      .where(
                        documentCatalogo.CODIGO_PRODUCTOS_ANIDADOS_COMPLETO,
                        'array-contains',
                        productoTicket.codigo_producto
                      )
                );

                const catalogos = await docCatalogoRef.get().toPromise();
                if (!catalogos.empty) {
                  catalogos.forEach((catalog) => {
                    const catalogData = catalog.data() as Catalogo;
                    catalogData.id = catalog.id;

                    catalogData.lista_productos.forEach((productoCat) => {
                      if (
                        productoCat.codigo_producto ==
                        productoTicket.codigo_producto
                      ) {
                        productoCat.cantidad_stock = nuevaCantidadProducto; //viene del calculo anterio de producto
                        (productoCat.es_vigente =
                          nuevaCantidadProducto <= 0 ? false : true),
                          (productoCat.usuario_modificacion = usuario);
                      }
                    });

                    //actualizando catalogo
                    transaccion.update(
                      docEmpRef
                        .collection(colections.CATALOGO)
                        .doc(catalogData.id).ref,
                      {
                        lista_productos: catalogData.lista_productos,
                        usuario_modificacion: usuario,
                        fecha_modificacion: serverTimestamp(),
                      }
                    );
                  });
                }
              }
            }
          }
          this.transaccion.tx = true;
          return this.transaccion;
        }
      );

      return txResult;
    } catch (error) {
      this.transaccion = { tx: false, error: error };
      //console.log(error);
      return this.transaccion;
    }
  }

  getProductosXPedido(idEmpresa: string, idTurno: string, idPedido: string) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef
      .collection(colections.PEDIDO)
      .doc(idPedido);
    return docPedidoRef
      .collection(colections.PEDIDO_PRODUCTOS, (ref) =>
        ref.orderBy(documentPedidoProductos.AUTO_NUMERICO, 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((productos) => {
          if (productos.length > 0) {
            let lista_productos: IProductoTicket[] = [];
            productos.forEach((prod) => {
              const productoPed = prod.payload.doc.data() as IProductoTicket;
              productoPed.id = prod.payload.doc.id;
              lista_productos.push(productoPed);
            });
            return lista_productos;
          } else {
            return null;
          }
        })
      );
  }

  //barra y cocina
  getPedidosProductosPaginado(
    idEmpresa: string,
    idTurno: string,
    filtroPedido: ITablePedidosFiltro,
    tipoProducto: TipoConsumibleType
  ): Observable<null | Pedido[]> {
    const tipoProductoSel: ListaTipoProducto[] = tipoProducto == TipoConsumibleType.BEBIDA ? Utils.getTipoProducto(true) : Utils.getTipoProducto(false);
    this.filtroPedido.codigoFiltro = filtroPedido.codigoFiltro;
    this.filtroPedido.estadoFiltro = filtroPedido.estadoFiltro;
    this.filtroPedido.usuarioFiltro = filtroPedido.usuarioFiltro;
    this.filtroPedido.estadoPedidoVigenteFiltro =
      filtroPedido.estadoPedidoVigenteFiltro;
    this.filtroPedido.mesas = filtroPedido.mesas;
    this.filtroPedido.minPage = filtroPedido.minPage;
    this.filtroPedido.maxPage = filtroPedido.maxPage;
    this.filtroPedido.ordenamiento = filtroPedido.ordenamiento;
    this.filtroPedido.pageSize = filtroPedido.pageSize;
    this.filtroPedido.lastVisibleDoc = filtroPedido.lastVisibleDoc;

    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);

    const queryPedido = docTurnoRef.collection(colections.PEDIDO, (ref) => {
      let query = ref.where(documentPedido.ESTADO_PEDIDO_VIGENTE, 'in', [EstadosPedidoType.PENDIENTE, EstadosPedidoType.EN_PROCESO, EstadosPedidoType.PENDIENTE_REVISION,]);
      if (tipoProducto == TipoConsumibleType.BEBIDA) {
        query = query
          .where(documentPedido.ESTADO_PEDIDO_VIGENTE_BEBIDA, '!=', EstadosProductoType.TERMINADO)
          .orderBy(documentPedido.ESTADO_PEDIDO_VIGENTE_BEBIDA, 'asc');
      }
      if (tipoProducto == TipoConsumibleType.COMIDA) {
        query = query
          .where(documentPedido.ESTADO_PEDIDO_VIGENTE_COMIDA, '!=', EstadosProductoType.TERMINADO)
          .orderBy(documentPedido.ESTADO_PEDIDO_VIGENTE_COMIDA, 'asc');
      }
      return query
        .orderBy(documentPedido.FECHA_CREACION, 'asc')
        //.orderBy(documentPedido.AUTO_NUMERICO, 'asc')
        .orderBy(documentPedido.ESTADO_PEDIDO_VIGENTE, 'asc')
        .limit(5);
    });

    return queryPedido.snapshotChanges().pipe(
      switchMap((pedidosSnapshot) => {
        if (pedidosSnapshot.length === 0) {
          // Si no hay documentos, retorna null
          return of(null);
        }
        this.filtroPedido.lastVisibleDoc = pedidosSnapshot[pedidosSnapshot.length - 1];
        const productosObservables = pedidosSnapshot.map((pedidoSnapshot) => {
          const pedido = pedidoSnapshot.payload.doc.data() as Pedido;
          pedido.id = pedidoSnapshot.payload.doc.id;
          pedido.mesas_asignado = Utils.generarMesasFila(pedido.mesas_completo);
          pedido.last_doc = this.filtroPedido.lastVisibleDoc;

          const productosCollection = docTurnoRef
            .collection(colections.PEDIDO)
            .doc(pedido.id)
            .collection(colections.PEDIDO_PRODUCTOS, (ref1) => {
              let query2 = ref1
                .where(documentPedidoProductos.ESTADO_PRODUCTO_VIGENTE, 'in', [
                  EstadosProductoType.PENDIENTE,
                  EstadosProductoType.EN_PROCESO,
                  EstadosProductoType.TERMINADO,
                ])
                //.where(documentPedidoProductos.TIPO_PRODUCTO,'in',tipoProductoSel)
                .orderBy(documentPedidoProductos.FECHA_CREACION, 'asc')
                //.orderBy(documentPedidoProductos.AUTO_NUMERICO, 'asc')
                .orderBy(documentPedidoProductos.TIPO_PRODUCTO, 'asc')
                .orderBy(documentPedidoProductos.ESTADO_PRODUCTO_VIGENTE, 'desc');
              return query2;
            });

          return productosCollection.snapshotChanges().pipe(
            map((productosSnapshot) => {
              const productos = productosSnapshot.map((prod) => {
                const productoTicket =
                  prod.payload.doc.data() as IProductoTicket;
                productoTicket.id = prod.payload.doc.id;
                return productoTicket;
              });

              return {
                ...pedido,
                lista_producto: (pedido.lista_producto || []).concat(productos),
              } as Pedido;
            })
          );
        });
        return combineLatest(productosObservables).pipe(
          map((pedidos) => pedidos.filter((pedido) => pedido !== null)), // Filtramos posibles nulls
          catchError((error) => {
            console.error('Error al combinar observables:', error);
            return of(null);
          })
        );
      })
    );
  }

  getPedidosProductosTodoXListaEstados(
    idEmpresa: string,
    idTurno: string,

  ): Observable<null | Pedido[]> {

    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);

    const queryPedido = docTurnoRef.collection(colections.PEDIDO, (ref) => {
      let query = ref.where(documentPedido.ESTADO_PEDIDO_VIGENTE, 'in', [
        EstadosPedidoType.PENDIENTE, 
        EstadosPedidoType.EN_PROCESO, 
        EstadosPedidoType.PENDIENTE_REVISION,
        EstadosPedidoType.TERMINADO
      ]);
      return query
        .orderBy(documentPedido.AUTO_NUMERICO, 'asc')
        .orderBy(documentPedido.ESTADO_PEDIDO_VIGENTE, 'asc')
    });

    return queryPedido.snapshotChanges().pipe(
      switchMap((pedidosSnapshot) => {
        if (pedidosSnapshot.length === 0) {
          // Si no hay documentos, retorna null
          return of(null);
        }

        const productosObservables = pedidosSnapshot.map((pedidoSnapshot) => {
          const pedido = pedidoSnapshot.payload.doc.data() as Pedido;
          pedido.id = pedidoSnapshot.payload.doc.id;
          pedido.mesas_asignado = Utils.generarMesasFila(pedido.mesas_completo);
          const milliseconds = pedido.fecha_creacion.seconds * 1000 + Math.floor(pedido.fecha_creacion.nanoseconds / 1000000);          
          pedido.fecha_creacion = new Date(milliseconds);

          //la fecha de modificacion solo existe para pedidos que modificaron su estado a terminado
          if(pedido.fecha_modificacion){
            const millisecondsFMod = pedido.fecha_modificacion.seconds * 1000 + Math.floor(pedido.fecha_modificacion.nanoseconds / 1000000);
            const fechaKey: Fecha = new Fecha();
            const fecha = new Date(millisecondsFMod); 
            fechaKey.anio = fecha.getFullYear().toString();
            fechaKey.mes =  String(fecha.getMonth() + 1).padStart(2, '0'),  // Mes
            fechaKey.dia = String(fecha.getDate()).padStart(2, '0'),       // Día
            fechaKey.hora =  String(fecha.getHours()).padStart(2, '0'),      // Hora
            fechaKey.minuto =  String(fecha.getMinutes()).padStart(2, '0'),    // Minuto
            fechaKey.segundo = String(fecha.getSeconds()).padStart(2, '0')     // Segundo
            pedido.fecha_modificacion = fechaKey;
            
          }
          

          const productosCollection = docTurnoRef.collection(colections.PEDIDO).doc(pedido.id).collection(colections.PEDIDO_PRODUCTOS, (ref1) => {
            let query2 = ref1
              .where(documentPedidoProductos.ESTADO_PRODUCTO_VIGENTE, 'in', [
                EstadosProductoType.PENDIENTE,
                EstadosProductoType.EN_PROCESO,
              ])
              .orderBy(documentPedidoProductos.AUTO_NUMERICO, 'asc')
              .orderBy(documentPedidoProductos.TIPO_PRODUCTO, 'asc')
              .orderBy(documentPedidoProductos.ESTADO_PRODUCTO_VIGENTE, 'desc');
            return query2;
          });

          return productosCollection.snapshotChanges().pipe(
            map((productosSnapshot) => {
              const productos = productosSnapshot.map((prod) => {
                const productoTicket = prod.payload.doc.data() as IProductoTicket;
                productoTicket.id = prod.payload.doc.id;
                const milis = productoTicket.fecha_creacion.seconds * 1000 + Math.floor(productoTicket.fecha_creacion.nanoseconds / 1000000);
                productoTicket.fecha_creacion = new Date(milis);
                return productoTicket;
              });

              return {
                ...pedido,
                lista_producto: (pedido.lista_producto || []).concat(productos),
              } as Pedido;
            })
          );
        });
        return combineLatest(productosObservables).pipe(
          map((pedidos) => pedidos.filter((pedido) => pedido !== null)), // Filtramos posibles nulls
          catchError((error) => {
            console.error('Error al combinar observables:', error);
            return of(null);
          })
        );
      })
    );
  }

  async getPedidoNoCerrado(idEmpresa: string, idTurno: string) {
    const estadosPermitidos = [EstadosPedidoType.CERRADO, EstadosPedidoType.ANULADO];

    const pedidos = await this.db.collection(colections.EMPRESA, (ref) => ref.where(Auditoria.es_borrado, '==', false)
      .where(Auditoria.es_vigente, '==', true)).doc(idEmpresa).collection(colections.TURNO).doc(idTurno)
      .collection(colections.PEDIDO, (ref2) => ref2.where(documentPedido.ESTADO_PEDIDO_VIGENTE, 'not-in', estadosPermitidos))
      .get().toPromise();

    if (!pedidos.empty) {
      return true;
    } else {
      return false;
    }
  }

  getPedidos(
    idEmpresa: string,
    filtroPedido: ITablePedidosFiltro,
    idTurno: string,
    esCerrar: boolean,
    esPagar: boolean,
    esIncidencia: boolean,
    esIndividualizado: boolean,
    correoUsuario: string,
    esAdministrador: boolean,
    esVentaCerrada: boolean
  ) {
    this.filtroPedido.codigoFiltro = filtroPedido.codigoFiltro;
    this.filtroPedido.estadoFiltro = filtroPedido.estadoFiltro;
    this.filtroPedido.usuarioFiltro = filtroPedido.usuarioFiltro;
    this.filtroPedido.estadoPedidoVigenteFiltro = filtroPedido.estadoPedidoVigenteFiltro;
    this.filtroPedido.mesas = filtroPedido.mesas;
    this.filtroPedido.minPage = filtroPedido.minPage;
    this.filtroPedido.maxPage = filtroPedido.maxPage;
    this.filtroPedido.ordenamiento = filtroPedido.ordenamiento;

    let listaPedidosLocal: Pedido[] = [];

    this.filtroPedido.ordenamiento = this.filtroPedido.minPage ? 'desc' : 'asc';

    return this.db.collection(colections.EMPRESA, (ref) =>
      ref.where(Auditoria.es_borrado, '==', false).where(Auditoria.es_vigente, '==', true))
      .doc(idEmpresa).collection(colections.TURNO).doc(idTurno).collection(colections.PEDIDO, (ref2) => {
        let query = ref2.where(Auditoria.es_borrado, '==', false)
        if (esVentaCerrada) {
          query = query.where(documentPedido.TIENE_PAGO_TOTAL, '==', true)
        } else {
          query = query.where(documentPedido.TIENE_PAGO_TOTAL, '==', false)
        }

        if (esIncidencia) {
          query = query.where(documentPedido.TIENE_INCIDENCIA, '==', esIncidencia);
        }

        //para mostrar solo pedidos registrados por el mismo gestor de mesa
        if (esIndividualizado && !esAdministrador) {
          query = query.where(documentPedido.USUARIO_CREACION, '==', correoUsuario);
        }

        if (!esPagar) {
          if (esCerrar) {
            //no se puede considerar in porque firestore no soporta filtro combinado de 'in' con 'array-constains-any'
            query = query.where(documentPedido.ESTADO_PEDIDO_VIGENTE, '==', EstadosPedidoType.CERRADO)            
          } else {
            query = query.where(documentPedido.ESTADO_PEDIDO_VIGENTE, '!=', EstadosPedidoType.CERRADO);
          }
        } else {
          query = query.where(documentPedido.ESTADO_PEDIDO_VIGENTE, '!=', EstadosPedidoType.ANULADO);
        }

        if (this.filtroPedido.mesas) {
          const lFiltroMesa = Utils.CadenaToArray(this.filtroPedido.mesas);
          query = query.where(documentPedido.MESAS_COMPLETO, 'array-contains-any', lFiltroMesa);
        }

        if (this.filtroPedido.codigoFiltro) {
          query = query.where(documentPedido.CODIGO_PEDIDO, '==', this.filtroPedido.codigoFiltro);
        }

        if (this.filtroPedido.estadoPedidoVigenteFiltro != null) {
          query = query.where(documentPedido.ESTADO_PEDIDO_VIGENTE, '==', this.filtroPedido.estadoPedidoVigenteFiltro);
        }

        if (this.filtroPedido.estadoFiltro != null) {
          query = query.where(Auditoria.es_vigente, '==', this.filtroPedido.estadoFiltro);
        }

        if (this.filtroPedido.usuarioFiltro) {
          query = query.where(documentPedido.USUARIO_CREACION_BUSQUEDA, '==', this.filtroPedido.usuarioFiltro);
        }
        query = query
          .orderBy(documentPedido.ESTADO_PEDIDO_VIGENTE, this.filtroPedido.ordenamiento)
          .orderBy(documentPedido.AUTO_NUMERICO, this.filtroPedido.ordenamiento);
        return query;
      })
      .snapshotChanges()
      .pipe(
        map((pedidos) => {
          if (pedidos.length === 0) {
            // Si no hay pedidos que cumplan con las condiciones, retorna null
            return null;
          }
          if (pedidos.length > 0) {
            // **para paginación
            const pedidosListaDatos = [...pedidos];

            // limpiamos el array y generamos numeracion
            listaPedidosLocal.length = 0;
            let numeracion: number = 1;
            //const listaPedidosLocal: Pedido[] = pedidos.map(ped => {
            pedidosListaDatos.forEach((ped) => {
              const pedidoData = Utils.convertDate({ ...ped.payload.doc.data(), }) as Pedido;

              //OJO se considera pedidos ANULADOS, en ninguna de las listas de pedidos
              if(pedidoData.es_pedido_anulado){
                return;
              }

              pedidoData.total_string = pedidoData.total.toFixed(2);
              // **generando propiedades utilitarias
              pedidoData.id = ped.payload.doc.id;
              pedidoData.totalRegistros_query = listaPedidosLocal.length; // indica cuántos registros hay por query
              //pedidoData.total_productos_pedidos = pedidoData.lista_producto.length;
              const pendiente = pedidosListaDatos.filter((pedidAn) => (pedidAn.payload.doc.data() as Pedido)
                .estado_pedido_vigente == EstadosPedidoType.PENDIENTE);
              pedidoData.totalRegistrosPendiente = pendiente.length;
              const enProceso = pedidosListaDatos.filter((pedidAn) => (pedidAn.payload.doc.data() as Pedido)
                .estado_pedido_vigente == EstadosPedidoType.EN_PROCESO);
              pedidoData.totalRegistrosEnProceso = enProceso.length;
              const terminado = pedidosListaDatos.filter((pedidAn) => (pedidAn.payload.doc.data() as Pedido)
                .estado_pedido_vigente == EstadosPedidoType.TERMINADO);
              pedidoData.totalRegistrosTerminado = terminado.length;
              const anulado = pedidosListaDatos.filter((pedidAn) => (pedidAn.payload.doc.data() as Pedido)
                .estado_pedido_vigente == EstadosPedidoType.ANULADO);
              pedidoData.totalRegistrosAnulado = anulado.length;
              const reasignado = pedidosListaDatos.filter((pedidAn) => (pedidAn.payload.doc.data() as Pedido)
                .estado_pedido_vigente == EstadosPedidoType.PENDIENTE_REVISION);
              pedidoData.totalRegistrosPendienteRevision = reasignado.length;
              const pendienteReabrir = pedidosListaDatos.filter((pedidAn) => (pedidAn.payload.doc.data() as Pedido)
                .estado_pedido_vigente == EstadosPedidoType.PENDIENTE_REABRIR);
              pedidoData.totalRegistrosPendienteReabrir = pendienteReabrir.length;
              pedidoData.numeracion = numeracion;
              pedidoData.fecha_registro = pedidoData.fecha_key.dia + '/' + pedidoData.fecha_key.mes + '/' +
                pedidoData.fecha_key.anio + ' ' + pedidoData.fecha_key.hora + ':' + pedidoData.fecha_key.minuto + ':' + pedidoData.fecha_key.segundo;
              pedidoData.mesas_asignado = Utils.generarMesasFila(pedidoData.mesas_completo);
              pedidoData.usuario_registro = pedidoData.usuario_creacion.split('@')[0];
              //para pedido cerrado 
              pedidoData.emision_comprobante = pedidoData.comprobante_electronico ?
                (pedidoData.comprobante_electronico!.tipo_de_comprobante == 1 ? EstadosComprobanteElectronicoType.FACTURA : EstadosComprobanteElectronicoType.BOLETA) : EstadosComprobanteElectronicoType.NINGUNO;
              //si requiere actualizarse
              if (pedidoData.comprobante_electronico_requiere_sincronizacion && pedidoData.comprobante_electronico_requiere_sincronizacion == true) {
                pedidoData.emision_comprobante = EstadosComprobanteElectronicoType.POR_ACTUALIZAR;
              }
              pedidoData.descuento = pedidoData.tiene_descuento ? 'Si' : '-';
              pedidoData.incidencia = pedidoData.tiene_incidencia ? 'Si' : '-';
              pedidoData.pedido_incidencia = pedidoData.es_pedido_incidencia ? 'Si' : 'No';
              numeracion += 1;
              listaPedidosLocal.push(pedidoData);
              //return pedidoData;
            });
            return listaPedidosLocal;
          } else {
            const pedidoFic: Pedido = new Pedido();
            const pedidoListaFic: Pedido[] = new Array();
            pedidoFic.totalRegistros_query = 0;
            pedidoListaFic.push(pedidoFic);
            return pedidoListaFic;
          }
        }),
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      );
  }

  getHistorial(
    idEmpresa: string,
    idTurno: string,
    idPedido: string,
    hMesa: boolean,
    hProducto: boolean,
    hPedido: boolean
  ) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(idPedido);
    let coleccion!: AngularFirestoreCollection<DocumentData>;
    if (hMesa) {
      coleccion = docPedidoRef.collection(colections.HISTORIAL_MESA, (ref) =>
        ref.orderBy(documentPedidoProductos.AUTO_NUMERICO, 'asc')
      );
    }
    if (hProducto) {
      coleccion = docPedidoRef.collection(
        colections.HISTORIAL_PRODUCTO,
        (ref) => ref.orderBy(documentPedidoProductos.AUTO_NUMERICO, 'asc')
      );
    }
    if (hPedido) {
      coleccion = docPedidoRef.collection(colections.HISTORIAL_PEDIDO, (ref) =>
        ref.orderBy(documentPedidoProductos.AUTO_NUMERICO, 'asc')
      );
    }
    return coleccion.snapshotChanges().pipe(
      map((items) => {
        if (items.length > 0) {
          let lista_items: (IAmbienteMesa | EstadosProducto | EstadoPedido)[] =
            [];
          items.forEach((prod) => {
            if (hMesa) {
              const itemPed = prod.payload.doc.data() as IAmbienteMesa;
              itemPed.id = prod.payload.doc.id;
              lista_items.push(itemPed);
            }
            if (hPedido) {
              const itemPed = prod.payload.doc.data() as EstadoPedido;
              lista_items.push(itemPed);
            }
            if (hProducto) {
              const itemPed = prod.payload.doc.data() as EstadosProducto;
              lista_items.push(itemPed);
            }
          });
          return lista_items;
        } else {
          return null;
        }
      })
    );
  }

  async updateEstadoProductoPedidoProximoEstado(
    idEmpresa: string,
    idTurno: string,
    correoUsuario: string,
    pedido: Pedido,
    listaHistorialProd: EstadosProducto[],
    updEstadoPedido: boolean,
    historialPedido: EstadoPedido | null,
    tipoProducto: TipoConsumibleType
  ): Promise<TransaccionModel> {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef
      .collection(colections.PEDIDO)
      .doc(pedido.id);
    const docHistorialPedidoRef = docPedidoRef
      .collection(colections.HISTORIAL_PEDIDO)
      .doc(pedido.id);

    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        //insertando historial de producto
        listaHistorialProd.forEach((hProd) => {
          const historialMesa: EstadosProducto = JSON.parse(
            JSON.stringify(hProd)
          );
          const docHistorialProdRef = docPedidoRef
            .collection(colections.HISTORIAL_PRODUCTO)
            .doc();
          const docHistorialProd = Utils.SerializeJsonToDb(historialMesa);
          docHistorialProd.fecha_creacion = serverTimestamp();
          transaccion.set(docHistorialProdRef.ref, docHistorialProd);
        });

        //actualizando estado de producto
        const prodUpdEstado = pedido.lista_producto?.filter(
          (prod) => prod.actualizarEstado == true
        );
        prodUpdEstado!.forEach((prodUpd) => {
          const docProductoRef = docPedidoRef
            .collection(colections.PEDIDO_PRODUCTOS)
            .doc(prodUpd.id);
          transaccion.update(docProductoRef.ref, {
            estado_producto_vigente: prodUpd.estado_producto_vigente,
            usuario_modificacion: correoUsuario,
            ...(prodUpd.estado_producto_vigente_bebida && {
              estado_producto_vigente_bebida:
                prodUpd.estado_producto_vigente_bebida,
            }),
            ...(prodUpd.estado_producto_vigente_comida && {
              estado_producto_vigente_comida:
                prodUpd.estado_producto_vigente_comida,
            }),
          });
        });

        //ACTUALIZANDO pedido
        transaccion.update(docPedidoRef.ref, {
          ...(updEstadoPedido
            ? { estado_pedido_vigente: pedido.estado_pedido_vigente }
            : {}),
          ...(tipoProducto === TipoConsumibleType.BEBIDA && {
            estado_pedido_vigente_bebida: pedido.estado_pedido_vigente_bebida,
          }),
          ...(tipoProducto === TipoConsumibleType.COMIDA && {
            estado_pedido_vigente_comida: pedido.estado_pedido_vigente_comida,
          }),
          usuario_modificacion: correoUsuario,
          fecha_modificacion: serverTimestamp(),
        });
        if (updEstadoPedido) {
          //INSERTANDO HISTORIAL DE PEDIDO
          const docHistorialPedido = Utils.SerializeJsonToDb(historialPedido);
          docHistorialPedido.fecha_creacion = serverTimestamp();
          const docHistorialPedidoRefx = docPedidoRef.collection(colections.HISTORIAL_PEDIDO).doc();
          transaccion.set(docHistorialPedidoRefx.ref, docHistorialPedido);
        }

        this.transaccion.tx = true;
        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  async updatePedidoEstadoCerradoOTerminado(
    idEmpresa: string,
    idTurno: string,
    correoUsuario: string,
    pedido: Pedido,
    historialPedido: EstadoPedido
  ) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef
      .collection(colections.PEDIDO)
      .doc(pedido.id);
    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        //INSERTANDO HISTORIAL DE PEDIDO
        const docHistorialPedido = Utils.SerializeJsonToDb(historialPedido);
        const docHistorialPedidoRefx = docPedidoRef
          .collection(colections.HISTORIAL_PEDIDO)
          .doc();
        docHistorialPedido.fecha_creacion = serverTimestamp();
        transaccion.set(docHistorialPedidoRefx.ref, docHistorialPedido);
        //ACTUALIZANDO pedido
        transaccion.update(docPedidoRef.ref, {
          estado_pedido_vigente: pedido.estado_pedido_vigente, //estado cerrado
          usuario_modificacion: correoUsuario,
          fecha_modificacion: serverTimestamp(),
        });

        this.transaccion.tx = true;
        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  async updateEstadoProductoPedido(
    idEmpresa: string,
    correoUsuario: string,
    pedido: Pedido,
    idTurno: string,
    producto: IProductoTicket,
    historialProd: EstadosProducto,
    updEstadoPedido: boolean,
    updEstadoResumenProdPedido: boolean,
    historialPedido: EstadoPedido | null,
    tipoProducto: TipoConsumibleType
  ): Promise<TransaccionModel> {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef
      .collection(colections.PEDIDO)
      .doc(pedido.id);
    const docHistorialPedidoRef = docPedidoRef
      .collection(colections.HISTORIAL_PEDIDO)
      .doc(pedido.id);

    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        //insertando historial de producto
        const historialMesa: EstadosProducto = JSON.parse(
          JSON.stringify(historialProd)
        );
        const docHistorialProdRef = docPedidoRef
          .collection(colections.HISTORIAL_PRODUCTO)
          .doc();
        const docHistorialProd = Utils.SerializeJsonToDb(historialMesa);
        docHistorialProd.fecha_creacion = serverTimestamp();
        transaccion.set(docHistorialProdRef.ref, docHistorialProd);

        //actulizando estado de producto
        const docProductoRef = docPedidoRef
          .collection(colections.PEDIDO_PRODUCTOS)
          .doc(producto.id);
        transaccion.update(docProductoRef.ref, {
          estado_producto_vigente: historialProd.estado_producto,
          usuario_modificacion: correoUsuario,
          ...(producto.estado_producto_vigente_bebida && {
            estado_producto_vigente_bebida:
              producto.estado_producto_vigente_bebida,
          }),
          ...(producto.estado_producto_vigente_comida && {
            estado_producto_vigente_comida:
              producto.estado_producto_vigente_comida,
          }),
        });

        if (updEstadoPedido && updEstadoResumenProdPedido) {
          //ACTUALIZANDO pedido
          transaccion.update(docPedidoRef.ref, {
            estado_pedido_vigente: pedido.estado_pedido_vigente,
            usuario_modificacion: correoUsuario,
            fecha_modificacion: serverTimestamp(),
            ...(tipoProducto === TipoConsumibleType.BEBIDA && {
              estado_pedido_vigente_bebida: pedido.estado_pedido_vigente_bebida,
            }),
            ...(tipoProducto === TipoConsumibleType.COMIDA && {
              estado_pedido_vigente_comida: pedido.estado_pedido_vigente_comida,
            }),
          });
          //INSERTANDO HISTORIAL DE PEDIDO
          const docHistorialPedido = Utils.SerializeJsonToDb(historialPedido);
          const docHistorialPedidoRefx = docPedidoRef
            .collection(colections.HISTORIAL_PEDIDO)
            .doc();
          docHistorialPedido.fecha_creacion = serverTimestamp();
          transaccion.set(docHistorialPedidoRefx.ref, docHistorialPedido);
        }

        if (!updEstadoPedido && updEstadoResumenProdPedido) {
          //ACTUALIZANDO estado resumen producto pedido
          transaccion.update(docPedidoRef.ref, {
            ...(tipoProducto === TipoConsumibleType.BEBIDA && {
              estado_pedido_vigente_bebida: pedido.estado_pedido_vigente_bebida,
            }),
            ...(tipoProducto === TipoConsumibleType.COMIDA && {
              estado_pedido_vigente_comida: pedido.estado_pedido_vigente_comida,
            }),
            usuario_modificacion: correoUsuario,
            fecha_modificacion: serverTimestamp(),
          });
        }
        if (updEstadoPedido && !updEstadoResumenProdPedido) {
          //ACTUALIZANDO pedido
          transaccion.update(docPedidoRef.ref, {
            estado_pedido_vigente: pedido.estado_pedido_vigente,
            usuario_modificacion: correoUsuario,
            fecha_modificacion: serverTimestamp(),
          });
          //INSERTANDO HISTORIAL DE PEDIDO
          const docHistorialPedido = Utils.SerializeJsonToDb(historialPedido);
          const docHistorialPedidoRefx = docPedidoRef
            .collection(colections.HISTORIAL_PEDIDO)
            .doc();
          docHistorialPedido.fecha_creacion = serverTimestamp();
          transaccion.set(docHistorialPedidoRefx.ref, docHistorialPedido);
        }

        this.transaccion.tx = true;
        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  //TODO: Actualizar pedido reporte tambien cuando se actualiza un pedido con boleta
  async updateComprobantePedido(idEmpresa: string, idTurno: string, idPedido: string, respReseller: IRespuestaReseller) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(idPedido);
    return docPedidoRef.update({
      comprobante_electronico: Utils.SerializeJsonToDb(respReseller)
    }).then(() => {
      this.transaccion.tx = true;
      return this.transaccion;
    }).catch((error) => {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    });
  }

  async updatePagarPedido(
    pedido: Pedido,
    idEmpresa: string,
    idTurno: string,
    correoUsuario: string,
    lista_pago_total: IPagoCuenta[],
    fechaHoy: Fecha
  ) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(pedido.id);
    const docReporteVentas = docEmpRef.collection(colections.REPORTE_VENTAS).doc();


    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        const pedidoCopy = JSON.parse(JSON.stringify(pedido)) as Pedido;
        //recuperando datos del pedido para objeto plano de reporte de ventas
        const listaProductos = await docPedidoRef.collection(colections.PEDIDO_PRODUCTOS).get().toPromise();
        const lista_producto: IProductoTicket[] = [];
        if (!listaProductos.empty) {
          listaProductos.docs.forEach(documentoProd => {
            const productoPed = documentoProd.data() as IProductoTicket;
            productoPed.id = documentoProd.id;
            productoPed.fecha_creacion = Timestamp.fromDate(productoPed.fecha_creacion.toDate());// por revisar
            lista_producto.push(productoPed);
          });

        }
        //recuperando datos de historial de producto para reporte de ventas
        const listaHistProductos = await docPedidoRef.collection(colections.HISTORIAL_PRODUCTO).get().toPromise();
        const historial_producto: EstadosProducto[] = [];
        if (!listaHistProductos.empty) {
          listaHistProductos.docs.forEach(docHistProd => {
            const histProd = docHistProd.data() as EstadosProducto;
            histProd.id = docHistProd.id;
            histProd.fecha_creacion = Timestamp.fromDate(histProd.fecha_creacion.toDate());
            historial_producto.push(histProd);
          });
          pedidoCopy.historial_producto = historial_producto;
        }
        // recuperando datos historial de pedido para reporte de ventas
        const listaHistPedido = await docPedidoRef.collection(colections.HISTORIAL_PEDIDO).get().toPromise();
        const historial_pedido: EstadoPedido[] = [];
        if (!listaHistPedido.empty) {
          listaHistPedido.docs.forEach(docHistPedido => {
            const histPedido = docHistPedido.data() as EstadoPedido;
            histPedido.id = docHistPedido.id;
            histPedido.fecha_creacion = Timestamp.fromDate(histPedido.fecha_creacion.toDate());
            historial_pedido.push(histPedido);
          });
          pedidoCopy.historial_pedido = historial_pedido;
        }
        // recuperando historial mesa para reporte de ventas        
        const listaHistMesa = await docPedidoRef.collection(colections.HISTORIAL_MESA).get().toPromise();
        const historial_mesa: IAmbienteMesa[] = [];
        if (!listaHistMesa.empty) {
          listaHistMesa.docs.forEach(docHistMesa => {  
            const histMesa = docHistMesa.data() as IAmbienteMesa;
            histMesa.id = docHistMesa.id;
            let fechaCreacion = histMesa?.fecha_creacion;
            histMesa.fecha_creacion = Timestamp.fromDate(histMesa.fecha_creacion.toDate());
            historial_mesa.push(histMesa);
          });
          pedidoCopy.historial_mesa = historial_mesa;
        }

        //generando objeto de pago  para reportes
        const pedidoInsReporteNew = this.removeUtilProperties(pedidoCopy);
        pedidoInsReporteNew.lista_producto = lista_producto;
        const pedidoInsReporte = JSON.parse(JSON.stringify(pedidoInsReporteNew)) as Pedido;

        pedidoInsReporte.usuario_creacion = correoUsuario;//usuario que cierra el pedido
        pedidoInsReporte.tiene_pago_total = true;
        pedidoInsReporte.lista_pago_total = lista_pago_total;
        pedidoInsReporte.id = pedidoCopy.id!;

        //generando objeto pago
        const ventaIns: Ventas = new Ventas();
        ventaIns.auto_numerico = Utils.generaFechaAutonumerico(fechaHoy);
        ventaIns.fecha_key = fechaHoy;
        ventaIns.pedido = pedidoInsReporte;
        ventaIns.usuario_creacion = correoUsuario;
        ventaIns.es_vigente = true;
        ventaIns.es_borrado = false;

        const ventaInsNew = Utils.SerializeJsonToDb(ventaIns);
        ventaInsNew.fecha_creacion = serverTimestamp();

        //insertando resumen de venta a reporte indicador
        ventaInsNew.id_turno = idTurno;
        transaccion.set(docReporteVentas.ref, ventaInsNew);

        //insertando lista pago en pedido
        transaccion.update(docPedidoRef.ref, {
          usuario_modificacion: correoUsuario,
          tiene_pago_total: true,
          lista_pago_total: lista_pago_total,
          fecha_key_pago_total: Utils.SerializeJsonToDb(fechaHoy),
          fecha_modificacion: serverTimestamp(),
        });

        //liberar mesa TODO
        pedidoCopy.ambiente_mesa.forEach(mesa => {
          const docMesa = docEmpRef.collection(colections.AMBIENTE_MESA).doc(mesa.id!);
          transaccion.update(docMesa.ref, {
            mesa_estado: EstadosMesaType.DISPONIBLE,
            usuario_modificacion: deleteField() //liberando la mesa
          });
        });



        this.transaccion = {
          tx: true,
          data: docReporteVentas.ref.id
        };
        return this.transaccion;

      });

      return txResult;
    } catch (error) {
      ///console.log(error);
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
  }

  async updatePagarPedidoParcial(
    pedido: Pedido,
    idEmpresa: string,
    idTurno: string,
    correoUsuario: string,
    prePagoCuenta: IPrePagoCuenta
  ) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(pedido.id);
    const docPrePago = docPedidoRef.collection(colections.PRE_PAGO).doc();
    let tieneServicio = pedido.servicio_total ? true : false;
    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        const docListaPago = Utils.SerializeJsonToDb(prePagoCuenta);

        //insertando el prepago
        transaccion.set(docPrePago.ref, docListaPago);

        //actualizando pedido
        transaccion.update(docPedidoRef.ref, {
          usuario_modificacion: correoUsuario,
          fecha_modificacion: serverTimestamp(),
          //total_productos_pedidos: pedido.total_productos_pedidos,
          tiene_pago_parcial: true,
          total: pedido.total,
          sub_total: pedido.sub_total,
          igv: pedido.igv,
          ...(tieneServicio == true && {
            servicio_total: pedido.servicio_total,
            servicio_subtotal: pedido.servicio_subtotal,
            servicio_igv: pedido.servicio_igv
          }),
        });

        //actualizando producto pedido
        prePagoCuenta.lista_producto.forEach(prod => {
          const docPedidoProductosRef = docPedidoRef.collection(colections.PEDIDO_PRODUCTOS).doc(prod.id!);
          transaccion.update(docPedidoProductosRef.ref, {
            es_pagado: true,
          });
        });


        this.transaccion = {
          tx: true,
        };
        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
  }

  async updateAsignarIncidenciaProducto(
    pedido: Pedido,
    idEmpresa: string,
    idTurno: string,
    correoUsuario: string,
    producto: IProductoTicket,
    historialProd: EstadosProducto
  ) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(pedido.id);
    const docPedidoProductosRef = docPedidoRef.collection(colections.PEDIDO_PRODUCTOS).doc(producto.id!);
    const docHistorialProdRef = docPedidoRef.collection(colections.HISTORIAL_PRODUCTO).doc();
    let tieneServicio = pedido.servicio_total ? true : false;
    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        // insertando historial prod
        const docHistorialProd = Utils.SerializeJsonToDb(historialProd);
        docHistorialProd.fecha_creacion = serverTimestamp();
        transaccion.set(docHistorialProdRef.ref, docHistorialProd);

        //actualizando producto
        transaccion.update(docPedidoProductosRef.ref, {
          usuario_modificacion: correoUsuario,
          tiene_incidencia: producto.tiene_incidencia,
          incidencia: producto.incidencia,
          precio_total: producto.precio_total,
        });

        //actualizando pedido
        transaccion.update(docPedidoRef.ref, {
          usuario_modificacion: correoUsuario,
          fecha_modificacion: serverTimestamp(),
          //total_productos_pedidos: pedido.total_productos_pedidos,
          tiene_incidencia: true,
          total: pedido.total,
          sub_total: pedido.sub_total,
          igv: pedido.igv,
          ...(tieneServicio == true && {
            servicio_total: pedido.servicio_total,
            servicio_subtotal: pedido.servicio_subtotal,
            servicio_igv: pedido.servicio_igv,
          }),
        });

        this.transaccion = {
          tx: true,
        };
        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
  }

  async updateQuitarIncidenciaProducto(
    pedido: Pedido,
    idEmpresa: string,
    idTurno: string,
    correoUsuario: string,
    producto: IProductoTicket,
    tieneOtrosDsctos: boolean,
    historialProd: EstadosProducto
  ) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(pedido.id);
    const docPedidoProductosRef = docPedidoRef.collection(colections.PEDIDO_PRODUCTOS).doc(producto.id!);
    const docHistorialProdRef = docPedidoRef.collection(colections.HISTORIAL_PRODUCTO).doc();
    let tieneServicio = pedido.servicio_total ? true : false;

    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        // insertando historial prod
        const docHistorialProd = Utils.SerializeJsonToDb(historialProd);
        docHistorialProd.fecha_creacion = serverTimestamp();
        transaccion.set(docHistorialProdRef.ref, docHistorialProd);

        //actualizando producto
        transaccion.update(docPedidoProductosRef.ref, {
          usuario_modificacion: correoUsuario,
          tiene_incidencia: deleteField(),
          incidencia: deleteField(),
          precio_total: producto.precio_total,
        });

        //actualizando pedido
        transaccion.update(docPedidoRef.ref, {
          usuario_modificacion: correoUsuario,
          fecha_modificacion: serverTimestamp(),
          //total_productos_pedidos: pedido.total_productos_pedidos,
          tiene_incidencia: tieneOtrosDsctos ? true : deleteField(),
          total: pedido.total,
          sub_total: pedido.sub_total,
          igv: pedido.igv,
          ...(tieneServicio == true && {
            servicio_total: pedido.servicio_total,
            servicio_subtotal: pedido.servicio_subtotal,
            servicio_igv: pedido.servicio_igv,
          }),
        });

        this.transaccion = {
          tx: true,
        };
        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
  }

  async updateAsignarDescuentoProducto(
    pedido: Pedido,
    idEmpresa: string,
    idTurno: string,
    correoUsuario: string,
    producto: IProductoTicket
  ) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(pedido.id);
    const docPedidoProductosRef = docPedidoRef.collection(colections.PEDIDO_PRODUCTOS).doc(producto.id!);
    let tieneServicio = pedido.servicio_total ? true : false;
    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        //actualizando producto
        transaccion.update(docPedidoProductosRef.ref, {
          usuario_modificacion: correoUsuario,
          tiene_descuento: producto.tiene_descuento,
          descuento: producto.descuento,
          precio_total: producto.precio_total,
        });

        //actualizando pedido
        transaccion.update(docPedidoRef.ref, {
          usuario_modificacion: correoUsuario,
          fecha_modificacion: serverTimestamp(),
          //total_productos_pedidos: pedido.total_productos_pedidos,
          tiene_descuento: true,
          total: pedido.total,
          sub_total: pedido.sub_total,
          igv: pedido.igv,
          ...(tieneServicio == true && {
            servicio_total: pedido.servicio_total,
            servicio_subtotal: pedido.servicio_subtotal,
            servicio_igv: pedido.servicio_igv,
          }),
        });

        this.transaccion = {
          tx: true,
        };
        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
  }

  async updateQuitarDescuentoProducto(
    pedido: Pedido,
    idEmpresa: string,
    idTurno: string,
    correoUsuario: string,
    producto: IProductoTicket,
    tieneOtrosDsctos: boolean
  ) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(idTurno);
    const docPedidoRef = docTurnoRef.collection(colections.PEDIDO).doc(pedido.id);
    const docPedidoProductosRef = docPedidoRef.collection(colections.PEDIDO_PRODUCTOS).doc(producto.id!);
    let tieneServicio = pedido.servicio_total ? true : false;

    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        //actualizando producto
        transaccion.update(docPedidoProductosRef.ref, {
          usuario_modificacion: correoUsuario,
          tiene_descuento: deleteField(),
          descuento: deleteField(),
          precio_total: producto.precio_total,
        });

        //actualizando pedido
        transaccion.update(docPedidoRef.ref, {
          usuario_modificacion: correoUsuario,
          fecha_modificacion: serverTimestamp(),
          //total_productos_pedidos: pedido.total_productos_pedidos,
          tiene_descuento: tieneOtrosDsctos ? true : deleteField(),
          total: pedido.total,
          sub_total: pedido.sub_total,
          igv: pedido.igv,
          ...(tieneServicio == true && {
            servicio_total: pedido.servicio_total,
            servicio_subtotal: pedido.servicio_subtotal,
            servicio_igv: pedido.servicio_igv,
          }),
        });

        this.transaccion = {
          tx: true,
        };
        return this.transaccion;
      });
      return txResult;
    } catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
  }
}

