import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mod-gestion-cliente-crm',
  templateUrl: './mod-gestion-cliente-crm.component.html',
  styleUrls: ['./mod-gestion-cliente-crm.component.scss']
})
export class ModGestionClienteCrmComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
