import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { IProductoTicket } from '../../registrar-pedido/models/IProductoTicket';
import { Icons } from 'src/app/shared/cons/icons';
import { TipoOperacionProductoCompuesto } from 'src/app/shared/types/tipo-operacion-prod-comp-type';
import { Subscription } from 'rxjs';
import { Msjs } from 'src/app/shared/cons/common';
import { ICaracteristicaProducto } from '../../registrar-pedido/models/CaracteristicaProducto';
import { EstadosProductoType } from 'src/app/shared/types/estado-producto-type';
import { blinkAnimation } from 'src/app/shared/animations/animations';
import { IProductoCaracteristicaMod } from './models/IProductoCaracteristicaMod';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';

@Component({
  selector: 'app-producto-compuesto',
  templateUrl: './producto-compuesto.component.html',
  styleUrls: ['./producto-compuesto.component.scss'],
  animations: [blinkAnimation]
})
export class ProductoCompuestoComponent implements OnInit {
  producto!:IProductoTicket;
  readonly Icons: typeof Icons = Icons;
  tipo_pago!: TipoOperacionProductoCompuesto;
  readonly EstadosProductoType: typeof EstadosProductoType = EstadosProductoType;
  readonly TipoOperacionProductoCompuesto: typeof TipoOperacionProductoCompuesto = TipoOperacionProductoCompuesto;
  modalSb!: Subscription;
  listaCodigosGrupoSel:string[]=[];
  listaDiferencia: ICaracteristicaProducto[]= [];  
  blinkState: string = 'inactive';
  caracteristicaMod:IProductoCaracteristicaMod[]=[];
  cantidadSel:number=0;
  cantidadRestante:number=0;
  cantidadAcum:number=0;
  esVerSeleccionado:boolean = false;
  esTodoSeleccionado:boolean = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<ProductoCompuestoComponent>,
    private readonly spinnerPageService: SpinnerPageService,
    private uiService:UIService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 1000);

    this.producto = JSON.parse(JSON.stringify(this.datoPasado.producto as IProductoTicket));   
    this.tipo_pago = this.datoPasado.tipo_pago as TipoOperacionProductoCompuesto;

    if(this.producto.estado_producto_vigente===EstadosProductoType.PENDIENTE_REVISION){
      this.generarProductoModificado();
    }

    if(this.tipo_pago===TipoOperacionProductoCompuesto.REGISTRAR){
      this.cantidadSel = this.datoPasado.cantidad_sel;
      this.cantidadRestante = this.cantidadSel;
      if(this.datoPasado.esVer!==undefined && this.datoPasado.esVer){
        this.esVerSeleccionado = true;
      }
      this.generarCaracteristicasAgrupadasProducto();      
    }


    if(this.tipo_pago===TipoOperacionProductoCompuesto.DESCUENTO){
      this.cantidadSel = this.datoPasado.cantidad_sel;
      this.cantidadRestante = this.cantidadSel;
      if(this.datoPasado.esVer!==undefined && this.datoPasado.esVer){
        this.esVerSeleccionado = true;
      }
      this.generarCaracteristicasAgrupadasProducto();      
    }

    if(this.tipo_pago===TipoOperacionProductoCompuesto.INCIDENCIA){
      this.cantidadSel = this.datoPasado.cantidad_sel;
      this.cantidadRestante = this.cantidadSel;
      if(this.datoPasado.esVer!==undefined && this.datoPasado.esVer){
        this.esVerSeleccionado = true;             
      }
      this.generarCaracteristicasAgrupadasProducto();      
    }

    if(this.tipo_pago===TipoOperacionProductoCompuesto.TRASPASO){  
      this.cantidadSel = this.datoPasado.cantidad_sel;
      this.cantidadRestante = this.cantidadSel;   
      if(this.datoPasado.esVer!==undefined && this.datoPasado.esVer){
        this.esVerSeleccionado = true;
      }
      this.generarCaracteristicasAgrupadasProducto();      
    }
    this.cdRef.detectChanges();   
   }
  
   seleccionarTodo(){
    this.esTodoSeleccionado = true;
    this.dialogRef.close({lista_seleccionados:this.listaCodigosGrupoSel, esTodo:this.esTodoSeleccionado});
   }

  seleccionarProdCompuesto(selecionado:boolean, indice:number, grupo_producto:string){
    if(selecionado){
      //solo para traspaso
      if(this.tipo_pago==TipoOperacionProductoCompuesto.TRASPASO){
        this.listaCodigosGrupoSel.push(grupo_producto);
      }    
      this.cantidadRestante-=1;
      this.cantidadAcum+=1;
      this.caracteristicaMod[indice].es_seleccionado = selecionado;      
      if(this.cantidadRestante===0){
        this.marcarCaracteristicaSend();
        this.onClose();        
      }
    }else{
      if(this.cantidadRestante>=0){
        //solo para traspaso
        if(this.tipo_pago==TipoOperacionProductoCompuesto.TRASPASO){
          this.listaCodigosGrupoSel = this.listaCodigosGrupoSel.filter(cod=>cod!=grupo_producto);
        }  
        this.cantidadAcum-=1;
        this.cantidadRestante+=1;
        this.caracteristicaMod[indice].es_seleccionado = selecionado;
      }
    }
  }

  marcarCaracteristicaSend(){
   const caractSel = this.caracteristicaMod.filter(caract=>caract.es_seleccionado!=undefined && caract.es_seleccionado)
   caractSel.forEach(codigo=>{
    this.producto.lista_caract_seleccionada_individualizada?.forEach(caracteristica=>{
      if(caracteristica.grupo_producto===codigo.grupo_producto){
        caracteristica.es_seleccionado = true;
      }
    });
   })

  }

  generarCaracteristicasAgrupadasProducto(){
    const codigosGruposUnicos = new Set<string>();
    this.producto.lista_caract_seleccionada_individualizada!.forEach(objeto => codigosGruposUnicos.add(objeto.grupo_producto!));
    const listaCodigosUnicos = Array.from(codigosGruposUnicos);
    listaCodigosUnicos.forEach(codigo=>{
      const caract = this.producto.lista_caract_seleccionada_individualizada?.filter(grupo=>grupo.grupo_producto===codigo);
      const caracteristicaSel:IProductoCaracteristicaMod={
        nombre_producto :this.producto.nombre_producto,
        caracteristicas:caract!,
        estado_producto_vigente: this.producto.estado_producto_vigente,
        grupo_producto:codigo
      }

      this.caracteristicaMod.push(caracteristicaSel);
    });
  }

  generarProductoModificado(){
    const gruposCodigosMod =   this.producto.lista_caract_seleccionada_individualizada_anterior?.filter(caract=> caract.es_eliminado);
    //creando el objeto set de codigos unicos
    const codigosUnicos = new Set<string>();
    gruposCodigosMod!.forEach(objeto => codigosUnicos.add(objeto.grupo_producto!));
    //convirtiendo en Array el objeto Set
    const listaCodigosUnicos = Array.from(codigosUnicos);

    listaCodigosUnicos.forEach(codigo=>{
      const caractMod = gruposCodigosMod?.filter(grupo=>grupo.grupo_producto===codigo);
      const productoMod:IProductoCaracteristicaMod={
        nombre_producto :this.producto.nombre_producto,
        caracteristicas:caractMod!
      }
      this.caracteristicaMod.push(productoMod);

    });
  }

  onClose(){
    if(this.tipo_pago== TipoOperacionProductoCompuesto.REGISTRAR){
      this.dialogRef.close({producto:this.producto, lista_eliminados:this.listaCodigosGrupoSel});
    }
    if((this.tipo_pago== TipoOperacionProductoCompuesto.DESCUENTO)||
      (this.tipo_pago== TipoOperacionProductoCompuesto.INCIDENCIA)){      
      this.dialogRef.close({
        producto:this.producto,        
        cantidad_restante:this.cantidadRestante,
        cantidad_acumulada: this.cantidadAcum
      });
    }
    if(this.tipo_pago== TipoOperacionProductoCompuesto.TRASPASO){
      this.dialogRef.close({lista_seleccionados:this.listaCodigosGrupoSel, esTodo:false});
    }
    
  }
  removeProducto(grupo_producto:string){
    this.listaCodigosGrupoSel.push(grupo_producto);
    this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_REMOVER, icon: Icons.QUESTION_MARK })
    .subscribe(result=>{
      if(result){
        this.producto.lista_caract_seleccionada_individualizada =  this.producto.lista_caract_seleccionada_individualizada?.filter(caract=>caract.grupo_producto!==grupo_producto);
        this.producto.cantidad_producto -=1;
        this.onClose();
      }
    });
    
  }

}
