
export class AuditoriaSet{
  es_borrado: boolean;
  es_vigente: boolean;
  fecha_creacion?:Date | any;
  fecha_modificacion?:Date|any;
  usuario_creacion?:any;
  usuario_modificacion?:any;
  constructor(es_borrado:boolean, es_vigente:boolean,fecha_creacion:Date|any,fecha_modificacion:Date|any, usuario_creacion:any, usuario_modificacion:any  ){
    this.es_borrado = es_borrado;
    this.es_vigente = es_vigente;
    this.fecha_creacion =fecha_creacion;
    this.fecha_modificacion = fecha_modificacion;
    this.usuario_creacion = usuario_creacion;
    this.usuario_modificacion = usuario_modificacion;
  }
}

export class AuditoriaGet{
  es_borrado: boolean;
  es_vigente: boolean;
  fecha_creacion:Date;
  fecha_modificacion?:Date;
  usuario_creacion:any;
  usuario_modificacion?:any;
  constructor(es_borrado:boolean, es_vigente:boolean,fecha_creacion:Date,fecha_modificacion:Date, usuario_creacion:any, usuario_modificacion:any  ){
    this.es_borrado = es_borrado;
    this.es_vigente = es_vigente;
    this.fecha_creacion =fecha_creacion;
    this.fecha_modificacion = fecha_modificacion;
    this.usuario_creacion = usuario_creacion;
    this.usuario_modificacion = usuario_modificacion;
  }
}
