import { Component, Inject, OnInit } from '@angular/core';
import { TipoPregunta } from '../models/tipo-pregunta';
import { Msjs_Validations, PatternsRegex, SistOperation } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-tipo-registrar-cliente',
  templateUrl: './tipo-registrar-cliente.component.html',
  styleUrls: ['./tipo-registrar-cliente.component.scss']
})
export class TipoRegistrarClienteComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  isLoading:boolean=false;
  titleModal:string='';
  tipoPregunta:TipoPregunta = new TipoPregunta();
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  modoOperacion: SistOperation = SistOperation.VER;
  readonly SistOperation: typeof SistOperation = SistOperation;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  codigo:number =0;
  preguntaEditar:TipoPregunta = new TipoPregunta();

  constructor(
        private uiService: UIService,
        @Inject(MAT_DIALOG_DATA) public datoPasado:any,
        private dialogRef: MatDialogRef<TipoRegistrarClienteComponent>,
  ) { }

  ngOnInit(): void {
    this.titleModal = this.datoPasado.data.tipo_pregunta.titulo;
    this.modoOperacion = this.datoPasado.data.modoOperacion;
    this.codigo = this.datoPasado.data.tipo_pregunta.codigo;
    if(this.modoOperacion==SistOperation.EDITAR){
      this.preguntaEditar = this.datoPasado.data.preguntaEditar;
      this.tipoPregunta.pregunta = this.preguntaEditar.pregunta;      
    }
  }


    onClose(){
      this.dialogRef.close();
    }
  
    agregarPregunta(f:NgForm){
      if(f.valid){
        if(this.modoOperacion==SistOperation.NUEVO){
          this.tipoPregunta.codigo_pregunta = Utils.generarCodigoRandom(6);
        }else{
          this.tipoPregunta.codigo_pregunta = this.preguntaEditar.codigo_pregunta;
        }        
        this.tipoPregunta.tipo_pregunta = this.codigo;    
        this.dialogRef.close(this.tipoPregunta);
      }
  
    }
    cancelarPregunta(){
      this.dialogRef.close();
    }
  

}
