<div style="margin-top: 20px;" fxLayout="column" fxLayoutAlign="space-between center">
    <div *ngIf="isLoading">
        <app-spinner></app-spinner>
    </div>
    <div    
    [ngClass.gt-sm]="{'container-gt-sm':true}" 
    [ngClass.lt-sm]="{'container-lt-sm':true}"
    [ngClass.gt-md]="{'container-gt-md':true}"   
    fxLayout="row" fxLayoutAlign="center center" 
        *ngIf="configuracionAmbienteFinal.length==0 && !isLoading">
        <span>No se tiene datos.</span>
    </div>

    <div *ngIf="!isLoading && configuracionAmbienteFinal.length>0" 
    [ngClass.gt-sm]="{'container-gt-sm':true}" 
    [ngClass.lt-sm]="{'container-lt-sm':true}"
    [ngClass.gt-md]="{'container-gt-md':true}" 
    fxLayout="column"
    >     
    <div *ngIf="!isLoading" id="ambMesas">
      <!-- leyenda -->
        <div fxLayout="row wrap" fxLayoutAlign="center center" >
          <span>Leyenda: &nbsp;</span>
          <mat-chip-list aria-label="Fish selection">
            <mat-chip class="chip-solicitado">TB</mat-chip>Min. de Barra
            <mat-chip class="chip-solicitado">TC</mat-chip>Min. de Cocina
            <mat-chip class="chip-solicitado">TUP</mat-chip>Min. de desde el último pedido
          </mat-chip-list>      
        </div>
        <div style="margin-top: 15px; margin-bottom: 15px;">
          <mat-divider></mat-divider>
        </div>
        <!-- resumen -->
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
          <mat-chip-list aria-label="Fish selection">
            <mat-chip class="chip-pendiente-revision">Métricas altas</mat-chip>       
          </mat-chip-list>
          <button mat-stroked-button type="button"
              *ngIf="ambienteMayorTB && ambienteMayorTB.TB"
              matTooltipPosition="above"
              [ngClass]="ambienteMayorTB.mesa_estado == EstadosMesaType.DISPONIBLE ? 'button-after-click' : 'button-before-click'"
              [matTooltip]="(ambienteMayorTB.mesa_estado.replace('_', ' ') | titlecase) + (ambienteMayorTB.usuario_modificacion ? (' | Gestor: '+ ambienteMayorTB.usuario_modificacion | hideMail) : '')"
              [disabled]="
                (ambienteMayorTB.mesa_estado != EstadosMesaType.DISPONIBLE &&    usuario.correo_electronico !== ambienteMayorTB.usuario_modificacion && !esAdministrador) ||
                (ambienteMayorTB.mesa_estado == EstadosMesaType.OCUPADO) ||
                (ambienteMayorTB.mesa_estado == EstadosMesaType.RESERVADO && !esAdministrador)"
                >                  
              <mat-icon>{{Icons.TABLE_BAR}}</mat-icon>
              <span>
                {{ambienteMayorTB.mesa_numero}}&nbsp;
              </span>                  
              (<span *ngIf="ambienteMayorTB.TB!=undefined">TB:{{ambienteMayorTB.TB}}</span>)
          </button>

          <button mat-stroked-button type="button"
              matTooltipPosition="above"
              *ngIf="ambienteMayorTC && ambienteMayorTC.TC"
              [ngClass]="ambienteMayorTC.mesa_estado == EstadosMesaType.DISPONIBLE ? 'button-after-click' : 'button-before-click'"
              [matTooltip]="(ambienteMayorTC.mesa_estado.replace('_', ' ') | titlecase) + (ambienteMayorTC.usuario_modificacion ? (' | Gestor: '+ ambienteMayorTC.usuario_modificacion | hideMail) : '')"
              [disabled]="
                (ambienteMayorTC.mesa_estado != EstadosMesaType.DISPONIBLE &&    usuario.correo_electronico !== ambienteMayorTC.usuario_modificacion && !esAdministrador) ||
                (ambienteMayorTC.mesa_estado == EstadosMesaType.OCUPADO) ||
                (ambienteMayorTC.mesa_estado == EstadosMesaType.RESERVADO && !esAdministrador)"
                >                  
              <mat-icon>{{Icons.TABLE_BAR}}</mat-icon>
              <span>
                {{ambienteMayorTC.mesa_numero}}&nbsp;
              </span>                  
              (<span *ngIf="ambienteMayorTC.TC!=undefined">TC:{{ambienteMayorTC.TC}}</span>)
          </button>

          <button mat-stroked-button type="button"
              matTooltipPosition="above"
              *ngIf="ambienteMayorTUP && ambienteMayorTUP.TUP"
              [ngClass]="ambienteMayorTUP.mesa_estado == EstadosMesaType.DISPONIBLE ? 'button-after-click' : 'button-before-click'"
              [matTooltip]="(ambienteMayorTUP.mesa_estado.replace('_', ' ') | titlecase) + (ambienteMayorTUP.usuario_modificacion ? (' | Gestor: '+ ambienteMayorTUP.usuario_modificacion | hideMail) : '')"
              [disabled]="
                (ambienteMayorTUP.mesa_estado != EstadosMesaType.DISPONIBLE &&    usuario.correo_electronico !== ambienteMayorTUP.usuario_modificacion && !esAdministrador) ||
                (ambienteMayorTUP.mesa_estado == EstadosMesaType.OCUPADO) ||
                (ambienteMayorTUP.mesa_estado == EstadosMesaType.RESERVADO && !esAdministrador)"
                >                  
              <mat-icon>{{Icons.TABLE_BAR}}</mat-icon>
              <span>
                {{ambienteMayorTUP.mesa_numero}}&nbsp;
              </span>                  
              (<span *ngIf="ambienteMayorTUP.TUP!=undefined">TUP:{{ambienteMayorTUP.TUP}}</span>)
          </button>

        </div>
        <div style="margin-top: 15px;">
          <mat-divider></mat-divider>
        </div>
        <!-- listadoMesas         -->
        <div *ngFor="let ambiente of ambientesUnicos">
          <div fxLayout="column" fxLayoutGap="20px" style="margin-top: 10px;">
            <div fxLayout="row">
              <mat-icon>{{Icons.DECK}}</mat-icon>
              <span class="titulo-accordeon">{{ambiente.toUpperCase()}}</span>
            </div>
            <!-- Agregar espacio entre las filas de botones -->
            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px" style="row-gap: 10px; margin-bottom: 5px;">
              <ng-container *ngFor="let mesa of configuracionAmbienteFinal">
                <button mat-stroked-button type="button" *ngIf="mesa.ambiente === ambiente"
                  matTooltipPosition="above"
                  [ngClass]="mesa.mesa_estado == EstadosMesaType.DISPONIBLE ? 'button-after-click' : 'button-before-click'"
                  [matTooltip]="(mesa.mesa_estado.replace('_', ' ') | titlecase) + (mesa.usuario_modificacion ? (' | Gestor: '+ mesa.usuario_modificacion | hideMail) : '')"
                  [disabled]="
                    (mesa.mesa_estado != EstadosMesaType.DISPONIBLE &&    usuario.correo_electronico !== mesa.usuario_modificacion && !esAdministrador) ||
                    (mesa.mesa_estado == EstadosMesaType.OCUPADO) ||
                    (mesa.mesa_estado == EstadosMesaType.RESERVADO && !esAdministrador)"
                    >                  
                  <mat-icon>{{Icons.TABLE_BAR}}</mat-icon>
                  <span>
                    {{mesa.mesa_numero}}&nbsp;
                  </span>                  
                  <span  *ngIf="mesa.lista_productos || mesa.TUP">                    
                    (<span *ngIf="mesa.TB!=undefined">TB:{{mesa.TB}} |</span> <span *ngIf="mesa.TC!=undefined">TC:{{mesa.TC}} |</span> <span *ngIf="mesa.TUP!=undefined">TUP:{{mesa.TUP}}</span>)
                  </span>
                </button>
               
              </ng-container>
            </div>
            <div style="margin-top: 10px; margin-bottom: 10px;">
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
      
    </div>
</div>