import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { ITableEvento } from './ITableEvento';
import { PageEvent } from '@angular/material/paginator';
import { TableEvento } from './TableEvento';

@Injectable({
  providedIn: 'root'
})

export class TableService {
  loadDataTable$ =  new Subject<string>();
  isLoadingDataTable$ = new Subject<ITableEvento>();
  btnNuevo$ =  new Subject();
  btnEditar$ =  new Subject<ITableEvento>();
  btnEditarPedido$ =  new Subject<ITableEvento>();
  btnAgregar$ =  new Subject<ITableEvento>();
  btnEditarActivarPersona$ =  new Subject<ITableEvento>();
  btnEditarInactivarPersona$ =  new Subject<ITableEvento>();
  btnEliminar$ =  new Subject<ITableEvento>();
  btnActivar$ =  new Subject<ITableEvento>();
  btnSuspender$ =  new Subject<ITableEvento>();
  btnVer$ =  new Subject<ITableEvento>();
  btnCerrar$ =  new Subject<ITableEvento>();
  btnAbrir$ =  new Subject<ITableEvento>();
  btnVerImagen$ =  new Subject<ITableEvento>();
  btnImprimir$ =  new Subject<ITableEvento>();
  btnResponderSolicitud$ =  new Subject<ITableEvento>();
  btnGestionarPassword$ =  new Subject<ITableEvento>();
  rowSelect$ = new Subject<ITableEvento>();
  btnPagoTotal$ =  new Subject<ITableEvento>();
  btnPagoParcial$ =  new Subject<ITableEvento>();
  btnPagoTraspasarProducto$ =  new Subject<ITableEvento>();
  btnPagoDividirCuenta$ =  new Subject<ITableEvento>();
  btnAnularComprobante$ = new Subject<ITableEvento>();
  btnEmitirComprobante$ = new Subject<ITableEvento>();
  btnSincronizarComprobante$ = new Subject<ITableEvento>();
  btnReimprimirComprobante$ = new Subject<ITableEvento>();


  constructor() { }
}
